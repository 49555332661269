import { defineMessages } from 'react-intl'

const scope = `procurify.components.Intercom`

export default defineMessages({
  intercomButtonText: {
    id: `${scope}.intercomButtonText`,
    defaultMessage: 'Help',
  },
})
