import * as React from 'react'

import { Redirect, Route, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/core/hoc/ProtectedRoute'
import { useAppRoutes, useFeatureFlag } from '@webapp/hooks'
import { ExpenseStatusPage } from './ExpenseStatusPage'

export const ExpenseRouter = () => {
  const { brp, url } = useAppRoutes()
  const ARCH_150_MIGRATE_EXPENSE_STATUS = useFeatureFlag(
    'ARCH_150_MIGRATE_EXPENSE_STATUS'
  )

  return (
    <Switch>
      {!ARCH_150_MIGRATE_EXPENSE_STATUS ? (
        <Route
          path={brp('ExpenseStatusPage', { status: ':status' })}
          render={() => <Redirect to={'/#/expenses/requests'} />}
        />
      ) : null}
      <ProtectedRoute
        path={brp('ExpenseStatusPage', { status: ':status' })}
        component={ExpenseStatusPage}
        featureFlags={['ARCH_150_MIGRATE_EXPENSE_STATUS']}
      />
      <Route
        path='*'
        render={() => (
          <Redirect to={url('ExpenseStatusPage', { status: 'pending' })} />
        )}
      />
    </Switch>
  )
}
