import { CardStyled } from '@procurify/ui'
import styled from 'styled-components'

import { PunchOutWelcomeBackgroundStyled } from './PunchOutWelcomeBackgroundStyled'

interface IPunchOutWelcomeStyledProps {}

export const PunchOutWelcomeStyled = styled.div<IPunchOutWelcomeStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  ${PunchOutWelcomeBackgroundStyled} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    pointer-events: none;
  }

  ${CardStyled} {
    width: ${(props) => props.theme.typography.pxToRem(800)};
    margin: auto;
  }
`
