import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { type Enum } from '@webapp/utils'
import messages from './messages'

export const UserProfilePageTabs = {
  PROFILE: '',
  PASSWORD: 'password',
  NOTIFICATIONS: 'notifications',
  APPROVER_DELEGATION: 'delegation',
} as const

export type UserProfilePageTab = Enum<typeof UserProfilePageTabs>

export interface UserProfilePageParams {
  tab: UserProfilePageTab
}

const UserAccountChunk = React.lazy(() =>
  import('../containers/UserAccountView').then(({ UserAccountView }) => ({
    default: UserAccountView,
  }))
)

export const UserAccountPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.userPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.userPageTitle),
        },
      ]}
      centered
    >
      <UserAccountChunk />
    </Page>
  )
}
