import * as React from 'react'

import {
  Card,
  EmptyState,
  Feed,
  Header,
  Icon,
  Space,
  TextButton,
  WithBadge,
} from '@procurify/ui'
import { pascalCase } from 'change-case'

import { useAppRoutes, useFeatureFlag, useProcurifyIntl } from '@webapp/hooks'
import { ContractListPageStatuses } from '@webapp/pages/ContractListPage'
import { OrderStatusPageStatuses } from '@webapp/pages/OrderStatusPage'
import { getFullName } from '@webapp/utils/user'
import messages from './messages'
import { InboxListNotificationMetaStyled } from './styles/InboxListNotificationMetaStyled'
import { InboxListStyled } from './styles/InboxListStyled'
import { FormatContentType } from '../../core/hoc/FormatContentType/FormatContentType'
import { type Inbox } from '../../models'
import { InboxNotification } from '../InboxNotification'
import {
  type InboxNotificationType,
  InboxNotificationTypes,
} from '../InboxNotification/types'
import { UserAvatar } from '../UserAvatar'

// tslint:disable-next-line: no-var-requires
const procurifyCompactLogo = require('!svg-url-loader!../../assets/img/procurify-logo-compact.svg')

export interface IInboxListProps {
  notifications: Inbox[]
  onMarkAllAsRead: () => void
  onRead: (notification: Inbox) => void
}

export const InboxList = ({
  notifications,
  onMarkAllAsRead,
  onRead,
}: IInboxListProps) => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  const FT_6224_MIGRATE_ORDER_DETAILS = useFeatureFlag(
    'FT_6224_MIGRATE_ORDER_DETAILS'
  )

  const renderMeta = (notification: Inbox) => {
    return (
      <InboxListNotificationMetaStyled>
        <FormatContentType id={notification.message.content_type}>
          {(contentType) => (
            <a
              href={notification.message.permalink}
              onClick={() => onRead(notification)}
            >
              {pascalCase(contentType.model)} #{notification.message.object_pk}
            </a>
          )}
        </FormatContentType>
      </InboxListNotificationMetaStyled>
    )
  }

  const getNotificationType = (notification: Inbox): InboxNotificationType => {
    switch (true) {
      case notification.metadata?.is_delegation:
        return InboxNotificationTypes.DELEGATION
      case notification.metadata?.is_contract_batch:
        return InboxNotificationTypes.CONTRACT_BATCH
      case !!notification.metadata?.punchout:
        return InboxNotificationTypes.PUNCHOUT
      case notification.message?.is_ping:
        return InboxNotificationTypes.PING
      case notification.metadata?.is_linked_bank_account:
        return InboxNotificationTypes.LINKED_BANK_ACCOUNT
      default:
        return InboxNotificationTypes.DEFAULT
    }
  }

  const isPing = (notification: Inbox) => {
    return getNotificationType(notification) === InboxNotificationTypes.PING
  }

  const isDelegation = (notification: Inbox) => {
    return (
      getNotificationType(notification) === InboxNotificationTypes.DELEGATION
    )
  }

  const renderBullet = (notification: Inbox) => {
    return notification.message.userprofile ? (
      <div className='comment-avatar'>
        <WithBadge
          color='secondary'
          text={
            <Icon
              icon={isPing(notification) ? 'notification' : 'timer'}
              ariaLabel={
                isPing(notification)
                  ? intl.formatMessage(messages.reminderIconLabel)
                  : intl.formatMessage(messages.delegationIconLabel)
              }
            />
          }
          top='65%'
          right='-15%'
          show={isPing(notification) || isDelegation(notification)}
        >
          <UserAvatar
            size='xsmall'
            url={notification.message.userprofile.profile_image}
          />
        </WithBadge>
      </div>
    ) : (
      <div className='logo-bullet'>
        <img
          src={procurifyCompactLogo}
          alt={intl.formatMessage(messages.procurifyLogoAltText)}
        />{' '}
      </div>
    )
  }

  const renderFeedContent = (notification: Inbox) => {
    const notificationType = getNotificationType(notification)
    switch (notificationType) {
      case InboxNotificationTypes.PING:
        return (
          <InboxNotification
            action={intl.formatMessage(messages.reminderActionMessage)}
            onRead={() => onRead(notification)}
            notification={notification}
            metas={renderMeta(notification)}
            isReminder
          />
        )
      case InboxNotificationTypes.DELEGATION:
        return (
          <InboxNotification
            onRead={() => onRead(notification)}
            notification={notification}
            hideLink
            action={intl.formatMessage(messages.delegateActionText, {
              name: getFullName(
                notification.metadata?.delegation?.approval_delegatee
              ),
            })}
          />
        )
      case InboxNotificationTypes.CONTRACT_BATCH:
        return (
          <InboxNotification
            onRead={() => onRead(notification)}
            notification={notification}
            link={url('ContractListPage', {
              status: ContractListPageStatuses.DRAFT,
            })}
          />
        )
      case InboxNotificationTypes.PUNCHOUT:
        return (
          <InboxNotification
            onRead={() => onRead(notification)}
            notification={notification}
            link={
              FT_6224_MIGRATE_ORDER_DETAILS
                ? url('OrderDetailsPage', {
                    uuid: notification.metadata.punchout.order_uuid,
                  })
                : url('OrderStatusPage', {
                    status: OrderStatusPageStatuses.APPROVED,
                  })
            }
          />
        )
      case InboxNotificationTypes.LINKED_BANK_ACCOUNT:
        return (
          <InboxNotification
            onRead={() => onRead(notification)}
            notification={notification}
          />
        )
      default:
        return (
          <InboxNotification
            onRead={() => onRead(notification)}
            notification={notification}
            metas={renderMeta(notification)}
          />
        )
    }
  }

  return (
    <InboxListStyled>
      <Card>
        <Card.Header bgcolor='offwhite'>
          <Space direction='horizontal' justify='space-between'>
            <Header as='h5'>
              {intl.formatMessage(messages.inboxListTitle)}
            </Header>
          </Space>
          <TextButton onClick={onMarkAllAsRead}>
            {intl.formatMessage(messages.markAllAsRead)}
          </TextButton>
        </Card.Header>
        <Card.Content maxHeight={400}>
          {!notifications.length && (
            <EmptyState
              header={intl.formatMessage(messages.emptyStateHeader)}
            />
          )}

          {!!notifications.length && (
            <Feed>
              {notifications.map((notification) => (
                <Feed.Row
                  key={notification.id}
                  bullet={renderBullet(notification)}
                >
                  <Feed.Content>{renderFeedContent(notification)}</Feed.Content>
                </Feed.Row>
              ))}
            </Feed>
          )}
        </Card.Content>
      </Card>
    </InboxListStyled>
  )
}

InboxList.displayName = 'InboxList'
