import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const PaymentFinancialAccountViewChunk = React.lazy(() =>
  import('../containers/PaymentFinancialAccountView').then(
    ({ PaymentFinancialAccountView }) => ({
      default: PaymentFinancialAccountView,
    })
  )
)

export const PaymentFinancialAccountPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.paymentFinancialAccountPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.paymentFinancialAccountPageTitle),
        },
      ]}
    >
      <PaymentFinancialAccountViewChunk />
    </Page>
  )
}
