import { defineMessages } from 'react-intl'

const scope = `procurify.platform.iam.pages`

export default defineMessages({
  settingsTitle: {
    id: `${scope}.settingsTitle`,
    defaultMessage: 'Application settings',
  },
  MFAPreferencesTitle: {
    id: `${scope}.MFAPreferencesTitle`,
    defaultMessage: 'Security and authentication: MFA',
  },
  ManageUsersTitle: {
    id: `${scope}.ManageUsersTitle`,
    defaultMessage: 'Manage users',
  },
})
