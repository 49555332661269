import { BaseButton } from '@procurify/ui'
import { applyButtonColorCSS } from '@procurify/ui'
import styled from 'styled-components'

export const QuickRequestButtonStyled = styled(BaseButton)`
  border-radius: 3em;

  ${(props) =>
    applyButtonColorCSS(
      props.theme.colors.button.request.background,
      undefined,
      props.theme.colors.button.base.dark,
      props.theme.colors.button.request.backgroundHover
    )}
`
