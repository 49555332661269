import { palette } from '@procurify/ui-theme'
import styled from 'styled-components'

import { type ILabelProps } from '../Label'

interface ILabelCaptionStyledProps {
  bgcolor?: ILabelProps['bgcolor']
}

export const LabelCaptionStyled = styled.div<ILabelCaptionStyledProps>`
  min-height: ${(props) => props.theme.typography.pxToRem(30)};

  padding: ${(props) => props.theme.typography.pxToRem(4)}
    ${(props) => props.theme.typography.pxToRem(9)};

  display: flex;
  align-items: center;

  border-radius: ${(props) => props.theme.typography.pxToRem(4)} 0 0
    ${(props) => props.theme.typography.pxToRem(4)};

  background-size: cover;
  background-position: center center;

  ${palette}
`
