import * as React from 'react'

import { type SpacingProps } from '@material-ui/system'

import { BadgeStyled } from './styles/BadgeStyled'

export interface IBadgeProps extends SpacingProps {
  /** Background color of the badge */
  color?: string
}

export class Badge extends React.Component<IBadgeProps> {
  static defaultProps = {}

  render() {
    const { color, ...otherProps } = this.props
    return (
      <BadgeStyled color={color} {...otherProps}>
        {this.props.children}
      </BadgeStyled>
    )
  }
}
