import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import messages from './messages'

export const SPENDING_CARD_CUSTOM_FIELDS_SETTINGS_PAGE =
  '/settings/spending-card-custom-fields'

const SpendingCardCustomFieldsSettingsViewChunk = React.lazy(() =>
  import('../containers/SpendingCardCustomFieldsSettingsView').then(
    ({ SpendingCardCustomFieldsSettingsView }) => ({
      default: SpendingCardCustomFieldsSettingsView,
    })
  )
)

export const SpendingCardCustomFieldsSettingsPage = () => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(
        messages.spendingCardCustomFieldsSettingsPageTitle
      )}
      breadcrumbs={[
        {
          link: url('SettingsPage'),
          name: intl.formatMessage(messages.settingsPageTitle),
        },
        {
          name: intl.formatMessage(
            messages.spendingCardCustomFieldsSettingsPageTitle
          ),
        },
      ]}
    >
      <SpendingCardCustomFieldsSettingsViewChunk />
    </Page>
  )
}
