import { defineMessages } from 'react-intl'

const scope = `procurify.components.SystemPushNotifications`

export default defineMessages({
  vendorPerformanceCompleteTitle: {
    id: `${scope}.vendorPerformanceCompleteTitle`,
    defaultMessage: 'Now available!',
  },
  vendorPerformanceCompleteMessage: {
    id: `${scope}.vendorPerformanceCompleteMessage`,
    defaultMessage: 'Vendor scores have been calculated.',
  },
  batchContractParsedFailedTitle: {
    id: `${scope}.batchContractParsedFailedTitle`,
    defaultMessage: 'Contracts processing failed',
  },
  batchContractParsedFailedMessage: {
    id: `${scope}.batchContractParsedFailedMessage`,
    defaultMessage:
      'We were unable to process the contract files you uploaded. You can review the contract drafts for more details.',
  },
  batchContractParsedPartialSuccessTitle: {
    id: `${scope}.batchContractParsedPartialSuccessTitle`,
    defaultMessage: 'Contracts processing complete',
  },
  backContractParsedPartialSuccessMessage: {
    id: `${scope}.backContractParsedPartialSuccessMessage`,
    defaultMessage:
      'The contract files you uploaded are ready for review, but there were some issues. You can review the contract drafts for more details.',
  },
  batchContractParsedSuccessTitle: {
    id: `${scope}.batchContractParsedSuccessTitle`,
    defaultMessage: 'Contracts processing successful',
  },
  batchContractParsedSuccessMessage: {
    id: `${scope}.batchContractParsedSuccessMessage`,
    defaultMessage:
      'The contract files you uploaded have been processed successfully and are ready for review.',
  },
})
