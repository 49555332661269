import * as React from 'react'

import { sentenceCase } from 'change-case'
import { useSelector } from 'react-redux'
import { type RouteComponentProps } from 'react-router'

import { PunchOutWelcome } from '@webapp/components/PunchOutWelcome'
import { type PunchOutSuppliersViewProps } from '@webapp/containers/PunchOutSuppliersView/PunchOutSuppliersView'
import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { makeSelectFeatureSwitches } from '@webapp/stores/global'
import messages from './messages'

export interface PunchOutSuppliersPageParams {
  supplier: PunchOutSuppliersViewProps['supplier']
}

export interface IPunchOutSuppliersPageProps
  extends RouteComponentProps<{
    supplier?: PunchOutSuppliersPageParams['supplier']
  }> {}

const PunchOutSuppliersViewChunk = React.lazy(() =>
  import('../containers/PunchOutSuppliersView').then(
    ({ PunchOutSuppliersView: PunchOutSupplierListView }) => ({
      default: PunchOutSupplierListView,
    })
  )
)

export const PunchOutSuppliersPage = (props: IPunchOutSuppliersPageProps) => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  const { supplier } = props.match.params

  const featureSwitches = useSelector(makeSelectFeatureSwitches())
  const isEnabled = featureSwitches.punchout

  return (
    <Page
      pageTitle={intl.formatMessage(messages.punchOutSupplierListPageTitle)}
      breadcrumbs={[
        {
          link: url('SettingsPage'),
          name: intl.formatMessage(messages.settingsPageTitle),
        },
        {
          link: supplier ? url('PunchOutSuppliersPage', { supplier: '' }) : '',
          name: intl.formatMessage(messages.punchOutSupplierListPageTitle),
        },
        ...(supplier ? [{ name: sentenceCase(supplier) }] : []),
      ]}
    >
      {isEnabled ? (
        <PunchOutSuppliersViewChunk supplier={supplier} />
      ) : (
        <PunchOutWelcome />
      )}
    </Page>
  )
}
