import produce from 'immer'

import { UPDATE_DEV_HAS_FF_OVERRIDE, UPDATE_LIVE_FF_STATE } from './constants'
import { type DevActions, type IDevState } from './types'

export const initialState: IDevState = {
  hasFFOverride: false,
  liveFlags: {},
}

export const reducer = (state = initialState, action: DevActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_DEV_HAS_FF_OVERRIDE:
        draft.hasFFOverride = action.override
        break
      case UPDATE_LIVE_FF_STATE:
        draft.liveFlags = action.flags
        break
    }
    return draft
  })
