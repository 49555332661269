import { rem } from 'polished'
import styled, { css } from 'styled-components'

import { FeedRowStyled } from './FeedRowStyled'
import { type IFeedProps } from '../Feed'

export const FeedStyled = styled.ul<Partial<IFeedProps>>`
  font-size: ${rem(16)};

  ${FeedRowStyled} + ${FeedRowStyled} {
    margin-top: ${rem(12)};
  }

  ${(props) => {
    if (props.ordered) {
      return css`
        list-style: decimal;
      `
    }

    if (props.disc) {
      return css`
        list-style: disc;
        padding-left: ${rem(16)};
      `
    }
  }}
`
