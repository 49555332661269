import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { ContractListPageStatuses } from './ContractListPage'
import messages from './messages'

export interface ContractDetailsPageParams {
  uuid: string
}

const ContractDetailsViewChunk = React.lazy(() =>
  import('../containers/ContractDetailsView').then(
    ({ ContractDetailsView }) => ({
      default: ContractDetailsView,
    })
  )
)

export const ContractDetailsPage = () => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.contractDetailsTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.contractListTitle),
          link: url('ContractListPage', {
            status: ContractListPageStatuses.ACTIVE,
          }),
        },
        {
          name: intl.formatMessage(messages.contractDetailsTitle),
        },
      ]}
    >
      <ContractDetailsViewChunk />
    </Page>
  )
}
