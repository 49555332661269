import * as React from 'react'

import { type TableCellProps } from '@material-ui/core/TableCell'

import { TableCellStyled } from './styles/TableCellStyled'
import { Icon } from '../../elements/Icon'

export interface ITableCellProps extends TableCellProps {
  orderBy?: string
  width?: React.CSSProperties['width']
  minWidth?: React.CSSProperties['minWidth']
  stickyRight?: boolean
  nowrap?: boolean
  onClick?(e: React.MouseEvent<HTMLElement>): void
}

export class TableCell extends React.Component<ITableCellProps> {
  static defaultProps = {
    orderBy: null,
    width: 'auto',
  }

  render() {
    const { onClick, orderBy, ...otherProps } = this.props
    return (
      <TableCellStyled className='table-cell' onClick={onClick} {...otherProps}>
        {this.props.children}
        {orderBy && (
          <Icon
            className='table-cell-order-by-icon'
            style={{ float: 'right' }}
            icon={orderBy === 'asc' ? 'chevron-thick-down' : 'chevron-thick-up'}
          />
        )}
      </TableCellStyled>
    )
  }
}
