import { ApiClient } from '../services/api'

export const ENDPOINT = '/api/v3/users/me/notification-count/'

export const NOTIFICATION_COUNT_URL = ENDPOINT

export class NotificationsAPI {
  static getNotificationsCount = () => {
    return ApiClient.get(NOTIFICATION_COUNT_URL, {
      params: {
        pending_approval: 'True',
        pending_payment: 'True',
        procure: 'True',
        receive: 'True',
      },
    })
  }
}
