import { defineMessages } from 'react-intl'

const scope = `procurify.expenses.ExpenseStatusPage`

export default defineMessages({
  pageTitle: {
    id: `${scope}.pageTitle`,
    defaultMessage: 'Expense Status',
  },
})
