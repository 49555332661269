import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { type Enum } from '@webapp/utils'
import messages from './messages'

export const PaymentPayBillsPageStatuses = {
  TO_BE_PAID: 'to-be-paid',
  DRAFT: 'draft',
} as const
export type PaymentPayBillsPageStatus = Enum<typeof PaymentPayBillsPageStatuses>

export interface PaymentPayBillsPageParams {
  status: PaymentPayBillsPageStatus
}

const PaymentPayBillsViewChunk = React.lazy(() =>
  import('../containers/PaymentPayBillsView').then(
    ({ PaymentPayBillsView }) => ({
      default: PaymentPayBillsView,
    })
  )
)

export const PaymentPayBillsPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.paymentPayBillsPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.paymentPayBillsPageTitle),
        },
      ]}
    >
      <PaymentPayBillsViewChunk />
    </Page>
  )
}
