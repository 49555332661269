import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { type Enum } from '@webapp/utils'
import messages from './messages'

export const PaymentApprovalListPageStatuses = {
  PENDING: 'pending',
  APPROVED: 'approved',
} as const
export type PaymentApprovalListPageStatus = Enum<
  typeof PaymentApprovalListPageStatuses
>

export interface PaymentApprovalListPageParams {
  status: PaymentApprovalListPageStatus
}

const PaymentApprovalListViewChunk = React.lazy(() =>
  import('../containers/PaymentApprovalListView').then(
    ({ PaymentApprovalListView }) => ({
      default: PaymentApprovalListView,
    })
  )
)

export const PaymentApprovalListPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.paymentApprovalListPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.paymentApprovalListPageTitle),
        },
      ]}
    >
      <PaymentApprovalListViewChunk />
    </Page>
  )
}
