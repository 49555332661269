export const IMAGE_TYPES = ['png', 'jpeg', 'jpg', 'gif']
export const SUPPORTED_TYPES = {
  doc: {
    bgcolor: 'bg-color-word',
    icon: 'icon-folder fa fa-file-word-o',
    textcolor: 'text-color-white',
  },
  docx: {
    bgcolor: 'bg-color-word',
    icon: 'icon-folder fa fa-file-word-o',
    textcolor: 'text-color-white',
  },
  pdf: {
    bgcolor: 'bg-color-pdf',
    icon: 'icon-folder fa fa-file-pdf-o',
    textcolor: 'text-color-white',
  },
  rar: {
    icon: 'icon-folder fa fa-file-archive-o',
  },
  xls: {
    bgcolor: 'bg-color-excel',
    icon: 'icon-folder fa fa-file-excel-o',
    textcolor: 'text-color-white',
  },
  xlsx: {
    bgcolor: 'bg-color-excel',
    icon: 'icon-folder fa fa-file-excel-o',
    textcolor: 'text-color-white',
  },
  zip: {
    bgcolor: 'bg-color-zip',
    icon: 'icon-folder fa fa-file-archive-o',
    textcolor: 'text-color-white',
  },
}

export default class MediaHelper {
  public static isSupportedType(fileType) {
    return SUPPORTED_TYPES[fileType]
  }

  public static getMimeType(mimetype) {
    if (!mimetype) {
      return null
    }

    return mimetype.split('/')[1]
  }

  /**
   * Returns the file extension in lowercase excluding the "." delimiter and
   * without white spaces.
   */
  public static getFileExtension(filename: string): string {
    return /\./.test(filename)
      ? filename.split('.').pop().trim().toLowerCase()
      : ''
  }

  public static isPdfMimetype(mimetype: string) {
    return mimetype === 'application/pdf'
  }

  public static isImageMimetype(mimetype: string) {
    return mimetype && mimetype.startsWith('image/')
  }

  public static isPdfFilename(source: string) {
    return MediaHelper.getFileExtension(source) === 'pdf'
  }

  public static isImageFilename(source: string) {
    return IMAGE_TYPES.includes(
      MediaHelper.getFileExtension(source).toLowerCase()
    )
  }

  public static createImage(src) {
    return new Promise((resolve, reject) => {
      if (!src) return reject()

      const image = new Image()
      image.onload = () => {
        resolve(image)
      }
      image.onerror = () => {
        reject()
      }
      image.src = src
    })
  }
}
