import * as React from 'react'

import { TransitionGroup } from 'react-transition-group'

import { NotificationWrapperStyled } from './styles/NotificationWrapperStyled'

export interface INotificationWrapperProps {
  offsetTop?: React.CSSProperties['top']
}

export const NotificationWrapper = ({
  offsetTop = 0,
  children,
  ...otherProps
}: React.PropsWithChildren<INotificationWrapperProps>) => {
  return (
    <NotificationWrapperStyled offsetTop={offsetTop} {...otherProps}>
      <TransitionGroup component={null}>{children}</TransitionGroup>
    </NotificationWrapperStyled>
  )
}
