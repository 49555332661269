import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { type PayFund } from '@webapp/models/PayFund'
import messages from './messages'
import { SpendingCardPhysicalCardTabs } from './SpendingCardPhysicalCardPage'

export interface SpendingCardFundDetailsPageParams {
  uuid: PayFund['uuid']
}

/**
 * TODO: to be replaced with new design - not priority for MVC FT_260_MULTIPLE_VIRTUAL_CARDS
 */
// const SpendingCardFundDetailsViewChunk = React.lazy(() =>
//   import('../containers/SpendingCardFundDetailsView').then(
//     ({ SpendingCardFundDetailsView }) => ({
//       default: SpendingCardFundDetailsView,
//     })
//   )
// )
const SpendingCardFundDetailsViewChunk = React.lazy(() =>
  import('../containers/SpendingCardFundDetailsViewLegacy').then(
    ({ SpendingCardFundDetailsViewLegacy }) => ({
      default: SpendingCardFundDetailsViewLegacy,
    })
  )
)

export const SpendingCardFundDetailsPage = (
  props: RouteComponentProps<SpendingCardFundDetailsPageParams>
) => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.spendingCardFundDetailsPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.spendingCardPhysicalCardPageTitle),
          link: url('SpendingCardPhysicalCardPage', {
            tab: SpendingCardPhysicalCardTabs.OPEN,
          }),
        },
        {
          name: intl.formatMessage(messages.spendingCardFundDetailsPageTitle),
        },
      ]}
    >
      <SpendingCardFundDetailsViewChunk />
    </Page>
  )
}
