import { defineMessages } from 'react-intl'

const scope = `procurify.components.RedirectView`

export default defineMessages({
  defaultRedirectingMessage: {
    id: `${scope}.defaultRedirectingMessage`,
    defaultMessage: 'Redirecting...',
  },
})
