import { rem } from 'polished'
import styled from 'styled-components'

export const FeedRowStyled = styled.li`
  font-size: ${rem(16)};

  > div {
    grid-template-columns: auto 1fr;
    display: grid;
  }
`
