import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { type PayCard } from '@webapp/models'
import { type PayRequest } from '@webapp/models/PayRequest'
import { SpendingCardVirtualCardsPageTabs } from '@webapp/pages/SpendingCardVirtualCardsPage'
import messages from './messages'

export interface SpendingCardVirtualCardTopUpDetailsPageParams {
  card: PayCard['external_id']
  uuid: PayRequest['uuid']
}

const SpendingCardVirtualCardTopUpDetailsPageViewChunk = React.lazy(() =>
  import('../containers/SpendingCardRequestDetailsView').then(
    ({ SpendingCardRequestDetailsView }) => ({
      default: SpendingCardRequestDetailsView,
    })
  )
)

export const SpendingCardVirtualCardTopUpDetailsPage = (
  props: RouteComponentProps<SpendingCardVirtualCardTopUpDetailsPageParams>
) => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  const { card } = props.match.params

  return (
    <Page
      pageTitle={intl.formatMessage(
        messages.spendingCardVirtualCardTopUpDetailsPageTitle
      )}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.spendingCardVirtualCardsPageTitle),
          link: url('SpendingCardVirtualCardsPage', {
            tab: SpendingCardVirtualCardsPageTabs.ACTIVE,
          }),
        },
        {
          name: intl.formatMessage(
            messages.spendingCardVirtualCardDetailsPageTitle
          ),
          link: url('SpendingCardVirtualCardDetailsPage', {
            uuid: card,
          }),
        },
        {
          name: intl.formatMessage(
            messages.spendingCardVirtualCardTopUpDetailsPageTitle
          ),
        },
      ]}
    >
      <SpendingCardVirtualCardTopUpDetailsPageViewChunk {...props} />
    </Page>
  )
}
