import moment from 'moment'
import { type FormatDateOptions, useIntl } from 'react-intl'

import { type IDateProps } from './FormattedDate'

export function useFormatDate() {
  const intl = useIntl()

  return (
    date: IDateProps['date'] = new Date(),
    formatDateOpts: FormatDateOptions = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    }
  ) => {
    return intl.formatDate(moment(date).toDate(), formatDateOpts)
  }
}
