import { spacing, type SpacingProps } from '@material-ui/system'
import styled, { css } from 'styled-components'

import { BaseButtonStyled } from '@ui/elements/Button/styles/BaseButtonStyled'

export const InputGroupAttachmentStyled = styled.div<SpacingProps>`
  display: flex;
  flex-shrink: 1;
  align-items: center;
  /* stylelint-disable-next-line */
  margin-right: -1px;

  ${spacing}

  ${BaseButtonStyled} {
    box-shadow: none;
  }
`
