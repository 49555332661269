import * as React from 'react'

import { sentenceCase } from 'change-case'
import { type RouteComponentProps } from 'react-router'

import { type IPunchOutGroupViewProps } from '@webapp/containers/PunchOutGroupView/PunchOutGroupView'
import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import messages from './messages'

export interface PunchOutGroupPageParams {
  supplier: IPunchOutGroupViewProps['supplier']
  group: string
}

export interface IPunchOutGroupPageProps
  extends RouteComponentProps<{
    supplier: PunchOutGroupPageParams['supplier']
    group: PunchOutGroupPageParams['group']
  }> {}

const PunchOutGroupViewChunk = React.lazy(() =>
  import('../containers/PunchOutGroupView').then(({ PunchOutGroupView }) => ({
    default: PunchOutGroupView,
  }))
)

export const PunchOutGroupPage = (props: IPunchOutGroupPageProps) => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  const { supplier, group } = props.match.params

  return (
    <Page
      pageTitle={intl.formatMessage(messages.punchOutSupplierListPageTitle)}
      breadcrumbs={[
        {
          link: url('SettingsPage'),
          name: intl.formatMessage(messages.settingsPageTitle),
        },
        {
          link: url('PunchOutSuppliersPage', { supplier: '' }),
          name: intl.formatMessage(messages.punchOutSupplierListPageTitle),
        },
        { name: sentenceCase(supplier) },
      ]}
    >
      <PunchOutGroupViewChunk supplier={supplier} group={group} />
    </Page>
  )
}
