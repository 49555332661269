import * as React from 'react'

import Helmet from 'react-helmet'
import { type RouteComponentProps } from 'react-router'

const CatalogChunk = React.lazy(() =>
  import('../containers/Catalog').then(({ Catalog }) => ({
    default: Catalog,
  }))
)

export class CatalogPage extends React.PureComponent<RouteComponentProps> {
  render() {
    return (
      <div>
        <Helmet>
          <title>Procurify | Catalog</title>
        </Helmet>

        <React.Suspense fallback={null}>
          <CatalogChunk />
        </React.Suspense>
      </div>
    )
  }
}
