import * as React from 'react'

import {
  EmptyState,
  Flex,
  Spacer,
  SubText,
  Table,
  Text,
  TransparentIconButton,
} from '@procurify/ui'
import { PrimaryButton } from '@procurify/ui'
import { useIntl } from 'react-intl'

import { TableLoader } from '@webapp/components/TableLoader'
import { UserAvatar } from '@webapp/components/UserAvatar'
import { UserLabel } from '@webapp/components/UserLabel'
import { FormattedEnhancedMessage } from '@webapp/core/hoc/FormattedEnhancedMessage'
import { type WatchlistUser } from '@webapp/models'
import { getFullName } from '@webapp/utils/user'
import messages from './messages'
import { ApproverOverwatchUserWatchListTableStyled } from './styles/ApproverOverwatchUserWatchListTableStyled'

export interface IApproverOverwatchUserWatchListTableProps {
  approver?: WatchlistUser
  watchlist?: WatchlistUser[]
  onUnsubscribe?: (approver: WatchlistUser) => void
  onOpenManageList?: () => void
  isFetching?: boolean
  unsubscribingApproverID?: number
}

export const ApproverOverwatchUserWatchListTable: React.FC<IApproverOverwatchUserWatchListTableProps> =
  React.memo((props) => {
    const {
      approver,
      watchlist = [],
      onOpenManageList = () => undefined,
      onUnsubscribe = () => undefined,
      isFetching,
      unsubscribingApproverID,
    } = props

    const intl = useIntl()

    return approver ? (
      <ApproverOverwatchUserWatchListTableStyled>
        <Flex.Row
          spacing={0}
          flexDirection='column'
          flexWrap='nowrap'
          height='100%'
        >
          <Flex.Item>
            <div className='selected-user-info'>
              <Flex spacing={0.5}>
                <Flex.Row spacing={1} alignItems='center'>
                  <Flex.Item>
                    <UserAvatar
                      circular
                      url={approver.profile_image}
                      shadow={false}
                      size='small'
                    />
                  </Flex.Item>
                  <Flex.Item flexGrow={1}>
                    <Text bold>{getFullName(approver)}</Text>
                    <Spacer space={0} />
                    <SubText>
                      <FormattedEnhancedMessage
                        {...messages.approverIsWatchingDescription}
                        values={{ numApprovers: watchlist.length }}
                      />
                    </SubText>
                  </Flex.Item>
                  <Flex.Item>
                    <PrimaryButton
                      onClick={onOpenManageList}
                      disabled={isFetching}
                    >
                      {intl.formatMessage(messages.manageListButtonLabel)}
                    </PrimaryButton>
                  </Flex.Item>
                </Flex.Row>
              </Flex>
            </div>
          </Flex.Item>

          <Flex.Item flexGrow={1}>
            <div className='table-scroller'>
              <Table aria-label={intl.formatMessage(messages.tableAriaLabel)}>
                <Table.Head>
                  <Table.Row>
                    <Table.Cell colSpan={2}>
                      {intl.formatMessage(messages.tableHeaderDescription)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Head>

                {isFetching && (
                  <TableLoader rows={5} widths={['100%', 'button']} />
                )}

                {!isFetching && watchlist.length > 0 && (
                  <Table.Body hoverable={false}>
                    {watchlist.map((user) => (
                      <Table.Row key={user.id}>
                        <Table.Cell>
                          <UserLabel user={user} />
                        </Table.Cell>
                        <Table.Cell width={1}>
                          <TransparentIconButton
                            isLoading={user.id === unsubscribingApproverID}
                            icon='times'
                            onClick={() => onUnsubscribe(user)}
                            aria-label={intl.formatMessage(
                              messages.unsubscribeButtonLabel,
                              {
                                user: user.name,
                              }
                            )}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                )}

                {!isFetching && watchlist.length === 0 && (
                  <Table.Body hoverable={false}>
                    <Table.Row>
                      <Table.Cell colSpan={2}>
                        <EmptyState
                          content={intl.formatMessage(messages.emptyStateText)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                )}
              </Table>
            </div>
          </Flex.Item>
        </Flex.Row>
      </ApproverOverwatchUserWatchListTableStyled>
    ) : null
  })

ApproverOverwatchUserWatchListTable.defaultProps = {
  watchlist: [],
  onOpenManageList: () => undefined,
  onUnsubscribe: () => undefined,
}

ApproverOverwatchUserWatchListTable.displayName =
  'ApproverOverwatchUserWatchListTable'
