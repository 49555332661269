import * as React from 'react'

import { NotificationWrapper } from '@procurify/ui'
import { cssTransition, ToastContainer } from 'react-toastify'
import { useTheme } from 'styled-components'

const Zoop = cssTransition({
  enter: 'zoopIn',
  exit: 'zoopOut',
  duration: 250,
})

export const ToastContainerWrapper: React.ComponentType = () => {
  const uiTheme = useTheme()

  return (
    <NotificationWrapper offsetTop={uiTheme.appHeader.height}>
      <ToastContainer
        hideProgressBar={true}
        closeButton={false}
        transition={Zoop}
        draggable={false}
      />
    </NotificationWrapper>
  )
}
