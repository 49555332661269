import styled from 'styled-components'

import { CardContentStyled } from '@ui/elements/Card'

export const ModalCardContentStyled = styled(CardContentStyled)`
  font-size: inherit;

  padding-top: ${(props) => props.theme.typography.pxToRem(40)};
`
ModalCardContentStyled.displayName = 'ModalCardContentStyled'
