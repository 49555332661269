import * as React from 'react'

import { useFeatureFlag } from '@webapp/hooks'
import { type SegmentAnalyticsMetadata, type User } from '@webapp/models'
import { injectSegment } from './utils'

export interface IGlobalAnalyticsProps {
  user: User
  segmentAnalyticsMetadata: SegmentAnalyticsMetadata
}

export const GlobalAnalytics: React.FC<IGlobalAnalyticsProps> = React.memo(
  ({ user, segmentAnalyticsMetadata }: IGlobalAnalyticsProps) => {
    if (!user) return null

    injectSegment()

    const SEGMENT_USE_UNIQUE_ID = useFeatureFlag('SEGMENT_USE_UNIQUE_ID')

    const analytics = window?.analytics

    React.useEffect(() => {
      if (SEGMENT_USE_UNIQUE_ID) {
        analytics?.identify(
          segmentAnalyticsMetadata.user.metadata.unique_id,
          segmentAnalyticsMetadata.user.metadata
        )
      } else {
        analytics?.identify(
          segmentAnalyticsMetadata.user.id,
          segmentAnalyticsMetadata.user.metadata
        )
      }

      analytics?.group(
        segmentAnalyticsMetadata.group.id,
        segmentAnalyticsMetadata.group.metadata
      )
    }, [])

    return null
  }
)

GlobalAnalytics.displayName = 'GlobalAnalytics'
