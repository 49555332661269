import { genericApi } from '@webapp/config/api'
import { type IntercomUserHash } from '../models'

export const ENDPOINT = '/authentication/'
export const AUTHENTICATION_URL = ENDPOINT

export const IntercomAPI = {
  getIntercomUserHash: () =>
    genericApi.get<IntercomUserHash>(`${AUTHENTICATION_URL}intercom/`),
} as const
