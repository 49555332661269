import type * as React from 'react'

import { useFormatMedia } from '@webapp/core/hooks/useFormatMedia'

export interface IFormatMedia {
  url: string
  placeholder?: string
  children: (url: string) => JSX.Element
}

export const FormatMedia: React.FC<IFormatMedia> = ({
  children,
  url,
  placeholder,
}) => {
  const formatMedia = useFormatMedia()

  const finalMediaUrl = formatMedia(url, placeholder)

  return children(finalMediaUrl)
}
