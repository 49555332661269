import styled from 'styled-components'

import { type IPageProps } from '../Page'

interface IPageStyledProps {
  centered?: IPageProps['centered']
}

export const PageStyled = styled.div<IPageStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  ${(props) =>
    props.centered
      ? `
  max-width: ${props.theme.typography.pxToRem(props.theme.content.widthCentered)};
  margin: 0 auto;
  `
      : ``}
`
