import * as React from 'react'

import { Select } from '@procurify/ui'
import { useSelector } from 'react-redux'

import { useProcurifyIntl } from '@webapp/hooks'
import { makeSelectUserProcurifyDomains } from '@webapp/stores/global'
import { type ProcurifyDomain } from '../../../models'
import messages from '../messages'
import { AppHeaderSelectStyled } from '../styles/AppHeaderSelectStyled'

const goToSubdomain = (subdomain) => {
  const currentSubdomain = window.location.hostname.split('.')[0]
  window.location.replace(
    window.location.origin.replace(currentSubdomain, subdomain)
  )
}

const onDomainSelect = (domain) => {
  goToSubdomain(domain.subdomain)
}

export const AppHeaderDomainSwitch = () => {
  const intl = useProcurifyIntl()
  const procurifyDomains = useSelector<any, ProcurifyDomain[]>(
    makeSelectUserProcurifyDomains()
  )

  return (
    <AppHeaderSelectStyled>
      <Select
        placeholder={intl.formatMessage(messages.domainSwitchPlaceholderText)}
        options={procurifyDomains}
        getOptionValue={(option) => option.subdomain}
        getOptionLabel={(option) => option.name}
        onChange={onDomainSelect}
      />
    </AppHeaderSelectStyled>
  )
}
