import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { type Enum } from '@webapp/utils'
import messages from './messages'

export const SpendingCardTransactionListPageTabs = {
  SUCCESSFUL: 'successful',
  DECLINED: 'declined',
} as const

export type SpendingCardTransactionListPageTab = Enum<
  typeof SpendingCardTransactionListPageTabs
>

export interface SpendingCardTransactionListPageParams {
  tab?: SpendingCardTransactionListPageTab
}

const SpendingCardTransactionListViewChunk = React.lazy(() =>
  import('../containers/SpendingCardTransactionListView').then(
    ({ SpendingCardTransactionListView }) => ({
      default: SpendingCardTransactionListView,
    })
  )
)

export const SpendingCardTransactionListPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.spendingCardTransactionsPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.spendingCardTransactionsPageTitle),
        },
      ]}
    >
      <SpendingCardTransactionListViewChunk />
    </Page>
  )
}
