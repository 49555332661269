import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { type PayRequestApproval } from '@webapp/models/PayRequest'
import messages from './messages'
import { SpendingCardPhysicalCardTabs } from './SpendingCardPhysicalCardPage'

export interface SpendingCardFundRequestDetailsPageParams {
  uuid: PayRequestApproval['uuid']
}

/**
 * TODO: to be replaced with new design - not priority for MVC FT_260_MULTIPLE_VIRTUAL_CARDS
 */
// const SpendingCardFundRequestDetailsViewChunk = React.lazy(() =>
//   import('../containers/SpendingCardFundRequestDetailsView').then(
//     ({ SpendingCardFundRequestDetailsView }) => ({
//       default: SpendingCardFundRequestDetailsView,
//     })
//   )
// )
const SpendingCardFundRequestDetailsViewChunk = React.lazy(() =>
  import('../containers/SpendingCardRequestDetailsView').then(
    ({ SpendingCardRequestDetailsView }) => ({
      default: SpendingCardRequestDetailsView,
    })
  )
)

export const SpendingCardFundRequestDetailsPage = (
  props: RouteComponentProps<SpendingCardFundRequestDetailsPageParams>
) => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(
        messages.spendingCardFundRequestDetailsPageTitle
      )}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.spendingCardPhysicalCardPageTitle),
          link: url('SpendingCardPhysicalCardPage', {
            tab: SpendingCardPhysicalCardTabs.PENDING,
          }),
        },
        {
          name: intl.formatMessage(
            messages.spendingCardFundRequestDetailsPageTitle
          ),
        },
      ]}
    >
      <SpendingCardFundRequestDetailsViewChunk {...props} />
    </Page>
  )
}
