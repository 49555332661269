import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

const DevelopmentChunk = React.lazy(() =>
  import(
    /* webpackChunkName: 'Development' */ '../containers/__DEVELOPMENT__/Development'
  ).then(({ Development }) => ({ default: Development }))
)

export class DevelopmentPage extends React.PureComponent<RouteComponentProps> {
  render() {
    return (
      <React.Suspense fallback={null}>
        <DevelopmentChunk {...this.props} />
      </React.Suspense>
    )
  }
}
