import * as React from 'react'

import { useAuthentication } from '@procurify/authentication'
import { useDispatch } from 'react-redux'

import {
  FEATURE_FLAG_DEFAULTS,
  FeatureFlagService,
} from '@webapp/core/services/FeatureFlag'
import {
  formatDataFromGlobalResponse,
  getLocaleFromGlobalResponse,
} from './utils'
import { CustomFieldAPI } from '../../../api/CustomFieldAPI'
import {
  type RenderedBy,
  RENDERED_BY,
  updateFlags,
  updateGlobal,
  updateOrderCustomfield,
  updateOrderItemCustomfield,
} from '../../../stores/global'
import { updateLocale } from '../../../stores/i18n'

export interface IApplicationContextProviderProps {
  renderedBy?: RenderedBy
}

export const ApplicationContextProvider = React.memo(
  ({
    renderedBy = RENDERED_BY.REACT,
    children,
  }: React.PropsWithChildren<IApplicationContextProviderProps>) => {
    const { isLoading, isAuthenticated, userGlobalData } = useAuthentication()
    const dispatch = useDispatch()

    React.useEffect(() => {
      if (isLoading || !isAuthenticated) return
      dispatch(
        updateGlobal({
          ...formatDataFromGlobalResponse(userGlobalData),
          renderedBy,
        })
      )

      dispatch(updateLocale(getLocaleFromGlobalResponse(userGlobalData)))

      const initFeatureFlagServicePromise = FeatureFlagService.initialize({
        clientId: userGlobalData?.sdk_keys?.LAUNCHDARKLY_CLIENT_ID,
        user: userGlobalData?.user_profile,
        domain: userGlobalData?.domain,
      })

      // Cache Order CustomFields
      const orderCustomFieldPromise =
        CustomFieldAPI.getOrderCustomfields().then((res) => res.data)
      const orderItemCustomFieldPromise =
        CustomFieldAPI.getOrderItemCustomfields().then((res) => res.data)

      Promise.all([
        initFeatureFlagServicePromise,
        orderCustomFieldPromise,
        orderItemCustomFieldPromise,
      ])
        .then(
          ([featureFlagService, orderCustomFields, orderItemCustomFields]) => {
            dispatch(updateOrderCustomfield(orderCustomFields))
            dispatch(updateOrderItemCustomfield(orderItemCustomFields))

            if (featureFlagService.initialized) {
              dispatch(updateFlags(featureFlagService.getAllFlags()))
            }
          }
        )
        .catch(() => {
          dispatch(
            updateFlags({
              ...FEATURE_FLAG_DEFAULTS,
            })
          )
        })
    }, [isLoading, isAuthenticated, userGlobalData])

    return <>{children}</>
  }
)
