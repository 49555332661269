import { defineMessages } from 'react-intl'

const scope = `procurify.components.InboxNotification`

export default defineMessages({
  markAsRead: {
    id: `${scope}.markAsRead`,
    defaultMessage: 'Mark as read',
  },
  goTo: {
    id: `${scope}.goTo`,
    defaultMessage: 'Go to',
  },
})
