import { MediaStyled } from '@procurify/ui'
import styled from 'styled-components'

interface IAppHeaderUserStyledProps {}

export const AppHeaderUserStyled = styled.div<IAppHeaderUserStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  ${MediaStyled} {
    box-sizing: border-box;

    width: ${(props) => props.theme.typography.pxToRem(36)};
    min-width: ${(props) => props.theme.typography.pxToRem(36)};
    height: ${(props) => props.theme.typography.pxToRem(36)};
  }
`
