import { defineMessages } from 'react-intl'

const scope = `procurify.components.InboxList`

export default defineMessages({
  delegateActionText: {
    id: `${scope}.delegateActionText`,
    defaultMessage: 'delegated approvals to <strong>{name}</strong>',
  },
  markAllAsRead: {
    id: `${scope}.markAllAsRead`,
    defaultMessage: 'Mark all as read',
  },
  inboxListTitle: {
    id: `${scope}.inboxListTitle`,
    defaultMessage: 'Notification Inbox',
  },
  emptyStateHeader: {
    id: `${scope}. emptyStateHeader`,
    defaultMessage: 'No new notifications',
  },
  reminderActionMessage: {
    id: `${scope}.reminderActionMessage`,
    defaultMessage: 'sent you a reminder',
  },
  delegationIconLabel: {
    id: `${scope}.delegationIconLabel`,
    defaultMessage: 'Delegated',
  },
  reminderIconLabel: {
    id: `${scope}.reminderIconLabel`,
    defaultMessage: 'Reminder',
  },
  procurifyLogoAltText: {
    id: `${scope}.procurifyLogoAltText`,
    defaultMessage: 'Procurify logo',
  },
})
