import { type FeatureFlags } from '@webapp/core/services/FeatureFlag'
import {
  type FeatureSwitches,
  type NavigationPermissions,
  type SetupProgress,
} from '@webapp/models'
import { type Enum } from '@webapp/utils'

export const TagTypes = {
  BETA: 'beta',
  NEW: 'new',
} as const

export type TagType = 'beta' | 'new'

export const NavigationSections = {
  DASHBOARD: 'dashboard',
  PROCURE: 'procure',
  SPEND: 'spend',
  MANAGE: 'manage',
  SETTINGS: 'settings',
  WHATS_NEW: 'whats_new__procurify_payments',
} as const
export type NavigationSection = Enum<typeof NavigationSections>

export const NavigationHeaders = {
  REQUEST: 'request',
  APPROVE: 'approval',
  PROCURE: 'procure',
  RECEIVE: 'receive',
  SPENDING_CARD: 'spending_card',
  PAYMENT_LEGACY: 'payment',
  ACCOUNTING: 'ap',
  REPORTS: 'report',
  DATA_AND_INSIGHTS: 'data_and_insights',
} as const
export type NavigationHeader = Enum<typeof NavigationHeaders>

export const NavigationSubheaders = {
  AP_BILLS: 'bills',
  AP_CREDIT_CARDS: 'credit_cards',
  AP_PAYMENT_LEGACY: 'payment',
  AP_PAYMENT: 'payments',
  AP_SPEND_REPORTS: 'spend',
} as const
export type NavigationSubheader = Enum<typeof NavigationSubheaders>

export interface INavigationSub {
  title?: string
  url?: string
  notificationKey?: string
  legacy?: boolean
  active?: boolean
  id?: NavigationSubheader | NavigationPermissions
  isSubheader?: boolean
  links?: NavigationPermissions[]
  condition?: (context: {
    featureFlags: Partial<FeatureFlags> & Partial<FeatureSwitches>
    setupProgress: SetupProgress
  }) => boolean
  matchUrls?: string[]
  exactMatch?: boolean
  tag?: TagType
}

export interface INavigationItem {
  title?: string
  icon?: React.ElementType
  tag?: TagType
  url?: string
  legacy?: boolean
  notificationKey?: string
  children?: INavigationSub[]
  id?: NavigationHeader | NavigationPermissions
  condition?: (context: {
    featureFlags: Partial<FeatureFlags> & Partial<FeatureSwitches>
    setupProgress: SetupProgress
  }) => boolean
  matchUrls?: string[]
  exactMatch?: boolean
}
