import * as React from 'react'

import { AsyncSelect, type IAsyncSelectProps, SubText } from '@procurify/ui'
import { useSelector } from 'react-redux'

import { Auth0AuthenticationAPI } from '@webapp/api/Auth0AuthenticationAPI'
import { useProcurifyIntl } from '@webapp/hooks'
import { type Auth0UserImpersonationOption, User } from '@webapp/models'
import { makeSelectUser } from '@webapp/stores/global'
import { getFullName } from '@webapp/utils/user'
import messages from '../messages'
import { AppHeaderSelectStyled } from '../styles/AppHeaderSelectStyled'

const loadOptions: IAsyncSelectProps<Auth0UserImpersonationOption>['loadOptions'] =
  (inputValue, pagination, callback) => {
    const params = {
      ...(inputValue && { search: inputValue }),
      page: pagination.page,
    }

    Auth0AuthenticationAPI.getImpersonationList(params)
      .then((res) => {
        callback({
          options: res.data,
          pagination: {
            hasMore: !!res.data && res.data.length > 0,
            page: pagination.page + 1,
          },
        })
      })
      .catch(() => null)
  }

const setOptionlabel = (option, { context }) => {
  if (context === 'menu') {
    return (
      <div>
        <SubText bold={true}>{option.name}</SubText>
        <SubText bold>{option.email}</SubText>
      </div>
    )
  } else {
    return <SubText bold={true}>{option.name}</SubText>
  }
}

export const AppHeaderUserImpersonationSwitch = () => {
  const intl = useProcurifyIntl()
  const currentUser = useSelector(makeSelectUser())

  const [user, setUser] = React.useState<Auth0UserImpersonationOption>(null)

  React.useEffect(() => {
    if (currentUser) {
      setUser({
        name: getFullName(currentUser),
      } as Auth0UserImpersonationOption)
    }
  }, [currentUser])

  const onUserSelect = (selectedUser: Auth0UserImpersonationOption) => {
    setUser(selectedUser)

    const { email } = selectedUser

    Auth0AuthenticationAPI.impersonateUser({ impersonatee_email: email })
      .then(() => {
        window.location.href = '/'
      })
      .catch(() => null)
  }

  return (
    <AppHeaderSelectStyled data-testid='header-user-impersonate-switch'>
      <AsyncSelect<Auth0UserImpersonationOption>
        placeholder={intl.formatMessage(
          messages.impersonateUserPlaceholderText
        )}
        cacheOptions={true}
        loadOptions={loadOptions}
        onChange={onUserSelect}
        getOptionValue={(option) => option.email}
        formatOptionLabel={setOptionlabel}
        value={user}
      />
    </AppHeaderSelectStyled>
  )
}
