import * as React from 'react'

import { DotLoader } from '@procurify/ui'

import { useProcurifyIntl } from '@webapp/hooks'
import messages from '../messages'

export interface IPageViewLoaderProps {}

export const PageViewLoader = ({
  children,
}: React.PropsWithChildren<IPageViewLoaderProps>) => {
  const intl = useProcurifyIntl()

  return (
    <React.Suspense
      fallback={
        <DotLoader
          aria-label={intl.formatMessage(messages.pageLoaderAriaLabel)}
        />
      }
    >
      {children}
    </React.Suspense>
  )
}

PageViewLoader.displayName = 'PageViewLoader'
