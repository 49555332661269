import { pusher } from './systemChannelPusherInstance'
import { WSChannelManager } from '../WSChannelManager/WSChannelManager.service'

export const getChannelsForCurrentUserAndDomain = (
  domain: string,
  userId: string | number
) => {
  if (!domain) throw new Error('Domain is missing')
  if (!userId) throw new Error('User ID is missing')

  const notificationChannel = `${domain}-${userId}-inbox`
  const systemChannel = `${domain}-${userId}-system`

  return {
    notificationChannel,
    systemChannel,
  }
}

export const SystemChannelManager = new WSChannelManager({
  pusherInstance: pusher,
})
