import { type CurrencyConstructor } from '@webapp/core/entities/external/Currency'
import { type CurrencyApiModel } from '../api-models/CurrencyApiModel'

export const mapCurrency = (
  currency: CurrencyApiModel
): CurrencyConstructor => ({
  id: currency.id,
  rate: parseFloat(currency.rate),
  name: currency.name,
  base: currency.base,
})
