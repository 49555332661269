import * as React from 'react'

import { InputGroupLabelStyled } from './styles/InputGroupLabelStyled'
import { Label } from '../Label'

export interface IInputGroupLabelProps {}

export class InputGroupLabel extends React.PureComponent<IInputGroupLabelProps> {
  render() {
    const { children, ...otherProps } = this.props
    return (
      <InputGroupLabelStyled {...otherProps}>
        <Label>{children}</Label>
      </InputGroupLabelStyled>
    )
  }
}
