import React from 'react'

import { Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/core/hoc/ProtectedRoute'
import { useAppRoutes } from '@webapp/hooks'
import { SpendInsightsLinkOutPage, SpendInsightsPage } from '@webapp/pages'
import { ReturnOnSpendPage } from '@webapp/pages/ReturnOnSpendPage'

export const DataAndInsightsRouter = () => {
  const { url } = useAppRoutes()

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={url('SpendInsightsPage')}
        render={() => <SpendInsightsPage />}
        navigationPermissions={['data_and_insights__spend_insights']}
      />
      <ProtectedRoute
        path={url('SpendInsightsLinkOutPage', {})}
        render={() => <SpendInsightsLinkOutPage />}
        navigationPermissions={['data_and_insights__spend_insights']}
      />
      <ProtectedRoute
        exact
        path={url('ReturnOnSpendPage')}
        render={() => <ReturnOnSpendPage />}
        navigationPermissions={['data_and_insights__return_on_spend']}
      />
    </Switch>
  )
}
