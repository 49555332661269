import { defineMessages } from 'react-intl'

const scope = `procurify.order.components.OrderStatusLabel`

export default defineMessages({
  statusAriaLabel: {
    id: `${scope}.statusAriaLabel`,
    defaultMessage: 'Order status',
  },
  statusTextPending: {
    id: `${scope}.statusTextPending`,
    defaultMessage: 'Pending',
  },
  statusTextDraft: {
    id: `${scope}.statusTextDraft`,
    defaultMessage: 'Draft',
  },
  statusTextApproved: {
    id: `${scope}.statusTextApproved`,
    defaultMessage: 'Approved',
  },
  statusTextReceived: {
    id: `${scope}.statusTextReceived`,
    defaultMessage: 'Received',
  },
  statusTextPurchased: {
    id: `${scope}.statusTextPurchased`,
    defaultMessage: 'Purchased',
  },
  statusTextCancelled: {
    id: `${scope}.statusTextCancelled`,
    defaultMessage: 'Cancelled',
  },
  statusTextRejected: {
    id: `${scope}.statusTextRejected`,
    defaultMessage: 'Denied',
  },
  statusTextUnknown: {
    id: `${scope}.statusTextUnknown`,
    defaultMessage: 'Unknown',
  },
})
