import * as React from 'react'

import { Redirect, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/core/hoc/ProtectedRoute'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import {
  PurchaseOrderDetailsPage,
  PurchaseOrderListPage,
  PurchaseOrderRevisePoPage,
} from '@webapp/pages'
import { PurchaseOrderCreatePoPage } from '@webapp/pages/PurchaseOrderCreatePoPage'
import { PurchaseOrderDetailsPageTabs } from '@webapp/pages/PurchaseOrderDetailsPage'
import { PurchaseOrderListPageTabStatuses } from '@webapp/pages/PurchaseOrderListPage'

export const PurchaseOrderRouter = () => {
  const { url, brp } = useAppRoutes()

  return (
    <Switch>
      <Redirect
        exact
        from={brp('PurchaseOrderListPage', {
          status: '',
        })}
        to={url('PurchaseOrderListPage', {
          status: PurchaseOrderListPageTabStatuses.OPEN,
        })}
      />

      <ProtectedRoute
        path={brp('PurchaseOrderListPage', {
          status: `:status(${PurchaseOrderListPageTabStatuses.ALL}|${PurchaseOrderListPageTabStatuses.OPEN}|${PurchaseOrderListPageTabStatuses.PARTIAL}|${PurchaseOrderListPageTabStatuses.CLOSED}|${PurchaseOrderListPageTabStatuses.CANCELLED}|${PurchaseOrderListPageTabStatuses.PAID})`,
        })}
        component={PurchaseOrderListPage}
      />

      <Redirect
        exact
        from={brp('PurchaseOrderCreatePoPage', {
          vendorId: '',
        })}
        to={url('ProcurementPage')}
      />

      <ProtectedRoute
        exact
        path={brp('PurchaseOrderDetailsPage', {
          uuid: `:uuid`,
          tab: `:tab(${PurchaseOrderDetailsPageTabs.RECEIVE_LOG})`,
        })}
        component={PurchaseOrderDetailsPage}
      />
      <ProtectedRoute
        exact
        path={brp('PurchaseOrderDetailsPage', {
          uuid: `:uuid`,
        })}
        component={PurchaseOrderDetailsPage}
      />

      <ProtectedRoute
        exact
        path={brp('PurchaseOrderDetailsPreviousVersionPage', {
          uuid: `:uuid`,
          version: `:version`,
        })}
        component={PurchaseOrderDetailsPage}
      />

      <ProtectedRoute
        path={brp('PurchaseOrderRevisePoPage', {
          uuid: `:uuid`,
        })}
        component={PurchaseOrderRevisePoPage}
        navigationPermissions={['procure__purchase_orders']}
      />

      <ProtectedRoute
        path={brp('PurchaseOrderCreatePoPage', {
          vendorId: `:vendorId`,
        })}
        component={PurchaseOrderCreatePoPage}
        navigationPermissions={['procure__procurement']}
      />
    </Switch>
  )
}
