import * as React from 'react'

import { InlineTextButton, Space, Spacer, WarningAlert } from '@procurify/ui'
import { useDispatch, useSelector } from 'react-redux'

import { updateFlags } from '@webapp/stores/global'
import { updateHasFFOverride } from './stores/actions'
import {
  makeSelectHasFFOverride,
  makeSelectLiveFFState,
} from './stores/selectors'

export interface IProcurifyDevToolsFfWarningProps {}

export const ProcurifyDevToolsFfWarning = ({
  ...props
}: IProcurifyDevToolsFfWarningProps) => {
  const hasFFOverride = useSelector(makeSelectHasFFOverride())
  const liveFFState = useSelector(makeSelectLiveFFState())

  const dispatch = useDispatch()

  const clearOverrides = () => {
    window.localStorage.__procurify_dev_local_ff = JSON.stringify({})

    dispatch(updateHasFFOverride(false))
    dispatch(updateFlags(liveFFState))
  }

  return hasFFOverride ? (
    <>
      <WarningAlert>
        <Space direction='horizontal' justify='space-between'>
          <span>
            Frontend Application is currently overwritten by local Feature
            Flags.
          </span>
          <InlineTextButton onClick={clearOverrides}>
            Clear Overrides
          </InlineTextButton>
        </Space>
      </WarningAlert>
      <Spacer />
    </>
  ) : null
}

ProcurifyDevToolsFfWarning.displayName = 'ProcurifyDevToolsFfWarning'
