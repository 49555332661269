import * as React from 'react'

import styled from 'styled-components'

import SVGLoader from './images/inline-loader.svg'

export interface IDotLoaderProps {
  width?: number
  height?: number
  sizeUnit?: string
}

export const DotLoader = styled.svg.attrs({
  'data-testid': 'dot-loader-svg',
})<IDotLoaderProps>`
  width: ${(props) => `${props.width}${props.sizeUnit}`};
  height: ${(props) => `${props.height}${props.sizeUnit}`};
  background-image: url(${SVGLoader});
  background-repeat: no-repeat;
`

DotLoader.defaultProps = {
  width: 18,
  height: 8,
  sizeUnit: 'px',
}
