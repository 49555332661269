import { em } from 'polished'
import styled from 'styled-components'

import four04SVG from '../assets/404.svg'
import activitySVG from '../assets/activity.svg'
import cardSVG from '../assets/card.svg'
import cartSVG from '../assets/cart.svg'
import discussionSVG from '../assets/discussion.svg'
import fileSVG from '../assets/file.svg'
import historySVG from '../assets/history.svg'
import informationSVG from '../assets/information.svg'
import { type EmptyStateContext } from '../EmptyState'

interface IEmptyStateImageStyledProps {
  /** determine which of the default EmptyStateContext svgs to use */
  context?: EmptyStateContext
  /** imgUrl prop accepts a custom img path to render in as the main illustration instead of the default illustrations provided via EmptyStateContext  */
  imgUrl?: string
}

export const background = ({ context = null }) => {
  switch (context) {
    case 'information':
      return informationSVG
    case 'activity':
      return activitySVG
    case 'cart':
      return cartSVG
    case 'discussion':
      return discussionSVG
    case 'file':
      return fileSVG
    case 'history':
      return historySVG
    case 'card':
      return cardSVG
    case '404':
      return four04SVG
    default:
      return informationSVG
  }
}

export const EmptyStateImageStyled = styled.div<IEmptyStateImageStyledProps>`
  display: inline-block;

  width: 100%;
  height: ${em(150)};

  max-width: ${em(200)};

  text-align: center;

  background-image: ${(props) =>
    props.imgUrl ? `url(${props.imgUrl})` : `url(${background(props)})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
