import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const MFAPreferencesViewChunk = React.lazy(() =>
  import('../feature-sets/MFAPreferencesView').then(
    ({ MFAPreferencesView }) => ({
      default: MFAPreferencesView,
    })
  )
)

export const MFAPreferencesPage = () => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={`Procurify | ${intl.formatMessage(messages.MFAPreferencesTitle)}`}
      breadcrumbs={[
        {
          link: url('SettingsPage'),
          name: intl.formatMessage(messages.settingsTitle),
        },
        {
          name: intl.formatMessage(messages.MFAPreferencesTitle),
          escapeCaseFormatting: true,
        },
      ]}
    >
      <MFAPreferencesViewChunk />
    </Page>
  )
}
