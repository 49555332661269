/*
 * This component should never be changed manually
 * Please use `yarn generate-svg "SVG FILENAME"`
 */
import * as React from 'react'

import { ReactComponent as Icon } from '../assets/Add.svg'
import { IconComponentStyled } from '../styles/IconComponentStyled'
import { type IIconComponentProps } from '../types'

export const IconAdd = ({ ...props }: IIconComponentProps) => {
  return (
    <IconComponentStyled {...props}>
      <Icon />
    </IconComponentStyled>
  )
}

IconAdd.displayName = 'IconAdd'
