import { spacing, type SpacingProps } from '@material-ui/system'
import styled from 'styled-components'

export const ToolbarStyled = styled.div<SpacingProps>`
  ${spacing}
  display: flex;
  align-items: center;

  width: 100%;
`
