import { em, rem } from 'polished'
import styled from 'styled-components'

interface IApproverOverwatchUserTableStyledProps {}

export const ApproverOverwatchUserTableStyled = styled.div<IApproverOverwatchUserTableStyledProps>`
  font-size: ${rem(16)};
  height: 100%;

  .user-table {
    border-top: none;

    & thead:first-of-type th {
      border-top: none;
    }
  }

  .user-table tbody tr {
    cursor: pointer;
  }
`
