import { em } from 'polished'
import styled from 'styled-components'

interface IEmptyStateStyledProps {
  small?: boolean
}

export const EmptyStateStyled = styled.div<IEmptyStateStyledProps>`
  font-size: ${(props) =>
    props.small
      ? props.theme.typography.pxToRem(12)
      : props.theme.typography.pxToRem(16)};

  margin: ${em(12)};
  padding: ${em(24)};

  text-align: center;
`
