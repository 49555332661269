import * as React from 'react'

import { em } from 'polished'
import styled from 'styled-components'

import { BaseButtonStyled } from './BaseButtonStyled'
import { ButtonLoaderStyled } from './ButtonLoaderStyled'
import { IconStyled } from '../../Icon/styles/IconStyled'
import { CircleLoader } from '../../Loaders'
import { IconComponentStyled } from '@ui/elements/Icon/styles/IconComponentStyled'

export const IconButtonStyled = styled((props) => (
  <BaseButtonStyled role='button' {...props} />
))`
  min-width: ${(props) => em(40, props.theme.fontSizes.button)};
  padding: ${(props) => em(12, props.theme.fontSizes.button)};

  > ${/* sc-selector */ IconStyled},
    > ${/* sc-selector */ IconComponentStyled} {
    margin: 0;
    font-size: ${(props) => em(11, props.theme.fontSizes.button)};

    transform: scale(1.5);
  }

  &[disabled] {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.disabled};
    background-color: ${(props) => props.theme.colors.offwhite};
    border-color: ${(props) => props.theme.colors.disabled};
    box-shadow: none;

    &:hover {
      color: ${(props) => props.theme.colors.disabled};
      background-color: ${(props) => props.theme.colors.offwhite};
      border-color: ${(props) => props.theme.colors.disabled};
      box-shadow: none;
    }
    &:active {
      color: ${(props) => props.theme.colors.disabled};
      background-color: ${(props) => props.theme.colors.offwhite};
      border-color: ${(props) => props.theme.colors.disabled};
      box-shadow: none;
    }
  }

  ${ButtonLoaderStyled} {
    background-color: ${(props) => props.theme.colors.offwhite};

    ${CircleLoader} {
      border-color: ${(props) => props.theme.colors.disabled};
      border-bottom-color: transparent;
    }
  }
`
