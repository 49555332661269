import { useMutation, type UseMutationOptions } from 'react-query'

import { Auth0AuthenticationAPI } from '@webapp/api/Auth0AuthenticationAPI'
import { type SsoConnection } from '@webapp/models'

export type CreateSsoConnectionParams = [SsoConnection]

const mutationFn = async (newConnection: SsoConnection) => {
  const { data: apiResponse } =
    await Auth0AuthenticationAPI.createSsoConnection(newConnection)

  const { data: ssoConnection } = apiResponse

  return ssoConnection
}

export const useCreateSsoConnection = (
  config: UseMutationOptions<SsoConnection, unknown, SsoConnection>
) => {
  return useMutation(mutationFn, {
    ...config,
  })
}
