import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { type PayRequestApproval } from '@webapp/models/PayRequest'
import messages from './messages'
import { SpendingCardApprovalListPageTabs } from './SpendingCardApprovalListPage'

export interface SpendingCardApproveVirtualCardRequestPageParams {
  uuid: PayRequestApproval['uuid']
}

const SpendingCardApproveVirtualCardRequestViewChunk = React.lazy(() =>
  import('../containers/SpendingCardApproveVirtualCardRequestView').then(
    ({ SpendingCardApproveVirtualCardRequestView }) => ({
      default: SpendingCardApproveVirtualCardRequestView,
    })
  )
)

export const SpendingCardApproveVirtualCardRequestPage = (
  props: RouteComponentProps<SpendingCardApproveVirtualCardRequestPageParams>
) => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(
        messages.spendingCardApproveVirtualCardRequestPageTitle
      )}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.spendingCardApprovalListPageTitle),
          link: url('SpendingCardApprovalListPage', {
            tab: SpendingCardApprovalListPageTabs.PENDING,
          }),
        },
        {
          name: intl.formatMessage(
            messages.spendingCardApproveVirtualCardRequestPageTitle
          ),
        },
      ]}
    >
      <SpendingCardApproveVirtualCardRequestViewChunk
        uuid={props.match.params.uuid}
      />
    </Page>
  )
}
