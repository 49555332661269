import styled from 'styled-components'

import { OverlayLoaderContentStyled } from './OverlayLoaderContentStyled'

export const OverlayLoaderContainerStyled = styled.div<{ offsetY: number }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background: rgba(255, 255, 255, 0.85);

  ${OverlayLoaderContentStyled} {
    position: absolute;

    top: ${(props) => 50 + (props.offsetY || 0)}%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
