import * as React from 'react'

import { Label, SubText } from '@procurify/ui'

import { UserLabelStyled } from './styles/UserLabelStyled'
import { type Department, type PayCardholder, type User } from '../../models'
import { getFullName } from '../../utils/user'
import { UserAvatar } from '../UserAvatar'
import { type IWithUserTooltipProps, WithUserTooltip } from '../WithUserTooltip'

export interface IUserLabelProps {
  user: Partial<User>
  WithUserTooltipProps?: Partial<IWithUserTooltipProps>
  showDelegatee?: boolean
}

export const cardHolderToUser = (
  cardholder: PayCardholder & {
    department: Department
  }
): Partial<User> => {
  return {
    id: cardholder.id,
    firstName: cardholder.first_name,
    lastName: cardholder.last_name,
    profile_image: cardholder.profile_image,
    department: cardholder.department,
  }
}

export const UserLabel = ({
  user,
  WithUserTooltipProps,
  showDelegatee = false,
}: IUserLabelProps) => {
  return user ? (
    <WithUserTooltip {...WithUserTooltipProps} user={user}>
      <UserLabelStyled>
        <Label
          component={
            <UserAvatar
              shadow={false}
              url={user.profile_image}
              size='xsmall'
              circular
            />
          }
        >
          <SubText>
            {getFullName(user)}
            {showDelegatee && user.approval_delegatee && (
              <>
                <br />({getFullName(user.approval_delegatee)})
              </>
            )}
          </SubText>
        </Label>
      </UserLabelStyled>
    </WithUserTooltip>
  ) : null
}
