import * as React from 'react'

import { RouteComponentProps } from 'react-router'

const SpendingCardManagementDetailsViewChunk = React.lazy(() =>
  import('../containers/SpendingCardManagementDetailsView').then(
    ({ SpendingCardManagementDetailsView }) => ({
      default: SpendingCardManagementDetailsView,
    })
  )
)

export const SpendingCardManagementDetailsPage = () => {
  return (
    <React.Suspense fallback={null}>
      <SpendingCardManagementDetailsViewChunk />
    </React.Suspense>
  )
}
