import { type ApproverConstructor } from '@webapp/core/entities/internal/Approver'
import { mapDepartment } from './mapDepartment'
import { mapLocation } from './mapLocation'
import { type ApproverApiModel } from '../api-models/ApproverApiModel'
import { type DepartmentApiModel } from '../api-models/DepartmentApiModel'
import { type LocationApiModel } from '../api-models/LocationApiModel'

const getPrimaryCategoryFromDepartment = (
  department: Nullable<DepartmentApiModel>
): Nullable<LocationApiModel> => {
  if (department?.branch) return department.branch
  if (department?.location) return department.location
  return null
}

export const mapApprover = (
  approver: ApproverApiModel
): ApproverConstructor => {
  const rawLocation = approver.department
    ? getPrimaryCategoryFromDepartment(approver.department)
    : null
  return {
    id: approver.id,
    firstName: approver.firstName,
    lastName: approver.lastName,
    email: approver.email,
    profileImage: approver.profile_image,
    approvalDelegatee: approver.approval_delegatee
      ? {
          id: approver.approval_delegatee.id,
          firstName: approver.approval_delegatee.firstName,
          lastName: approver.approval_delegatee.lastName,
          expectedReturnDate: approver.expected_return_date || null,
        }
      : null,
    primaryCategory: rawLocation ? mapLocation(rawLocation) : null,
    secondaryCategory: approver.department
      ? mapDepartment(approver.department)
      : null,
  }
}
