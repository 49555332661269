import * as React from 'react'

import { useSelector } from 'react-redux'

import { Auth0AuthenticationAPI } from '@webapp/api/Auth0AuthenticationAPI'
import { useFeatureFlag } from '@webapp/hooks'
import {
  makeSelectApplicationStatus,
  makeSelectDomainSwitchActive,
} from '@webapp/stores/global'
import { AppHeader } from './AppHeader'
import { type User } from '../../models'
import { makeSelectUser } from '../../stores/global'

export const ConnectedAppHeader = () => {
  const user = useSelector<any, User>(makeSelectUser())

  const showDomainSwitch = useSelector(makeSelectDomainSwitchActive())
  const applicationStatus = useSelector(makeSelectApplicationStatus())

  const [showUserImpersonationSwitch, setShowUserImpersonationSwitch] =
    React.useState(false)

  React.useEffect(() => {
    if (!showUserImpersonationSwitch) {
      Auth0AuthenticationAPI.getImpersonationList()
        .then((res) => {
          if (res.data) {
            setShowUserImpersonationSwitch(true)
          }
        })
        .catch(() => null)
    }
  }, [])

  return (
    <AppHeader
      user={user}
      showUserImpersonationSwitch={showUserImpersonationSwitch}
      showDomainSwitch={showDomainSwitch}
      applicationStatus={applicationStatus}
    />
  )
}
