import * as React from 'react'

import { shade, transparentize } from 'polished'
import styled from 'styled-components'

import { IconButtonStyled } from './IconButtonStyled'
import { applyButtonColorCSS } from './utils'

export const TransparentIconButtonStyled = styled((props) => (
  <IconButtonStyled role='button' {...props} />
))`
  ${(props) =>
    applyButtonColorCSS(
      'transparent',
      'transparent',
      props.iconColor || props.theme.colors.button.transparentIcon.content,
      props.theme.colors.button.transparentIcon.contentHover
    )}

  box-shadow: none;

  &[disabled] {
    background-color: transparent;
    border-color: transparent;

    &:hover {
      background-color: transparent;
      border-color: transparent;
    }
    &:active {
      background-color: transparent;
      border-color: transparent;
    }
  }
`
