import { call, put, takeLatest } from 'redux-saga/effects'

import { NotificationsAPI } from '@webapp/api/NotificationsAPI'
import { flattenNotificationsCount } from '@webapp/components/Navigation/utils'
import { updateCounts } from './actions'
import { FETCH_COUNTS } from './constants'

const notificationApi = () =>
  NotificationsAPI.getNotificationsCount().then((res) => res.data)

export function* fetchNotificationCounts() {
  try {
    const counts = yield call(notificationApi)
    yield put(updateCounts(flattenNotificationsCount(counts)))
  } catch (err) {
    console.log('Error fetching notification counts')
  }
}

export default function* notificationCounts() {
  yield takeLatest(FETCH_COUNTS, fetchNotificationCounts)
}
