import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const ReturnOnSpendChunk = React.lazy(() =>
  import('../containers/ReturnOnSpendView').then(({ ReturnOnSpendView }) => ({
    default: ReturnOnSpendView,
  }))
)

export const ReturnOnSpendPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.returnOnSpendPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.returnOnSpendPageTitle),
        },
      ]}
    >
      <ReturnOnSpendChunk />
    </Page>
  )
}
