import * as React from 'react'

import { useAuthentication } from '@procurify/authentication'
import { logoutUrl } from '@procurify/authentication/src/configs'
import { SheetProvider, ModalProvider, ModalRoot } from '@procurify/ui'
import { theme } from '@procurify/ui-theme'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory } from 'react-router'

import { ThemeWrapper } from '@webapp/app/providers/ThemeWrapper'
import { UIProvider } from '@webapp/app/providers/UIProvider'
import { SettingsRouter } from '@webapp/app/routers/SettingsRouter'
import { ConnectedAppHeader } from '@webapp/components/AppHeader/ConnectedAppHeader'
import { ErrorBoundary } from '@webapp/components/ErrorBoundary'
import { GlobalAnalytics } from '@webapp/components/GlobalAnalytics'
import { GlobalErrorScreen } from '@webapp/components/GlobalErrorScreen'
import { Intercom } from '@webapp/components/Intercom'
import { getAppStatusMeta } from '@webapp/components/Navigation/utils'
import { SystemPushNotifications } from '@webapp/components/SystemPushNotifications'
import { ProtectedRoute } from '@webapp/core/hoc/ProtectedRoute'
import { PaginationProvider } from '@webapp/core/providers/Pagination/PaginationProvider'
import { ProcurifyDevToolsFfWarning } from '@webapp/dev/ProcurifyDevTools/ProcurifyDevToolsFfWarning'
import { ProcurifyDevToolsLoadable } from '@webapp/dev/ProcurifyDevTools/ProcurifyDevToolsLoadable'
import {
  useAppRoutes,
  useCheckNoPermission,
  useFeatureFlag,
} from '@webapp/hooks'
import { IS_DEV, IS_PROD } from '@webapp/infrastructure/envs'
import { AuthenticationErrorTypes } from '@webapp/models/enums/AuthenticationErrorType'
import { ExpenseRouter } from '@webapp/modules/expense'
import { OrderRouter } from '@webapp/modules/order'
import { NoPermissionsPage } from '@webapp/pages'
import { RedirectPage } from '@webapp/pages/RedirectPage'
import {
  makeSelectApplicationStatus,
  makeSelectIsAppReady,
  makeSelectRole,
  makeSelectSegmentAnalyticsMetadata,
  makeSelectUser,
} from '@webapp/stores/global'
import { AppLoader } from './AppLoader'
import { AppContentWrapper } from './components/AppContentWrapper/AppContentWrapper'
import { GlobalStyles } from './components/GlobalStyles'
import { ToastContainerWrapper } from './components/ToastContainerWrapper/ToastContainerWrapper'
import { usePersistReferrerUrl } from './hooks/usePersistReferrerUrl'
import { BillRouter } from './routers/BillRouter/BillRouter'
import { ContractRouter } from './routers/ContractRouter'
import { DataAndInsightsRouter } from './routers/DataAndInsightsRouter'
import { InstanceManagementRouter } from './routers/InstanceManagementRouter'
import { IntegrationsRouter } from './routers/IntegrationsRouter'
import { PaymentRouter } from './routers/PaymentRouter'
import { ProcurementRouter } from './routers/ProcurementRouter'
import { PurchaseOrderRouter } from './routers/PurchaseOrderRouter'
import { ReceiveRouter } from './routers/ReceiveRouter'
import { SpendingCardRouter } from './routers/SpendingCardRouter'
import { UnbilledItemsRouter } from './routers/UnbilledItemsRouter'
import { UserProfileRouter } from './routers/UserProfileRouter'
import { VendorRouter } from './routers/VendorRouter'
import { AppStyled } from './styles/AppStyled'
import { AppContent } from '../components/AppContent'
import { ConnectedNavigation } from '../components/Navigation'
import {
  CatalogPage,
  DevelopmentPage,
  LoginPage,
  ProcurifyPaymentsPage,
  WelcomePage,
} from '../pages'

const AuthenticatedApp = () => {
  usePersistReferrerUrl()

  const { brp } = useAppRoutes()
  const user = useSelector(makeSelectUser())
  const segmentAnalyticsMetadata = useSelector(
    makeSelectSegmentAnalyticsMetadata()
  )

  // Check for trial mode and expired trial
  const applicationStatus = useSelector(makeSelectApplicationStatus())
  const role = useSelector(makeSelectRole())

  const CL_1324_NEW_NO_PERMISSION_PAGE = useFeatureFlag(
    'CL_1324_NEW_NO_PERMISSION_PAGE'
  )

  const hasNoPermission = useCheckNoPermission()

  const { isAdmin, isTrialMode, isTrialExpired } = getAppStatusMeta(
    applicationStatus,
    role
  )

  if (CL_1324_NEW_NO_PERMISSION_PAGE) {
    if (isTrialMode && isTrialExpired && !isAdmin && !hasNoPermission) {
      window.location.replace('/#/')
    }
  } else {
    if (isTrialMode && isTrialExpired && !isAdmin) {
      window.location.replace('/#/')
    }
  }

  return (
    <AppStyled>
      <ConnectedAppHeader />

      <AppContentWrapper>
        {IS_DEV && <ProcurifyDevToolsLoadable />}

        {!CL_1324_NEW_NO_PERMISSION_PAGE && <ConnectedNavigation />}

        {CL_1324_NEW_NO_PERMISSION_PAGE && !hasNoPermission && (
          <ConnectedNavigation />
        )}

        <ErrorBoundary>
          <SystemPushNotifications />
          <GlobalAnalytics
            user={user}
            segmentAnalyticsMetadata={segmentAnalyticsMetadata}
          />
          <PaginationProvider>
            <ModalProvider>
              <SheetProvider>
                <ModalRoot />
                <AppContent>
                  {IS_DEV && <ProcurifyDevToolsFfWarning />}

                  <Switch>
                    <Route
                      exact
                      path='/'
                      render={() => {
                        window.location.reload()
                        return null
                      }}
                    />
                    <ProtectedRoute
                      path={brp('ProcurifyPaymentsPage')}
                      component={ProcurifyPaymentsPage}
                    />
                    {/* Redirect `/pay` to `/spending-card` for a transitional period */}
                    <Route
                      path='/pay/*'
                      render={({ match }) => (
                        <Redirect
                          to={match.url.replace('/pay/', '/spending-card/')}
                        />
                      )}
                    />
                    <Route
                      path='/spending-card'
                      component={SpendingCardRouter}
                    />
                    <ProtectedRoute
                      path='/catalog'
                      component={CatalogPage}
                      navigationPermissions={['procure__product_catalog']}
                    />
                    <Route path='/profile' component={UserProfileRouter} />
                    <Route path='/welcome' component={WelcomePage} />
                    <Route path='/settings' component={SettingsRouter} />
                    <Route path='/expenses' component={ExpenseRouter} />
                    {/* Bill pages */}
                    <Route path='/bills' component={BillRouter} />
                    {/* Unbilled items page */}
                    <Route
                      path='/unbilled/items'
                      component={UnbilledItemsRouter}
                    />
                    {/* Contract pages */}
                    <Route path='/contracts' component={ContractRouter} />
                    {/* Payment pages */}
                    <Route path='/payments' component={PaymentRouter} />
                    {/* Procurement */}
                    <Route path='/procurement' component={ProcurementRouter} />
                    {/* Data and Insights */}
                    <Route
                      path='/data-and-insights'
                      component={DataAndInsightsRouter}
                    />
                    {/* Order pages */}
                    <Route path='/orders' component={OrderRouter} />
                    {/* PO pages */}
                    <Route
                      path='/purchase-orders'
                      component={PurchaseOrderRouter}
                    />
                    {/* Receive pages */}
                    <Route path='/receive' component={ReceiveRouter} />
                    {/* Integration pages */}
                    <Route
                      path='/integrations'
                      component={IntegrationsRouter}
                    />
                    {/* Instance management pages */}
                    <Route
                      path='/instance-management'
                      component={InstanceManagementRouter}
                    />
                    {/* Vendors pages */}
                    <Route path='/vendor' component={VendorRouter} />

                    <Route path='/redirect' component={RedirectPage} />
                    {IS_DEV && (
                      <Route path='/dev' component={DevelopmentPage} />
                    )}
                    {CL_1324_NEW_NO_PERMISSION_PAGE && hasNoPermission && (
                      <Route
                        path='/permissions-pending'
                        component={NoPermissionsPage}
                      />
                    )}
                    <Route path='*' render={() => <Redirect to='/' />} />
                  </Switch>
                </AppContent>
              </SheetProvider>
            </ModalProvider>
          </PaginationProvider>
          {IS_PROD && <Intercom />}
        </ErrorBoundary>
        <ToastContainerWrapper />
      </AppContentWrapper>
    </AppStyled>
  )
}

const PublicRoutes = () => {
  return [<Route key='login' path='/login' component={LoginPage} />]
}

export const App = () => {
  const history = useHistory()

  const {
    isLoading,
    isAuthenticated,
    error: authError,
    errorCode: authErrorCode,
  } = useAuthentication()
  const isAppReady = useSelector(makeSelectIsAppReady())

  const showAuthenticatedApp = isAuthenticated && isAppReady

  React.useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      history.push('/login')
    }
  }, [isLoading, isAuthenticated, history])

  React.useEffect(() => {
    if (authErrorCode === AuthenticationErrorTypes.STAFF_ACCESS_EXPIRED) {
      window.location.replace(logoutUrl)
    }
  }, [authErrorCode])

  const renderApplication = () => {
    if (isLoading) return <AppLoader />

    if (authError) return <GlobalErrorScreen />

    return (
      <Switch>
        {PublicRoutes()}

        {showAuthenticatedApp && (
          <Route path='*' component={AuthenticatedApp} />
        )}
      </Switch>
    )
  }

  return (
    <ThemeWrapper theme={theme}>
      <UIProvider>
        <GlobalStyles />

        {renderApplication()}
      </UIProvider>
    </ThemeWrapper>
  )
}
