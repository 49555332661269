import * as React from 'react'

import { TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { theme } from '@procurify/ui-theme'
import { tint } from 'polished'

import { type ITableRowProps } from '.././TableRow'

const styles = {
  root: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: `${theme.colors.table.hover} !important`,
    },
    height: 0,
    color: theme.colors.textPrimary,
    fontWeight: 400,
    'border-bottom': `1px solid ${theme.colors.content.border}`,
  },
  unhoverable: {
    height: 0,
    color: theme.colors.darkGrey,
    fontWeight: 400,
    'border-bottom': `1px solid ${theme.colors.content.border}`,
  },
  selected: {
    backgroundColor: `${tint(0.9, theme.colors.darkGreen)} !important`,
  },
}

class TableRowStyledComponent extends React.Component<
  { classes: any } & ITableRowProps,
  any
> {
  render() {
    const { classes, index, hoverable, selected, ...otherProps } = this.props
    return (
      <TableRow
        selected={this.props.selected}
        classes={{
          root: hoverable ? classes.root : classes.unhoverable,
          selected: classes.selected,
        }}
        key={index}
        {...otherProps}
      />
    )
  }
}

export const TableRowStyled = withStyles(styles)(TableRowStyledComponent)
