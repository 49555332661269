import React from 'react'

import { StatusLabel } from '@procurify/ui'
import { theme } from '@procurify/ui-theme'
import { type IntlShape } from 'react-intl'

import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'
import {
  OrderStatuses,
  type OrderStatusEnum,
} from '../../entities/enums/OrderStatusEnum'

const getType = (status: OrderStatusEnum) => {
  switch (status) {
    case OrderStatuses.PENDING:
    case OrderStatuses.DRAFT:
      return 'warning'
    case OrderStatuses.APPROVED:
    case OrderStatuses.RECEIVED:
    case OrderStatuses.PURCHASED:
      return 'success'
    case OrderStatuses.CANCELLED:
    case OrderStatuses.REJECTED:
      return 'error'
    default:
      return 'inactive'
  }
}

const getText = (intl: IntlShape, status: OrderStatusEnum) => {
  switch (status) {
    case OrderStatuses.PENDING:
      return intl.formatMessage(messages.statusTextPending)
    case OrderStatuses.DRAFT:
      return intl.formatMessage(messages.statusTextDraft)
    case OrderStatuses.APPROVED:
      return intl.formatMessage(messages.statusTextApproved)
    case OrderStatuses.RECEIVED:
      return intl.formatMessage(messages.statusTextReceived)
    case OrderStatuses.PURCHASED:
      return intl.formatMessage(messages.statusTextPurchased)
    case OrderStatuses.CANCELLED:
      return intl.formatMessage(messages.statusTextCancelled)
    case OrderStatuses.REJECTED:
      return intl.formatMessage(messages.statusTextRejected)
    default:
      return intl.formatMessage(messages.statusTextUnknown)
  }
}

interface IOrderStatusLabelProps {
  status: OrderStatusEnum
  size?: 'md' | 'lg'
}

export const OrderStatusLabel = ({
  status,
  size = 'md',
}: IOrderStatusLabelProps) => {
  const intl = useProcurifyIntl()
  return (
    <StatusLabel
      aria-label={intl.formatMessage(messages.statusAriaLabel)}
      fontSize={size === 'md' ? theme.fontSizes.body2 : theme.fontSizes.body1}
      type={getType(status)}
      text={getText(intl, status)}
    />
  )
}
