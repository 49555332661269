import * as React from 'react'

import { Redirect, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/core/hoc/ProtectedRoute'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { ReceiveDetailsPage } from '@webapp/pages'
import {
  ReceiveListPage,
  ReceiveListPageTabStatuses,
} from '@webapp/pages/ReceiveListPage'

const receiveStatusFormat = Object.values(ReceiveListPageTabStatuses).join('|')

export const ReceiveRouter = () => {
  const { brp } = useAppRoutes()

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={brp('ReceiveDetailsPage', {
          uuid: `:uuid(${/[0-9a-fA-F]{32}/.source})`,
        })}
        component={ReceiveDetailsPage}
        navigationPermissions={['receive']}
      />

      <ProtectedRoute
        path={brp('ReceiveListPage', {
          status: `:status(${receiveStatusFormat})`,
        })}
        component={ReceiveListPage}
        navigationPermissions={['receive']}
      />

      <Redirect
        path={brp('ReceiveListPage', {
          status: '',
        })}
        to={brp('ReceiveListPage', {
          status: 'open',
        })}
      />
    </Switch>
  )
}
