import { type StripeAccount, type StripeAccountOnboardingLink } from '../models'
import { ApiClient, type IApiRequestParam } from '../services/api'

export const ENDPOINT = '/api/v3/integrations/stripe/accounts'

export const CARD_MANAGEMENT_ACCOUNT_URL = ENDPOINT

export interface StripeAccountUpdateSchema {}

export interface StripeAccountOnboardingLinkSchema {
  redirect_type: 'spending_card' | 'financial_account'
}

export interface StripeAccountCreationSchema {
  address_id: number
  tos_acceptance: boolean
  user_id: number
  enable_treasury?: boolean
}

export class CardManagementAccountAPI {
  static readonly getAccounts = (params: IApiRequestParam = {}) =>
    ApiClient.get<StripeAccount[]>(`${CARD_MANAGEMENT_ACCOUNT_URL}/`, {
      params,
    })

  static createAccount = (params: StripeAccountCreationSchema) =>
    ApiClient.post<StripeAccountCreationSchema, StripeAccount>(
      `${CARD_MANAGEMENT_ACCOUNT_URL}/`,
      params
    )

  static updateAccountWithTreasury = (
    accountId: StripeAccount['stripe_account_id']
  ) =>
    ApiClient.patch<StripeAccountUpdateSchema, StripeAccount>(
      `${CARD_MANAGEMENT_ACCOUNT_URL}/${accountId}/enable-treasury/`
    )

  static readonly getOnboardingLink = (
    params: StripeAccountOnboardingLinkSchema,
    accountId: string
  ) =>
    ApiClient.get<StripeAccountOnboardingLink>(
      `${CARD_MANAGEMENT_ACCOUNT_URL}/${accountId}/onboarding-link/`,
      { params }
    )
}
