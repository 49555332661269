import * as React from 'react'

import { Card, Flex, Header, Spacer, Text } from '@procurify/ui'
import produce from 'immer'
import { isUndefined } from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'

import { ApproverWatchlistAPI } from '@webapp/api/ApproverWatchlistAPI'
import { ApproverOverwatch } from '@webapp/components/ApproverOverwatch'
import { ApproverOverwatchSubscriberListModal } from '@webapp/components/ApproverOverwatch/components/ApproverOverwatchSubscriberList/ApproverOverwatchSubscriberListModal'
import { type ApproverOverwatchUserTableData } from '@webapp/components/ApproverOverwatch/components/ApproverOverwatchUserTable/ApproverOverwatchUserTable'
import { Breadcrumb } from '@webapp/core/components/Breadcrumb'
import { FormattedEnhancedMessage } from '@webapp/core/hoc/FormattedEnhancedMessage'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { type WatchlistUser } from '@webapp/models'
import messages from './messages'
import { ApproverOverwatchViewStyled } from './styles/ApproverOverwatchViewStyled'
import settingsMessages from '../SettingsView/messages'

export interface IApproverOverwatchViewProps {}

export const ApproverOverwatchView: React.FC<IApproverOverwatchViewProps> =
  React.memo((props) => {
    const intl = useIntl()
    const { url } = useAppRoutes()

    // Decided to group the states into one object, so it only triggers one rerender
    const [
      {
        approversByID,
        approversList,
        watchlistApprovers,
        watchlistByApprover,
        selectedApproversToSubscribe,
      },
      setState,
    ] = React.useState<{
      approversByID: { [userId: string]: WatchlistUser }
      approversList: WatchlistUser[]
      watchlistApprovers: ApproverOverwatchUserTableData[]
      watchlistByApprover: {
        [key: string]: WatchlistUser[]
      }
      selectedApproversToSubscribe: { [userId: string]: boolean }
    }>({
      approversByID: {},
      approversList: [],
      watchlistApprovers: [],
      watchlistByApprover: {},
      selectedApproversToSubscribe: {},
    })

    const [isFetching, setIsFetching] = React.useState<boolean>(false)
    const [isSaving, setIsSaving] = React.useState<boolean>(false)
    const [unsubscribingApproverID, setUnsubscribingApproverID] =
      React.useState<number>(null)
    const [selectedApprover, setSelectedApprover] =
      React.useState<WatchlistUser>(null)
    const [approverSearchTerm, setApproverSearchTerm] =
      React.useState<string>('')
    const [isManageListModalOpen, setIsManageListModalOpen] =
      React.useState(false)

    React.useEffect(() => {
      setIsFetching(true)

      ApproverWatchlistAPI.getApproversWatchlist()
        .then((res) => res.data)
        .then((data) => {
          setIsFetching(false)

          setState((state) =>
            produce(state, (draft) => {
              draft.watchlistByApprover = data.json_data.reduce(
                (prev, watchlist) => {
                  prev[watchlist.pk] = watchlist.children

                  return prev
                },
                {}
              )

              draft.approversList = data.selectable_users

              draft.approversByID = data.selectable_users.reduce(
                (prev, approver) => {
                  prev[approver.id] = approver
                  return prev
                },
                {}
              )

              draft.watchlistApprovers = draft.approversList.map((user) => ({
                user,
                watchCount: draft.watchlistByApprover[user.id]
                  ? draft.watchlistByApprover[user.id].length
                  : 0,
              }))
            })
          )

          setSelectedApprover(data.selectable_users[0])
        })
        .catch(() => {
          setIsFetching(false)
        })
    }, [])

    const onSelectApprover = (approver: WatchlistUser) => {
      setSelectedApprover(approver)
      setUnsubscribingApproverID(null)
    }

    const onSelectApproverToSubscribe = (
      selectedApprovers: WatchlistUser[],
      checked: boolean
    ) => {
      setState((state) =>
        produce(state, (draft) => {
          selectedApprovers.forEach((approver) => {
            draft.selectedApproversToSubscribe[approver.id] = !isUndefined(
              checked
            )
              ? checked
              : !draft.selectedApproversToSubscribe[approver.id]
          })
        })
      )
    }

    const onOpenManageListModal = (approversWatchlist: WatchlistUser[]) => {
      setIsManageListModalOpen(true)

      setState((state) =>
        produce(state, (draft) => {
          draft.selectedApproversToSubscribe = approversWatchlist.reduce(
            (prev, approver) => {
              prev[approver.id] = true
              return prev
            },
            {}
          )
        })
      )
    }

    const onDismissManageListModal = () => {
      setIsManageListModalOpen(false)

      setState((state) =>
        produce(state, (draft) => {
          draft.selectedApproversToSubscribe = {}
        })
      )
    }

    const onSubmitApprovers = () => {
      const saveData = {
        subscriber: selectedApprover.id,
        approvers: [],
      }

      Object.keys(selectedApproversToSubscribe)
        .filter((userId) => !!selectedApproversToSubscribe[userId])
        .forEach((userId) => saveData.approvers.push(userId))

      setIsSaving(true)

      ApproverWatchlistAPI.updateApproversWatchlist(saveData)
        .then(() => {
          setIsSaving(false)

          setIsManageListModalOpen(false)

          setState((state) =>
            produce(state, (draft) => {
              draft.watchlistByApprover[selectedApprover.id] = Object.keys(
                selectedApproversToSubscribe
              )
                .filter((userId) => !!selectedApproversToSubscribe[userId])
                .map((userId) => approversByID[userId])

              const updatedApprover = draft.watchlistApprovers.find(
                (data) => data.user.id === selectedApprover.id
              )

              updatedApprover.watchCount =
                draft.watchlistByApprover[selectedApprover.id].length
            })
          )
        })
        .catch(() => {
          setIsSaving(false)
        })
    }

    const onApproverUnsubscribe = (approverToRemove: WatchlistUser) => {
      removeSubscription(selectedApprover.id, approverToRemove.id)
    }

    const removeSubscription = (selectedApproverID, approverIDToRemove) => {
      const newWatchlist = [...watchlistByApprover[selectedApproverID]]

      newWatchlist.splice(
        watchlistByApprover[selectedApproverID].findIndex(
          (approver) => approver.id === approverIDToRemove
        ),
        1
      )

      const saveData = {
        subscriber: selectedApprover.id,
        approvers: [],
      }

      newWatchlist.forEach((approver) => saveData.approvers.push(approver.id))

      setUnsubscribingApproverID(approverIDToRemove)

      ApproverWatchlistAPI.updateApproversWatchlist(saveData)
        .then(() => {
          setUnsubscribingApproverID(null)

          setState((state) => {
            const newState = produce(state, (draft) => {
              draft.watchlistByApprover[selectedApproverID] = newWatchlist

              const updatedApprover = draft.watchlistApprovers.find(
                (data) => data.user.id === selectedApproverID
              )

              updatedApprover.watchCount = newWatchlist.length
            })

            return newState
          })
        })
        .catch(() => {
          setUnsubscribingApproverID(null)
        })
    }

    const filteredWatchlistApproversListBySearch = React.useMemo(() => {
      return watchlistApprovers.filter((approver) =>
        new RegExp(approverSearchTerm, 'i').test(approver.user.name)
      )
    }, [watchlistApprovers, approverSearchTerm])

    const filteredApproversList = selectedApprover
      ? approversList.filter((approver) => {
          // Filter out the selected Approver
          if (approver.id === selectedApprover.id) {
            return false
          }
          return true
        })
      : approversList

    return (
      <Flex.Row
        spacing={0}
        flexDirection='column'
        flexWrap='nowrap'
        height='100%'
      >
        <Flex.Item flexShrink={1}>
          <Breadcrumb>
            <Breadcrumb.Link to={url('SettingsPage')}>
              {intl.formatMessage(settingsMessages.breadcrumbTitle)}
            </Breadcrumb.Link>
            <Breadcrumb.Crumb>
              {intl.formatMessage(messages.breadcrumbTitle)}
            </Breadcrumb.Crumb>
          </Breadcrumb>
        </Flex.Item>
        <Flex.Row alignItems='stretch'>
          <ApproverOverwatchViewStyled>
            <Card>
              <Card.Content height='100%'>
                <Flex.Row spacing={0} flexDirection='column' height='100%'>
                  <Flex.Item>
                    <Header as='h4' gutter>
                      {intl.formatMessage(messages.header)}
                    </Header>

                    <Text>{intl.formatMessage(messages.featureOverview)}</Text>

                    <Text>
                      <FormattedEnhancedMessage
                        {...messages.featureDisclaimer}
                        values={{
                          approvalRoutingLink: (
                            <a href='/#/settings/approvalrouting/request'>
                              Approval Routing
                            </a>
                          ),
                        }}
                      />
                    </Text>
                    <Spacer space={2} />
                  </Flex.Item>

                  <Flex.Row alignItems='stretch'>
                    <ApproverOverwatchSubscriberListModal
                      users={filteredApproversList}
                      open={isManageListModalOpen}
                      selectedUsers={selectedApproversToSubscribe}
                      onSelect={onSelectApproverToSubscribe}
                      onDismiss={onDismissManageListModal}
                      onSubmit={onSubmitApprovers}
                      isSaving={isSaving}
                    />

                    <ApproverOverwatch
                      isApproverListFetching={isFetching}
                      isApproverWatchlistFetching={isFetching}
                      approvers={filteredWatchlistApproversListBySearch}
                      selectedApprover={selectedApprover}
                      selectedApproverWatchlist={
                        selectedApprover
                          ? watchlistByApprover[selectedApprover.id]
                          : []
                      }
                      onApproverSelect={onSelectApprover}
                      onApproverSearch={setApproverSearchTerm}
                      onOpenManageList={() =>
                        onOpenManageListModal(
                          selectedApprover
                            ? watchlistByApprover[selectedApprover.id] || []
                            : []
                        )
                      }
                      onApproverUnsubscribe={onApproverUnsubscribe}
                      unsubscribingApproverID={unsubscribingApproverID}
                    />
                  </Flex.Row>
                </Flex.Row>
              </Card.Content>
            </Card>
          </ApproverOverwatchViewStyled>
        </Flex.Row>
      </Flex.Row>
    )
  })

ApproverOverwatchView.defaultProps = {}
ApproverOverwatchView.displayName = 'ApproverOverwatchView'
