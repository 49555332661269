import * as React from 'react'

import { Badge } from './Badge'
import { WithBadgeStyled } from './styles/WithBadgeStyled'

export interface IWithBadgeProps {
  id?: string
  /** Background color of the badge */
  color?: string
  text?: React.ReactElement | string | number
  show?: boolean
  top?: number | string
  right?: number | string
}

export class WithBadge extends React.Component<IWithBadgeProps> {
  static defaultProps = {
    show: true,
    top: 0,
    right: 0,
  }

  render() {
    const { color, children, text, show, top, right, id } = this.props

    return (
      <WithBadgeStyled top={top} right={right} id={id}>
        {children}
        {show && (
          <Badge color={color} aria-label={this.props?.['aria-label']}>
            {text}
          </Badge>
        )}
      </WithBadgeStyled>
    )
  }
}
