import * as React from 'react'

import {
  Checkbox,
  EmptyState,
  Flex,
  IconButton,
  InputGroup,
  Spacer,
  SubText,
  Table,
  TextField,
} from '@procurify/ui'
import { useIntl } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'

import { UserLabel } from '@webapp/components/UserLabel'
import { type WatchlistUser } from '@webapp/models'
import messages from './messages'
import { ApproverOverwatchSubscriberListStyled } from './styles/ApproverOverwatchSubscriberListStyled'

export interface IApproverOverwatchSubscriberListProps {
  users?: WatchlistUser[]
  selectedUsers?: {
    [userId: string]: boolean
  }
  onSelect?: (user: WatchlistUser[], checked?: boolean) => void
  disabled?: boolean
}

export const ApproverOverwatchSubscriberList: React.FC<IApproverOverwatchSubscriberListProps> =
  React.memo(
    ({
      users = [],
      selectedUsers = {},
      onSelect = () => undefined,
      disabled,
    }) => {
      const intl = useIntl()

      const isAllSelected = users.every((user) => !!selectedUsers[user.id])

      const [searchValue, setSearchValue] = React.useState('')

      const onApproverSearch = useDebouncedCallback((searchTerm: string) => {
        setSearchValue(searchTerm)
      }, 150)

      const onApproverSelect = (
        selectedApprovers: WatchlistUser[],
        checked?: boolean
      ) => {
        if (disabled) return

        onSelect(selectedApprovers, checked)
      }

      const filteredApproverList = users.filter((user) =>
        new RegExp(searchValue, 'i').test(user.name)
      )

      const numSelected = Object.keys(selectedUsers).reduce(
        (prev, curr) => prev + (selectedUsers[curr] ? 1 : 0),
        0
      )

      return (
        <ApproverOverwatchSubscriberListStyled>
          <InputGroup>
            <InputGroup.Item>
              <IconButton
                icon='search'
                aria-label={intl.formatMessage(messages.searchButtonLabel)}
              />
            </InputGroup.Item>
            <TextField
              type='search'
              placeholder={intl.formatMessage(messages.userSelectPlaceholder)}
              onChange={(e) => onApproverSearch(e.target.value)}
            />
          </InputGroup>

          <Spacer />

          <Table aria-label={intl.formatMessage(messages.tableAriaLabel)}>
            <Table.Head>
              <Table.Row>
                <Table.Cell
                  width={1}
                  onClick={() =>
                    onApproverSelect(filteredApproverList, !isAllSelected)
                  }
                >
                  <Checkbox
                    disabled={disabled}
                    checked={!!isAllSelected && filteredApproverList.length > 0}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Flex.Row>
                    <Flex.Item>
                      <SubText>Approvers</SubText>
                    </Flex.Item>
                    <Flex.Item ml='auto'>
                      <SubText>{numSelected} selected</SubText>
                    </Flex.Item>
                  </Flex.Row>
                </Table.Cell>
              </Table.Row>
            </Table.Head>

            {filteredApproverList.length > 0 && (
              <Table.Body>
                {filteredApproverList.map((user) => (
                  <Table.Row
                    key={user.id}
                    onClick={() => onApproverSelect([user])}
                  >
                    <Table.Cell width={1}>
                      <Checkbox
                        disabled={disabled}
                        checked={!!selectedUsers[user.id]}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <UserLabel
                        user={user}
                        WithUserTooltipProps={{ disabled: true }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}

            {filteredApproverList.length === 0 && (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={2}>
                    <EmptyState
                      content={intl.formatMessage(messages.emptyStateText)}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            )}
          </Table>
        </ApproverOverwatchSubscriberListStyled>
      )
    }
  )

ApproverOverwatchSubscriberList.defaultProps = {
  users: [],
  selectedUsers: {},
  onSelect: () => undefined,
}
ApproverOverwatchSubscriberList.displayName = 'ApproverOverwatchSubscriberList'
