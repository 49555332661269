import { defineMessages } from 'react-intl'

const scope = `procurify.components.ErrorBoundary`

export default defineMessages({
  errorHeader: {
    id: `${scope}.errorHeader`,
    defaultMessage: 'We are having trouble displaying that page',
  },
  errorMessage: {
    id: `${scope}.errorMessage`,
    defaultMessage:
      'Error reports have been submitted to the control center. Sorry for the inconvenience.',
  },
})
