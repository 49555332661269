import * as React from 'react'

import {
  EmptyState,
  Flex,
  IconButton,
  InputGroup,
  Table,
  TextField,
} from '@procurify/ui'
import { useIntl } from 'react-intl'
import { useDebouncedCallback } from 'use-debounce'

import { TableLoader } from '@webapp/components/TableLoader'
import { UserLabel } from '@webapp/components/UserLabel'
import { type WatchlistUser } from '@webapp/models'
import messages from './messages'
import { ApproverOverwatchUserTableStyled } from './styles/ApproverOverwatchUserTableStyled'

export interface ApproverOverwatchUserTableData {
  user: WatchlistUser
  watchCount: number
}

export interface IApproverOverwatchUserTableProps {
  approvers?: ApproverOverwatchUserTableData[]
  selectedApproverID?: number
  onSelect?: (approver: WatchlistUser) => void
  onSearch?: (searchterm: string) => void
  isFetching?: boolean
}

export const ApproverOverwatchUserTable: React.FC<IApproverOverwatchUserTableProps> =
  React.memo(
    ({
      approvers = [],
      selectedApproverID,
      onSearch = (searchTerm: string) => undefined,
      onSelect = () => undefined,
      isFetching,
    }) => {
      const intl = useIntl()

      const [searchValue, setSearchValue] = React.useState('')

      const onApproverSearch = (event) => {
        setSearchValue(event.target.value)
        debouncedSearch(event.target.value)
      }

      const debouncedSearch = useDebouncedCallback((searchTerm: string) => {
        onSearch(searchTerm)
      }, 150)

      const onApproverSelect = (user: WatchlistUser) => {
        onSelect(user)
      }

      return (
        <ApproverOverwatchUserTableStyled>
          <Flex.Row
            spacing={0}
            flexDirection='column'
            flexWrap='nowrap'
            height='100%'
          >
            <Flex.Item>
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Cell>
                      {intl.formatMessage(messages.tableHeaderDescriptionText)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body hoverable={false}>
                  <Table.Row>
                    <Table.Cell>
                      <InputGroup>
                        <InputGroup.Item>
                          <IconButton
                            icon='search'
                            onClick={() => onSearch(searchValue)}
                            aria-label={intl.formatMessage(
                              messages.searchButtonLabel
                            )}
                          />
                        </InputGroup.Item>
                        <TextField
                          type='search'
                          placeholder={intl.formatMessage(
                            messages.userSelectPlaceholder
                          )}
                          value={searchValue}
                          onChange={onApproverSearch}
                        />
                      </InputGroup>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Flex.Item>

            <Flex.Item flexGrow={1}>
              <div className='table-scroller'>
                <Table
                  className='user-table'
                  aria-label={intl.formatMessage(messages.tableAriaLabel)}
                >
                  <Table.Head>
                    <Table.Row>
                      <Table.Cell>
                        {intl.formatMessage(messages.tableHeaderApproversText)}
                      </Table.Cell>
                      <Table.Cell>
                        {intl.formatMessage(messages.tableHeaderIsWatchingText)}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Head>

                  {isFetching && <TableLoader rows={10} widths={[150, 100]} />}

                  {!isFetching && approvers.length > 0 && (
                    <Table.Body>
                      {approvers.map((approver) => (
                        <Table.Row
                          key={approver.user.id}
                          selected={selectedApproverID === approver.user.id}
                          onClick={() => onApproverSelect(approver.user)}
                        >
                          <Table.Cell>
                            <UserLabel user={approver.user} />
                          </Table.Cell>
                          <Table.Cell>
                            {approver.watchCount}{' '}
                            {intl.formatMessage(messages.approvers)}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  )}

                  {!isFetching && approvers.length === 0 && (
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell colSpan={2}>
                          <EmptyState
                            content={intl.formatMessage(
                              messages.emptyStateText
                            )}
                          />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  )}
                </Table>
              </div>
            </Flex.Item>
          </Flex.Row>
        </ApproverOverwatchUserTableStyled>
      )
    }
  )

ApproverOverwatchUserTable.defaultProps = {
  approvers: [],
}

ApproverOverwatchUserTable.displayName = 'ApproverOverwatchUserTable'
