import { type Permission } from './Permission'

export const PermissionGroupNames = {
  SUPERUSER: 'Superuser',
  SITE_ADMINISTRATOR: 'EKA',
} as const

export interface PermissionGroup {
  id: number
  name: string // Group name can be any string, as it's often generated.
  user_count: number
  permissions: Permission[]
  type: number
}
