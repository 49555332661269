import { type Enum } from '@webapp/core/types/Enum'

// Expense Status Page
export const ExpenseStatusPageTabStatuses = {
  ALL: 'all',
  PENDING: 'pending',
  DENIED: 'denied',
  APPROVED: 'approved',
} as const

export type ExpenseStatusPageTabStatus = Enum<
  typeof ExpenseStatusPageTabStatuses
>

export interface ExpenseStatusPageParams {
  status: ExpenseStatusPageTabStatus
}

export interface ExpenseDetailsPageParams {
  uuid: uuid
}
