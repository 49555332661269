import * as React from 'react'

import { useHotkeys } from 'react-hotkeys-hook'

import { useInjectReducer, useInjectSaga } from '@webapp/utils'
import { RESTART_ON_REMOUNT } from '@webapp/utils/constants'
import { reducer } from './stores/reducer'
import sagas from './stores/sagas'

const ProcurifyDevToolsChunk = React.lazy(() =>
  import('./ProcurifyDevTools').then(({ ProcurifyDevTools }) => ({
    default: ProcurifyDevTools,
  }))
)

export const ProcurifyDevToolsLoadable = () => {
  const [show, setShow] = React.useState(false)

  useInjectReducer({ key: 'dev', reducer })
  useInjectSaga({
    key: 'dev',
    saga: sagas,
    mode: RESTART_ON_REMOUNT,
  })

  useHotkeys(
    'meta+k',
    () => {
      setShow(true)
    },
    {
      enableOnFormTags: ['input'],
    }
  )

  return (
    <React.Suspense fallback={null}>
      {show && <ProcurifyDevToolsChunk />}
    </React.Suspense>
  )
}
