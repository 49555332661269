import * as React from 'react'

import { SPENDING_CARD_CARD_URL } from '@webapp/app/routers/SpendingCardRouter/constants/urls'
import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { type PayFund } from '@webapp/models/PayFund'
import messages from './messages'

export interface SpendingCardFundDetailsPageParams {
  uuid: PayFund['uuid']
}

const SpendingCardFundDetailsViewChunk = React.lazy(() =>
  import('../containers/SpendingCardFundDetailsViewLegacy').then(
    ({ SpendingCardFundDetailsViewLegacy: SpendingCardFundDetailsView }) => ({
      default: SpendingCardFundDetailsView,
    })
  )
)

export const SpendingCardFundDetailsPageLegacy = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.spendingCardFundDetailsPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.spendingCardDetailsPageTitle),
          link: `${SPENDING_CARD_CARD_URL}/open`,
        },
        {
          name: intl.formatMessage(messages.spendingCardFundDetailsPageTitle),
        },
      ]}
    >
      <SpendingCardFundDetailsViewChunk />
    </Page>
  )
}
