import styled from 'styled-components'

interface IAlertStyledProps {}

export const AlertStyled = styled.div<IAlertStyledProps>`
  font-size: ${(props) =>
    props.theme.typography.pxToRem(props.theme.fontSizes.body1)};

  padding: ${(props) => props.theme.space.medium};

  border-radius: ${(props) =>
    props.theme.typography.pxToRem(props.theme.shape.borderRadius)};
  border: 1px solid ${(props) => props.theme.colors.content.border};

  ul {
    list-style-type: initial;
    list-style-position: inside;
    li {
      margin-left: ${(props) => props.theme.space.medium};
    }
  }
`
