import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { type Enum } from '@webapp/utils'
import messages from './messages'

export const PurchaseOrderListPageTabStatuses = {
  ALL: 'all',
  OPEN: 'open',
  PARTIAL: 'partial',
  CLOSED: 'closed',
  CANCELLED: 'cancelled',
  PAID: 'paid',
} as const

export type PurchaseOrderListPageTabStatus = Enum<
  typeof PurchaseOrderListPageTabStatuses
>

export interface PurchaseOrderListPageParams {
  status: PurchaseOrderListPageTabStatus
}

const PurchaseOrderListViewChunk = React.lazy(() =>
  import('../containers/PurchaseOrderListView').then(
    ({ PurchaseOrderListView }) => ({
      default: PurchaseOrderListView,
    })
  )
)

export const PurchaseOrderListPage = (
  props: RouteComponentProps<PurchaseOrderListPageParams>
) => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.purchaseOrderListPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.purchaseOrderListPageTitle),
        },
      ]}
    >
      <PurchaseOrderListViewChunk {...props} />
    </Page>
  )
}
