import { type IntegrationLegacyPlatform } from '@webapp/models/enums/IntegrationPlatforms'
import { ApiClient } from '@webapp/services/api'

export const REQUEST_DEMO_URL = '/api/v2/integration/demo/'

export interface RequestDemoResponse {
  responseString: string
}

export const requestDemo = (integration: IntegrationLegacyPlatform) => {
  return ApiClient.post<string, RequestDemoResponse>(
    REQUEST_DEMO_URL,
    JSON.stringify(integration),
    {
      headers: { 'Content-Type': 'application/json' },
    }
  )
}
