import * as React from 'react'

import { Helmet } from 'react-helmet'

import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const IntegrationPlatformListViewChunk = React.lazy(() =>
  import('../containers/IntegrationPlatformListView').then(
    ({ IntegrationPlatformListView }) => ({
      default: IntegrationPlatformListView,
    })
  )
)

export const IntegrationPlatformListPage = () => {
  const intl = useProcurifyIntl()

  return (
    <React.Suspense fallback={null}>
      <Helmet>
        <title>
          Procurify | {intl.formatMessage(messages.integrationsListTitle)}
        </title>
      </Helmet>
      <IntegrationPlatformListViewChunk />
    </React.Suspense>
  )
}
