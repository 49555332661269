import * as React from 'react'

import { Table } from '@material-ui/core'
import styled, { css } from 'styled-components'

import { type ITableProps } from './../Table'
import { TableCellStyled } from './TableCellStyled'

export const TableStyled = styled(
  ({
    zebraTableBodyAsRow,
    tableBodyCellPadding,
    ...otherProps
  }: ITableProps) => (
    <Table
      {...otherProps}
      classes={{
        stickyHeader: 'stickyHeader',
      }}
    />
  )
)`
  border: 1px solid ${(props) => props.theme.colors.content.border};
  font-family: 'Inter', sans-serif;
  color: ${(props) => props.theme.colors.text.primary};

  width: ${(props) => props.width || 'auto'};

  ${(props) =>
    props.zebraTableBodyAsRow &&
    css`
      & tbody:nth-child(odd) {
        background-color: ${props.theme.colors.offwhite};
      }

      & tbody tr:nth-child(even) {
        background-color: inherit;
      }
    `}

  ${(props) =>
    props.tableBodyCellPadding &&
    css`
      tbody > tr > ${TableCellStyled} {
        padding-top: ${props.theme.typography.pxToRem(
          props.tableBodyCellPadding
        )};
        padding-bottom: ${props.theme.typography.pxToRem(
          props.tableBodyCellPadding
        )};
      }
    `}

  &.stickyHeader {
    border: none;

    tbody > tr:not(:last-child) > td {
      border-bottom: 1px solid ${(props) => props.theme.colors.content.border};
    }
  }
`
