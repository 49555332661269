import * as React from 'react'

import { Media } from '@procurify/ui'

import { FormatMedia } from '../../core/hoc/FormatMedia/FormatMedia'

export interface IUserAvatarProps {
  url?: string
  circular?: boolean
  shadow?: boolean
  size?: 'xsmall' | 'small' | 'large'
}

const FALLBACK_URL =
  'https://www.gravatar.com/avatar/d5d337c1de8ae8da734a1fe838c0acb4?d=https://storage.googleapis.com/procurifylite/default.png&s=120'

export const UserAvatar = ({
  url = FALLBACK_URL,
  size,
  circular,
  shadow,
}: IUserAvatarProps) => {
  return (
    <FormatMedia url={url}>
      {(src) => (
        <Media
          as='div'
          circular={circular}
          shadow={shadow}
          src={src || FALLBACK_URL}
          fallbackSrc={FALLBACK_URL}
          size={size}
        />
      )}
    </FormatMedia>
  )
}

UserAvatar.defaultProps = {}
UserAvatar.displayName = 'UserAvatar'
