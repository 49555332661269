import { IconComponentStyled } from '@procurify/ui'
import styled from 'styled-components'

export const AppHeaderButtonStyled = styled.button`
  position: relative;

  display: flex;
  align-items: center;

  background: none;
  border: none;

  height: ${(props) => props.theme.typography.pxToRem(48)};
  line-height: ${(props) => props.theme.typography.pxToRem(48)};
  text-align: center;

  padding: 0;

  &:active,
  &:focus,
  &:hover {
    outline: none;

    cursor: pointer;
  }

  ${IconComponentStyled} {
    display: inline;
  }
`
