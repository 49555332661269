import styled, { css } from 'styled-components'

import { type ITableProps } from '../Table'

export const TableContainer = styled.div<ITableProps>`
  ${(props) =>
    props.horizontalScroll &&
    css`
      width: 100%;
      overflow-x: auto;

      border-right: 1px solid ${props.theme.colors.content.border};
      border-left: 1px solid ${props.theme.colors.content.border};

      > table {
        border-left: none;
        border-right: none;

        > thead > tr > th:first-child,
        > tbody > tr > td:first-child,
        > tbody > tr {
          border-left: none;
        }

        > thead > tr > th:last-child,
        > tbody > tr > td:last-child,
        > tbody > tr {
          border-right: none;
        }
      }
    `}
`
