import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl, useAppRoutes } from '@webapp/hooks'
import { type Enum } from '@webapp/utils'
import messages from './messages'

export const ApprovalRoutingPageTabTypes = {
  REQUESTS: 'requests',
  BILLS: 'bills',
  PAYMENTS: 'payments',
  VENDORS: 'vendors',
} as const
export type ApprovalRoutingPageTabType = Enum<
  typeof ApprovalRoutingPageTabTypes
>

export interface ApprovalRoutingPageParams {
  tab: ApprovalRoutingPageTabType
}

const ApprovalRoutingChunk = React.lazy(() =>
  import('../containers/ApprovalRoutingView').then(
    ({ ApprovalRoutingView }) => ({
      default: ApprovalRoutingView,
    })
  )
)

export const ApprovalRoutingPage = () => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.approvalRoutingPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.settingsPageTitle),
          link: url('SettingsPage'),
        },
        {
          name: intl.formatMessage(messages.approvalRoutingPageTitle),
        },
      ]}
    >
      <ApprovalRoutingChunk />
    </Page>
  )
}
