import * as React from 'react'

import { Label, SubText } from '@procurify/ui'
import moment from 'moment'

import { useProcurifyIntl } from '@webapp/hooks'
import { type ApplicationStatus } from '@webapp/models'
import messages from './messages'
import { TrialModeIndicatorStyled } from './styles/TrialModeIndicatorStyled'

export interface ITrialModeIndicatorProps {
  applicationStatus: ApplicationStatus
}

export const TrialModeIndicator = ({
  applicationStatus,
}: ITrialModeIndicatorProps) => {
  const intl = useProcurifyIntl()

  const { expiry_date, lock_mode, trial_mode } = applicationStatus

  const differenceInDays = moment(expiry_date).diff(
    moment().startOf('day'),
    'days'
  )

  const daysLeftInTrial = differenceInDays > 0 ? differenceInDays : 0

  return (
    <TrialModeIndicatorStyled>
      <Label
        {...(trial_mode && {
          label: intl.formatMessage(messages.trialMode).toLocaleUpperCase(),
        })}
        {...(lock_mode && {
          label: intl.formatMessage(messages.lockMode).toLocaleUpperCase(),
          bgcolor: 'error',
        })}
        fluid
      >
        <SubText bold inline>
          {daysLeftInTrial}
        </SubText>{' '}
        <SubText inline>
          {intl.formatMessage(messages.daysLeftInTrial, {
            daysLeftInTrial,
          })}
        </SubText>
      </Label>
    </TrialModeIndicatorStyled>
  )
}

TrialModeIndicator.displayName = 'TrialModeIndicator'
