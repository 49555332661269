import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { type Enum } from '@webapp/utils'

export const BillListPageStatuses = {
  ALL: 'all',
  DRAFT: 'draft',
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
  PAID: 'paid',
} as const

export type BillListPageStatus = Enum<typeof BillListPageStatuses>

export interface BillListPageParams {
  status: BillListPageStatus
}

const BillListViewChunk = React.lazy(() =>
  import('../containers/BillListView').then(({ BillListView }) => ({
    default: BillListView,
  }))
)

export class BillListPage extends React.PureComponent<
  RouteComponentProps<BillListPageParams>
> {
  render() {
    return (
      <React.Suspense fallback={null}>
        <BillListViewChunk {...this.props} />
      </React.Suspense>
    )
  }
}
