import * as React from 'react'

import { BreadcrumbCrumb } from './BreadcrumbCrumb'
import { BreadcrumbLink } from './BreadcrumbLink'
import { BreadcrumbStyled } from './styles/BreadcrumbStyled'

export interface IBreadcrumbProps {}

export const Breadcrumb = (props: IBreadcrumbProps) => {
  return <BreadcrumbStyled {...props} />
}

Breadcrumb.Crumb = BreadcrumbCrumb
Breadcrumb.Link = BreadcrumbLink
