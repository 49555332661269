import styled from 'styled-components'

import { BadgeStyled } from './BadgeStyled'

interface IWithBadgeStyled {
  top: number | string
  right: number | string
}

export const WithBadgeStyled = styled.div<IWithBadgeStyled>`
  display: inline-block;
  position: relative;

  ${BadgeStyled} {
    position: absolute;
    top: ${(props) => props.top};
    right: ${(props) => props.right};
  }
`
