import * as React from 'react'

import {
  Flex,
  type IModalProps,
  Modal,
  PrimaryButton,
  Spacer,
  TertiaryButton,
} from '@procurify/ui'
import { useIntl } from 'react-intl'

import {
  ApproverOverwatchSubscriberList,
  type IApproverOverwatchSubscriberListProps,
} from './ApproverOverwatchSubscriberList'
import messages from './messages'

export interface IApproverOverwatchSubscriberListModalProps
  extends IApproverOverwatchSubscriberListProps {
  open: IModalProps['open']
  onDismiss?: () => void
  onSubmit?: (selectedUsers) => void
  isSaving?: boolean
}

export const ApproverOverwatchSubscriberListModal: React.FC<IApproverOverwatchSubscriberListModalProps> =
  React.memo(
    ({
      users = [],
      selectedUsers = {},
      onSelect = () => undefined,
      open,
      onDismiss = () => undefined,
      onSubmit = () => undefined,
      isSaving,
    }) => {
      const intl = useIntl()

      return (
        <Modal open={open} onClose={onDismiss}>
          <Modal.Card>
            <Modal.Header onClose={onDismiss}>
              {intl.formatMessage(messages.modalTitle)}
            </Modal.Header>
            <Modal.Content>
              <ApproverOverwatchSubscriberList
                users={users}
                selectedUsers={selectedUsers}
                onSelect={onSelect}
                disabled={isSaving}
              />

              <Spacer />

              <Flex.Row>
                <Flex.Item ml='auto'>
                  <TertiaryButton disabled={isSaving} onClick={onDismiss}>
                    Cancel
                  </TertiaryButton>
                  <PrimaryButton
                    isLoading={isSaving}
                    onClick={() => onSubmit(selectedUsers)}
                  >
                    Save
                  </PrimaryButton>
                </Flex.Item>
              </Flex.Row>
            </Modal.Content>
          </Modal.Card>
        </Modal>
      )
    }
  )

ApproverOverwatchSubscriberListModal.defaultProps = {
  users: [],
  selectedUsers: {},
  onSelect: () => undefined,
  isSaving: false,
}
ApproverOverwatchSubscriberListModal.displayName =
  'ApproverOverwatchSubscriberList'
