import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import messages from './messages'
import { VendorApprovalListPageTabStatuses } from './VendorApprovalListPage'

export interface VendorApprovalDetailsPageParams {
  id: string
}

const VendorApprovalDetailsViewChunk = React.lazy(() =>
  import('../containers/VendorApprovalDetailsView').then(
    ({ VendorApprovalDetailsView }) => ({
      default: VendorApprovalDetailsView,
    })
  )
)

export const VendorApprovalDetailsPage = () => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.VendorApprovalDetailsPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.vendorApprovalListPageTitle),
          link: url('VendorApprovalListPage', {
            status: VendorApprovalListPageTabStatuses.PENDING,
          }),
        },
        {
          name: intl.formatMessage(messages.VendorApprovalDetailsPageTitle),
        },
      ]}
    >
      <VendorApprovalDetailsViewChunk />
    </Page>
  )
}
