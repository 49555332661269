import * as React from 'react'

import { TableBody } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { theme } from '@procurify/ui-theme'

import { type ITableBodyProps } from './../TableBody'

const styles = {
  root: {
    '& tr:nth-child(even)': {
      backgroundColor: `${theme.colors.offwhite}`,
    },
  },
}

class TableBodyStyledComponent extends React.Component<
  { classes: any } & ITableBodyProps
> {
  render() {
    const { classes, zebra, zebraTableBody, hoverable, ...otherProps } =
      this.props
    return (
      <TableBody
        classes={{ root: zebra ? classes.root : classes.noZebra }}
        {...otherProps}
      />
    )
  }
}

export const TableBodyStyled = withStyles(styles)(TableBodyStyledComponent)
