import styled from 'styled-components'

import { LabelCaptionStyled } from '@ui/elements/Label/styles/LabelCaptionStyled'

export interface ILabelImageStyledProps {
  image?: string
}

export const LabelImageStyled = styled(
  LabelCaptionStyled
)<ILabelImageStyledProps>`
  min-width: ${(props) => props.theme.typography.pxToRem(30)};
  background-image: ${(props) => `url(${props.image})`};
`
