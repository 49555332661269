import { defineMessages } from 'react-intl'

const scope = `procurify.components.PunchOutWelcome`

export default defineMessages({
  benefits: {
    id: `${scope}.benefits`,
    defaultMessage: 'Benefits',
  },
  requestDemo: {
    id: `${scope}.requestDemo`,
    defaultMessage: 'Request a demo',
  },
  apiRequestSuccess: {
    id: `${scope}.benefits`,
    defaultMessage:
      'Thank you for your interest! Someone from Procurify will reach out to you.',
  },
  apiRequestError: {
    id: `${scope}.benefits`,
    defaultMessage:
      'There was an error while requesting an demo, please try again or contact support@procurify.com.',
  },
  apiRequestSuccessTitle: {
    id: `${scope}.benefits`,
    defaultMessage: 'Success',
  },
  apiRequestErrorTitle: {
    id: `${scope}.benefits`,
    defaultMessage: 'Service error',
  },
  punchoutIntegrationTitle: {
    id: `${scope}.punchoutIntegrationTitle`,
    defaultMessage: 'Procurify and Punchout Integration',
  },
  punchoutIntegrationSummary: {
    id: `${scope}.punchoutIntegrationSummary`,
    defaultMessage: 'Streamline Requests and Automate Purchasing',
  },
  punchoutIntegrationParagraph1: {
    id: `${scope}.punchoutIntegrationParagraph1`,
    defaultMessage:
      'PunchOut integration allows Procurify users to browse various suppliers like Amazon and Staples and add items to a Request for Order. Once these orders are Approved, the items will be purchased and shipped, with no additional Procurement steps.',
  },
  punchoutIntegrationParagraph2: {
    id: `${scope}.punchoutIntegrationParagraph2`,
    defaultMessage:
      'Business-ready Purchase Orders are created instantaneously, eliminating the need for manual entry, and making the process easy and worry free.',
  },
  punchoutIntegrationBenefitsHeader1: {
    id: `${scope}.punchoutIntegrationBenefitsHeader1`,
    defaultMessage: 'Browse items directly from the supplier portal',
  },
  punchoutIntegrationBenefitsBody1: {
    id: `${scope}.punchoutIntegrationBenefitsBody1`,
    defaultMessage:
      'Shop through the supplier portal and your cart will be synced directly to Procurify, where the items you select will be ready for request submission. No more copying and pasting links into Procurify.',
  },
  punchoutIntegrationBenefitsHeader2: {
    id: `${scope}.punchoutIntegrationBenefitsHeader2`,
    defaultMessage: 'Instant purchase after approval',
  },
  punchoutIntegrationBenefitsBody2: {
    id: `${scope}.punchoutIntegrationBenefitsBody2`,
    defaultMessage:
      'Once the request is approved, items are immediately purchased through the supplier. Requesters no longer need to wait for the Purchasers to place the order.',
  },
  punchoutIntegrationBenefitsHeader3: {
    id: `${scope}.punchoutIntegrationBenefitsHeader3`,
    defaultMessage: 'Automatic PO creation',
  },
  punchoutIntegrationBenefitsBody3: {
    id: `${scope}.punchoutIntegrationBenefitsBody3`,
    defaultMessage:
      'Along with instant purchasing, an automatic Purchase Order is created at approval. This eliminates the manual process while increasing accuracy.',
  },
  punchoutIntegrationBenefitsHeader4: {
    id: `${scope}.punchoutIntegrationBenefitsHeader4`,
    defaultMessage: 'Business-only Prices',
  },
  punchoutIntegrationBenefitsBody4: {
    id: `${scope}.punchoutIntegrationBenefitsBody4`,
    defaultMessage:
      'Some suppliers offer price discounts just for businesses on millions of products. ',
  },
  punchoutIntegrationBenefitsHeader5: {
    id: `${scope}.punchoutIntegrationBenefitsHeader5`,
    defaultMessage: 'Compare Offers',
  },
  punchoutIntegrationBenefitsBody5: {
    id: `${scope}.punchoutIntegrationBenefitsBody5`,
    defaultMessage:
      'Satisfy your sourcing requirements: See multiple offers from a variety of sellers on a single page. ',
  },
})
