import * as React from 'react'

import { ListItemIcon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { theme } from '@procurify/ui-theme'

import { Icon, type IconTypes } from '../../../elements/Icon'

const styles = {
  root: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: theme.space.small,
    marginRight: 0,
    color: theme.colors.darkGrey,
    minWidth: 0,
  },
}

interface ListItemIconStyledProps extends React.Props<any> {
  pIcon?: IconTypes
  className?: string
}

class ListItemIconStyledComponent extends React.Component<
  { classes: any } & ListItemIconStyledProps
> {
  render() {
    const { classes, children, ...otherProps } = this.props
    return children ? (
      <ListItemIcon
        classes={{ root: classes.root }}
        className={this.props.className}
      >
        <>{children}</>
      </ListItemIcon>
    ) : null
  }
}

export const ListItemIconStyled = withStyles(styles)(
  ListItemIconStyledComponent
)
