import { em, rem } from 'polished'
import styled from 'styled-components'

interface IApproverOverwatchUserWatchListTableStyledProps {}

export const ApproverOverwatchUserWatchListTableStyled = styled.div<IApproverOverwatchUserWatchListTableStyledProps>`
  font-size: ${rem(16)};

  height: 100%;

  .selected-user-info {
    border: 1px solid ${(props) => props.theme.colors.content.border};
    border-bottom: none;

    padding: ${em(12)};
  }
`
