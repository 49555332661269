import { type Enum } from '@webapp/utils'

export const SpendInsightPeriodDurations = {
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
} as const

export type SpendInsightPeriodDuration = Enum<
  typeof SpendInsightPeriodDurations
>
