import * as React from 'react'

import { Icon } from '@procurify/ui'
import classNames from 'classnames'

import { type IBridgeLinkProps } from '@webapp/core/components/BridgeLink'
import { BreadcrumbLinkStyled } from './styles/BreadcrumbLinkStyled'

export interface IBreadcrumbLinkProps extends IBridgeLinkProps {
  children: string
  escapeCaseFormatting?: boolean
}

export const BreadcrumbLink = (props: IBreadcrumbLinkProps) => {
  const { className, children, ...otherProps } = props

  let formattedChildren = children
  if (!props.escapeCaseFormatting) {
    formattedChildren =
      children.charAt(0).toUpperCase() + children.slice(1).toLowerCase()
  }

  return (
    <>
      <Icon icon='chevron-thick-right' />
      <BreadcrumbLinkStyled
        className={classNames('crumb', className)}
        {...otherProps}
      >
        {formattedChildren}
      </BreadcrumbLinkStyled>
    </>
  )
}

BreadcrumbLink.displayName = 'Breadcrumb.Link'
