import * as React from 'react'

import { Flex, Spacer } from '@procurify/ui'

import { Breadcrumb } from '@webapp/core/components/Breadcrumb'
import { useFeatureFlag } from '@webapp/hooks'
import { PageTopContainerStyled } from './styles/PageTopContainerStyled'

export interface Crumb {
  link?: string
  name: string
  escapeCaseFormatting?: boolean
}

export interface IPageTopContainerProps {
  breadcrumbs: Crumb[]
}

export const PageTopContainer = ({
  breadcrumbs = [],
}: React.PropsWithChildren<IPageTopContainerProps>) => {
  const CL_1058_AP_GRANULAR_PERMISSIONS = useFeatureFlag(
    'CL_1058_AP_GRANULAR_PERMISSIONS'
  )

  return (
    <PageTopContainerStyled>
      <Flex.Row alignItems='center'>
        <Flex.Item>
          {breadcrumbs?.length > 0 ? (
            <Breadcrumb>
              {breadcrumbs.map((crumb) =>
                crumb?.link ? (
                  <Breadcrumb.Link
                    key={`${crumb.name}-${crumb.link}`}
                    to={crumb.link}
                    escapeCaseFormatting={crumb.escapeCaseFormatting}
                  >
                    {crumb.name}
                  </Breadcrumb.Link>
                ) : (
                  <Breadcrumb.Crumb
                    key={crumb.name}
                    escapeCaseFormatting={crumb.escapeCaseFormatting}
                  >
                    {crumb.name}
                  </Breadcrumb.Crumb>
                )
              )}
            </Breadcrumb>
          ) : null}
        </Flex.Item>
        {CL_1058_AP_GRANULAR_PERMISSIONS && (
          <Flex.Item>
            <div id='page-badge-container' />
          </Flex.Item>
        )}
        <Flex.Item ml='auto'>
          <div id='page-action-container' />
        </Flex.Item>
      </Flex.Row>

      <Spacer />
    </PageTopContainerStyled>
  )
}

PageTopContainer.displayName = 'PageTopContainer'
