import { createSelector } from 'reselect'

import { initialState } from './reducer'
import { type IDevState } from './types'

export const selectDev = (state): IDevState => {
  return state.dev || initialState
}

export const makeSelectHasFFOverride = () =>
  createSelector(selectDev, (state) => state.hasFFOverride)

export const makeSelectLiveFFState = () =>
  createSelector(selectDev, (state) => state.liveFlags)
