import * as React from 'react'

import type { StandardTextFieldProps } from '@material-ui/core/TextField'

import { TextFieldStyled } from './styles/TextFieldStyled'

export interface ITextFieldProps extends StandardTextFieldProps {}

// Styled Material TextField
export const TextField: React.FunctionComponent<ITextFieldProps> = (props) => (
  <TextFieldStyled {...(props as any)} />
)

TextField.displayName = 'TextField'
