import * as React from 'react'

import { type TooltipProps } from '@material-ui/core/Tooltip'

import { WithTooltipWrapper } from './components/WithTooltipWrapper'
import { MuiTooltipStyled } from './styles/MuiTooltipStyled'
import { type IUIProviderValue, UIContext } from '../../core/UIContext'

export interface IWithTooltipProps extends TooltipProps {
  key?: any
  isDisabled?: boolean
  textAlign?: 'left' | 'center' | 'right'
}

export class WithTooltip extends React.Component<IWithTooltipProps> {
  static readonly Wrapper = WithTooltipWrapper

  static readonly contextType = UIContext
  context: IUIProviderValue

  render() {
    const { isDisabled, children, ...otherProps } = this.props

    const { container } = this.context

    return isDisabled ? (
      children
    ) : (
      <MuiTooltipStyled
        {...otherProps}
        PopperProps={{
          container,
        }}
      >
        {children}
      </MuiTooltipStyled>
    )
  }
}
