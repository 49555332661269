import { type WatchlistUser } from '@webapp/models'
import { ApiClient } from '../services/api'

export const ENDPOINT = '/settings/notifications/approver/'

export interface ApproverWatchlistAPIResponse {
  selectable_users: WatchlistUser[]
  json_data: {
    pk: number
    children: WatchlistUser[]
    name: string
  }[]
}

export interface ApproverWatchlistAPIPostSchema {
  action?: string
  subscriber: number
  approvers: number[]
}

export const ApproverWatchlistAPI = {
  getApproversWatchlist: () => {
    return ApiClient.get<ApproverWatchlistAPIResponse>(`${ENDPOINT}?json`)
  },
  updateApproversWatchlist: (data: ApproverWatchlistAPIPostSchema) => {
    const formData = new FormData()

    formData.set('action', data.approvers.length ? 'update_sub' : 'clear_sub')
    formData.set('subscriber', data.subscriber.toString())

    data.approvers.forEach((approverId) => {
      formData.append('approvers[]', approverId.toString())
    })

    return ApiClient.post(ENDPOINT, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
}
