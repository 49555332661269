import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { type Enum } from '@webapp/utils'
import messages from './messages'

export const PaymentManagePayeesPageTabs = {
  VENDORS: 'vendors',
  CREDIT_CARD_PROVIDERS: 'credit-card-providers',
} as const
export type PaymentManagePayeesPageTab = Enum<
  typeof PaymentManagePayeesPageTabs
>

export const PaymentManagePayeesPageVendorDetailsTabs = {
  PAYMENT_METHODS: 'payment-methods',
  CONTACT_INFO: 'contact-info',
  SETTINGS: 'settings',
  DOCUMENTS: 'documents',
} as const
export type PaymentManagePayeesPageVendorDetailsTab = Enum<
  typeof PaymentManagePayeesPageVendorDetailsTabs
>

export const PaymentManagePayeesPageCreditCardProviderDetailsTabs = {
  PAYMENT_METHODS: 'payment-methods',
  CONTACT_INFO: 'contact-info',
  SETTINGS: 'settings',
  CREDIT_CARDS: 'credit-cards',
} as const
export type PaymentManagePayeesPageCreditCardProviderDetailsTab = Enum<
  typeof PaymentManagePayeesPageCreditCardProviderDetailsTabs
>

interface PaymentManagePayeesPageDetailsTabMapping {
  [PaymentManagePayeesPageTabs.VENDORS]: PaymentManagePayeesPageVendorDetailsTab
  [PaymentManagePayeesPageTabs.CREDIT_CARD_PROVIDERS]: PaymentManagePayeesPageCreditCardProviderDetailsTab
}

export interface PaymentManagePayeesPageParams<
  T extends PaymentManagePayeesPageTab = PaymentManagePayeesPageTab,
> {
  type: T
  id?: string
  tab?: PaymentManagePayeesPageDetailsTabMapping[T] | ''
}

const PaymentManagePayeesViewChunk = React.lazy(() =>
  import('../containers/PaymentManagePayeesView').then(
    ({ PaymentManagePayeesView }) => ({
      default: PaymentManagePayeesView,
    })
  )
)

export const PaymentManagePayeesPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.paymentManagePayeesPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.paymentManagePayeesPageTitle),
        },
      ]}
    >
      <PaymentManagePayeesViewChunk />
    </Page>
  )
}
