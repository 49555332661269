import { LabelContentStyled } from '@procurify/ui'
import { LabelStyled } from '@procurify/ui'
import styled from 'styled-components'

interface IUserLabelStyledProps {}

export const UserLabelStyled = styled.div<IUserLabelStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  display: inline-flex;

  ${LabelStyled} {
    background: transparent;
    align-items: center;

    ${LabelContentStyled} {
      padding-right: 0;
    }
  }
`
