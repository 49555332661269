import { rem } from 'polished'
import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

export const GlobalStyles = createGlobalStyle<any>`
    ${reset}

    * {
      font-family: 'Inter', sans-serif;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &:active {
        outline: none;
      }
    }

    html, body {
      font-size: ${(props) =>
        (props.theme.typography.htmlFontSize / 16) * 100}%;
      color: ${(props) => props.theme.colors.text.primary};
      height: 100%;
    }

    #root {
      height: 100%;
    }

    body {
      line-height: 1.4;
      font-family: 'Inter', sans-serif;
    }

    .text-align {
      text-align: center !important;
    }

    .text-right {
      text-align: right !important;
    }

    a, a:visited {
      cursor: pointer;
      color: ${(props) => props.theme.colors.text.link};
      text-decoration: none;
    }

    strong {
      font-weight: ${(props) => props.theme.typography.fontWeightBold}
    }

    i {
      font-style: italic;
    }

    ::-webkit-scrollbar {
      height: ${rem(10)};
      width: ${rem(10)};
      background: ${(props) => props.theme.colors.offwhite};
    }

    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.darkGrey};
        box-shadow: 0 ${rem(1)} ${rem(2)} rgba(0, 0, 0, 0.2);
    }

    ::-webkit-scrollbar-corner {
        background: #bbb;
    }
  `
