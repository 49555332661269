import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

export const WELCOME_PAGE_BASE_URL = '/welcome'

const WelcomeChunk = React.lazy(() =>
  import('../containers/WelcomeView').then(({ WelcomeView }) => ({
    default: WelcomeView,
  }))
)

export class WelcomePage extends React.PureComponent<RouteComponentProps> {
  render() {
    return (
      <React.Suspense fallback={null}>
        <WelcomeChunk {...this.props} />
      </React.Suspense>
    )
  }
}
