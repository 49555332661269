import * as React from 'react'

import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

import messages from './messages'

const APIApplicationChunk = React.lazy(() =>
  import('../containers/APIApplication').then(({ APIApplication }) => ({
    default: APIApplication,
  }))
)

export const APIApplicationPage = () => {
  const intl = useIntl()
  return (
    <React.Suspense fallback={null}>
      <Helmet>
        <title>
          Procurify | {intl.formatMessage(messages.apiApplicationTitle)}
        </title>
      </Helmet>
      <APIApplicationChunk />
    </React.Suspense>
  )
}
