import * as React from 'react'

import { StatusLabel, StatusLabelStatuses, Text } from '@procurify/ui'

import { useProcurifyIntl } from '@webapp/hooks'
import { type NavigationNotificationCount } from '../../../stores/notificationCount'
import messages from '../messages'
import { NavigationSublinkStyled } from '../styles/NavigationSublinkStyled'
import { type TagType, TagTypes } from '../types'

export interface INavigationSublinkProps {
  id: string
  title: string
  to: string
  legacy?: boolean
  notificationKey?: string
  notificationCount: NavigationNotificationCount
  active?: boolean
  tag?: TagType
  onClick?: () => void
}

export const NavigationSublink = ({
  title,
  to,
  notificationKey,
  legacy = false,
  notificationCount = {},
  active,
  id,
  tag,
  onClick,
}: INavigationSublinkProps) => {
  const intl = useProcurifyIntl()

  const tagText =
    tag === TagTypes.NEW
      ? intl.formatMessage(messages.newTag)
      : intl.formatMessage(messages.betaTag)

  let badgeCount = null
  if (notificationKey && notificationCount[notificationKey] > 0) {
    badgeCount = notificationCount[notificationKey]
  }

  return (
    <NavigationSublinkStyled
      id={id}
      to={to}
      legacy={legacy}
      className={active ? 'active' : ''}
      onClick={onClick}
    >
      <Text bold={active}> {title}</Text>
      {badgeCount > 0 && <Text bold> {badgeCount}</Text>}
      {tag && (
        <StatusLabel
          type={StatusLabelStatuses.NEW}
          text={tagText}
          fontSize={12}
        />
      )}
    </NavigationSublinkStyled>
  )
}
