import * as React from 'react'

import { debounce, throttle } from 'lodash'
import {
  type GroupBase,
  type InputActionMeta,
  type OptionsOrGroups,
} from 'react-select'
import { type AsyncProps } from 'react-select/async'

import { type ISelectProps, Select } from './Select'
import { handleInputChange } from './utils'

export type MutableGroupBase<Option> = {
  -readonly [key in keyof GroupBase<Option>]: GroupBase<Option>[key]
}
export interface AsyncSelectPagination {
  hasMore: boolean
  page: number
}

export type AsyncSelectLoadOptions<T> = (
  data: any,
  pagination: AsyncSelectPagination,
  cb: (args: {
    options: OptionsOrGroups<T, GroupBase<T>>
    pagination: Partial<AsyncSelectPagination>
  }) => void
) => void

export interface IAsyncSelectProps<
  Option = any,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends ISelectProps<Option, IsMulti, Group>,
    Omit<AsyncProps<Option, IsMulti, Group>, 'loadOptions'> {
  loadOptions?: AsyncSelectLoadOptions<Option>
  concatOptions?: (
    prevOptions: OptionsOrGroups<Option, MutableGroupBase<Option>>,
    newOptions: OptionsOrGroups<Option, MutableGroupBase<Option>>
  ) => OptionsOrGroups<Option, GroupBase<Option>>
  error?: boolean
  clearCacheOnMenuClose?: boolean
  cacheOptions?: boolean
}

export const defaultProps = {
  clearCacheOnMenuClose: false,
  concatOptions: (prevOptions, newOptions) => prevOptions.concat(newOptions),
}

interface IAsyncSelectState<
  Option = any,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> {
  defaultOptions?: AsyncProps<Option, IsMulti, Group>['defaultOptions']
  inputValue: string
  isLoading: boolean
  loadedInputValue?: string
  loadedOptions: OptionsOrGroups<Option, GroupBase<Option>>
  passEmptyOptions: boolean
}

interface AsyncOptionCache<Option> {
  options: OptionsOrGroups<Option, GroupBase<Option>>
  pagination: {
    hasMore: boolean
    page: 1
    [x: string]: any
  }
}

export class AsyncSelect<
  Option,
  IsMulti extends boolean = false,
> extends React.Component<
  IAsyncSelectProps<Option, IsMulti>,
  IAsyncSelectState<Option>
> {
  static defaultProps = defaultProps

  readonly state: IAsyncSelectState<Option> = {
    defaultOptions: this.props.defaultOptions,
    inputValue: this.props.inputValue,
    isLoading: false,
    loadedOptions: [],
    passEmptyOptions: false,
  }

  select: HTMLInputElement
  lastRequest: object
  mounted: boolean = false
  isMenuOpen: boolean
  optionsCache: {
    [x: string]: AsyncOptionCache<Option>
  } = {}

  debouncedLoadOptions = debounce((inputValue, fetchMore) => {
    this.loadOptions(inputValue, fetchMore)
  }, 150)

  componentDidMount() {
    this.mounted = true

    const { defaultOptions } = this.props
    const { inputValue } = this.state
    if (defaultOptions === true) {
      this.loadOptions(inputValue, false)
    }
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: IAsyncSelectProps<Option, IsMulti>
  ) {
    // if the cacheOptions prop changes, clear the cache
    if (nextProps.cacheOptions !== this.props.cacheOptions) {
      this.optionsCache = {}
    }
    if (nextProps.defaultOptions !== this.props.defaultOptions) {
      this.setState({
        defaultOptions: Array.isArray(nextProps.defaultOptions)
          ? nextProps.defaultOptions
          : undefined,
      })
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  loadOptions(inputValue: string, fetchMore: boolean) {
    const { loadOptions, cacheOptions } = this.props

    const request = (this.lastRequest = {})
    const callback = ({ options, pagination }) => {
      if (!this.mounted) return

      if (request !== this.lastRequest) return
      delete this.lastRequest

      if (options) {
        if (fetchMore) {
          this.optionsCache[inputValue].options = this.props.concatOptions(
            this.optionsCache[inputValue].options,
            options
          )
        } else {
          this.optionsCache[inputValue].options = options
        }
      }

      this.optionsCache[inputValue].pagination = pagination

      this.setState({
        isLoading: false,
        loadedInputValue: inputValue,
        loadedOptions: this.optionsCache[inputValue].options || [],
        passEmptyOptions: false,
      })
    }

    if (cacheOptions && this.optionsCache[inputValue] && !fetchMore) {
      this.setState({
        inputValue,
        isLoading: false,
        loadedInputValue: inputValue,
        loadedOptions: this.optionsCache[inputValue].options,
        passEmptyOptions: false,
      })
    } else if (
      !this.optionsCache[inputValue] ||
      (this.optionsCache[inputValue] &&
        this.optionsCache[inputValue].pagination.hasMore)
    ) {
      this.setState(
        {
          inputValue,
          isLoading: true,
          passEmptyOptions: cacheOptions ? false : true,
        },
        () => {
          this.optionsCache[inputValue] = this.optionsCache[inputValue]
            ? this.optionsCache[inputValue]
            : {
                options: [],
                pagination: {
                  hasMore: true,
                  page: 1,
                },
              }

          const pagination =
            cacheOptions && fetchMore
              ? this.optionsCache[inputValue].pagination
              : { hasMore: true, page: 1 }

          if (loadOptions) {
            loadOptions(inputValue, pagination, callback)
          }
        }
      )
    }
  }

  /**
   * Bug with react-select firing this callback twice
   */
  handleScrollToBottom = throttle(
    () => {
      this.loadOptions(this.state.inputValue, true)
    },
    100,
    {
      leading: true,
      trailing: false,
    }
  )

  handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    const { onInputChange } = this.props

    const inputValue = handleInputChange(newValue, actionMeta, onInputChange)

    if (actionMeta.action === 'input-change') {
      this.debouncedLoadOptions(inputValue, false)
    }

    return inputValue
  }

  onMenuOpen = () => {
    if (!this.isMenuOpen) {
      this.loadOptions('', false)
    }

    this.isMenuOpen = true
  }

  onMenuClose = () => {
    this.isMenuOpen = false

    if (this.props.clearCacheOnMenuClose) {
      this.optionsCache = {}

      this.setState({
        loadedOptions: [],
      })
    }
  }

  render() {
    const { loadOptions, ...props } = this.props
    const { isLoading, loadedOptions, passEmptyOptions } = this.state

    const options = passEmptyOptions ? [] : loadedOptions || []

    return (
      <Select
        filterOption={this.props.filterOption || null}
        options={options}
        isLoading={isLoading || this.props.isLoading}
        onInputChange={this.handleInputChange}
        onMenuOpen={this.onMenuOpen}
        onMenuClose={this.onMenuClose}
        onMenuScrollToBottom={this.handleScrollToBottom}
        {...props}
      />
    )
  }
}
