import * as React from 'react'

import { Card, Header, PrimaryButton, Space, Spacer, Text } from '@procurify/ui'

import { useCreateDemoRequest } from '@webapp/api/procurify/hooks/Demo/useCreateDemoRequest'
import { useProcurifyIntl } from '@webapp/hooks'
import { IntegrationLegacyPlatforms } from '@webapp/models/enums'
import messages from './messages'
import { PunchOutWelcomeBackgroundStyled } from './styles/PunchOutWelcomeBackgroundStyled'
import { PunchOutWelcomeStyled } from './styles/PunchOutWelcomeStyled'
import { toast } from '../Notification'

const punchoutInformation = {
  title: messages.punchoutIntegrationTitle,
  summary: messages.punchoutIntegrationSummary,
  paragraphs: [
    messages.punchoutIntegrationParagraph1,
    messages.punchoutIntegrationParagraph2,
  ],
  benefits: [
    {
      header: messages.punchoutIntegrationBenefitsHeader1,
      body: messages.punchoutIntegrationBenefitsBody1,
    },
    {
      header: messages.punchoutIntegrationBenefitsHeader2,
      body: messages.punchoutIntegrationBenefitsBody2,
    },
    {
      header: messages.punchoutIntegrationBenefitsHeader3,
      body: messages.punchoutIntegrationBenefitsBody3,
    },
    {
      header: messages.punchoutIntegrationBenefitsHeader4,
      body: messages.punchoutIntegrationBenefitsBody4,
    },
    {
      header: messages.punchoutIntegrationBenefitsHeader5,
      body: messages.punchoutIntegrationBenefitsBody5,
    },
  ],
}

export const PunchOutWelcome = () => {
  const intl = useProcurifyIntl()

  const { mutate: requestDemo, isLoading } = useCreateDemoRequest({
    onSuccess: () => {
      toast.success({
        children: intl.formatMessage(messages.apiRequestSuccess),
        title: intl.formatMessage(messages.apiRequestSuccessTitle),
      })
    },
    onError: () => {
      toast.error({
        children: intl.formatMessage(messages.apiRequestError),
        title: intl.formatMessage(messages.apiRequestErrorTitle),
      })
    },
  })

  return (
    <PunchOutWelcomeStyled>
      <PunchOutWelcomeBackgroundStyled />

      <Spacer space={2} />

      <Card width={800}>
        <Card.Content>
          <Space>
            <Space direction='vertical' align='center'>
              <Header as='h4' textAlign='center'>
                {intl.formatMessage(punchoutInformation.title)}
              </Header>
              <Spacer space={0} />
              <Header as='h5'>
                {intl.formatMessage(punchoutInformation.summary)}
              </Header>
              {punchoutInformation.paragraphs.map((item, index) => (
                <Text key={index}>{intl.formatMessage(item)}</Text>
              ))}
            </Space>
            <Space direction='vertical' align='start'>
              <Header as='h5'>{intl.formatMessage(messages.benefits)}</Header>
              {punchoutInformation.benefits.map((item, index) => (
                <Space direction='vertical' size='small' key={index}>
                  <Text bold>{intl.formatMessage(item.header)}</Text>
                  <Text>{intl.formatMessage(item.body)}</Text>
                </Space>
              ))}
            </Space>
          </Space>

          <Spacer />

          <Space direction='vertical' align='center' size='small'>
            <PrimaryButton
              isLoading={isLoading}
              onClick={() => {
                requestDemo(IntegrationLegacyPlatforms.PUNCHOUT)
              }}
            >
              {intl.formatMessage(messages.requestDemo)}
            </PrimaryButton>
          </Space>
        </Card.Content>
      </Card>
    </PunchOutWelcomeStyled>
  )
}

PunchOutWelcome.displayName = 'PunchOutWelcome'
