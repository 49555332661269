import { IconStyled } from '@procurify/ui'
import { BaseButton } from '@procurify/ui'
import { applyButtonColorCSS } from '@procurify/ui'
import { shade } from 'polished'
import styled from 'styled-components'

export const IntercomButtonStyled = styled(BaseButton)`
  position: fixed;
  bottom: 0;
  right: 0;
  ${(props) =>
    applyButtonColorCSS(
      props.theme.colors.blueShade30,
      undefined,
      undefined,
      shade(0.4, props.theme.colors.blue)
    )}
  min-width: 0;
  align-items: center;
  gap: ${(props) => props.theme.typography.pxToRem(8)};
  padding: ${(props) => props.theme.typography.pxToRem(11)}
    ${(props) => props.theme.typography.pxToRem(16)};
  margin: ${(props) => props.theme.typography.pxToRem(20)};
  border-radius: 24px;
  ${IconStyled} {
    margin-right: 0;
  }
`
