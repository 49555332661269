import * as React from 'react'

import MuiTooltip, { type TooltipProps } from '@material-ui/core/Tooltip'
import { sizing, type SizingProps } from '@material-ui/system'
import styled from 'styled-components'

const arrowSize = '8px'

const ArrowArrowStyled = styled.span`
  && {
    border-color: transparent;
    border-style: solid;
    height: 0;
    position: absolute;
    width: 0;
  }
`
export interface IPopoverTooltipStyledProps extends TooltipProps, SizingProps {}

export const PopoverTooltipStyled = styled(
  (props: IPopoverTooltipStyledProps) => {
    const { title, PopperProps, ...otherProps } = props

    const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(null)

    return (
      <MuiTooltip
        classes={{
          popper: props.className,
          tooltip: 'popoverContent',
        }}
        title={
          <React.Fragment>
            <ArrowArrowStyled ref={setArrowRef} />
            <div style={{ position: 'relative' }}>{title}</div>
          </React.Fragment>
        }
        {...otherProps}
        PopperProps={{
          ...PopperProps,
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: Boolean(arrowRef),
                element: arrowRef,
              },
            },
          },
        }}
      />
    )
  }
)`
  opacity: 1;
  &[x-placement='bottom'] ${ArrowArrowStyled} {
    border-bottom-color: ${(props) => props.theme.colors.white};
    border-width: 0 ${arrowSize} ${arrowSize};
    bottom: 100%;
    left: 50%;
  }
  &[x-placement='bottom-end'] ${ArrowArrowStyled} {
    border-bottom-color: ${(props) => props.theme.colors.white};
    border-width: 0 ${arrowSize} ${arrowSize};
    bottom: 100%;
    right: ${(props) => props.theme.typography.pxToRem(5)};
  }
  &[x-placement='bottom-start'] ${ArrowArrowStyled} {
    border-bottom-color: ${(props) => props.theme.colors.white};
    border-width: 0 ${arrowSize} ${arrowSize};
    bottom: 100%;
    left: ${(props) => props.theme.typography.pxToRem(5)};
  }
  &[x-placement='left'] ${ArrowArrowStyled} {
    border-left-color: ${(props) => props.theme.colors.white};
    border-width: ${arrowSize} 0 ${arrowSize} ${arrowSize};
    left: 100%;
    top: 50%;
  }
  &[x-placement='left-end'] ${ArrowArrowStyled} {
    border-left-color: ${(props) => props.theme.colors.white};
    border-width: ${arrowSize} 0 ${arrowSize} ${arrowSize};
    bottom: ${(props) => props.theme.typography.pxToRem(5)};
    left: 100%;
  }
  &[x-placement='left-start'] ${ArrowArrowStyled} {
    border-left-color: ${(props) => props.theme.colors.white};
    border-width: ${arrowSize} 0 ${arrowSize} ${arrowSize};
    left: 100%;
    top: ${(props) => props.theme.typography.pxToRem(5)};
  }
  &[x-placement='right'] ${ArrowArrowStyled} {
    border-right-color: ${(props) => props.theme.colors.white};
    border-width: ${arrowSize} ${arrowSize} ${arrowSize} 0;
    right: 100%;
    top: 50%;
  }
  &[x-placement='right-end'] ${ArrowArrowStyled} {
    border-right-color: ${(props) => props.theme.colors.white};
    border-width: ${arrowSize} ${arrowSize} ${arrowSize} 0;
    bottom: ${(props) => props.theme.typography.pxToRem(5)};
    right: 100%;
  }
  &[x-placement='right-start'] ${ArrowArrowStyled} {
    border-right-color: ${(props) => props.theme.colors.white};
    border-width: ${arrowSize} ${arrowSize} ${arrowSize} 0;
    right: 100%;
    top: ${(props) => props.theme.typography.pxToRem(5)};
  }
  &[x-placement='top'] ${ArrowArrowStyled} {
    border-top-color: ${(props) => props.theme.colors.white};
    border-width: ${arrowSize} ${arrowSize} 0;
    left: 50%;
    top: 100%;
  }
  &[x-placement='top-end'] ${ArrowArrowStyled} {
    border-top-color: ${(props) => props.theme.colors.white};
    border-width: ${arrowSize} ${arrowSize} 0;
    right: ${(props) => props.theme.typography.pxToRem(5)} !important;
    top: 100%;
  }
  &[x-placement='top-start'] ${ArrowArrowStyled} {
    border-top-color: ${(props) => props.theme.colors.white};
    border-width: ${arrowSize} ${arrowSize} 0;
    left: ${(props) => props.theme.typography.pxToRem(5)} !important;
    top: 100%;
  }

  & .popoverContent {
    background: ${(props) => props.theme.colors.white};
    border: 1px solid #f1f1f4;
    border-radius: ${(props) =>
      props.theme.box.borderRadius(props.theme.typography.htmlFontSize)};
    box-shadow: 0 5px 1em rgba(0, 0, 0, 0.2);
    color: ${(props) => props.theme.colors.text.primary};
    font-family: inherit;
    font-size: ${(props) => props.theme.typography.pxToRem(12)};
    max-width: ${(props) => props.theme.typography.pxToRem(800)};
    min-width: ${(props) => props.theme.typography.pxToRem(240)};
    padding: ${(props) => props.theme.typography.pxToRem(0)};
    position: relative;
    ${sizing}
  }
`
