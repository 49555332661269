import { type InputActionMeta } from 'react-select'

import { fireEvent } from '@ui/tests'

export const mockOptionCreate = (select, value) => {
  fireEvent(
    select,
    new CustomEvent('create', {
      detail: {
        value: {
          ...value,
          __isNew__: true,
        },
      },
    })
  )
}

// ==============================
// Handle Input Change
// https://github.com/JedWatson/react-select/blob/b7868d23ccd0b9e623b277f971e89020d426fbdb/packages/react-select/src/utils.js#L71
// ==============================

export type InputActionTypes =
  | 'set-value'
  | 'input-change'
  | 'input-blur'
  | 'menu-close'

export function handleInputChange(
  inputValue: string,
  actionMeta: InputActionMeta,
  onInputChange?: (
    str: string,
    InputActionMeta: InputActionMeta
  ) => string | void
) {
  if (onInputChange) {
    const newValue = onInputChange(inputValue, actionMeta)
    if (typeof newValue === 'string') return newValue
  }
  return inputValue
}
