import * as React from 'react'

import { withRouter } from 'react-router'

const ScrollToTopBase: React.FC<any> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.location.pathname])

  return props.children || null
}

export const ScrollToTop = withRouter(ScrollToTopBase)
