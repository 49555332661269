import * as React from 'react'

import { type TypographyProps } from '@material-ui/system'
import { type Enum } from '@procurify/webapp/src/utils'

import { StatusLabelStyled } from './styles/StatusLabelStyled'
import { Icon, type IconTypes } from '../Icon'

export const StatusLabelStatuses = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  INACTIVE: 'inactive',
  NEW: 'new',
  AI: 'ai',
} as const

export type StatusLabelStatus = Enum<typeof StatusLabelStatuses>

export interface IStatusLabelProps extends Pick<TypographyProps, 'fontSize'> {
  type: StatusLabelStatus
  text: string
  icon?: IconTypes
  iconComponent?: React.ReactNode
  'aria-label'?: string
}

export const StatusLabel = ({
  type,
  text,
  icon,
  iconComponent,
  fontSize = 14,
  'aria-label': ariaLabel,
}: IStatusLabelProps) => {
  return (
    <StatusLabelStyled type={type} fontSize={fontSize} aria-label={ariaLabel}>
      {!!iconComponent && iconComponent}

      {!!icon && <Icon icon={icon} />}

      <span>{text}</span>
    </StatusLabelStyled>
  )
}
