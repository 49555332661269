import styled from 'styled-components'

export interface ISpacerStyledProps {
  /**
   * Sets the height of the spacer component. If less than `0`, this value is
   * assumed to be `1`.
   */
  space: number
}

const getHeight = ({ space }) => (space >= 0 ? space : 1)

export const SpacerStyled = styled.span<ISpacerStyledProps>`
  display: block;
  height: ${getHeight}rem;
  min-height: ${getHeight}rem;
  width: 100%;
`
