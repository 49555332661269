export const enum QBOMappableFields {
  LOCATION = 'location',
  DEPARTMENT_BY_NAME = 'department_by_name',
  DEPARTMENT_BY_EXTERNAL_ID = 'department_by_external_id',
  CUSTOM_FIELD = 'custom_field',
}

export interface QBOMapField {
  internal_target: QBOMappableFields
  custom_field_id: number
  custom_field_name: string
}
