import { defineMessages } from 'react-intl'

const scope = `procurify.components.ApproverOverwatchView`

export default defineMessages({
  breadcrumbTitle: {
    id: `${scope}.breadcrumbTitle`,
    defaultMessage: 'Approver View Access',
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Approver View Access',
  },
  featureOverview: {
    id: `${scope}.featureOverview`,
    defaultMessage:
      "Use this page to manage which users can see other approver's existing pending, approved, denied requests from the Approve module.",
  },
  featureDisclaimer: {
    id: `${scope}.featureDisclaimer`,
    defaultMessage: `*Giving Approver View Access <strong>is only for viewing other users Approvals.</strong>`,
  },
})
