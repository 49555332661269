import * as React from 'react'

import { FormattedMessage } from 'react-intl'
import { type Props } from 'react-intl/dist/components/message'

export const FormattedEnhancedMessage: React.SFC<Props> = ({
  values,
  ...otherProps
}) => {
  return (
    <FormattedMessage
      {...otherProps}
      values={{
        strong: (str) => <strong>{str}</strong>,
        br: <br />,
        ...values,
      }}
    />
  )
}
