import { useMutation, type UseMutationOptions } from 'react-query'

import { requestDemo, type RequestDemoResponse } from '@webapp/api/DemoAPI'
import { type IntegrationLegacyPlatform } from '@webapp/models/enums/IntegrationPlatforms'
import { type IApiResponse } from '@webapp/services/api'

const mutationFn = async (integration: IntegrationLegacyPlatform) => {
  const result = await requestDemo(integration)
  return result
}

export const useCreateDemoRequest = (
  config?: UseMutationOptions<
    IApiResponse<RequestDemoResponse>,
    unknown,
    IntegrationLegacyPlatform
  >
) => {
  return useMutation(mutationFn, config)
}
