import * as React from 'react'

import {
  flexbox,
  type FlexboxProps,
  spacing,
  type SpacingProps,
} from '@material-ui/system'
import styled from 'styled-components'

import { ToolbarStyled } from './styles/ToolbarStyled'

export const ToolbarFill = styled.div`
  flex-grow: 1;
`

export const ToolbarItem = styled.div<FlexboxProps & SpacingProps>`
  ${spacing}
  ${flexbox}
`

export class Toolbar extends React.PureComponent<SpacingProps> {
  public static Fill = ToolbarFill
  public static Item = ToolbarItem

  render() {
    return <ToolbarStyled {...this.props} />
  }
}
