import { type Enum } from '@webapp/utils'

export const InboxNotificationTypes = {
  DELEGATION: 'delegation',
  PING: 'ping',
  CONTRACT_BATCH: 'contract_batch',
  DEFAULT: 'default',
  LINKED_BANK_ACCOUNT: 'linked_bank_account',
  PUNCHOUT: 'punchout',
} as const

export type InboxNotificationType = Enum<typeof InboxNotificationTypes>
