import * as React from 'react'

import { type TableRowProps } from '@material-ui/core/TableRow'

import { TableCellStyled } from './styles/TableCellStyled'
import { TableRowStyled } from './styles/TableRowStyled'

export interface ITableRowProps extends TableRowProps {
  variant?: string
  index?: number
  hoverable?: boolean
  selected?: boolean
  children: any
  onClick?(e: React.MouseEvent<HTMLElement>): void
  omitRowClickCellIndices?: number[]
}

export class TableRow extends React.Component<ITableRowProps> {
  static defaultProps = {
    selected: false,
  }

  render() {
    const {
      index,
      variant,
      omitRowClickCellIndices = [],
      onClick,
      selected,
      ...otherProps
    } = this.props

    const childrenWithInheritedProps = React.Children.map(
      this.props.children,
      (child: React.ReactElement<any>, i) => {
        if (!child) return

        if (omitRowClickCellIndices.length > 0) {
          return React.cloneElement(child, {
            variant,
            index: i,
            onClick: omitRowClickCellIndices.includes(i) ? undefined : onClick,
          })
        } else {
          return React.cloneElement(child, {
            variant,
            index: i,
          })
        }
      }
    )

    return (
      <TableRowStyled
        selected={this.props.selected}
        onClick={omitRowClickCellIndices.length > 0 ? undefined : onClick}
        {...otherProps}
      >
        {childrenWithInheritedProps}
      </TableRowStyled>
    )
  }
}
