import * as React from 'react'

import { OverlayLoader } from '@procurify/ui'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  type RouteComponentProps,
  Switch,
  useRouteMatch,
} from 'react-router'

import { useGetSsoConnection } from '@webapp/api/procurify/hooks/Authentication'
import { toast } from '@webapp/components/Notification'
import {
  type IProtectedRouteProps,
  ProtectedRoute,
} from '@webapp/core/hoc/ProtectedRoute'
import { useProcurifyIntl } from '@webapp/hooks'
import { PermissionGroupNames, type SsoProvider } from '@webapp/models'
import {
  makeSelectFeatureSwitches,
  makeSelectRole,
} from '@webapp/stores/global'
import messages from './messages'

const SsoProviderViewChunk = React.lazy(() =>
  import('../containers/SsoProviderView').then(({ SsoProviderView }) => ({
    default: SsoProviderView,
  }))
)

const SsoConnectionViewChunk = React.lazy(() =>
  import('../containers/SsoConnectionView').then(({ SsoConnectionView }) => ({
    default: SsoConnectionView,
  }))
)

type FormParams = {
  provider?: SsoProvider['provider']
}

export interface ISsoConfigurationPageProps
  extends RouteComponentProps<FormParams> {}

export const ProtectedSsoRoute = (props: IProtectedRouteProps) => {
  const intl = useProcurifyIntl()
  const { sso_setup, sso } = useSelector(makeSelectFeatureSwitches())
  const hasFeatureAccess = sso && sso_setup

  const { name } = useSelector(makeSelectRole())
  const hasRoleAccess =
    name === PermissionGroupNames.SUPERUSER ||
    name === PermissionGroupNames.SITE_ADMINISTRATOR

  const canAccess = hasFeatureAccess && hasRoleAccess

  if (!canAccess) {
    toast.info({
      title: intl.formatMessage(messages.ssoConfigurationAccessDeniedTitle),
      children: intl.formatMessage(
        messages.ssoConfigurationAccessDeniedMessage
      ),
    })
  }

  return canAccess ? <ProtectedRoute {...props} /> : null
}

export const SsoConfigurationPage = () => {
  const intl = useProcurifyIntl()

  const { data, isFetchedAfterMount } = useGetSsoConnection({
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  })

  const activeProvider = data?.provider

  const { path } = useRouteMatch()

  return (
    <React.Suspense fallback={null}>
      <Helmet>
        <title>
          Procurify | {intl.formatMessage(messages.ssoConfigurationTitle)}
        </title>
      </Helmet>
      <Switch>
        <Route exact path={path}>
          <SsoProviderViewChunk />
        </Route>
        <Route path='/settings/sso/:provider'>
          {!isFetchedAfterMount && <OverlayLoader />}
          {isFetchedAfterMount && activeProvider && (
            <Redirect to='/settings/sso' />
          )}
          {isFetchedAfterMount && !activeProvider && <SsoConnectionViewChunk />}
        </Route>
      </Switch>
    </React.Suspense>
  )
}
