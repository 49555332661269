import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { type PayCard } from '@webapp/models'
import messages from './messages'
import { SpendingCardVirtualCardsPageTabs } from './SpendingCardVirtualCardsPage'

export interface SpendingCardVirtualCardDetailsPageParams {
  uuid: PayCard['external_id']
}

const SpendingCardVirtualCardDetailsViewChunk = React.lazy(() =>
  import('../containers/SpendingCardVirtualCardDetailsView').then(
    ({ SpendingCardVirtualCardDetailsView }) => ({
      default: SpendingCardVirtualCardDetailsView,
    })
  )
)

export const SpendingCardVirtualCardDetailsPage = (
  props: RouteComponentProps<SpendingCardVirtualCardDetailsPageParams>
) => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(
        messages.spendingCardVirtualCardDetailsPageTitle
      )}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.spendingCardVirtualCardsPageTitle),
          link: url('SpendingCardVirtualCardsPage', {
            tab: SpendingCardVirtualCardsPageTabs.ACTIVE,
          }),
        },
        {
          name: intl.formatMessage(
            messages.spendingCardVirtualCardDetailsPageTitle
          ),
        },
      ]}
    >
      <SpendingCardVirtualCardDetailsViewChunk uuid={props.match.params.uuid} />
    </Page>
  )
}
