import * as React from 'react'

import { type PayCard } from '@webapp/models'
import { type PayFund } from '@webapp/models/PayFund'

export interface SpendingCardManagementFundDetailsPageParams {
  card: PayCard['external_id']
  fund: PayFund['uuid']
}

const SpendingCardManagementFundDetailsViewChunk = React.lazy(() =>
  import('../containers/SpendingCardManagementFundDetailsView').then(
    ({ SpendingCardManagementFundDetailsView }) => ({
      default: SpendingCardManagementFundDetailsView,
    })
  )
)

export const SpendingCardManagementFundDetailsPage = () => {
  return (
    <React.Suspense fallback={null}>
      <SpendingCardManagementFundDetailsViewChunk />
    </React.Suspense>
  )
}
