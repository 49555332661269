import React from 'react'

import { Redirect, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/core/hoc/ProtectedRoute'
import { useAppRoutes } from '@webapp/hooks'
import {
  BudgetCategoryCreatePage,
  BudgetCategoryEditPage,
  BudgetCategoryPage,
} from '@webapp/pages'

export const BudgetCategoryManagementRouter = () => {
  const { brp } = useAppRoutes()

  return (
    <Switch>
      <ProtectedRoute
        exact
        path={brp('BudgetCategoryPage')}
        render={() => <BudgetCategoryPage />}
      />
      <ProtectedRoute
        exact
        path={'/settings/budget-categories'}
        render={() => <Redirect to={brp('BudgetsPage')} />}
        featureFlags={['FT_5817_ENABLE_BUDGETS_REDESIGN']}
      />

      <ProtectedRoute
        path={brp('BudgetCategoryCreatePage')}
        render={() => <BudgetCategoryCreatePage />}
      />
      <ProtectedRoute
        exact
        path={'/settings/budget-categories/create'}
        render={() => <Redirect to={brp('BudgetsCreatePage')} />}
        featureFlags={['FT_5817_ENABLE_BUDGETS_REDESIGN']}
      />

      <ProtectedRoute
        path={brp('BudgetCategoryEditPage', { uuid: `:uuid` })}
        render={() => <BudgetCategoryEditPage />}
      />
      <ProtectedRoute
        path={`/settings/budget-categories/edit/:uuid`}
        render={() => (
          <Redirect to={brp('BudgetsEditPage', { uuid: ':uuid' })} />
        )}
        featureFlags={['FT_5817_ENABLE_BUDGETS_REDESIGN']}
      />
    </Switch>
  )
}
