import * as React from 'react'

import { EmptyStateImageStyled } from './styles/EmptyStateImageStyled'
import { EmptyStateStyled } from './styles/EmptyStateStyled'
import { Header } from '../../elements/Header'
import { Spacer } from '@ui/elements/Spacer'

const DEFAULT_CONTEXT_TEXT = {
  information: 'No information available',
  activity: 'No recent activity',
  cart: 'No items found',
  discussion: 'No recent comments',
  file: 'No files attached',
  history: 'No action history found',
  card: 'No cards found',
  '404': 'Not found',
}

export type EmptyStateContext =
  | 'information'
  | 'activity'
  | 'cart'
  | 'discussion'
  | 'file'
  | 'history'
  | 'card'
  | '404'

export interface IEmptyStateProps {
  /** This determines which illustration will be rendered */
  context?: EmptyStateContext
  /** Header of the EmptyState */
  header?: React.ReactNode
  /** Description of the EmptyState */
  content?: React.ReactNode
  /** When set, the button element will appear below the `content` */
  button?: JSX.Element
  /** Show compact version when `true` */
  small?: boolean
  /** Hide the illustration when `true` */
  hideImage?: boolean
  /** when set, this img will override the illustrations rendered via the context prop */
  imgUrl?: string
}

export const EmptyState = ({
  button,
  content,
  context = 'information',
  header,
  hideImage,
  small = false,
  imgUrl,
}: IEmptyStateProps) => {
  return (
    <EmptyStateStyled small={small}>
      {!hideImage && (
        <EmptyStateImageStyled context={context} imgUrl={imgUrl} />
      )}
      {content ? (
        <div>
          <Header as='h5'>{header}</Header>
          <div className='content'>{content}</div>
        </div>
      ) : (
        <p className='header'>{header || DEFAULT_CONTEXT_TEXT[context]}</p>
      )}

      <Spacer />

      {button}
    </EmptyStateStyled>
  )
}
