import * as React from 'react'

import { type TableHeadProps } from '@material-ui/core/TableHead'

import { TableHeadStyled } from './styles/TableHeadStyled'

export interface ITableHeadProps extends TableHeadProps {
  selectable?: boolean
  zebraTableBodyAsRow?: boolean
  children: JSX.Element[] | JSX.Element
}

export class TableHead extends React.Component<ITableHeadProps> {
  render() {
    // inherit parent prop with NumberColumn
    const childrenWithInheritedProps = React.Children.map(
      this.props.children,
      (child: React.ReactElement<any>) => {
        return React.cloneElement(child, {
          variant: 'head',
        })
      }
    )
    const { zebraTableBodyAsRow, ...otherProps } = this.props
    return (
      <TableHeadStyled {...otherProps}>
        {childrenWithInheritedProps}
      </TableHeadStyled>
    )
  }
}
