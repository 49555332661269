import * as React from 'react'

import { AlertStyled } from './styles/AlertStyled'
import { Flex } from '@ui/elements/Flex'
import { SubText } from '@ui/elements/Text'

export interface IBaseAlertProps {
  action?: React.ReactNode
  icon?: React.ReactNode
}

export const BaseAlert = ({
  action,
  children,
  icon,
  ...otherProps
}: React.PropsWithChildren<IBaseAlertProps>) => {
  const isChildrenString = typeof children === 'string'

  return (
    <AlertStyled {...otherProps}>
      <Flex.Row flexWrap='nowrap' alignItems='center'>
        {icon && <Flex.Item flex='0 1'>{icon}</Flex.Item>}
        <Flex.Item flex='1 0'>
          {isChildrenString ? <SubText>{children}</SubText> : <>{children}</>}
        </Flex.Item>
        {action && <Flex.Item ml='auto'>{action}</Flex.Item>}
      </Flex.Row>
    </AlertStyled>
  )
}

BaseAlert.displayName = 'BaseAlert'
