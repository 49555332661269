import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import messages from './messages'

export interface PurchaseOrderCreatePoPageParams {
  vendorId: string
}

const PurchaseOrderCreatePoViewChunk = React.lazy(() =>
  import('../containers/PurchaseOrderCreatePoView').then(
    ({ PurchaseOrderCreatePoView }) => ({
      default: PurchaseOrderCreatePoView,
    })
  )
)

export const PurchaseOrderCreatePoPage = () => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.procurementPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.procurementPageTitle),
          link: url('ProcurementPage'),
        },
        {
          name: intl.formatMessage(messages.purchaseOrderCreatePoPageTitle),
        },
      ]}
    >
      <PurchaseOrderCreatePoViewChunk />
    </Page>
  )
}
