import * as React from 'react'

import styled, { css } from 'styled-components'

export interface IWithTooltipWrapperProps {
  isContentDisabled?: boolean
  isFullWidth?: boolean
}

export const WithTooltipWrapper = styled.span<IWithTooltipWrapperProps>`
  display: inline-block;
  width: ${(props) => (props.isFullWidth ? '100%' : 'auto')};

  ${(props) => {
    if (props.isContentDisabled) {
      return css`
        /* the two CSS properties are to fix Material UI tooltip unexpected functionality when used with disabled elements:
      https://mui.com/components/tooltips/#disabled-elements */
        cursor: not-allowed;
        * {
          pointer-events: none;
        }
      `
    }
  }}
`

WithTooltipWrapper.displayName = 'WithTooltipWrapper'
