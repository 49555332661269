import * as React from 'react'

import type { wsChannel } from '@webapp/services/WSChannelManager'
import { SystemChannelManager } from './SystemChannelManager.service'
export interface IUseChannelSubscriptionProps {
  channel: string
}

export const useSystemChannelSubscription = ({
  channel,
}: IUseChannelSubscriptionProps) => {
  const [subscribedChannel, setSubscribedChannel] = React.useState<wsChannel>()

  React.useEffect(() => {
    const subscriptionResult = SystemChannelManager.subscribe(channel)
    setSubscribedChannel(subscriptionResult)

    return () => {
      SystemChannelManager.unsubscribe(channel)
    }
  }, [])

  return subscribedChannel
}
