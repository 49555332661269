import styled from 'styled-components'

import { InputGroupAttachmentStyled } from './InputGroupAttachmentStyled'
import { InputGroupLabelStyled } from './InputGroupLabelStyled'
import { LabelStyled } from '../../Label/styles/LabelStyled'
import { TextFieldStyled } from '../../TextField/styles/TextFieldStyled'
import { BaseButtonStyled } from '@ui/elements/Button/styles/BaseButtonStyled'

export const InputGroupStyled = styled.div`
  display: flex;

  > *:not(${/* sc-selector */ InputGroupAttachmentStyled}):not(
      ${/* sc-selector */ InputGroupLabelStyled}
    ) {
    flex-grow: 1;
  }

  & > ${/* sc-selector */ TextFieldStyled} {
    .Mui-focused {
      z-index: 2;
    }

    .input {
      border-radius: 0;
    }

    &:first-child .input {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child .input {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:not(:last-child) {
      /* stylelint-disable-next-line */
      margin-right: -1px;
    }
  }

  & > .react-select {
    z-index: 2;
    .react-select__control {
      border-radius: 0;
    }

    &:first-child .react-select__control {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child .react-select__control {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:not(:last-child) {
      /* stylelint-disable-next-line */
      margin-right: -1px;
    }
  }

  > ${InputGroupAttachmentStyled} {
    flex-shrink: 1;

    ${BaseButtonStyled} {
      border-radius: 0;
      box-shadow: none;
      border-color: ${(props) => props.theme.colors.content.border};
    }

    &:hover,
    &:active {
      z-index: 3;
    }

    &:first-child {
      ${BaseButtonStyled} {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &:last-child {
      ${BaseButtonStyled} {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  > ${InputGroupLabelStyled} {
    flex-shrink: 1;

    ${LabelStyled} {
      border-radius: 0;
    }

    &:first-child {
      ${LabelStyled} {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }

    &:last-child {
      ${LabelStyled} {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    &:not(:last-child) {
      /* stylelint-disable-next-line */
      margin-right: -1px;
    }
  }

  /* & > ${TextFieldStyled}:not(:first-child) .input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > .react-select:not(:first-child) .react-select__control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  } */
`
