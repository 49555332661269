import { useMutation, type UseMutationOptions } from 'react-query'

import { Auth0AuthenticationAPI } from '@webapp/api/Auth0AuthenticationAPI'

const mutationFn = async () => {
  const { data } = await Auth0AuthenticationAPI.deleteSsoConnection()

  return data
}

export const useDeleteSsoConnection = (config?: UseMutationOptions<{}>) => {
  return useMutation(mutationFn, config)
}
