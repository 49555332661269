import * as React from 'react'

import { type DialogProps } from '@material-ui/core/Dialog'

import { ModalHeader } from './ModalHeader'
import { ModalCardContentStyled } from './styles/ModalCardContentStyled'
import { ModalCardFooterStyled } from './styles/ModalCardFooterStyled'
import { ModalCardStyled } from './styles/ModalCardStyled'
import { ModalStyled } from './styles/ModalStyled'
import { UIContext } from '../../core/UIContext'

type VerticalAlign = 'top' | 'middle' | 'bottom'
export interface IModalProps extends DialogProps {
  verticalAlign?: VerticalAlign
}

/**
 * @see DialogProps
 */
export const Modal = ({
  verticalAlign = 'top',
  onClose,
  ...otherProps
}: IModalProps) => {
  const { container } = React.useContext(UIContext)

  return (
    <ModalStyled
      scroll='body'
      container={container}
      verticalAlign={verticalAlign}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose?.(event, reason)
        }
      }}
      {...otherProps}
    />
  )
}
Modal.Card = ModalCardStyled
Modal.Header = ModalHeader
Modal.Content = ModalCardContentStyled
Modal.Footer = ModalCardFooterStyled

Modal.Card.displayName = 'Modal.Card'
Modal.Content.displayName = 'Modal.Content'
Modal.Footer.displayName = 'Modal.Footer'
