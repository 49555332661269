import axios from 'axios'
import { v4 } from 'uuid'

import { AxiosHttpClient } from '@webapp/core/libs/AxiosHttpClient'

const axiosClient = axios.create({
  headers: {
    'X-Procurify-Client': 'web:react',
    'X-Correlation-Id': v4(),
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

export const ApiClient = new AxiosHttpClient(axiosClient)
