import styled, { css } from 'styled-components'

import { LabelStyled } from '../../Label/styles/LabelStyled'

export const InputGroupLabelStyled = styled.div`
  display: flex;
  flex-shrink: 1;

  > ${LabelStyled} {
    border: 1px solid ${(props) => props.theme.colors.content.border};
  }
`
