import { defineMessages } from 'react-intl'

const scope = `procurify.components.ApproverOverwatchUserWatchListTable`

export default defineMessages({
  approverIsWatchingDescription: {
    id: `${scope}.tableHeaderDescription`,
    defaultMessage:
      'is currently able to view approvals from <strong>{numApprovers} Approvers</strong>',
  },
  manageListButtonLabel: {
    id: `${scope}.manageListButtonLabel`,
    defaultMessage: 'Manage List',
  },
  tableHeaderDescription: {
    id: `${scope}.tableHeaderDescription`,
    defaultMessage: 'Which Approvers do they want to see?',
  },
  emptyStateText: {
    id: `${scope}.emptyStateText`,
    defaultMessage: 'This user is not able to view as any other Approvers.',
  },
  tableAriaLabel: {
    id: `${scope}.tableAriaLabel`,
    defaultMessage: 'Approver Subscriber List',
  },
  unsubscribeButtonLabel: {
    id: `${scope}.unsubscribeButtonLabel`,
    defaultMessage: "Unsubscribe from {user}'s approvals",
  },
})
