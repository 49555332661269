import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { type PayCardStatusV2 } from '@webapp/models/enums'
import { type Enum } from '@webapp/utils'

export const SpendingCardManagementListPageTabs = {
  ACTIVE: 'active',
  REQUESTED: 'requested',
  PENDING: 'pending',
  INACTIVE: 'inactive',
  CANCELED: 'canceled',
} as const

export type SpendingCardManagementListPageTabStatus = Enum<
  typeof SpendingCardManagementListPageTabs
>

export interface SpendingCardManagementListPageParams {
  tab: SpendingCardManagementListPageTabStatus
}

const SpendingCardManagementListViewChunk = React.lazy(() =>
  import('../containers/SpendingCardManagementListView').then(
    ({ SpendingCardManagementListView }) => ({
      default: SpendingCardManagementListView,
    })
  )
)

export const SpendingCardManagementListPage = (
  props: RouteComponentProps<{ status: PayCardStatusV2 }>
) => {
  return (
    <React.Suspense fallback={null}>
      <SpendingCardManagementListViewChunk {...props} />
    </React.Suspense>
  )
}
