import { type DepartmentApiModel } from '@webapp/core/data-access/api-models/DepartmentApiModel'
import { mapApprover } from '@webapp/core/data-access/mappers/mapApprover'
import { mapCurrency } from '@webapp/core/data-access/mappers/mapCurrency'
import { mapDepartment } from '@webapp/core/data-access/mappers/mapDepartment'
import { mapLocation } from '@webapp/core/data-access/mappers/mapLocation'
import { mapRequester } from '@webapp/core/data-access/mappers/mapRequester'
import { type ApproverConstructor } from '@webapp/core/entities/internal/Approver'
import { mapOrderApprovalLog } from './mapOrderApprovalLog'
import { mapOrderItemApiModel } from './mapOrderItemApiModel'
import { type OrderConstructorData } from '../../entities/Order'
import { type OrderApiModel } from '../api-models/OrderApiModel'

const mapNextApproverGroup = (nextApproverGroup: {
  id?: number
  name: string
  rank: Nullable<number>
}) => {
  if (
    !nextApproverGroup.id ||
    !nextApproverGroup.name ||
    nextApproverGroup.rank === null
  )
    return null
  return {
    id: nextApproverGroup.id,
    name: nextApproverGroup.name,
    rank: nextApproverGroup.rank,
  }
}

const mapNextApproverChoice = (nextApproverChoice: {
  approval_delegatee: Nullable<{
    department: Nullable<DepartmentApiModel>
    email: string
    firstName: string
    id: number
    lastName: string
  }>
  email: string
  firstName: string
  id: number
  lastName: string
  profile_image: string
}): ApproverConstructor => {
  return {
    id: nextApproverChoice.id,
    firstName: nextApproverChoice.firstName,
    lastName: nextApproverChoice.lastName,
    email: nextApproverChoice.email,
    profileImage: nextApproverChoice.profile_image,
    primaryCategory: null,
    secondaryCategory: null,
    approvalDelegatee: nextApproverChoice.approval_delegatee
      ? {
          id: nextApproverChoice.approval_delegatee.id,
          firstName: nextApproverChoice.approval_delegatee.firstName,
          lastName: nextApproverChoice.approval_delegatee.lastName,
          expectedReturnDate: null,
        }
      : null,
  }
}

const mapPunchOutSupplier = (punchoutSupplierData: {
  punchout_supplier_icon: Nullable<string>
  punchout_supplier_name: Nullable<string>
  punchout_vendor_reference: Nullable<number>
}) => {
  if (
    !punchoutSupplierData.punchout_supplier_icon ||
    !punchoutSupplierData.punchout_supplier_name ||
    punchoutSupplierData.punchout_vendor_reference === null
  )
    return null

  return {
    id: punchoutSupplierData.punchout_vendor_reference,
    icon: punchoutSupplierData.punchout_supplier_icon,
    name: punchoutSupplierData.punchout_supplier_name,
  }
}

export const mapOrderApiModel = (
  order: OrderApiModel
): OrderConstructorData => ({
  uuid: order.uuid,
  id: order.num,
  status: order.status,
  dateSubmitted: order.date,
  dateRequired: order.dateRequired,
  dateModified: order.dateModified,
  primaryCategory: mapLocation(order.branch),
  secondaryCategory: mapDepartment(order.department),
  requester: mapRequester(order.user),
  approvalDelegateeId: order.approval_delegatee,
  nextApproverGroup: mapNextApproverGroup(order.next_approver_group),
  nextApprover: order.next_approver ? mapApprover(order.next_approver) : null,
  nextApproverChoices: order.next_approver_choices
    ? order.next_approver_choices.map((choice) => {
        return mapNextApproverChoice(choice)
      })
    : null,
  description: order.description,
  currency: mapCurrency(order.currency),
  totalCost: order.totalPrice,
  totalCostInBaseCurrency: order.total_cost_in_base_currency,
  hasBlanketOrderItems: order.has_blanket_order_items,
  punchoutGroup: order.punchout_group,
  punchoutHasRejectedItems: order.punchout_items_rejected,
  punchoutShippingAddress: order.punchout_shipping_address,
  punchoutSupplier: mapPunchOutSupplier(order),
  items: order.order_items.map(mapOrderItemApiModel),
  logs: order.logs.map(mapOrderApprovalLog),
})
