export const SPENDING_CARD_APPROVE_URL = '/spending-card/approvals'

export const SPENDING_CARD_RECONCILE_TRANSACTIONS_URL =
  '/spending-card/reconciliation'

export const SPENDING_CARD_MANAGEMENT_URL = '/spending-card/card-management'

export const SPENDING_CARD_TRANSACTIONS_URL = '/spending-card/transactions'

export const SPENDING_CARD_CARD_URL = '/spending-card/card'
