import * as React from 'react'

import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

/**
 * @techdebt
 * This is an component made for Bridging Angular/React links
 *
 * This component should be removed when React migration is over
 */
export interface IBridgeLinkProps {
  to: string
  id?: string
  className?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

export const BridgeLink = ({
  to,
  id,
  className,
  children,
  onClick,
}: React.PropsWithChildren<IBridgeLinkProps>) => {
  // Check if Router context exist, if not, it means we are in Angular
  const history = useHistory()

  // Check if [to] starts with /#/, if so render a regular anchor
  if (/^\/#\//.test(to) || !history) {
    return (
      <a href={to} id={id} className={className} onClick={onClick}>
        {children}
      </a>
    )
  } else {
    return (
      <Link to={to} id={id} className={className} onClick={onClick}>
        {children}
      </Link>
    )
  }
}

BridgeLink.displayName = 'BridgeLink'
