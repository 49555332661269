import { SpaceStyled } from '@procurify/ui'
import styled from 'styled-components'

interface IGlobalErrorScreenStyledProps {}

export const GlobalErrorScreenStyled = styled.div<IGlobalErrorScreenStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background: ${(props) => props.theme.colors.content.mainBackground};

  > ${SpaceStyled} {
    height: 100%;
  }
`
