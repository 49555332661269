import { useEffect, useRef } from 'react'

import { type History } from 'history'
import { useHistory } from 'react-router'

import { useFeatureFlag } from '@webapp/hooks'

const getUrlFromLocation = (location: History['location']) => {
  return `${location.pathname}${location.search}`
}

// This hook is used to keep track of the previous location by adding a "from" property to the
// location state. This allows us to determine who the referrer was once the user has navigated
// to a new location, which extends react-router's default ability to only go back to the previous
// location (exposes no info about the previous location).
export const usePersistReferrerUrl = () => {
  const history = useHistory()
  const prevPathnameRef = useRef<string>(getUrlFromLocation(history.location))
  const FT_6224_MIGRATE_ORDER_DETAILS = useFeatureFlag(
    'FT_6224_MIGRATE_ORDER_DETAILS'
  )

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'PUSH' && FT_6224_MIGRATE_ORDER_DETAILS) {
        history.replace(getUrlFromLocation(location), {
          ...location.state,
          from: prevPathnameRef.current,
        })
      }

      // Important: Update the previous pathname after adding it to the state value so that
      // we don't overwrite the previous pathname with the current pathname. This is done
      // on any navigation action (push, replace, or pop) so that we can keep it as accurate
      // as possible. We avoid setting the state on some actions (like replace) to prevent
      // currently unnecessary state updates.
      prevPathnameRef.current = getUrlFromLocation(location)
    })

    return () => unlisten()
  }, [FT_6224_MIGRATE_ORDER_DETAILS])
}
