import { type Enum } from '@webapp/core/types/Enum'

export const OrderStatuses = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
  PURCHASED: 3,
  CANCELLED: 4,
  RECEIVED: 5,
  DRAFT: 6,
} as const

export type OrderStatusEnum = Enum<typeof OrderStatuses>
