import { CREATED_NEW_CARD, UPDATE_COUNTS } from './constants'
import {
  type IPayCardManagementTabCount,
  type NotifyNewCardCreatedAction,
  type UpdateCountsAction,
} from './types'

export const updateCounts = (
  counts: IPayCardManagementTabCount
): UpdateCountsAction => ({
  type: UPDATE_COUNTS,
  counts,
})

export const notifyNewCardCreated = (
  newCardCreated: boolean
): NotifyNewCardCreatedAction => ({
  type: CREATED_NEW_CARD,
  newCardCreated,
})
