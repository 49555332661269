import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import messages from './messages'

export interface BillApprovalDetailsPageParams {
  uuid: uuid
}

const BillRequestDetailsViewChunk = React.lazy(() =>
  import('../containers/BillRequestDetailsView').then(
    ({ BillRequestDetailsView }) => ({
      default: BillRequestDetailsView,
    })
  )
)

export const BillApprovalDetailsPage = (
  props: RouteComponentProps<BillApprovalDetailsPageParams>
) => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.billDetailsPageTitle)}
      breadcrumbs={[
        {
          link: url('BillApprovalListPage'),
          name: intl.formatMessage(messages.billApprovalPageTitle),
        },
        {
          name: intl.formatMessage(messages.billDetailsPageTitle),
        },
      ]}
    >
      <BillRequestDetailsViewChunk isFromApprovals {...props} />
    </Page>
  )
}
