import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { type Enum } from '@webapp/utils'
import messages from './messages'

export const VendorApprovalListPageTabStatuses = {
  ALL: 'all',
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
} as const

export type VendorApprovalListPageTabStatus = Enum<
  typeof VendorApprovalListPageTabStatuses
>

export interface VendorApprovalListPageParams {
  status: VendorApprovalListPageTabStatus
}

const VendorApprovalListViewChunk = React.lazy(() =>
  import('../containers/VendorApprovalListView').then(
    ({ VendorApprovalListView }) => ({
      default: VendorApprovalListView,
    })
  )
)

export const VendorApprovalListPage = (
  props: RouteComponentProps<VendorApprovalListPageParams>
) => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.vendorApprovalListPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.vendorApprovalListPageTitle),
        },
      ]}
    >
      <VendorApprovalListViewChunk />
    </Page>
  )
}
