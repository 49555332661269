import { CardStyled } from '@procurify/ui'
import styled from 'styled-components'

import { IInboxNotificationProps } from '../InboxNotification'

interface IInboxNotificationStyledProps {
  read?: boolean
  isReminder?: boolean
}

export const InboxNotificationStyled = styled.div<IInboxNotificationStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  ${(props) => {
    if (props.isReminder) {
      return `border-left: ${props.theme.typography.pxToRem(3)} solid ${
        props.theme.colors.warning
      };`
    } else if (!props.read) {
      return `border-left: ${props.theme.typography.pxToRem(3)} solid ${
        props.theme.colors.primary
      };`
    } else {
      return `border-left: ${props.theme.typography.pxToRem(
        3
      )} solid transparent;`
    }
  }}

  ${CardStyled} {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .action {
    font-weight: normal;

    strong {
      font-weight: ${(props) => props.theme.fontWeights.bold};
    }
  }
`
