import * as React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import {
  makeSelectApplicationStatus,
  makeSelectFeatureFlags,
  makeSelectFeatureSwitches,
  makeSelectIsAppReady,
  makeSelectNavigationPermissions,
  makeSelectRole,
  makeSelectSetupProgress,
} from '@webapp/stores/global'
import { makeSelectNotificationCount } from '@webapp/stores/notificationCount/selectors'
import { useInjectSaga } from '@webapp/utils'
import { NavigationLoader } from './components/NavigationLoader'
import { Navigation } from './Navigation'
import { getBaseNavigation } from './NavigationMapping'
import { NavigationSections } from './types'
import {
  filterByApplicationStatus,
  filterByCondition,
  filterByPermissions,
  getManageSection,
  getProcureSection,
  getSpendSection,
} from './utils'
import { fetchCounts } from '../../stores/notificationCount'
import saga from '../../stores/notificationCount/sagas'

export const API_NOTIFICATION_COUNT = '/api/v3/users/me/notification-count/'

export const ConnectedNavigation = React.memo(() => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  useInjectSaga({ key: 'ConnectedNavigation', saga })

  const dispatch = useDispatch()
  const { pathname, hash } = useLocation()

  const navigationPermissions = useSelector(makeSelectNavigationPermissions())
  const featureSwitches = useSelector(makeSelectFeatureSwitches())
  const featureFlags = useSelector(makeSelectFeatureFlags())
  const notificationCount = useSelector(makeSelectNotificationCount())
  const applicationStatus = useSelector(makeSelectApplicationStatus())
  const setupProgress = useSelector(makeSelectSetupProgress())
  const role = useSelector(makeSelectRole())
  const isAppReady = useSelector(makeSelectIsAppReady())

  React.useEffect(() => {
    dispatch(fetchCounts())
  }, [])

  const filteredNavigation = isAppReady
    ? getBaseNavigation(intl, url)
        .filter(filterByApplicationStatus(applicationStatus, role))
        .filter(
          filterByCondition({
            featureFlags: { ...featureFlags, ...featureSwitches },
            setupProgress,
          })
        )
        .filter(filterByPermissions(navigationPermissions))
    : []

  const navigationItems = {
    [NavigationSections.DASHBOARD]: filteredNavigation.find(
      (navItem) => navItem.id === NavigationSections.DASHBOARD
    ),
    [NavigationSections.WHATS_NEW]: filteredNavigation.find(
      (navItem) => navItem.id === NavigationSections.WHATS_NEW
    ),
    [NavigationSections.PROCURE]: getProcureSection(filteredNavigation),
    [NavigationSections.SPEND]: getSpendSection(filteredNavigation),
    [NavigationSections.MANAGE]: getManageSection(filteredNavigation),
    [NavigationSections.SETTINGS]: filteredNavigation.find(
      (navItem) => navItem.id === NavigationSections.SETTINGS
    ),
  }

  return isAppReady ? (
    <Navigation
      location={pathname + hash}
      notificationCount={notificationCount}
      navigationItems={navigationItems}
    />
  ) : (
    <NavigationLoader />
  )
})
