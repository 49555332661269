import * as React from 'react'

import { type IDateProps } from '@webapp/core/components/FormattedDate/FormattedDate'
import { FormattedTimeStyled } from './styles/FormattedTimeStyled'
import { useFormatTime } from './useFormatTime'

export const FormattedTime: React.FC<IDateProps> = React.memo(
  ({ date = new Date() }) => {
    const formatTime = useFormatTime()
    return <FormattedTimeStyled>{formatTime(date)}</FormattedTimeStyled>
  }
)

FormattedTime.defaultProps = {}
FormattedTime.displayName = 'FormattedTime'
