import * as React from 'react'

import { useSelector } from 'react-redux'
import { Redirect, Switch } from 'react-router'

import { uuidRegExp } from '@webapp/config/react-router'
import { ProtectedRoute } from '@webapp/core/hoc/ProtectedRoute'
import { useFeatureFlag } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import {
  BillCreatePage,
  BillListPage,
  BillRequestDetailsPage,
} from '@webapp/pages'
import { BillApprovalDetailsPage } from '@webapp/pages/BillApprovalDetailsPage'
import { BillListPageStatuses } from '@webapp/pages/BillListPage'
import { makeSelectFeatureSwitches } from '@webapp/stores/global'

export const BillRouter = () => {
  const { url, brp } = useAppRoutes()

  const CL_1058_AP_GRANULAR_PERMISSIONS = useFeatureFlag(
    'CL_1058_AP_GRANULAR_PERMISSIONS'
  )

  const { disable_ap_navigation: disableAPNavigation } = useSelector(
    makeSelectFeatureSwitches()
  )

  return (
    <Switch>
      {disableAPNavigation && (
        <Redirect path='/bills' to={url('DashboardPage')} />
      )}

      <Redirect
        exact
        from={brp('BillListPage', {
          status: '',
        })}
        to={url('BillListPage', {
          status: BillListPageStatuses.DRAFT,
        })}
      />

      <ProtectedRoute
        path={brp('BillRequestDetailsPage', {
          uuid: `:uuid(${uuidRegExp.source})`,
        })}
        component={BillRequestDetailsPage}
      />

      <ProtectedRoute
        path={brp('BillApprovalDetailsPage', {
          uuid: `:uuid(${uuidRegExp.source})`,
        })}
        component={BillApprovalDetailsPage}
      />

      <ProtectedRoute
        path={brp('BillListPage', {
          status: ':status?',
        })}
        component={BillListPage}
        navigationPermissions={
          CL_1058_AP_GRANULAR_PERMISSIONS ? ['ap__manage_bills'] : ['ap__bills']
        }
      />

      <ProtectedRoute
        path={brp('BillCreatePage', {
          uuid: ':uuid?',
        })}
        component={BillCreatePage}
      />

      <Redirect to={url('DashboardPage')} />
    </Switch>
  )
}
