import { createSelector } from 'reselect'

import { type RootState } from '@webapp/reducers'
import { initialState } from './reducers'
import { type NavigationNotificationCount } from './types'

export const selectNotifications = (
  state: RootState
): NavigationNotificationCount => {
  return state.notificationCount || initialState
}

export const makeSelectInboxCount = () =>
  createSelector(
    selectNotifications,
    (notificationState) => notificationState.unread_notifications
  )

export const makeSelectNotificationCount = () =>
  createSelector(selectNotifications, (notificationState) => notificationState)
