export const downloadBlob = (blob: Blob, fileName: string) => {
  const tempUrl = URL.createObjectURL(blob)

  const tempLink = document.createElement('a')
  tempLink.href = tempUrl
  tempLink.download = fileName
  document.body.appendChild(tempLink)
  tempLink.click()
  document.body.removeChild(tempLink)
  window.URL.revokeObjectURL(tempUrl)
}
