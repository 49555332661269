import * as React from 'react'

import { Space } from '@procurify/ui'

import { type ApplicationStatus, type User } from '@webapp/models'
import { AppHeaderDomainSwitch } from './components/AppHeaderDomainSwitch'
import { AppHeaderLogo } from './components/AppHeaderLogo'
import { AppHeaderUser } from './components/AppHeaderUser'
import { AppHeaderUserImpersonationSwitch } from './components/AppHeaderUserImpersonationSwitch'
import { ConnectedAppHeaderInbox } from './components/ConnectedAppHeaderInbox'
import { QuickRequestButton } from './components/QuickRequestButton'
import { AppHeaderStyled } from './styles/AppHeaderStyled'
import { TrialModeIndicator } from '../TrialModeIndicator'

export interface IAppHeaderProps {
  user: User
  showUserImpersonationSwitch?: boolean
  showDomainSwitch?: boolean
  applicationStatus: ApplicationStatus
}

export const AppHeader = ({
  user,
  showUserImpersonationSwitch,
  showDomainSwitch,
  applicationStatus,
}: IAppHeaderProps) => {
  const { lock_mode, trial_mode } = applicationStatus
  const showTrialIndicatorButton = lock_mode || trial_mode

  return (
    <AppHeaderStyled>
      <Space direction='horizontal' align='center' justify='space-between'>
        <AppHeaderLogo />

        <Space direction='horizontal' align='center' size='large'>
          <QuickRequestButton />

          {showTrialIndicatorButton && (
            <TrialModeIndicator applicationStatus={applicationStatus} />
          )}

          {(showUserImpersonationSwitch || showDomainSwitch) && (
            <Space direction='horizontal' align='center' size='small'>
              {showUserImpersonationSwitch && (
                <AppHeaderUserImpersonationSwitch />
              )}

              {showDomainSwitch && <AppHeaderDomainSwitch />}
            </Space>
          )}

          <Space direction='horizontal' align='center' size='medium'>
            <ConnectedAppHeaderInbox />
            <AppHeaderUser user={user} />
          </Space>
        </Space>
      </Space>
    </AppHeaderStyled>
  )
}

AppHeader.displayName = 'AppHeader'
