import styled from 'styled-components'

import { type ILabelProps } from '../Label'

interface ILabelContentStyledProps {
  fluid?: ILabelProps['fluid']
}

export const LabelContentStyled = styled.div<ILabelContentStyledProps>`
  padding: ${(props) => props.theme.typography.pxToRem(3)}
    ${(props) => props.theme.typography.pxToRem(9)};

  ${(props) => {
    if (!props.fluid) {
      return `
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        `
    }
  }}

  align-self: center;
`
