import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { type Enum } from '@webapp/utils'
import messages from './messages'

export const SpendingCardApprovalListPageTabs = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
  ALL: 'all',
} as const

export type SpendingCardApprovalListPageTab = Enum<
  typeof SpendingCardApprovalListPageTabs
>
export interface SpendingCardApprovalListPageParams {
  tab: SpendingCardApprovalListPageTab
}

const SpendingCardApprovalListViewChunk = React.lazy(() =>
  import('../containers/SpendingCardApprovalListView').then(
    ({ SpendingCardApprovalListView }) => ({
      default: SpendingCardApprovalListView,
    })
  )
)

export const SpendingCardApprovalListPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.spendingCardApprovalListPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.spendingCardApprovalListPageTitle),
        },
      ]}
    >
      <SpendingCardApprovalListViewChunk />
    </Page>
  )
}
