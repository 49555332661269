import * as React from 'react'

import { type RouteComponentProps } from 'react-router-dom'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import messages from './messages'

const QboBillsSetupViewChunk = React.lazy(() =>
  import('../containers/CodatDetailsView').then(({ CodatDetailsView }) => ({
    default: CodatDetailsView,
  }))
)

export const QboPage = (props: RouteComponentProps) => {
  const { url } = useAppRoutes()
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.qboSetupPageTitle)}
      breadcrumbs={[
        {
          link: url('SettingsPage'),
          name: intl.formatMessage(messages.settingsPageTitle),
        },
        {
          link: url('IntegrationsPage'),
          name: intl.formatMessage(messages.integrationsListTitle),
        },
        {
          name: intl.formatMessage(messages.qboSetupPageTitle),
        },
      ]}
    >
      <QboBillsSetupViewChunk {...props} />
    </Page>
  )
}
