import * as React from 'react'

import { type PaletteProps } from '@material-ui/system'

import { LabelStyled } from './styles/LabelStyled'
import { Icon, type IconTypes } from '../Icon'
import { LabelCaptionStyled } from '@ui/elements/Label/styles/LabelCaptionStyled'
import { LabelContentStyled } from '@ui/elements/Label/styles/LabelContentStyled'
import {
  type ILabelImageStyledProps,
  LabelImageStyled,
} from '@ui/elements/Label/styles/LabelImageStyled'

export interface ILabelProps extends PaletteProps {
  /** Label text on the left */
  label?: string
  /** Icon on the left */
  icon?: IconTypes
  component?: React.ReactNode
  /** Image on the left */
  image?: ILabelImageStyledProps['image']
  /**
   * If true, then the label will automatically resize
   * to show the full content
   */
  fluid?: boolean
}

export const Label = ({
  component = null,
  bgcolor = 'darkGreen',
  color = 'white',
  label,
  icon,
  image,
  children,
  fluid,
  ...otherProps
}: React.PropsWithChildren<ILabelProps>) => {
  return (
    <LabelStyled fluid={fluid} aria-label={otherProps?.['aria-label']}>
      {component}
      {label && (
        <LabelCaptionStyled color={color} bgcolor={bgcolor}>
          {label}
        </LabelCaptionStyled>
      )}
      {icon && (
        <LabelCaptionStyled color={color} bgcolor={bgcolor}>
          <Icon icon={icon} size={20} />
        </LabelCaptionStyled>
      )}
      {image && <LabelImageStyled image={image} />}
      <LabelContentStyled fluid={fluid}>
        <span>{children}</span>
      </LabelContentStyled>
    </LabelStyled>
  )
}

Label.displayName = 'Label'
