import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const SpendInsightsChunk = React.lazy(() =>
  import('../containers/SpendInsightsView').then(({ SpendInsightsView }) => ({
    default: SpendInsightsView,
  }))
)

export const SpendInsightsPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.spendInsightsPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.spendInsightsPageTitle),
        },
      ]}
    >
      <SpendInsightsChunk />
    </Page>
  )
}
