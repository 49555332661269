import {
  REVERT_TO_LIVE_FF_STATE,
  UPDATE_DEV_HAS_FF_OVERRIDE,
  UPDATE_LIVE_FF_STATE,
} from './constants'
import {
  type RevertToLiveFFStateAction,
  type UpdateHasFFOverrideAction,
  type UpdateLiveFFStateAction,
} from './types'

export const updateHasFFOverride = (
  override: boolean
): UpdateHasFFOverrideAction => {
  return { type: UPDATE_DEV_HAS_FF_OVERRIDE, override }
}

export const updateLiveFFState = (flags): UpdateLiveFFStateAction => {
  return { type: UPDATE_LIVE_FF_STATE, flags }
}

export const revertToLiveFFState = (): RevertToLiveFFStateAction => {
  return { type: REVERT_TO_LIVE_FF_STATE }
}
