import moment from 'moment'
import { type FormatDateOptions, useIntl } from 'react-intl'

import { type IDateProps } from '@webapp/core/components/FormattedDate/FormattedDate'

export function useFormatTime() {
  const intl = useIntl()

  return (
    date: IDateProps['date'] = new Date(),
    options?: FormatDateOptions & { format?: string }
  ) => intl.formatTime(moment(date).toDate(), options)
}
