import * as React from 'react'

import { TableHead } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { theme } from '@procurify/ui-theme'
import { tint } from 'polished'

import { type ITableHeadProps } from '../TableHead'

const styles = {
  root: {
    backgroundColor: theme.colors.offwhite,
    '&:tr': {
      height: 0,
    },
  },
}

class TableHeadStyledComponent extends React.Component<
  { classes: any } & ITableHeadProps
> {
  render() {
    const { classes, ...otherProps } = this.props
    return <TableHead classes={{ root: classes.root }} {...otherProps} />
  }
}

export const TableHeadStyled = withStyles(styles)(TableHeadStyledComponent)
