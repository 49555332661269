import * as React from 'react'

import hoistNonReactStatics from 'hoist-non-react-statics'
import { ReactReduxContext } from 'react-redux'

import getInjectors from './reducerInjectors'

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
export default ({ key, reducer }) =>
  (WrappedComponent): any => {
    class ReducerInjector extends React.Component {
      static WrappedComponent = WrappedComponent

      static contextType = ReactReduxContext

      static displayName = `withReducer(${
        WrappedComponent.displayName || WrappedComponent.name || 'Component'
      })`

      injectors = getInjectors(this.context.store)

      UNSAFE_componentWillMount() {
        const { injectReducer } = this.injectors

        injectReducer(key, reducer)
      }

      render() {
        return <WrappedComponent {...this.props} />
      }
    }

    return hoistNonReactStatics(ReducerInjector, WrappedComponent)
  }

const useInjectReducer = ({ key, reducer }) => {
  const context = React.useContext(ReactReduxContext)
  React.useEffect(() => {
    getInjectors(context.store).injectReducer(key, reducer)
  }, [])
}

export { useInjectReducer }
