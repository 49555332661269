import { darken, lighten } from 'polished'
import styled from 'styled-components'

export const NavigationSubheaderStyled = styled.div`
  font-size: ${(props) => props.theme.typography.pxToRem(14)};

  display: flex;
  align-items: center;
  height: ${(props) => props.theme.typography.pxToRem(36)};
  background-color: ${(props) => props.theme.colors.white};

  font-weight: ${(props) => props.theme.typography.fontWeightMedium};

  padding-left: ${(props) => props.theme.typography.pxToRem(18)};

  @media (max-width: ${(props) => props.theme.breakpoints.values.xl + 'px'}) {
    padding-left: ${(props) => props.theme.space.small};
  }
`
