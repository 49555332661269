import * as React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import MuiTooltip, { type TooltipProps } from '@material-ui/core/Tooltip'
import styled from 'styled-components'

interface IToolTipStyleProps {
  textAlign?: 'left' | 'center' | 'right'
}

const arrowSize = '6px'

const useStyles = makeStyles((theme) => ({
  arrowArrow: {
    borderColor: 'transparent',
    borderStyle: 'solid',
    height: '0',
    position: 'absolute',
    width: '0',
  },
  arrowPopper: {
    '&[x-placement="bottom"] $arrowArrow': {
      borderBottomColor: '#1B3D46',
      borderWidth: `0 ${arrowSize} ${arrowSize}`,
      bottom: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    '&[x-placement="bottom-end"] $arrowArrow': {
      borderBottomColor: '#1B3D46',
      borderWidth: `0 ${arrowSize} ${arrowSize}`,
      bottom: '100%',
      right: '5px',
    },
    '&[x-placement="bottom-start"] $arrowArrow': {
      borderBottomColor: '#1B3D46',
      borderWidth: `0 ${arrowSize} ${arrowSize}`,
      bottom: '100%',
      left: '5px',
    },
    '&[x-placement="left"] $arrowArrow': {
      borderLeftColor: '#1B3D46',
      borderWidth: `${arrowSize} 0 ${arrowSize} ${arrowSize}`,
      left: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '&[x-placement="left-end"] $arrowArrow': {
      borderLeftColor: '#1B3D46',
      borderWidth: `${arrowSize} 0 ${arrowSize} ${arrowSize}`,
      bottom: '5px',
      left: '100%',
    },
    '&[x-placement="left-start"] $arrowArrow': {
      borderLeftColor: '#1B3D46',
      borderWidth: `${arrowSize} 0 ${arrowSize} ${arrowSize}`,
      left: '100%',
      top: '5px',
    },
    '&[x-placement="right"] $arrowArrow': {
      borderRightColor: '#1B3D46',
      borderWidth: `${arrowSize} ${arrowSize} ${arrowSize} 0`,
      right: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '&[x-placement="right-end"] $arrowArrow': {
      borderRightColor: '#1B3D46',
      borderWidth: `${arrowSize} ${arrowSize} ${arrowSize} 0`,
      bottom: '5px',
      right: '100%',
    },
    '&[x-placement="right-start"] $arrowArrow': {
      borderRightColor: '#1B3D46',
      borderWidth: `${arrowSize} ${arrowSize} ${arrowSize} 0`,
      right: '100%',
      top: '5px',
    },
    '&[x-placement="top"] $arrowArrow': {
      borderTopColor: '#1B3D46',
      borderWidth: `${arrowSize} ${arrowSize} 0`,
      left: '50%',
      top: '100%',
      transform: 'translateX(-50%)',
    },
    '&[x-placement="top-end"] $arrowArrow': {
      borderTopColor: '#1B3D46',
      borderWidth: `${arrowSize} ${arrowSize} 0`,
      right: '5px !important',
      top: '100%',
    },
  },
  tooltip: {
    backgroundColor: '#1B3D46',
    borderRadius: '4px',
    fontSize: theme.typography.pxToRem(12),
    maxWidth: theme.typography.pxToRem(272),
    textAlign: ({ textAlign }: IToolTipStyleProps) => textAlign || 'center',
    position: 'relative',
  },
}))

const MuiTooltipTitleStyled = styled.div`
  font-size: ${(props) => props.theme.typography.pxToRem(12)};
  padding: 1em;
`

export const MuiTooltipStyled = (props: IToolTipStyleProps & TooltipProps) => {
  const { children, title, textAlign, ...otherProps } = props
  const classes = useStyles({ textAlign })
  return (
    <MuiTooltip
      title={
        <MuiTooltipTitleStyled>
          {title}
          <span className={classes.arrowArrow} />
        </MuiTooltipTitleStyled>
      }
      classes={{ popper: classes.arrowPopper, tooltip: classes.tooltip }}
      {...otherProps}
    >
      {children}
    </MuiTooltip>
  )
}
