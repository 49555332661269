import * as React from 'react'

import ReactDOM from 'react-dom'

export interface IPageActionsProps {}

export const PageActions = ({
  children,
}: React.PropsWithChildren<IPageActionsProps>) => {
  const [pageActionContainer, setPageActionContainer] =
    React.useState<Element | null>(null)

  React.useEffect(() => {
    setPageActionContainer(document.querySelector('#page-action-container'))
  }, [])

  return pageActionContainer ? (
    ReactDOM.createPortal(children, pageActionContainer)
  ) : (
    <>{children}</>
  )
}

PageActions.displayName = 'PageActions'
