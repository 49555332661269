import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { type Enum } from '@webapp/utils'
import messages from './messages'

export const OrderStatusPageStatuses = {
  ALL: 'all',
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
  PURCHASED: 'purchased',
  RECEIVED: 'received',
} as const
export type OrderStatusPageStatus = Enum<typeof OrderStatusPageStatuses>

export interface OrderStatusPageParams {
  status: OrderStatusPageStatus
}

const OrderStatusViewChunk = React.lazy(() =>
  import('../containers/OrderStatusView').then(({ OrderStatusView }) => ({
    default: OrderStatusView,
  }))
)

export const OrderStatusPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.orderStatusPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.orderStatusPageTitle),
        },
      ]}
    >
      <OrderStatusViewChunk />
    </Page>
  )
}
