import * as React from 'react'

import { OverlayLoaderContainerStyled } from './styles/OverlayLoaderContainerStyled'
import { OverlayLoaderContentStyled } from './styles/OverlayLoaderContentStyled'
import { DotLoader } from '../Loaders'
import { SubText } from '../Text'

export interface IOverLayLoaderProps {
  text?: string
  hideIcon?: boolean
  visible?: boolean
  customLoader?: React.ReactNode
  /** Offset the loader by percentage */
  loaderOffsetY?: number
}

export const OverlayLoader = ({
  text = 'LOADING.........',
  hideIcon = false,
  visible = false,
  customLoader,
  loaderOffsetY,
}: IOverLayLoaderProps) => {
  return visible ? (
    <OverlayLoaderContainerStyled offsetY={loaderOffsetY}>
      <OverlayLoaderContentStyled>
        {customLoader || (
          <>
            {!hideIcon ? <DotLoader /> : null}
            <SubText>{text}</SubText>
          </>
        )}
      </OverlayLoaderContentStyled>
    </OverlayLoaderContainerStyled>
  ) : null
}
