import styled from 'styled-components'

import { ButtonLoaderStyled } from './ButtonLoaderStyled'
import { applyButtonColorCSS } from './utils'
import { BaseButton } from '../BaseButton'

export const TertiaryButtonStyled = styled(BaseButton)`
  ${(props) =>
    applyButtonColorCSS(
      props.theme.colors.button.tertiary.background,
      undefined,
      props.theme.colors.button.tertiary.text,
      props.theme.colors.button.tertiary.backgroundHover
    )}
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }

  &[disabled] {
    cursor: not-allowed;
    color: ${(props) => props.theme.colors.disabled};
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;

    &:hover {
      color: ${(props) => props.theme.colors.disabled};
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }
    &:active {
      color: ${(props) => props.theme.colors.disabled};
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;
    }
  }

  ${ButtonLoaderStyled} {
    display: none;
  }
`
