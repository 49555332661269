import * as React from 'react'

import { List } from '@material-ui/core'
import { IconAdd, MenuListItem, WithPopover } from '@procurify/ui'
import { IconBills } from '@procurify/ui'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { useProcurifyIntl, useFeatureFlag } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import {
  makeSelectFeatureSwitches,
  makeSelectNavigationPermissions,
} from '../../../stores/global'
import messages from '../messages'
import { QuickRequestButtonStyled } from '../styles/QuickRequestButtonStyled'

export const QuickRequestButton = () => {
  const intl = useProcurifyIntl()
  const CL_1058_AP_GRANULAR_PERMISSIONS = useFeatureFlag(
    'CL_1058_AP_GRANULAR_PERMISSIONS'
  )
  const permissions = useSelector(makeSelectNavigationPermissions())

  const { stripe_integration } = useSelector(makeSelectFeatureSwitches())

  const canRequestOrder = permissions.includes('request__order')
  const canRequestExpense = permissions.includes('request__expense')
  const canRequestTravel = permissions.includes('request__travel')
  const canRequestFund =
    stripe_integration && permissions.includes('request__pay_request')
  const canRequestVirtualCard =
    stripe_integration && permissions.includes('pay__virtual_cards')
  const canRequestBill = CL_1058_AP_GRANULAR_PERMISSIONS
    ? permissions.includes('ap__create_bills')
    : permissions.includes('ap__bills')

  const { url } = useAppRoutes()

  return canRequestOrder ||
    canRequestExpense ||
    canRequestTravel ||
    canRequestFund ? (
    <WithPopover
      content={({ dismiss }) => (
        <List
          style={{
            maxHeight: '230px',
            overflowX: 'hidden',
            overflowY: 'auto',
            paddingTop: '0.75rem',
            paddingBottom: '0.75rem',
          }}
        >
          {canRequestOrder && (
            <a
              href='/#/orders/request'
              title={intl.formatMessage(messages.quickRequestOrderBtnTitle)}
              onClick={dismiss}
            >
              <MenuListItem
                icon='request'
                text={intl.formatMessage(messages.quickRequestOrderBtnText)}
              />
            </a>
          )}
          {canRequestExpense && (
            <a
              href='/#/expenses/request'
              title={intl.formatMessage(messages.quickRequestExpenseBtnTitle)}
              onClick={dismiss}
            >
              <MenuListItem
                icon='meals'
                text={intl.formatMessage(messages.quickRequestExpenseBtnText)}
              />
            </a>
          )}
          {canRequestTravel && (
            <a
              href='/#/travels/request'
              title={intl.formatMessage(messages.quickRequestTravelBtnTitle)}
              onClick={dismiss}
            >
              <MenuListItem
                icon='flight'
                text={intl.formatMessage(messages.quickRequestTravelBtnText)}
              />
            </a>
          )}
          {canRequestVirtualCard && (
            <Link
              to={url('SpendingCardRequestVirtualCardPage')}
              title={intl.formatMessage(
                messages.quickRequestVirtualCardBtnTitle
              )}
              onClick={dismiss}
            >
              <MenuListItem
                icon='creditcard'
                text={intl.formatMessage(
                  messages.quickRequestVirtualCardBtnText
                )}
              />
            </Link>
          )}
          {canRequestFund && (
            <Link
              to={url('SpendingCardCreateFundRequestPage')}
              title={intl.formatMessage(
                messages.quickRequestPhysicalCardFundBtnTitle
              )}
              onClick={dismiss}
            >
              <MenuListItem
                icon='payment'
                text={intl.formatMessage(
                  messages.quickRequestPhysicalCardFundBtnText
                )}
              />
            </Link>
          )}
          {canRequestBill && (
            <Link
              to={url('BillCreatePage', {})}
              title={intl.formatMessage(messages.quickRequestBillBtnTitle)}
              onClick={dismiss}
            >
              <MenuListItem
                icon={<IconBills size={16} />}
                text={intl.formatMessage(messages.quickRequestBillBtnText)}
              />
            </Link>
          )}
        </List>
      )}
      trigger='click'
      placement='bottom'
      TooltipProps={{
        minWidth: 0,
      }}
    >
      <QuickRequestButtonStyled
        iconComponent={<IconAdd />}
        className='qa-header-shortcut-button'
      >
        {intl.formatMessage(messages.quickRequestBtnTitle)}
      </QuickRequestButtonStyled>
    </WithPopover>
  ) : null
}
