import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { type Enum } from '@webapp/utils'
import messages from './messages'

export const SpendingCardPhysicalCardTabs = {
  PENDING: 'pending',
  OPEN: 'open',
  CLOSED: 'closed',
} as const

export type SpendingCardPhysicalCardTabStatus = Enum<
  typeof SpendingCardPhysicalCardTabs
>

export interface SpendingCardPhysicalCardPageParams {
  tab: SpendingCardPhysicalCardTabStatus
}

const SpendingCardPhysicalCardViewChunk = React.lazy(() =>
  import('../containers/SpendingCardPhysicalCardView').then(
    ({ SpendingCardPhysicalCardView }) => ({
      default: SpendingCardPhysicalCardView,
    })
  )
)

export const SpendingCardPhysicalCardPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.spendingCardPhysicalCardPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.spendingCardPhysicalCardPageTitle),
        },
      ]}
    >
      <SpendingCardPhysicalCardViewChunk />
    </Page>
  )
}
