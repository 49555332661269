import { defineMessages } from 'react-intl'

const scope = `procurify.components.GlobalErrorScreen`

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Procurify',
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage: 'We have a problem...',
  },
  lostInSpace: {
    id: `${scope}.lostInSpace`,
    defaultMessage: "The page you're looking for is lost in space",
  },
  returnToDashboardButton: {
    id: `${scope}.returnToDashboardButton`,
    defaultMessage: 'Return to Dashboard',
  },
})
