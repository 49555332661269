import * as React from 'react'

import Helmet from 'react-helmet'

import {
  type IPageTopContainerProps,
  PageTopContainer,
} from './components/PageTopContainer'
import { PageViewLoader } from './components/PageViewLoader'
import { PageStyled } from './styles/PageStyled'

export interface IPageProps {
  pageTitle: string
  breadcrumbs: IPageTopContainerProps['breadcrumbs']
  /**
   * If `true`, the content of the page will be set to a max-width of 950px and horizontally centered in the app content
   */
  centered?: boolean
}

export const Page = ({
  children,
  pageTitle,
  breadcrumbs,
  centered = false,
}: React.PropsWithChildren<IPageProps>) => {
  return (
    <PageStyled centered={centered}>
      <Helmet>
        <title>Procurify | {pageTitle}</title>
      </Helmet>

      <PageTopContainer breadcrumbs={breadcrumbs} />
      <PageViewLoader>{children}</PageViewLoader>
    </PageStyled>
  )
}

Page.displayName = 'Page'
