import * as React from 'react'

import { type ListItemProps } from '@material-ui/core/ListItem'

import { ListItemIconStyled } from './styles/ListItemIconStyled'
import { ListItemTextStyled } from './styles/ListItemTextStyled'
import { MenuListItemStyled } from './styles/MenuListItemStyled'
import { type IconTypes, Icon } from '@ui/elements/Icon'
import { type IWithTooltipProps, WithTooltip } from '@ui/elements/Tooltip'

export interface IMenuListItemProps extends ListItemProps {
  icon?: IconTypes | React.ReactElement
  text: string | React.ReactElement
  sticky?: boolean
  disabled?: boolean
  selected?: boolean
  selectedIcon?: IconTypes
  closeOnSelect?: boolean
  centerText?: boolean
  tooltip?: IWithTooltipProps['title']
  linkProps?: React.AnchorHTMLAttributes<HTMLAnchorElement>
  onClick?(e: React.MouseEvent<HTMLElement>): void
}

export const MenuListItem = ({
  text,
  icon,
  selectedIcon,
  sticky = false,
  disabled = false,
  selected = false,
  closeOnSelect = null,
  centerText = false,
  tooltip,
  linkProps,
  onClick = () => undefined,
  ...props
}: IMenuListItemProps) => {
  const [isSelected, setIsSelected] = React.useState(selected)

  const handleMenuItemClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (onClick) {
      onClick(e)
    }
    setIsSelected(!isSelected)
  }

  const renderMenuItem = () => (
    <MenuListItemStyled
      className='menu-list-item'
      disabled={disabled}
      selected={isSelected}
      onClick={handleMenuItemClick}
      aria-label={props['aria-label']}
    >
      {typeof icon === 'string' && (
        <ListItemIconStyled className={icon}>
          <Icon
            icon={isSelected && selectedIcon ? selectedIcon : icon}
            ariaLabel={isSelected ? 'selected icon' : 'icon'}
            size={16}
          />
        </ListItemIconStyled>
      )}

      {typeof icon !== 'string' && (
        <ListItemIconStyled>{icon}</ListItemIconStyled>
      )}

      <ListItemTextStyled centerText={centerText} inset={true} primary={text} />
    </MenuListItemStyled>
  )

  const renderMenuListItem = () => {
    return linkProps ? (
      <a {...linkProps}>{renderMenuItem()}</a>
    ) : (
      renderMenuItem()
    )
  }

  return tooltip ? (
    <WithTooltip title={tooltip} placement='left'>
      <div>{renderMenuListItem()}</div>
    </WithTooltip>
  ) : (
    renderMenuListItem()
  )
}
