import * as React from 'react'

import { Checkbox } from '@material-ui/core'
import { type CheckboxProps } from '@material-ui/core/Checkbox/Checkbox'
import { shade } from 'polished'
import styled from 'styled-components'

export const CheckboxStyled = styled((props: CheckboxProps) => (
  <Checkbox
    {...props}
    classes={{
      checked: 'CheckboxChecked',
      disabled: 'CheckboxDisabled',
    }}
  />
))`
  && {
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.theme.typography.pxToRem(12)};
    width: ${(props) => props.theme.typography.pxToRem(16)};
    height: ${(props) => props.theme.typography.pxToRem(16)};
    padding: ${(props) => props.theme.typography.pxToRem(7)};
    border-radius: ${(props) =>
      props.theme.typography.pxToRem(props.theme.shape.borderRadius / 2)};
    background: ${(props) => props.theme.colors.white};
    border: 1px solid
      ${(props) => shade(0.3, props.theme.colors.content.border)};
    &.CheckboxDisabled {
      background: ${(props) => props.theme.colors.content.border};
      border-color: ${(props) => props.theme.colors.content.border};
      color: ${(props) => shade(0.3, props.theme.colors.lightGrey)};
    }
  }
  &.CheckboxChecked {
    background: ${(props) => props.theme.palette.primary.main};
    border-color: ${(props) => props.theme.palette.primary.main};
    &:hover {
      background: ${(props) => props.theme.palette.primary.main};
    }
  }
`
CheckboxStyled.displayName = 'CheckboxStyled'
