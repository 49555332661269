import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import messages from './messages'

const SupportAccessViewChunk = React.lazy(() =>
  import('../containers/SupportAccessView').then(({ SupportAccessView }) => ({
    default: SupportAccessView,
  }))
)

export const SupportAccessPage = () => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.supportAccessPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.settingsPageTitle),
          link: url('SettingsPage'),
        },
        {
          name: 'Support Access',
        },
      ]}
    >
      <SupportAccessViewChunk />
    </Page>
  )
}
