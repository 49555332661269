import * as React from 'react'

import { Icon } from '@procurify/ui'
import classNames from 'classnames'

import { BreadcrumbCrumbStyled } from './styles/BreadcrumbCrumbStyled'

export interface IBreadcrumbCrumbProps extends React.HtmlHTMLAttributes<any> {
  children: string
  escapeCaseFormatting?: boolean
}

export const BreadcrumbCrumb = (props: IBreadcrumbCrumbProps) => {
  const { className, children, ...otherProps } = props

  let formattedChildren = children
  if (!props.escapeCaseFormatting) {
    formattedChildren =
      children.charAt(0).toUpperCase() + children.slice(1).toLowerCase()
  }

  return (
    <>
      <Icon icon='chevron-thick-right' />
      <BreadcrumbCrumbStyled
        className={classNames('crumb', className)}
        {...otherProps}
      >
        {formattedChildren}
      </BreadcrumbCrumbStyled>
    </>
  )
}

BreadcrumbCrumb.displayName = 'Breadcrumb.Crumb'
