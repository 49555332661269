import React from 'react'

import { useRouteMatch } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { ContractListPageStatuses } from './ContractListPage'
import messages from './messages'

export interface ContractEditDetailsPageParams {
  uuid: string
}

const ContractEditDetailsAIViewChunk = React.lazy(() =>
  import(
    '../containers/ContractEditDetailsView/ContractEditDetailsAIView'
  ).then(({ ContractEditDetailsAIView }) => ({
    default: ContractEditDetailsAIView,
  }))
)

export const ContractEditDetailsPage = () => {
  const intl = useProcurifyIntl()
  const match = useRouteMatch<ContractEditDetailsPageParams>()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.contractEditDetailsTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.contractListTitle),
          link: url('ContractListPage', {
            status: ContractListPageStatuses.ACTIVE,
          }),
        },
        {
          name: intl.formatMessage(messages.contractDetailsTitle),
          link: url('ContractDetailsPage', {
            uuid: match.params.uuid,
          }),
        },
        {
          name: intl.formatMessage(messages.contractEditDetailsTitle),
        },
      ]}
    >
      <ContractEditDetailsAIViewChunk />
    </Page>
  )
}
