import * as React from 'react'

import { ModalCardHeaderStyled } from './styles/ModalCardHeaderStyled'
import { TransparentIconButton } from '../../elements/Button'
import { Toolbar } from '../../elements/Toolbar'
import { Header } from '@ui/elements/Header'

interface IModalHeaderProps {
  id?: string
  /** Callback function when the close button is pressed */
  onClose?: (event?: any) => void
}

export class ModalHeader extends React.Component<IModalHeaderProps> {
  render() {
    const { id, onClose, children } = this.props

    return (
      <ModalCardHeaderStyled haveCloseButton={!!onClose}>
        <Toolbar>
          <Header as='h3' id={id}>
            {children}
          </Header>
          <Toolbar.Fill />

          {!!onClose && (
            <TransparentIconButton
              icon='times'
              onClick={onClose}
              className='header-close-btn'
              aria-label='Close'
            />
          )}
        </Toolbar>
      </ModalCardHeaderStyled>
    )
  }
}
