import * as React from 'react'

import { FeedRow } from './FeedRow'
import { FeedContentStyled } from './styles/FeedContentStyled'
import { FeedLabelStyled } from './styles/FeedLabelStyled'
import { FeedStyled } from './styles/FeedStyled'

export interface IFeedProps {
  ordered?: boolean
  disc?: boolean
}

export class Feed extends React.PureComponent<IFeedProps> {
  static defaultProps = {}
  static Row = FeedRow
  static Label = FeedLabelStyled
  static Content = FeedContentStyled

  render() {
    return <FeedStyled {...this.props} />
  }
}
