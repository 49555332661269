import { type RepoStandardResponse } from '@webapp/infrastructure/interfaces/repositories/RepoStandardResponse'
import { type IHttpClient } from '@webapp/infrastructure/interfaces/services/IHttpClient'
import { type IOrderRepository } from '../../../business-logic/interfaces/OrderRepository'
import { type OrderConstructorData } from '../../../entities/Order'
import { mapOrderApiModel } from '../../mappers/mapOrderApiModel'
import { type GetOrderDetailsResponse } from '../../types/GetOrderDetailsResponse'

interface IDependency {
  httpClient: IHttpClient
}

export class OrderRepository implements IOrderRepository {
  constructor(private readonly deps: IDependency) {}

  async getDetails(uuid: uuid): Promise<
    RepoStandardResponse<
      OrderConstructorData,
      {
        permissions: {
          canApprove: boolean
          canDelete: boolean
          canEdit: boolean
          hasPendingItems: boolean
        }
      }
    >
  > {
    const res = await this.deps.httpClient.get<GetOrderDetailsResponse>(
      `/api/v3/orders/${uuid}/`
    )

    const { data: order, metadata } = res.data

    return {
      data: mapOrderApiModel(order),
      metadata: {
        permissions: {
          canApprove: metadata?.permissions.can_approve || false,
          canDelete: metadata?.permissions.can_delete || false,
          canEdit: metadata?.permissions.can_edit || false,
          hasPendingItems: metadata?.permissions.has_pending_items || false,
        },
      },
    }
  }
}
