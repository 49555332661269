import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'
import { PaymentPayBillsPageStatuses } from './PaymentPayBillsPage'

export interface PaymentPreparePageParams {
  uuid: string
}

const PaymentPrepareViewChunk = React.lazy(() =>
  import('../containers/PaymentPrepareView').then(({ PaymentPrepareView }) => ({
    default: PaymentPrepareView,
  }))
)

export const PaymentPreparePage = () => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.paymentPreparePageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.paymentPayBillsPageTitle),
          link: url('PaymentPayBillsPage', {
            status: PaymentPayBillsPageStatuses.DRAFT,
          }),
        },
        {
          name: intl.formatMessage(messages.paymentPreparePageTitle),
        },
      ]}
    >
      <PaymentPrepareViewChunk />
    </Page>
  )
}
