import { SpaceStyled } from '@procurify/ui'
import styled from 'styled-components'

export const AppHeaderStyled = styled.header`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  display: flex;
  grid-area: header;
  top: 0;
  left: 0;
  width: 100%;

  background-color: ${(props) => props.theme.colors.white};
  line-height: 1;
  height: ${(props) => props.theme.appHeader.height};

  position: fixed;
  z-index: ${(props) => props.theme.zIndex.appHeader};

  padding: 0 ${(props) => props.theme.space.medium};

  box-shadow: ${(props) => props.theme.shadows[4]};

  @media print {
    position: relative;
  }
`
