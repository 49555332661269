import { CardStyled } from '@procurify/ui'
import { FlexItemStyled } from '@procurify/ui'
import { em, rem } from 'polished'
import styled from 'styled-components'

interface IApproverOverwatchViewStyledProps {}

export const ApproverOverwatchViewStyled = styled.div<IApproverOverwatchViewStyledProps>`
  display: flex;
  font-size: ${rem(16)};

  width: 100%;

  > ${CardStyled} {
    height: 100%;
    width: 100%;
  }
`
