import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const NoPermissionsViewChunk = React.lazy(() =>
  import('../containers/NoPermissionView').then(({ NoPermissionsView }) => ({
    default: NoPermissionsView,
  }))
)

export const NoPermissionsPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={`${intl.formatMessage(messages.NoPermissionsTitle)}`}
      breadcrumbs={[]}
    >
      <NoPermissionsViewChunk />
    </Page>
  )
}
