import * as React from 'react'

import { TableCell } from '@material-ui/core'
import { tint } from 'polished'
import styled from 'styled-components'

import { type ITableCellProps } from './../TableCell'

export const TableCellStyled = styled((props: ITableCellProps) => {
  const { children, minWidth, nowrap, stickyRight, ...otherProps } = props

  return (
    <TableCell
      {...otherProps}
      classes={{
        root: 'root',
        head: 'head',
        body: 'body',
        alignRight: 'alignRight',
        stickyHeader: 'stickyHeader',
      }}
    >
      {children}
    </TableCell>
  )
})`
  &.root {
    font-family: inherit;
    padding: ${(props) =>
      props.size === 'small'
        ? `${props.theme.typography.pxToRem(
            4
          )} ${props.theme.typography.pxToRem(12)}`
        : props.theme.typography.pxToRem(12)};

    color: inherit;
    position: ${(props) => (props.stickyRight ? 'sticky' : 'relative')};
    right: ${(props) => (props.stickyRight ? 0 : 'auto')};
    min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  }

  &.head {
    border: none;
    font-size: ${(props) =>
      props.theme.typography.pxToRem(props.theme.fontSizes.table)};
    color: ${(props) => props.theme.colors.darkGrey};
    font-weight: ${(props) => props.theme.fontWeights.bold};
    white-space: ${(props) => (props.nowrap ? 'nowrap' : 'normal')};
    line-height: 1.2;
  }

  &.body {
    font-size: ${(props) =>
      props.theme.typography.pxToRem(props.theme.fontSizes.table)};
    color: inherit;
    font-weight: inherit;
    border-width: 0;
    border-style: none;
    white-space: ${(props) => (props.nowrap ? 'nowrap' : 'normal')};
  }

  &.alignRight {
    text-align: right;
  }

  &.head.stickyHeader {
    position: sticky;
    background-color: ${(props) => tint(0.5, props.theme.colors.lightGrey)};
    /* have to specify border here because stickyHeader has border-collaspe: seperate; */
    border: 1px solid ${(props) => props.theme.colors.content.border};
    border-top: 0;
    border-left: 0;
  }
`
