import { get } from 'lodash'

import { type GlobalAPIResponse } from '@webapp/api/GlobalAPI'
import {
  type ContentType,
  Currency,
  FeatureSwitches,
  SDKKeys,
  SegmentAnalyticsMetadata,
  SystemPreferences,
  User,
} from '@webapp/models'
import { type IGlobalState } from '@webapp/stores/global'

export const formatDataFromGlobalResponse = (
  globals: GlobalAPIResponse
): Partial<IGlobalState> => {
  return {
    user: globals?.user_profile,
    domain: globals?.domain,
    featureSwitches: globals?.feature_switches,
    baseCurrency: globals?.base_currency,
    navigationPermissions: globals?.filtered_navigation,
    permissions: globals?.permissions,
    endpoints: {
      media: globals?.url?.media,
      static: globals?.url?.static,
      pix: globals?.pix_host,
    },
    contenttypes: (globals?.contenttypes || []).reduce(
      (prev, ct: ContentType) => {
        prev[`${ct.app_label}__${ct.model}`] = ct
        return prev
      },
      {}
    ),
    systemPreferences: globals?.system_preferences,
    sdkKeys: globals?.sdk_keys,
    segmentAnalyticsMetadata: globals?.analytics,
    applicationStatus: globals?.application_status,
    role: globals?.role,
    headquartersTimezone: globals?.headquarters_timezone,
    setupProgress: globals?.setup_progress,
    env: globals?.env,
  }
}

export const getLocaleFromGlobalResponse = (globals: GlobalAPIResponse) => {
  return {
    locale: (globals?.system_preferences?.locale || '').split('-')[0] || 'en',
    fullLocale: globals?.system_preferences?.locale || 'en-us',
  }
}
