import * as React from 'react'

import { TableBody, TableCell, TableRow } from '@procurify/ui'
import ContentLoader from 'react-content-loader'

import { TextLoader } from '@webapp/core/components/ContentLoader'

export interface CustomWidthOption {
  width: number
  height?: number
  alignRight?: boolean
}

export interface ITableLoaderProps {
  rows?: number
  widths?: ('checkbox' | 'button' | `${number}%` | number | CustomWidthOption)[]
}

const Checkbox = () => {
  return (
    <ContentLoader
      height={14}
      width={14}
      style={{
        width: 14,
        height: 14,
      }}
    >
      <rect x='0' y='0' rx='4' ry='4' width={14} height='14' />
    </ContentLoader>
  )
}

const Button = () => {
  return (
    <ContentLoader height={32} width={32} style={{ width: 32, height: 32 }}>
      <rect x='0' y='0' rx='4' ry='4' width={32} height='32' />
    </ContentLoader>
  )
}

export class TableLoader extends React.PureComponent<ITableLoaderProps> {
  static defaultProps = {
    rows: 10,
    widths: [100],
  }

  render() {
    const TableCells = this.props.widths.map((width, cellIndex) => {
      let loader
      let align: 'left' | 'right' = 'left'

      if (typeof width === 'string') {
        switch (width) {
          case 'checkbox':
            loader = <Checkbox />
            break
          case 'button':
            loader = <Button />
            break
          default:
            loader = <TextLoader width={width} />
            break
        }
      } else if (typeof width === 'object' && width.width) {
        align = width.alignRight ? 'right' : 'left'
        loader = <TextLoader width={width.width} height={width.height} />
      } else {
        loader = <TextLoader width={width as number} />
      }

      return (
        <TableCell
          style={{ verticalAlign: 'middle' }}
          key={cellIndex}
          width={width === 'button' || width === 'checkbox' ? 1 : null}
          align={align}
        >
          {loader}
        </TableCell>
      )
    })

    const { rows } = this.props

    return (
      <TableBody>
        <React.Fragment>
          {Array(rows)
            .fill('')
            .map((e, i) => (
              <TableRow key={i}>{TableCells}</TableRow>
            ))}
        </React.Fragment>
      </TableBody>
    )
  }
}
