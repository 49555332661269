import { spacing } from '@material-ui/system'
import { em, rgba } from 'polished'
import styled from 'styled-components'

import { type IBadgeProps } from '../Badge'

export const badgeFontSize = 10

export const BadgeStyled = styled.span<IBadgeProps>`
  display: inline-block;
  font-size: ${(props) => props.theme.typography.pxToRem(badgeFontSize)};

  padding: ${em(3, badgeFontSize)} ${em(6, badgeFontSize)};

  color: ${(props) => props.theme.colors.text.inverse};
  background-color: ${(props) =>
    props.theme.colors[props.color] || props.theme.colors.darkGrey};

  line-height: 1.5;

  ${spacing}
`
