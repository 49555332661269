import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { type Enum } from '@webapp/utils'
import messages from './messages'

export const SpendingCardVirtualCardsPageTabs = {
  PENDING: 'pending',
  ACTIVE: 'active',
  DENIED: 'denied',
  LOCKED: 'locked',
} as const

export type SpendingCardVirtualCardsPageTab = Enum<
  typeof SpendingCardVirtualCardsPageTabs
>
export interface SpendingCardVirtualCardsPageParams {
  tab: SpendingCardVirtualCardsPageTab
}

const SpendingCardVirtualCardsViewChunk = React.lazy(() =>
  import('../containers/SpendingCardVirtualCardsView').then(
    ({ SpendingCardVirtualCardsView }) => ({
      default: SpendingCardVirtualCardsView,
    })
  )
)

export const SpendingCardVirtualCardsPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.spendingCardVirtualCardsPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.spendingCardVirtualCardsPageTitle),
        },
      ]}
    >
      <SpendingCardVirtualCardsViewChunk />
    </Page>
  )
}
