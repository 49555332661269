import * as React from 'react'

import {
  Header,
  type IWithPopoverProps,
  Space,
  StatusLabel,
  StatusLabelStatuses,
  SubText,
  WithPopover,
} from '@procurify/ui'
import { Card } from '@procurify/ui'

import { useFormatDate } from '@webapp/core/components/FormattedDate/useFormatDate'
import { FormatMedia } from '@webapp/core/hoc/FormatMedia/FormatMedia'
import { useProcurifyIntl } from '@webapp/hooks'
import { getFullName } from '@webapp/utils/user'
import messages from './messages'
import { WithUserTooltipProfileImageStyled } from './styles/WithUserTooltipProfileImageStyled'
import { WithUserTooltipStyled } from './styles/WithUserTooltipStyled'
import { type User } from '../../models'

export interface IWithUserTooltipProps extends Partial<IWithPopoverProps> {
  user: Partial<User>
  disabled?: boolean
}

export const WithUserTooltipInfo = ({
  user,
}: {
  user: IWithUserTooltipProps['user']
}) => {
  const intl = useProcurifyIntl()
  const formatDate = useFormatDate()

  return (
    <WithUserTooltipStyled>
      {user.profile_image && (
        <FormatMedia url={user.profile_image}>
          {(formattedImage) => (
            <WithUserTooltipProfileImageStyled profileImage={formattedImage} />
          )}
        </FormatMedia>
      )}
      <Card.Content p={1}>
        <Space size='small'>
          <Header as='h5'>
            {user.firstName} {user.lastName}
          </Header>

          {user.approval_delegatee && (
            <StatusLabel
              fontSize={10}
              text={intl.formatMessage(messages.approvalsDelegatedLabel)}
              type={StatusLabelStatuses.WARNING}
            />
          )}
          {user?.department && (
            <SubText>
              {user?.department?.branch?.name && (
                <>
                  <span>{user.department.branch.name}</span>
                  <br />
                </>
              )}
              {user?.department?.name && <span>{user.department.name}</span>}
            </SubText>
          )}

          {user.approval_delegatee && (
            <SubText>
              <span>
                {intl.formatMessage(messages.delegatedTo, {
                  name: getFullName(user.approval_delegatee),
                })}
              </span>
              {user.approval_delegatee?.expected_return_date && (
                <>
                  <br />
                  <span>
                    {intl.formatMessage(messages.expectedToReturn, {
                      date: formatDate(
                        user.approval_delegatee?.expected_return_date
                      ),
                    })}
                  </span>
                </>
              )}
            </SubText>
          )}
        </Space>
      </Card.Content>
    </WithUserTooltipStyled>
  )
}

export const WithUserTooltip = ({
  children,
  trigger,
  placement,
  user,
  disabled,
}: IWithUserTooltipProps) => {
  return (
    <WithPopover
      content={
        <div>
          <WithUserTooltipInfo user={user} />
        </div>
      }
      placement={placement ? placement : 'top'}
      trigger={trigger ? trigger : 'hover'}
      disableHoverListener={disabled}
    >
      {children}
    </WithPopover>
  )
}
