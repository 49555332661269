import styled from 'styled-components'

import { BreadcrumbStyled } from '@webapp/core/components/Breadcrumb/styles/BreadcrumbStyled'

interface IPageTopContainerStyledProps {}

export const PageTopContainerStyled = styled.div<IPageTopContainerStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  z-index: 1;
  position: relative;

  ${BreadcrumbStyled} {
    margin: 0;
  }
`
