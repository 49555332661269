import { type RequesterConstructor } from '@webapp/core/entities/internal/Requester'
import { mapDepartment } from './mapDepartment'
import { mapLocation } from './mapLocation'
import { type RequesterApiModel } from '../api-models/RequesterApiModel'

export const mapRequester = (
  requester: RequesterApiModel
): RequesterConstructor => ({
  id: requester.id,
  firstName: requester.firstName,
  lastName: requester.lastName,
  profileImage: requester.profile_image,
  primaryCategory: requester.department
    ? mapLocation(requester.department.branch)
    : null,
  secondaryCategory: requester.department
    ? mapDepartment(requester.department)
    : null,
})
