import * as React from 'react'

import { useSelector } from 'react-redux'

import { IntercomAPI } from '@webapp/api/IntercomAPI'
import { useFeatureFlag, useProcurifyIntl } from '@webapp/hooks'
import { INTERCOM_APP_ID } from '@webapp/infrastructure/envs'
import { PermissionGroupNames } from '@webapp/models/PermissionGroups'
import {
  makeSelectDomain,
  makeSelectRole,
  makeSelectUser,
} from '@webapp/stores/global'
import { IntercomButton } from './components/IntercomButton'
import messages from './messages'
import { IntercomContainerStyled } from './styles/IntercomContainer'

export const Intercom = () => {
  const intl = useProcurifyIntl()
  const FT_4880_ENABLE_INTERCOM = useFeatureFlag('FT_4880_ENABLE_INTERCOM')
  const user = useSelector(makeSelectUser())
  const domain = useSelector(makeSelectDomain())
  const role = useSelector(makeSelectRole())

  const [intercomUserHash, setIntercomUserHash] = React.useState<string>(null)

  React.useEffect(() => {
    IntercomAPI.getIntercomUserHash()
      .then((res) => {
        if (res?.data?.user_hash) {
          setIntercomUserHash(res.data.user_hash)
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  React.useEffect(() => {
    if (
      FT_4880_ENABLE_INTERCOM &&
      window?.Intercom &&
      intercomUserHash &&
      role &&
      PermissionGroupNames.SITE_ADMINISTRATOR !== role.name
    ) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: INTERCOM_APP_ID,
        email: user.email,
        user_hash: intercomUserHash,
        name: `${user.firstName} ${user.lastName}`,
        domain,
        role: role.name,
        company: {
          name: domain,
          id: domain,
        },
        is_superuser:
          PermissionGroupNames.SITE_ADMINISTRATOR === role.name ||
          PermissionGroupNames.SUPERUSER === role.name,
        custom_launcher_selector: '#intercom-custom-launcher',
        hide_default_launcher: true,
        vertical_padding: 70,
      })
    }
  }, [FT_4880_ENABLE_INTERCOM, user.email, role, domain, intercomUserHash])

  if (
    FT_4880_ENABLE_INTERCOM &&
    role &&
    PermissionGroupNames.SITE_ADMINISTRATOR !== role.name
  ) {
    return (
      <IntercomContainerStyled>
        <IntercomButton
          id='intercom-custom-launcher'
          icon='question'
          aria-label={intl.formatMessage(messages.intercomButtonText)}
        >
          {intl.formatMessage(messages.intercomButtonText)}
        </IntercomButton>
      </IntercomContainerStyled>
    )
  }
  return null
}
