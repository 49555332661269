import React, { Suspense } from 'react'

import Helmet from 'react-helmet'

import { useProcurifyIntl, useFeatureFlag } from '@webapp/hooks'
import { type BudgetCategoryDetails } from '@webapp/models'
import messages from './messages'

export const BUDGET_CATEGORY_EDIT_PAGE = '/settings/budget-categories/edit'
export const BUDGETS_EDIT_PAGE = '/settings/budgets/edit'

const BudgetCategoryEditChunk = React.lazy(() =>
  import('../containers/BudgetCategoryUpsertView').then(
    ({ BudgetCategoryUpsertView }) => ({
      default: BudgetCategoryUpsertView,
    })
  )
)

export interface IBudgetEditPageProps {}

export interface BudgetsEditPageParams {
  uuid: BudgetCategoryDetails['uuid']
}

export const BudgetCategoryEditPage = () => {
  const intl = useProcurifyIntl()
  const FT_5817_ENABLE_BUDGETS_REDESIGN = useFeatureFlag(
    'FT_5817_ENABLE_BUDGETS_REDESIGN'
  )

  return (
    <Suspense fallback={null}>
      <Helmet>
        <title>
          Procurify |{' '}
          {intl.formatMessage(
            FT_5817_ENABLE_BUDGETS_REDESIGN
              ? messages.editBudgetTitle
              : messages.editBudgetCategoryTitle
          )}
        </title>
      </Helmet>
      <BudgetCategoryEditChunk isEdit />
    </Suspense>
  )
}
