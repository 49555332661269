import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const ManageUsersViewChunk = React.lazy(() =>
  import('../feature-sets/ManageUsersView').then(({ ManageUsersView }) => ({
    default: ManageUsersView,
  }))
)

export const ManageUsersPage = () => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={`${intl.formatMessage(messages.ManageUsersTitle)}`}
      breadcrumbs={[
        {
          link: url('SettingsPage'),
          name: intl.formatMessage(messages.settingsTitle),
        },
        {
          name: intl.formatMessage(messages.ManageUsersTitle),
          escapeCaseFormatting: true,
        },
      ]}
    >
      <ManageUsersViewChunk />
    </Page>
  )
}
