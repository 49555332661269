/*
 * This component should never be changed manually
 * Please use `yarn generate-svg "SVG FILENAME"`
 */
import * as React from 'react'

import { ReactComponent as Icon } from '../assets/Notification.svg'
import { IconComponentStyled } from '../styles/IconComponentStyled'
import { type IIconComponentProps } from '../types'

export const IconNotification = ({ ...props }: IIconComponentProps) => {
  return (
    <IconComponentStyled {...props}>
      <Icon />
    </IconComponentStyled>
  )
}

IconNotification.displayName = 'IconNotification'
