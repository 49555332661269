import { css } from 'styled-components'

export const baseBreadcrumbStyles = css`
  display: inline-flex;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.typography.pxToRem(14)};

  min-height: ${(props) => props.theme.typography.pxToRem(40)};
  align-items: center;
`
