import type { SsoProviderOption } from '@webapp/models/enums'
import { ssoProviderOptions } from '@webapp/models/enums'

export interface SsoProvider {
  provider: SsoProviderOption
  name: string
}

export const availableSsoProviders = [
  {
    provider: ssoProviderOptions.AZURE,
    name: 'Azure',
  },
  {
    provider: ssoProviderOptions.OKTA,
    name: 'Okta',
  },
  {
    provider: ssoProviderOptions.ONELOGIN,
    name: 'OneLogin',
  },
] as readonly SsoProvider[]
