import * as React from 'react'

import styled, { css } from 'styled-components'

import { ModalCardContentStyled } from './ModalCardContentStyled'
import { CardHeaderStyled } from '@ui/elements/Card'

interface IModalCardHeaderStyled {
  haveCloseButton?: boolean
}

export const ModalCardHeaderStyled = styled(
  (props: React.PropsWithChildren<IModalCardHeaderStyled>) => (
    <CardHeaderStyled {...props} />
  )
)`
  font-size: inherit;
  position: relative;
  word-break: break-all;

  padding-top: ${(props) => props.theme.typography.pxToRem(40)};

  ${(props) =>
    props.haveCloseButton &&
    css`
      padding-right: ${props.theme.typography.pxToRem(88)};
    `}

  & + ${ModalCardContentStyled} {
    padding-top: ${(props) => props.theme.typography.pxToRem(16)};
  }

  .header-close-btn {
    position: absolute;
    top: ${(props) => props.theme.typography.pxToRem(24)};
    right: ${(props) => props.theme.typography.pxToRem(24)};
  }

  h3 {
    display: block;
    width: 100%;
  }
`
ModalCardHeaderStyled.displayName = 'ModalCardHeaderStyled'
