import * as React from 'react'

import { Header, Space } from '@procurify/ui'

import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'
import { GlobalErrorScreenStyled } from './styles/GlobalErrorScreenStyled'

export const GlobalErrorScreen = () => {
  const intl = useProcurifyIntl()

  return (
    <GlobalErrorScreenStyled>
      <Space align='center' justify='center'>
        <div>
          <Header gutter>
            {intl.formatMessage(messages.title)}
            <br />
            {intl.formatMessage(messages.subtitle)}
          </Header>

          <Header gutter as='h5'>
            {intl.formatMessage(messages.lostInSpace)}
          </Header>
        </div>
      </Space>
    </GlobalErrorScreenStyled>
  )
}

GlobalErrorScreen.displayName = 'GlobalErrorScreen'
