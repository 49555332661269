import { ApiClient } from '../services/api'

export const ENDPOINT = '/api/v3/inbox/'
export const INBOX_URL = ENDPOINT

export class InboxAPI {
  static getUnreadMessages = () => {
    return ApiClient.cancellable.get<any, any, { unread_count: number }>(
      `${INBOX_URL}?is_read=False`
    )
  }
  static markAsRead = (notificationId) => {
    return ApiClient.put(`${INBOX_URL}${notificationId}/`, {
      is_read: true,
    })
  }
  static markAllAsRead = () => {
    return ApiClient.put(`${INBOX_URL}read_all/`)
  }
}
