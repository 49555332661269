import * as React from 'react'

interface SheetContext {
  isSheetOpen: boolean
  setIsSheetOpen: (isOpen: boolean) => void
}

const SheetContext = React.createContext<SheetContext | undefined>(undefined)

const SheetContextProvider = SheetContext.Provider

export const useSheet = () => {
  const context = React.useContext(SheetContext)

  if (context === undefined) {
    throw new Error('useSheet must be used within a SheetProvider')
  }

  return context
}

export const SheetProvider = ({ children }) => {
  const [isSheetOpen, setIsSheetOpen] = React.useState<boolean>(false)

  return (
    <SheetContextProvider
      value={{
        isSheetOpen,
        setIsSheetOpen,
      }}
    >
      {children}
    </SheetContextProvider>
  )
}

SheetProvider.displayName = 'SheetProvider'
