import * as React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { updateCount } from '@webapp/stores/notificationCount'
import { AppHeaderInbox } from './AppHeaderInbox'
import { makeSelectInboxCount } from '../../../stores/notificationCount/selectors'

export const ConnectedAppHeaderInbox = () => {
  const dispatch = useDispatch()

  const unreadCount = useSelector<any, number>(makeSelectInboxCount())

  const onFetch = (res: any) => {
    dispatch(updateCount('unread_notifications', res?.metadata?.unread_count))
  }

  return <AppHeaderInbox unreadCount={unreadCount} onFetch={onFetch} />
}
