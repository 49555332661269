import * as React from 'react'

import { ListItem } from '@material-ui/core'
import { type ListItemProps } from '@material-ui/core/ListItem'
import { withStyles } from '@material-ui/core/styles'
import { theme } from '@procurify/ui-theme'
import { rem, tint } from 'polished'

const styles = {
  root: {
    backgroundColor: theme.colors.white,
    paddingRight: rem(12),
    paddingTop: rem(8),
    paddingBottom: rem(8),
    paddingLeft: rem(12),
    '&:hover': {
      backgroundColor: theme.colors.selected,
    },
  },
  selected: {
    backgroundColor: `${theme.colors.white} !important`,
  },
}

class MenuListItemStyledComponent extends React.Component<
  ListItemProps<'div'>
> {
  render() {
    const { classes, button, ...otherProps } = this.props
    return (
      <ListItem
        button={true}
        disableGutters={true}
        disableRipple={true}
        classes={{ root: classes.root, selected: classes.selected }}
        {...otherProps}
      />
    )
  }
}

export const MenuListItemStyled = withStyles(styles)(
  MenuListItemStyledComponent
)
