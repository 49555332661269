import * as React from 'react'

import { type CheckboxProps } from '@material-ui/core/Checkbox/'

import { CheckboxStyled } from './styles/CheckboxStyled'
import { Icon } from '../Icon'
import { Space } from '../Space'
import { SubText } from '../Text'

export interface ICheckboxProps extends CheckboxProps {}

export const Checkbox = (props: React.PropsWithChildren<ICheckboxProps>) => {
  const { children, checked = false, ...otherProps } = props
  return (
    <Space size='small' direction='horizontal' align='baseline'>
      <CheckboxStyled
        disableRipple={true}
        color='default'
        icon={<></>}
        checkedIcon={<Icon icon='checkmark' bold />}
        indeterminateIcon={<Icon icon='times' bold />}
        aria-checked={checked}
        checked={checked}
        {...otherProps}
      />
      {children && <SubText>{children}</SubText>}
    </Space>
  )
}
Checkbox.displayName = 'Checkbox'
