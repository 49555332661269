import * as React from 'react'

import { Card, EmptyState } from '@procurify/ui'
import * as Sentry from '@sentry/react'
import { get } from 'lodash'
import { FormattedMessage } from 'react-intl'

import messages from './messages'

export interface IErrorBoundaryProps {}
export interface IErrorBoundaryState {}

export class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  static initialState = {
    hasError: false,
  }
  readonly state = ErrorBoundary.initialState

  componentDidCatch(error) {
    this.setState({ hasError: true })
    // Do no report bubbled up sentry errors
    if (get(error, 'sdk.name') !== 'sentry.javascript.react') {
      const message = get(error, 'message')
      // Create a scope for error report
      Sentry.withScope((scope) => {
        // Set urgency to the max
        scope.setLevel(Sentry.Severity.Fatal)
        // Set custom tags for better filtering
        scope.setTags({
          errorBoundary: 'AppContent',
        })
        // Group similar events by their error message
        scope.setFingerprint([message])
        Sentry.captureException(error)
      })
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Card>
          <Card.Content>
            <FormattedMessage {...messages.errorHeader}>
              {(header) => (
                <FormattedMessage {...messages.errorMessage}>
                  {(message) => (
                    <EmptyState
                      context='activity'
                      header={header as string}
                      content={message as string}
                    />
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Card.Content>
        </Card>
      )
    }
    return this.props.children
  }
}
