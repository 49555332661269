import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { type Enum } from '@webapp/utils'
import messages from './messages'
import { PurchaseOrderListPageTabStatuses } from './PurchaseOrderListPage'

export const PurchaseOrderDetailsPageTabs = {
  RECEIVE_LOG: 'receive-log',
} as const
export type PurchaseOrderDetailsPageTab = Enum<
  typeof PurchaseOrderDetailsPageTabs
>

export interface PurchaseOrderDetailsPageParams {
  uuid: uuid
  version?: string
  tab?: PurchaseOrderDetailsPageTab
}

const PurchaseOrderDetailsViewChunk = React.lazy(() =>
  import('../containers/PurchaseOrderDetailsView').then(
    ({ PurchaseOrderDetailsView }) => ({
      default: PurchaseOrderDetailsView,
    })
  )
)

export const PurchaseOrderDetailsPage = () => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.purchaseOrderListPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.purchaseOrderListPageTitle),
          link: url('PurchaseOrderListPage', {
            status: PurchaseOrderListPageTabStatuses.OPEN,
          }),
        },
        {
          name: intl.formatMessage(messages.purchaseOrderDetailsPageTitle),
        },
      ]}
    >
      <PurchaseOrderDetailsViewChunk />
    </Page>
  )
}
