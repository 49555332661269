import * as React from 'react'

import Helmet from 'react-helmet'

import { PageViewLoader } from '@webapp/core/components/Page/components/PageViewLoader'
import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const LoginViewChunk = React.lazy(() =>
  import('../containers/LoginView').then(({ LoginView }) => ({
    default: LoginView,
  }))
)

export const LoginPage = () => {
  const intl = useProcurifyIntl()

  return (
    <div>
      <Helmet>
        <title>Procurify | {intl.formatMessage(messages.loginPageTitle)}</title>
      </Helmet>
      <PageViewLoader>
        <LoginViewChunk />
      </PageViewLoader>
    </div>
  )
}
