import * as React from 'react'

import { Redirect, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/core/hoc/ProtectedRoute'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import {
  ContractCreatePage,
  ContractDetailsPage,
  ContractEditDetailsPage,
  ContractListPage,
} from '@webapp/pages'
import { ContractListPageStatuses } from '@webapp/pages/ContractListPage'

const contractStatusFormat = Object.values(ContractListPageStatuses).join('|')

const ContractRoute = ({ path, component, exact = false }) => {
  return (
    <ProtectedRoute
      exact={exact}
      path={path}
      component={component}
      navigationPermissions={['procure__contracts']}
    />
  )
}

export const ContractRouter = () => {
  const { url, brp } = useAppRoutes()

  return (
    <Switch>
      <Redirect
        exact
        from={brp('ContractListPage', {
          status: '',
        })}
        to={url('ContractListPage', {
          status: ContractListPageStatuses.ACTIVE,
        })}
      />

      <ContractRoute
        path={brp('ContractListPage', {
          status: `:status(${contractStatusFormat})`,
        })}
        component={ContractListPage}
      />

      <ContractRoute
        path={brp('ContractCreatePage', {
          uuid: ':uuid?',
        })}
        component={ContractCreatePage}
      />

      <ContractRoute
        exact
        path={brp('ContractDetailsPage', {
          uuid: ':uuid',
        })}
        component={ContractDetailsPage}
      />

      <ContractRoute
        path={brp('ContractEditDetailsPage', {
          uuid: ':uuid',
        })}
        component={ContractEditDetailsPage}
      />

      <Redirect to={url('DashboardPage')} />
    </Switch>
  )
}
