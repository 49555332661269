import * as React from 'react'

import { em } from 'polished'
import styled, { type StyledComponent } from 'styled-components'

export const MediaFileTextStyled = styled.div`
  color: ${(props) => props.theme.colors.text.primary};
  font-size: ${em(9)};
  font-weight: bold;

  margin-top: ${em(6)};
  line-height: 1;
`

interface IMediaFileStyled {
  Text?: StyledComponent<any, any>
}

export const MediaFileStyled: IMediaFileStyled & StyledComponent<'div', any> =
  styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;

    align-items: center;
    justify-content: center;
    flex-direction: column;
  `

MediaFileStyled.Text = MediaFileTextStyled
