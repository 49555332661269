import { type SimpleVendor, type Vendor } from '@webapp/models'

export const isPreferredVendor = (vendor: Vendor | SimpleVendor) => {
  return vendor?.id > 1
}

export const isActiveVendor = (vendor: Vendor | SimpleVendor) => {
  return vendor?.active === true
}

export const getTruncatedAccountNumber = (accountNumber: string) => {
  return accountNumber.replace(/[^a-zA-Z0-9]/g, '').slice(-4)
}

export const isPendingVendorApproval = (vendor: Vendor) =>
  !!vendor?.vendor_request && vendor?.active === false
