import { defineMessages } from 'react-intl'

const scope = `procurify.components.ApproverOverwatchSubscriberList`

export default defineMessages({
  modalTitle: {
    id: `${scope}.modalTitle`,
    defaultMessage: 'Select Approvers',
  },
  userSelectPlaceholder: {
    id: `${scope}.userSelectPlaceholder`,
    defaultMessage: 'Who are you looking for?',
  },
  emptyStateText: {
    id: `${scope}.emptyStateText`,
    defaultMessage: 'There are no available approvers',
  },
  tableAriaLabel: {
    id: `${scope}.tableAriaLabel`,
    defaultMessage: 'Approver Subscriber Management List',
  },
  searchButtonLabel: {
    id: `${scope}.searchButtonLabel`,
    defaultMessage: 'Search',
  },
})
