import type * as React from 'react'

import { useSelector } from 'react-redux'

import { type ContentType } from '../../../models'
import {
  makeSelectContentTypesByID,
  makeSelectContentTypesByModel,
} from '../../../stores/global'

export interface IFormatContentType {
  app?: ContentType['app_label']
  model?: ContentType['model']
  id?: number
  children: (contenttype: ContentType) => JSX.Element
}

export const FormatContentType: React.SFC<IFormatContentType> = ({
  id,
  app,
  model,
  children,
}) => {
  if (app && model) {
    const contenttype = useSelector(makeSelectContentTypesByModel(app, model))

    if (contenttype) {
      return children(contenttype)
    } else {
      return null
    }
  } else if (id) {
    const contenttype = useSelector(makeSelectContentTypesByID(id))

    if (contenttype) {
      return children(contenttype)
    } else {
      return null
    }
  } else {
    return null
  }
}
