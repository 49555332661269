import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const SettingsBillFormViewChunk = React.lazy(() =>
  import('../containers/SettingsBillFormView').then(
    ({ SettingsBillFormView }) => ({
      default: SettingsBillFormView,
    })
  )
)

export const SettingsBillFormPage = () => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.settingsBillFormPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.settingsPageTitle),
          link: url('SettingsPage'),
        },
        { name: intl.formatMessage(messages.settingsBillFormPageTitle) },
      ]}
    >
      <SettingsBillFormViewChunk />
    </Page>
  )
}
