import * as React from 'react'

import { useSelector } from 'react-redux'

import { toast } from '@webapp/components/Notification'
import { useProcurifyIntl } from '@webapp/hooks'
import { getChannelsForCurrentUserAndDomain } from '@webapp/services/SystemChannelManager/SystemChannelManager.service'
import { useSystemChannelSubscription } from '@webapp/services/SystemChannelManager/useSystemChannelSubscription'
import { makeSelectDomain, makeSelectUser } from '@webapp/stores/global'
import messages from '../messages'

interface VendorPerformanceCompleteEvent {
  data?: {
    migration_status: 'complete'
  }
}

const VENDOR_PERFORMANCE_COMPLETE_EVENT = 'system-message'

export const useSubscribeVendorPerformanceChannel = () => {
  const intl = useProcurifyIntl()

  const user = useSelector(makeSelectUser())
  const domain = useSelector(makeSelectDomain())

  const { systemChannel } = getChannelsForCurrentUserAndDomain(domain, user.id)

  const channel = useSystemChannelSubscription({ channel: systemChannel })

  React.useEffect(() => {
    const callback = (data: VendorPerformanceCompleteEvent) => {
      if (data?.data?.migration_status === 'complete') {
        toast.info({
          title: intl.formatMessage(messages.vendorPerformanceCompleteTitle),
          children: intl.formatMessage(
            messages.vendorPerformanceCompleteMessage
          ),
        })
      }
    }

    if (channel) {
      channel.bind(VENDOR_PERFORMANCE_COMPLETE_EVENT, callback)
    }

    return () => {
      if (channel) {
        channel.unbind(VENDOR_PERFORMANCE_COMPLETE_EVENT, callback)
      }
    }
  }, [channel])
}
