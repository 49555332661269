import { defineMessages } from 'react-intl'

const scope = `procurify.order.pages`

export default defineMessages({
  orderDetailsPageTitle: {
    id: `${scope}.orderDetailsPageTitle`,
    defaultMessage: 'Order Details',
  },
})
