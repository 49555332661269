import * as React from 'react'

import { type RouteComponentProps } from 'react-router-dom'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { type Enum } from '@webapp/utils'
import messages from './messages'

export const ReceiveListPageTabStatuses = {
  OPEN: 'open',
  PARTIAL: 'partial',
  CLOSED: 'closed',
  CANCELLED: 'cancelled',
  PAID: 'paid',
  ALL: 'all',
} as const
export type ReceiveListPageTabStatus = Enum<typeof ReceiveListPageTabStatuses>

export interface ReceiveListPageParams {
  status: ReceiveListPageTabStatus
}

const ReceiveListViewChunk = React.lazy(() =>
  import('../containers/ReceiveListView').then(({ ReceiveListView }) => ({
    default: ReceiveListView,
  }))
)

export const ReceiveListPage = (
  props: RouteComponentProps<ReceiveListPageParams>
) => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.receiveListPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.receiveListBreadcrumbTitle),
        },
      ]}
    >
      <ReceiveListViewChunk {...props} />
    </Page>
  )
}
