import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const SpendingCardReconcileTransactionsChunk = React.lazy(() =>
  import(
    /* webpackChunkName: 'PayReconciliation' */ '../containers/SpendingCardReconcileTransactionsView'
  ).then(({ SpendingCardReconcileTransactionsView }) => ({
    default: SpendingCardReconcileTransactionsView,
  }))
)

export const SpendingCardReconcileTransactionsPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(
        messages.spendingCardReconcileTransactionsPageTitle
      )}
      breadcrumbs={[
        {
          name: intl.formatMessage(
            messages.spendingCardReconcileTransactionsPageTitle
          ),
        },
      ]}
    >
      <SpendingCardReconcileTransactionsChunk />
    </Page>
  )
}
