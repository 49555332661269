import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const ExpenseStatusViewChunk = React.lazy(() =>
  import('../feature-sets/ExpenseStatusView').then(({ ExpenseStatusView }) => ({
    default: ExpenseStatusView,
  }))
)

export const ExpenseStatusPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle='Procurify | Expense Status'
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.pageTitle),
        },
      ]}
    >
      <ExpenseStatusViewChunk />
    </Page>
  )
}
