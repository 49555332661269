import * as React from 'react'

import { MediaFileStyled } from './styles/MediaFileStyled'
import { MediaImageStyled } from './styles/MediaImageStyled'
import { MediaStyled } from './styles/MediaStyled'
import MediaHelper from '../../utils/mediaHelper'
import { IconFile } from '../Icon/components/IconFile'

export interface IMediaProps {
  as?: any
  rel?: string
  /** Source URL of the media */
  src?: string
  fallbackSrc?: string
  name?: string
  /** Size of the media */
  size?: 'xsmall' | 'small' | 'large' | 'maximize'
  href?: string
  icon?: React.ReactNode
  target?: string
  circular?: boolean
  shadow?: boolean
  className?: any
  onClick?(e: React.MouseEvent<HTMLElement>): void
}

const setBackgroundImage = (div: HTMLDivElement, src: string) => {
  div.style.backgroundImage = `url(${src})`
}

export const Media: React.FC<IMediaProps> = ({
  as = 'a',
  rel = 'noreferrer',
  src,
  fallbackSrc = '',
  name,
  size,
  icon = <IconFile size={20} />,
  href,
  target = '_blank',
  circular,
  shadow = true,
  onClick,
}) => {
  const imagePromise = React.useRef<Promise<unknown>>()
  const imgRef = React.useRef<HTMLDivElement>()

  React.useEffect(() => {
    if (!src) return

    setBackgroundImage(imgRef.current, src)

    if (fallbackSrc) {
      testForFallback(src)
    }
  }, [src])

  const handleClick = (e) => {
    if (onClick) {
      onClick(e)
    }
  }

  const testForFallback = (imageSrc) => {
    imagePromise.current = MediaHelper.createImage(imageSrc)
    imagePromise.current.catch(
      ((promise) => {
        return () => {
          if (imagePromise.current === promise) {
            if (fallbackSrc) {
              setBackgroundImage(imgRef.current, fallbackSrc)
            }
          }
        }
      })(imagePromise.current)
    )
  }

  const fileExtenstion = MediaHelper.getFileExtension(name || src)
  const fileTypeText = fileExtenstion ? fileExtenstion.toUpperCase() : 'FILE'

  return (
    <MediaStyled
      as={as}
      href={href ? href : src}
      target={target}
      size={size}
      onClick={handleClick}
      rel={rel}
      circular={circular}
      shadow={shadow}
      title={name || src}
    >
      <MediaFileStyled>
        {icon}
        <MediaFileStyled.Text>
          <span>{fileTypeText}</span>
        </MediaFileStyled.Text>
      </MediaFileStyled>

      <MediaImageStyled ref={imgRef} />
    </MediaStyled>
  )
}
