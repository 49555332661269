import * as React from 'react'

import { type ISpacerStyledProps, SpacerStyled } from './styles/SpacerStyled'

export interface ISpacerProps extends Partial<ISpacerStyledProps> {
  /**
   * This component does not accept any children.
   */
  children?: never
}

/**
 * Spacer provides a visual separation between vertically stacked components.
 */
export const Spacer = ({ space = 1 }: ISpacerProps) => (
  <SpacerStyled space={space} />
)

Spacer.displayName = 'Spacer'
