import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const InstanceManagementViewChunk = React.lazy(() =>
  import('../containers/InstanceManagementView').then(
    ({ InstanceManagementView }) => ({
      default: InstanceManagementView,
    })
  )
)

export const InstanceManagementPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.instanceManagementPageTitle)}
      breadcrumbs={[]}
    >
      <InstanceManagementViewChunk />
    </Page>
  )
}
