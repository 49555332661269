import { rem } from 'polished'
import styled from 'styled-components'

import { type User } from '../../../models'

interface IWithUserTooltipStyledProps {
  profileImage: User['profile_image']
}

export const WithUserTooltipStyled = styled.div`
  font-size: ${rem(16)};

  .profile-info {
    padding: 1em;
  }
`
