import * as React from 'react'

import { type IBaseTextProps } from './BaseText'
import { TextStyled } from './styles/TextStyled'

export const Text = (props: React.PropsWithChildren<IBaseTextProps>) => (
  <TextStyled {...props} />
)

Text.displayName = 'Text'
