import * as React from 'react'

import { Redirect, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/core/hoc/ProtectedRoute'
import { useFeatureFlag } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { UnbilledItemStatuses } from '@webapp/models/enums/UnbilledItemStatus'
import { UnbilledItemsPage } from '@webapp/pages/UnbilledItemsPage'

export const UnbilledItemsRouter = () => {
  const { url, brp } = useAppRoutes()

  const FT_4287_MIGRATE_UNBILLED_ITEMS_PAGE = useFeatureFlag(
    'FT_4287_MIGRATE_UNBILLED_ITEMS_PAGE'
  )

  return FT_4287_MIGRATE_UNBILLED_ITEMS_PAGE ? (
    <Switch>
      <Redirect
        exact
        from={brp('UnbilledItemsPage', {
          status: '',
        })}
        to={url('UnbilledItemsPage', {
          status: UnbilledItemStatuses.ACTIVE,
        })}
      />

      <ProtectedRoute
        path={brp('UnbilledItemsPage', {
          status: ':status?',
        })}
        component={UnbilledItemsPage}
      />

      <Redirect to={url('DashboardPage')} />
    </Switch>
  ) : (
    <Redirect to={url('DashboardPage')} />
  )
}
