import { type StripeBankAccount } from '../models'
import { ApiClient, type IApiRequestParam } from '../services/api'

export const ENDPOINT = '/api/v3/integrations/stripe/bank-accounts'

export const CARD_MANAGEMENT_BANK_ACCOUNT_URL = ENDPOINT
export const PLAID_INTEGRATION_URL = '/api/v3/integrations/plaid'

export interface StripeAccountVerifySchema {
  first_amount: number
  second_amount: number
}

export interface StripeAccountConnectBankAccountSchema
  extends Pick<
    StripeBankAccount,
    'account_holder_name' | 'status' | 'account_number'
  > {
  routing_number: string
}
export interface StripeAccountConnectBankAccountWithPlaidSchema {
  plaid_public_token: string
  plaid_account_id: number
}

export class CardManagementBankAPI {
  static readonly getBankAccounts = (params: IApiRequestParam = {}) =>
    ApiClient.get<StripeBankAccount[]>(`${CARD_MANAGEMENT_BANK_ACCOUNT_URL}/`, {
      params,
    })

  static readonly connectBankAccountWithStripe = (
    data: StripeAccountConnectBankAccountSchema
  ) =>
    ApiClient.post<StripeAccountConnectBankAccountSchema, StripeBankAccount>(
      `${CARD_MANAGEMENT_BANK_ACCOUNT_URL}/`,
      data
    )

  static readonly getPlaidLinkToken = () =>
    ApiClient.post<undefined, { link_token: string }>(
      `${PLAID_INTEGRATION_URL}/link-token/`
    )

  static readonly connectBankAccountWithPlaid = (
    data: StripeAccountConnectBankAccountWithPlaidSchema
  ) =>
    ApiClient.post<
      StripeAccountConnectBankAccountWithPlaidSchema,
      StripeBankAccount
    >(`${CARD_MANAGEMENT_BANK_ACCOUNT_URL}/`, data)

  static readonly verifyBankAccount = (
    bankAccountID: string,
    params: StripeAccountVerifySchema
  ) =>
    ApiClient.post<StripeAccountVerifySchema, StripeBankAccount>(
      `${CARD_MANAGEMENT_BANK_ACCOUNT_URL}/${bankAccountID}/verify/`,
      params
    )
}
