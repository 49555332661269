import { type AxiosResponse } from 'axios'

import { genericApi } from '@webapp/config/api'
import { type APIApplication } from '@webapp/models/APIApplication'
import {
  type Auth0UserImpersonationOption,
  type SsoConnection,
} from '../models'
import {
  ApiClient,
  GenericApiClient,
  type IApiRequestParam,
  type IApiResponse,
} from '../services/api'

export const ENDPOINT = '/authentication/'

export const AUTHENTICATION_URL = ENDPOINT

export interface IAPIApplicationCreateFormSchema
  extends Partial<Pick<APIApplication, 'description'>> {}

export const Auth0AuthenticationAPI = {
  getImpersonationList: (params: IApiRequestParam = {}) => {
    return GenericApiClient.get<Auth0UserImpersonationOption[]>(
      `${AUTHENTICATION_URL}impersonate/`,
      { params }
    )
  },
  impersonateUser: (data = null) => {
    return GenericApiClient.post<Auth0UserImpersonationOption[]>(
      `${AUTHENTICATION_URL}impersonate/`,
      data
    )
  },
  createApplication: (data: IAPIApplicationCreateFormSchema) =>
    ApiClient.post<IAPIApplicationCreateFormSchema, APIApplication>(
      `${AUTHENTICATION_URL}application/`,
      data
    ),
  getApplication: () =>
    ApiClient.get<Omit<APIApplication, 'client_secret'>>(
      `${AUTHENTICATION_URL}application/`
    ),
  deleteApplication: () =>
    ApiClient.delete(`${AUTHENTICATION_URL}application/`),
  getSsoConnection: () =>
    genericApi.get<IApiResponse<SsoConnection>>(
      `${AUTHENTICATION_URL}connection/`
    ),
  createSsoConnection: (newConnection: SsoConnection) =>
    genericApi.post<SsoConnection, AxiosResponse<IApiResponse<SsoConnection>>>(
      `${AUTHENTICATION_URL}connection/`,
      newConnection
    ),
  deleteSsoConnection: () =>
    genericApi.delete<null>(`${AUTHENTICATION_URL}connection/`),
} as const
