import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import messages from './messages'
import { SpendingCardVirtualCardsPageTabs } from './SpendingCardVirtualCardsPage'

const SpendingCardRequestVirtualCardViewChunk = React.lazy(() =>
  import('../containers/SpendingCardRequestVirtualCardView').then(
    ({ SpendingCardRequestVirtualCardView }) => ({
      default: SpendingCardRequestVirtualCardView,
    })
  )
)

export const SpendingCardRequestVirtualCardPage = () => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(
        messages.spendingCardRequestVirtualCardPageTitle
      )}
      breadcrumbs={[
        {
          link: url('SpendingCardVirtualCardsPage', {
            tab: SpendingCardVirtualCardsPageTabs.ACTIVE,
          }),
          name: intl.formatMessage(messages.spendingCardVirtualCardsPageTitle),
        },
        {
          name: intl.formatMessage(
            messages.spendingCardRequestVirtualCardPageTitle
          ),
        },
      ]}
    >
      <SpendingCardRequestVirtualCardViewChunk />
    </Page>
  )
}
