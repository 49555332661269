import { em, rem } from 'polished'
import styled from 'styled-components'

interface IApproverOverwatchStyledProps {}

export const ApproverOverwatchStyled = styled.div<IApproverOverwatchStyledProps>`
  font-size: ${rem(16)};
  width: 100%;

  .table-scroller {
    height: 100%;
    width: 100%;
    overflow: auto;
    position: relative;

    border: 1px solid ${(props) => props.theme.colors.content.border};

    > table {
      position: absolute;
      border: none;

      th {
        top: 0;
        position: sticky;
        border-top: none;
        z-index: 1;
      }

      th:first-child {
        border-left: none;
      }

      th:last-child {
        border-right: none;
      }

      tr {
        border: none;
      }

      td {
        border: none;
      }
    }
  }
`
