import * as React from 'react'

import { type IIconButtonProps } from './IconButton'
import { TransparentIconButtonStyled } from './styles/TransparentIconButtonStyled'
import { Icon, type IIconProps } from '../Icon'
import { CircleLoader } from '../Loaders'

export interface ITransparentIconButtonProps extends IIconButtonProps {
  iconColor?: IIconProps['color']
}

export const TransparentIconButton = ({
  htmlTitle,
  onClick,
  isLoading,
  iconComponent,
  icon,
  disabled,
  iconColor = 'textPrimary',
  ...otherProps
}: ITransparentIconButtonProps) => {
  return (
    <TransparentIconButtonStyled
      title={htmlTitle}
      onClick={!isLoading ? onClick : undefined}
      isLoading={isLoading}
      disabled={disabled || isLoading}
      {...otherProps}
    >
      {!isLoading ? (
        iconComponent ? (
          iconComponent
        ) : (
          <Icon icon={icon} color={iconColor} />
        )
      ) : (
        <CircleLoader size={14} color={iconColor} />
      )}
    </TransparentIconButtonStyled>
  )
}
