import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

export interface PaymentDetailsPageParams {
  uuid: string
}

const PaymentDetailsViewChunk = React.lazy(() =>
  import('../containers/PaymentDetailsView').then(({ PaymentDetailsView }) => ({
    default: PaymentDetailsView,
  }))
)

export const PaymentDetailsPage = () => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.paymentDetailsPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.paymentListPageTitle),
          link: url('PaymentListPage'),
        },
        {
          name: intl.formatMessage(messages.paymentDetailsPageTitle),
        },
      ]}
    >
      <PaymentDetailsViewChunk />
    </Page>
  )
}
