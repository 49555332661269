import { defineMessages } from 'react-intl'

const scope = `procurify.components.ApproverOverwatchUserTable`

export default defineMessages({
  tableHeaderDescriptionText: {
    id: `${scope}.tableHeaderDescriptionText`,
    defaultMessage: 'Who is interested in seeing other Approvals?',
  },
  userSelectPlaceholder: {
    id: `${scope}.userSelectPlaceholder`,
    defaultMessage: 'Who are you looking for?',
  },
  tableHeaderApproversText: {
    id: `${scope}.tableHeaderApproversText`,
    defaultMessage: 'Approvers',
  },
  tableHeaderIsWatchingText: {
    id: `${scope}.tableHeaderIsWatchingText`,
    defaultMessage: 'is watching',
  },
  approvers: {
    id: `${scope}.approvers`,
    defaultMessage: 'approvers',
  },
  emptyStateText: {
    id: `${scope}.emptyStateText`,
    defaultMessage: 'There are no available approvers',
  },
  tableAriaLabel: {
    id: `${scope}.tableAriaLabel`,
    defaultMessage: 'Approvers List',
  },
  searchButtonLabel: {
    id: `${scope}.searchButtonLabel`,
    defaultMessage: 'Search',
  },
})
