import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const SettingsExpenseFormViewChunk = React.lazy(() =>
  import('../containers/SettingsExpenseFormView').then(
    ({ SettingsExpenseFormView }) => ({
      default: SettingsExpenseFormView,
    })
  )
)

export const SettingsExpenseFormPage = () => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.settingsExpenseFormPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.settingsPageTitle),
          link: url('SettingsPage'),
        },
        { name: intl.formatMessage(messages.settingsExpenseFormPageTitle) },
      ]}
    >
      <SettingsExpenseFormViewChunk />
    </Page>
  )
}
