import { em } from 'polished'
import styled from 'styled-components'

import { type IMediaProps } from '../Media'
import { boxShadow } from '@ui/utils/boxShadow'

const sizes = {
  xsmall: 0.6,
  small: 0.8,
  large: 1.2,
  default: 1,
}

export const MediaStyled = styled.div<IMediaProps>`
  font-size: ${(props) => sizes[props.size || 'default'] || sizes.default}rem;

  color: ${(props) => props.theme.colors.text.primary};
  display: ${(props) => (props.as === 'div' ? 'block' : 'inline-block')};

  ${(props) => {
    if (props.size !== 'maximize') {
      return `
        position: relative;
        width: ${em(52, props.theme.typography.htmlFontSize)};
        min-width: ${em(52, props.theme.typography.htmlFontSize)};
        height: ${em(52, props.theme.typography.htmlFontSize)};
      `
    } else if (props.size === 'maximize') {
      return `
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      `
    }
  }}

  background: ${(props) => props.theme.colors.offwhite};
  border: 1px solid ${(props) => props.theme.colors.content.border};

  border-radius: ${(props) => (props.circular ? '50%' : '4px')};
  overflow: hidden;

  ${(props) => (props.shadow ? boxShadow() : null)};

  & + & {
    margin-left: ${(props) => props.theme.typography.pxToRem(12)};
  }
`
