import { type ApprovalStatus } from '@webapp/components/ApprovalStatusLabel/ApprovalStatusLabel'
import {
  type PayCard,
  type PayCardholder,
  type SpendingCardCard,
  type User,
} from '@webapp/models'
import { PayRequestStatusEnum } from '@webapp/models/enums'
import { type PayRequest } from '@webapp/models/PayRequest'

export const formatCardExpiryDate = (expiryDate: string) => {
  if (expiryDate?.length !== 4) return ''

  // Date is formatted MMYY from Pay Card API
  return expiryDate.slice(0, 2).concat('/').concat(expiryDate.slice(2, 4))
}

export const removeWhiteSpace = (val: string) => {
  return val.replace(/\u2000/g, '').replace(/ /g, '')
}

export const convertCentsToDollars = (cents: number) => {
  return cents / 100
}

export const convertDollarsToCents = (dollars: number) => {
  return Number((dollars * 100).toFixed())
}

export const convertUserToCardholder = (user: User): PayCardholder => {
  return {
    id: user.id,
    user_id: user.id,
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    profile_image: user.profile_image,
    department_id: user.department?.id,
    external_id: undefined,
  }
}

export const convertCardCardholderToCardholder = (
  card: PayCard | SpendingCardCard,
  cardholder: PayCard['cardholder'] | SpendingCardCard['cardholder']
): PayCardholder => {
  if ((card as PayCard)?.cardholder.user_id) {
    return {
      ...cardholder,
      user_id: (card as PayCard).cardholder.user_id,
    }
  }
  return {
    ...cardholder,
    user_id: (card as SpendingCardCard)?.cardholder.user,
  }
}

export const getRequestableUser = (card: PayCard | SpendingCardCard) => {
  if ((card as PayCard)?.cardholder.user_id) {
    return (card as PayCard).cardholder.user_id
  } else {
    return (card as SpendingCardCard)?.cardholder.user
  }
}

export const getApprovalStatus = (request: PayRequest): ApprovalStatus => {
  switch (request.status) {
    case PayRequestStatusEnum.APPROVED:
      return 'approved'
    case PayRequestStatusEnum.DENIED:
      return 'denied'
    case PayRequestStatusEnum.PENDING:
      return 'pending'
  }
}
