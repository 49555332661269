import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl, useFeatureFlag } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import messages from './messages'

const BudgetCategoryChunk = React.lazy(() =>
  import('../containers/BudgetCategoryView').then(({ BudgetCategoryView }) => ({
    default: BudgetCategoryView,
  }))
)

export const BudgetCategoryPage = () => {
  const intl = useProcurifyIntl()
  const FT_5817_ENABLE_BUDGETS_REDESIGN = useFeatureFlag(
    'FT_5817_ENABLE_BUDGETS_REDESIGN'
  )
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(
        FT_5817_ENABLE_BUDGETS_REDESIGN
          ? messages.manageBudgetsTitle
          : messages.manageBudgetCategoryTitle
      )}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.settingsPageTitle),
          link: url('SettingsPage'),
        },
        {
          name: intl.formatMessage(
            FT_5817_ENABLE_BUDGETS_REDESIGN
              ? messages.manageBudgetsTitle
              : messages.manageBudgetCategoriesPageTitle
          ),
        },
      ]}
    >
      <BudgetCategoryChunk />
    </Page>
  )
}
