import { type Channel } from 'pusher-js'
import type Pusher from 'pusher-js'

export type wsChannel = Channel

export class WSChannelManager {
  pusherInstance: Pusher

  constructor({ pusherInstance }: { pusherInstance: Pusher }) {
    this.pusherInstance = pusherInstance
  }

  subscribe = (channelName: string): wsChannel =>
    this.pusherInstance.subscribe(channelName)

  unsubscribe = (channelName: string): void =>
    this.pusherInstance.unsubscribe(channelName)

  channel = (channelName: string): wsChannel =>
    this.pusherInstance.channel(channelName)

  disconnect = () => this.pusherInstance.disconnect()
}
