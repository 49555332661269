import styled from 'styled-components'

import leftPattern from '../assets/bg-left-pattern.svg'
import rightPattern from '../assets/bg-right-pattern.svg'

export const PunchOutWelcomeBackgroundStyled = styled.div`
  background:
    url(${leftPattern}) no-repeat
      ${(props) => props.theme.typography.pxToRem(-120)}
      calc(100% + ${(props) => props.theme.typography.pxToRem(20)}),
    url(${rightPattern}) no-repeat
      calc(100% + ${(props) => props.theme.typography.pxToRem(10)})
      ${(props) => props.theme.typography.pxToRem(-80)};
`
