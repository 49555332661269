import * as React from 'react'

import { InputGroupLabel } from './InputGroupLabel'
import { InputGroupAttachmentStyled } from './styles/InputGroupAttachmentStyled'
import { InputGroupStyled } from './styles/InputGroupStyled'

export interface IInputGroupProps {}

export class InputGroup extends React.PureComponent<IInputGroupProps> {
  static displayName = 'InputGroup'
  static Item = InputGroupAttachmentStyled
  static Label = InputGroupLabel

  render() {
    return <InputGroupStyled {...this.props} />
  }
}
