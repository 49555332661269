import { CardContentStyled } from '@procurify/ui'
import styled from 'styled-components'

import { type IInboxListProps } from '../InboxList'

export const InboxListStyled = styled.div<Partial<IInboxListProps>>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  .comment-avatar {
    position: relative;

    .ping-icon {
      position: absolute;

      bottom: -25%;
      right: -25%;
    }
  }

  .logo-bullet {
    width: ${(props) => props.theme.typography.pxToRem(31)};
    height: ${(props) => props.theme.typography.pxToRem(31)};

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  ${CardContentStyled} {
    overflow-y: auto;
    padding: ${(props) => props.theme.space.medium};

    ${CardContentStyled} {
      padding: ${(props) => props.theme.space.small};
    }
  }
`
