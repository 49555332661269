import { mapApprover } from '@webapp/core/data-access/mappers/mapApprover'
import { type OrderApprovalLogApiModel } from '../api-models/OrderApprovalLogApiModel'

export const mapOrderApprovalLog = (log: OrderApprovalLogApiModel) => ({
  action: log.action,
  dateTime: log.dateTime,
  nextApprover: log.next_approver
    ? mapApprover({
        id: log.next_approver.id,
        firstName: log.next_approver.firstName,
        lastName: log.next_approver.lastName,
        email: log.next_approver.email,
        profile_image: log.next_approver.profile_image,
        approval_delegatee: log.approval_delegation
          ? log.approval_delegation
          : null,
      })
    : null,
  user: {
    id: log.user.id,
    firstName: log.user.firstName,
    lastName: log.user.lastName,
    profileImage: log.user.profile_image,
  },
})
