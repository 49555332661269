import { em } from 'polished'
import styled from 'styled-components'

interface IAppContentStyledProps {
  isSheetOpen?: boolean
}

export const AppContentStyled = styled.div<IAppContentStyledProps>`
  position: relative;
  grid-area: content;
  background-color: ${(props) => props.theme.colors.offwhite};
  padding: ${em(20)};
  padding-right: ${(props) => (props.isSheetOpen ? em(420) : em(20))};

  @media print {
    position: absolute;
    width: 100%;
  }
`
