import * as React from 'react'

import { type IBaseButtonProps } from './BaseButton'
import { IconButtonStyled } from './styles/IconButtonStyled'
import { Icon } from '../Icon'
import { CircleLoader } from '../Loaders'

export interface IIconButtonProps extends IBaseButtonProps {
  ['aria-label']: string
  htmlTitle?: string
}

export class IconButton extends React.PureComponent<IIconButtonProps> {
  public static Styled = IconButtonStyled
  render() {
    const {
      htmlTitle,
      onClick,
      isLoading,
      iconComponent,
      disabled,
      ...otherProps
    } = this.props
    return (
      <IconButtonStyled
        title={htmlTitle}
        onClick={!isLoading ? onClick : undefined}
        isLoading={isLoading}
        disabled={disabled || isLoading}
        {...otherProps}
      >
        {!this.props.isLoading ? (
          iconComponent ? (
            iconComponent
          ) : (
            <Icon icon={this.props.icon} />
          )
        ) : (
          <CircleLoader size={14} color='textPrimary' />
        )}
      </IconButtonStyled>
    )
  }
}
