import * as React from 'react'

import { Redirect, Switch } from 'react-router'

import { ProtectedRoute } from '@webapp/core/hoc/ProtectedRoute'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { ProcurementPage } from '@webapp/pages'

export const ProcurementRouter = () => {
  const { brp } = useAppRoutes()

  return (
    <Switch>
      <ProtectedRoute
        path={brp('ProcurementPage')}
        component={ProcurementPage}
      />

      <Redirect to='/' />
    </Switch>
  )
}
