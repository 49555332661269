import { type FormikErrors } from 'formik'

import { type IApiError } from '../services/api'

export const apiErrorsToFormikErrors = (
  error: IApiError<any>
): FormikErrors<any> => {
  if (!error.data) return {}

  const { errors } = error.data

  if (!errors) return {}
  return Object.entries(errors).reduce((errorObject, currentError) => {
    const [errorKey, errorDescription] = currentError
    return {
      ...errorObject,
      [errorKey]: errorDescription.message,
    }
  }, {})
}
