import { defineMessages } from 'react-intl'

const scope = `procurify.pages.SpendInsightsLinkOutPage`

export default defineMessages({
  linkOutFilterErrorTitle: {
    id: `${scope}.invalidLinkOutTitle`,
    defaultMessage: 'Invalid link out',
  },
  linkOutFilterErrorMessage: {
    id: `${scope}.invalidLinkOutTitleMessage`,
    defaultMessage:
      'Invalid link out. The chart you selected does not have an associated link out.',
  },
})
