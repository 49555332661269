import * as React from 'react'

import { Route, Switch } from 'react-router'

import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { InstanceManagementPage } from '@webapp/pages/InstanceManagementPage'

export const InstanceManagementRouter = () => {
  const { brp } = useAppRoutes()

  return (
    <Switch>
      <Route
        exact
        path={brp('InstanceManagementPage')}
        component={InstanceManagementPage}
      />
    </Switch>
  )
}
