import { defineMessages } from 'react-intl'

const scope = `procurify.components.TrialModeIndicator`

export default defineMessages({
  daysLeftInTrial: {
    id: `${scope}.daysLeftInTrial`,
    defaultMessage: `{daysLeftInTrial, plural,
      =0 {days}
      one {day}
      other {days}} left`,
  },
  trialMode: {
    id: `${scope}.trialMode`,
    defaultMessage: `trial`,
  },
  lockMode: {
    id: `${scope}.lockMode`,
    defaultMessage: `expired`,
  },
})
