import * as React from 'react'

import { List } from '@material-ui/core'
import { logoutUrl } from '@procurify/authentication/src/configs'
import { Icon, MenuListItem, WithBadge, WithPopover } from '@procurify/ui'

import { ConnectedLink } from '@webapp/components/Link'
import { UserAvatar } from '@webapp/components/UserAvatar/UserAvatar'
import { useProcurifyIntl } from '@webapp/hooks'
import { getFullName } from '@webapp/utils/user'
import { type User } from '../../../models'
import messages from '../messages'
import { AppHeaderButtonStyled } from '../styles/AppHeaderButtonStyled'
import { AppHeaderUserStyled } from '../styles/AppHeaderUserStyled'

export interface IAppHeaderUserProps {
  user: User
}

export const AppHeaderUser = ({ user }: IAppHeaderUserProps) => {
  const intl = useProcurifyIntl()

  return (
    <WithPopover
      trigger='click'
      content={() => (
        <List
          style={{
            maxHeight: '200px',
            overflowX: 'hidden',
            overflowY: 'auto',
            paddingTop: '0.75rem',
            paddingBottom: '0.75rem',
          }}
        >
          <ConnectedLink
            to='/profile'
            title={intl.formatMessage(messages.accountBtnText)}
          >
            <MenuListItem
              text={
                user
                  ? getFullName(user)
                  : intl.formatMessage(messages.accountBtnText)
              }
            />
          </ConnectedLink>

          <a
            href={logoutUrl}
            title={intl.formatMessage(messages.logoutBtnText)}
          >
            <MenuListItem text={intl.formatMessage(messages.logoutBtnText)} />
          </a>
        </List>
      )}
      placement='bottom'
    >
      <AppHeaderButtonStyled
        aria-label={intl.formatMessage(messages.accountBtnText)}
      >
        <WithBadge
          text={<Icon icon='timer' />}
          color='secondary'
          top='-11px'
          right='-8px'
          show={!!user?.approval_delegatee}
          aria-label={intl.formatMessage(messages.approvalDelegatedLabel)}
        >
          <AppHeaderUserStyled>
            <UserAvatar
              url={user?.profile_image}
              size='xsmall'
              circular
              shadow={false}
            />
          </AppHeaderUserStyled>
        </WithBadge>
      </AppHeaderButtonStyled>
    </WithPopover>
  )
}

AppHeaderUser.displayName = 'AppHeaderUser'
