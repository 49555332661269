import * as React from 'react'

import { ListItemText } from '@material-ui/core'
import { type ListItemTextProps } from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import { theme } from '@procurify/ui-theme'

const styles = {
  root: {
    display: 'inline-flex',
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    margin: 0,
    fontFamily: theme.typography.fontFamily,
    justifyContent: (props) => {
      return props.centerText ? 'center' : 'start'
    },
  },
  primary: {
    color: theme.colors.darkGrey,
    fontSize: theme.fontSizes.body2,
    fontFamily: theme.typography.fontFamily,
  },
}

class ListItemTextStyledComponent extends React.Component<
  ListItemTextProps & { classes: any; centerText?: boolean }
> {
  render() {
    const { classes } = this.props

    return (
      <ListItemText
        primary={this.props.primary}
        classes={{ root: classes.root, primary: classes.primary }}
      />
    )
  }
}

export const ListItemTextStyled = withStyles(styles)(
  ListItemTextStyledComponent
)
