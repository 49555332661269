import * as React from 'react'

import { Redirect, Route, Switch } from 'react-router'

import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { UserAccountPage } from '@webapp/pages'
import { UserProfilePageTabs } from '@webapp/pages/UserAccountPage'

export const UserProfileRouter = () => {
  const { url, brp } = useAppRoutes()

  return (
    <Switch>
      <Route exact path='/profile' component={UserAccountPage} />

      <Route
        path={brp('UserProfilePage', {
          tab: `:tab(${UserProfilePageTabs.PASSWORD}|${UserProfilePageTabs.NOTIFICATIONS}|${UserProfilePageTabs.APPROVER_DELEGATION})`,
        })}
        component={UserAccountPage}
      />

      <Redirect to={url('UserProfilePage', { tab: '' })} />
    </Switch>
  )
}
