import { defineMessages } from 'react-intl'

const scope = `procurify.components.Navigation`

export default defineMessages({
  betaTag: {
    id: `${scope}.betaTag`,
    defaultMessage: 'Beta',
  },
  dashboardLink: {
    id: `${scope}.dashboardLink`,
    defaultMessage: 'Dashboard',
  },
  requestLink: {
    id: `${scope}.requestLink`,
    defaultMessage: 'Request',
  },
  requestOrderLink: {
    id: `${scope}.requestOrderLink`,
    defaultMessage: 'Order',
  },
  requestTravelLink: {
    id: `${scope}.requestTravelLink`,
    defaultMessage: 'Travel',
  },
  requestExpenseLink: {
    id: `${scope}.requestExpenseLink`,
    defaultMessage: 'Expense',
  },
  requestPurchaseAgreementLink: {
    id: `${scope}.requestPurchaseAgreementLink`,
    defaultMessage: 'Purchase Agreement',
  },
  requestProcurifyPayLink: {
    id: `${scope}.requestProcurifyPayLink`,
    defaultMessage: 'Fund',
  },
  approvalLink: {
    id: `${scope}.approvalLink`,
    defaultMessage: 'Approve',
  },
  approvalOrderLink: {
    id: `${scope}.approvalOrderLink`,
    defaultMessage: 'Order',
  },
  approvalTravelLink: {
    id: `${scope}.approvalTravelLink`,
    defaultMessage: 'Travel',
  },
  approvalExpenseLink: {
    id: `${scope}.approvalExpenseLink`,
    defaultMessage: 'Expense',
  },
  approvalBillLink: {
    id: `${scope}.approvalBillLink`,
    defaultMessage: 'Bill',
  },
  approvalPaymentLink: {
    id: `${scope}.approvalPaymentLink`,
    defaultMessage: 'Payment',
  },
  approvalPurchaseAgreementLink: {
    id: `${scope}.approvalPurchaseAgreementLink`,
    defaultMessage: 'Purchase Agreement',
  },
  approvalProcurifyPayLink: {
    id: `${scope}.approvalProcurifyPayLink`,
    defaultMessage: 'Fund',
  },
  approvalSpendingCardLink: {
    id: `${scope}.approvalSpendingCardLink`,
    defaultMessage: 'Spending Card',
  },
  procureLink: {
    id: `${scope}.procureLink`,
    defaultMessage: 'Procure',
  },
  procureProcurementLink: {
    id: `${scope}.procureProcurementLink`,
    defaultMessage: 'Procurement',
  },
  procurePurchaseOrdersLink: {
    id: `${scope}.procurePurchaseOrdersLink`,
    defaultMessage: 'Purchase Orders',
  },
  procurePurchaseAgreementLink: {
    id: `${scope}.procurePurchaseAgreementLink`,
    defaultMessage: 'Purchase Agreements',
  },
  procureVendorsLink: {
    id: `${scope}.procureVendorsLink`,
    defaultMessage: 'Vendors',
  },
  procureContractsLink: {
    id: `${scope}.procureContractsLink`,
    defaultMessage: 'Contracts',
  },
  procureProductCatalogLink: {
    id: `${scope}.procureProductCatalogLink`,
    defaultMessage: 'Product Catalog',
  },
  receiveLink: {
    id: `${scope}.receiveLink`,
    defaultMessage: 'Receive',
  },
  spendingCardLink: {
    id: `${scope}.payLink`,
    defaultMessage: 'Spending Card',
  },
  payCardManagementLink: {
    id: `${scope}.payCardManagementLink`,
    defaultMessage: 'Manage Cards',
  },
  payReconcileTransactionsLink: {
    id: `${scope}.payReconcileTransactionsLink`,
    defaultMessage: 'Reconciliation',
  },
  payCardLink: {
    id: `${scope}.payCardLink`,
    defaultMessage: 'My Card',
  },
  spendingCardVirtualCardsLink: {
    id: `${scope}.spendingCardVirtualCardsLink`,
    defaultMessage: 'Virtual Cards',
  },
  spendingCardPhysicalCardLink: {
    id: `${scope}.spendingCardPhysicalCardLink`,
    defaultMessage: 'Physical Card',
  },
  spendingCardTransactionsLink: {
    id: `${scope}.spendingCardTransactionsLink`,
    defaultMessage: 'My Transactions',
  },
  inventoryLink: {
    id: `${scope}.inventoryLink`,
    defaultMessage: 'Inventory',
  },
  paymentLink: {
    id: `${scope}.paymentLink`,
    defaultMessage: 'Payment',
  },
  paymentPaymentRecordsLink: {
    id: `${scope}.paymentPaymentRecordsLink`,
    defaultMessage: 'Payment Records',
  },
  paymentExpenseRecordsLink: {
    id: `${scope}.paymentExpenseRecordsLink`,
    defaultMessage: 'Expense Records',
  },
  paymentReceivingSummaryLink: {
    id: `${scope}.paymentReceivingSummaryLink`,
    defaultMessage: 'Receiving Summary',
  },
  apLink: {
    id: `${scope}.apLink`,
    defaultMessage: 'Accounts Payable',
  },
  apBillsLink: {
    id: `${scope}.apBillsLink`,
    defaultMessage: 'Bills',
  },
  apCreateBillLink: {
    id: `${scope}.apCreateBillLink`,
    defaultMessage: 'Create Bill',
  },
  apManageBillsLink: {
    id: `${scope}.apManageBillsLink`,
    defaultMessage: 'Manage Bills',
  },
  apUnbilledOrderItemsLink: {
    id: `${scope}.apUnbilledOrderItemsLink`,
    defaultMessage: 'Unbilled Items',
  },
  apUnbilledExpenseItemsLink: {
    id: `${scope}.apUnbilledExpenseItemsLink`,
    defaultMessage: 'Unbilled Expenses',
  },
  apCreditCardsLink: {
    id: `${scope}.apCreditCardsLink`,
    defaultMessage: 'Credit Cards',
  },
  apReconciliationLink: {
    id: `${scope}.apReconciliationLink`,
    defaultMessage: 'Reconciliation',
  },
  apPaymentLink: {
    id: `${scope}.apPaymentLink`,
    defaultMessage: 'Payment',
  },
  apPaymentsLink: {
    id: `${scope}.apPaymentsLink`,
    defaultMessage: 'Payments',
  },
  apPaymentTrackingLink: {
    id: `${scope}.apPaymentTrackingLink`,
    defaultMessage: 'Payment Tracking',
  },
  apPayeeManagementLink: {
    id: `${scope}.apPayeeManagementLink`,
    defaultMessage: 'Manage Payees',
  },
  apPaymentManagementLink: {
    id: `${scope}.apPaymentManagementLink`,
    defaultMessage: 'Manage Payments',
  },
  apPayBillsLink: {
    id: `${scope}.apPayBillsLink`,
    defaultMessage: 'Pay Bills',
  },
  apFinancialAccountLink: {
    id: `${scope}.apFinancialAccountLink`,
    defaultMessage: 'Financial Account',
  },
  apPaymentHistoryLink: {
    id: `${scope}.apPaymentHistoryLink`,
    defaultMessage: 'Payment History',
  },
  apSpendReportsLink: {
    id: `${scope}.apSpendReportsLink`,
    defaultMessage: 'Spend Reports',
  },
  apAccrualsLink: {
    id: `${scope}.apAccrualsLink`,
    defaultMessage: 'Accruals',
  },
  apOverallSpendLink: {
    id: `${scope}.apOverallSpendLink`,
    defaultMessage: 'Overall Spend',
  },
  apExpensesLink: {
    id: `${scope}.apExpensesLink`,
    defaultMessage: 'Expenses',
  },
  apReceivingLink: {
    id: `${scope}.apReceivingLink`,
    defaultMessage: 'Receiving',
  },
  reportsLink: {
    id: `${scope}.reportsLink`,
    defaultMessage: 'Reports',
  },
  reportsOrdersByVendorLink: {
    id: `${scope}.reportsOrdersByVendorLink`,
    defaultMessage: 'Orders by Vendor',
  },
  reportsOrdersByUserLink: {
    id: `${scope}.reportsOrdersByUserLink`,
    defaultMessage: 'Orders by User',
  },
  reportsOrdersBySecondaryOrgCategoryLink: {
    id: `${scope}.reportsOrdersBySecondaryOrgCategoryLink`,
    defaultMessage: 'Orders by {singularSecondaryOrgCategoryLabel}',
  },
  reportsExpensesBySecondaryOrgCategoryLink: {
    id: `${scope}.reportsExpensesByDepartmentLink`,
    defaultMessage: 'Expenses by {singularSecondaryOrgCategoryLabel}',
  },
  settingsLink: {
    id: `${scope}.settingsLink`,
    defaultMessage: 'Settings',
  },
  purchaseSectionHeader: {
    id: `${scope}.purchaseSectionHeader`,
    defaultMessage: 'Purchase',
  },
  spendSectionHeader: {
    id: `${scope}.spendSectionHeader`,
    defaultMessage: 'Spend',
  },
  manageSectionLabel: {
    id: `${scope}.manageSectionLabel`,
    defaultMessage: 'Manage',
  },
  unreadCount: {
    id: `${scope}.unreadCount`,
    defaultMessage: '{count} new items',
  },
  dataAndInsightsLink: {
    id: `${scope}.dataAndInsightsLink`,
    defaultMessage: 'Data and Insights',
  },
  spendInsightsLink: {
    id: `${scope}.spendInsightsLink`,
    defaultMessage: 'Spend Insights',
  },
  returnOnSpend: {
    id: `${scope}.returnOnSpend`,
    defaultMessage: 'Return on Spend',
  },
  approvalVendorLink: {
    id: `${scope}.approvalVendorLink`,
    defaultMessage: 'Vendor',
  },
  whatsNewHeader: {
    id: `${scope}.whatsNewHeader`,
    defaultMessage: "What's New",
  },
  billPaymentsLink: {
    id: `${scope}.billPaymentsLink`,
    defaultMessage: 'Bill Payments',
  },
  newTag: {
    id: `${scope}.newTag`,
    defaultMessage: 'New',
  },
})
