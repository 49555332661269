import { rgba } from 'polished'
import styled from 'styled-components'

import { type User } from '../../../models'

interface IWithUserTooltipProfileImageStyledProps {
  profileImage: User['profile_image']
}

export const WithUserTooltipProfileImageStyled = styled.div<IWithUserTooltipProfileImageStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  ${(props) => {
    if (props.profileImage) {
      return `
          width: ${props.theme.typography.pxToRem(240)};
          height: ${props.theme.typography.pxToRem(100)};
          background-position: center center;
          background-size: cover;
          background-image: radial-gradient(transparent, ${rgba(
            props.theme.colors.darkGrey,
            0.5
          )}), url(${props.profileImage});
        `
    }
  }}
`
