import { userSelectNone } from '@procurify/ui'
import { em, rem } from 'polished'
import styled from 'styled-components'

interface IApproverOverwatchSubscriberListStyledProps {}

export const ApproverOverwatchSubscriberListStyled = styled.div<IApproverOverwatchSubscriberListStyledProps>`
  font-size: ${rem(16)};

  table tbody tr {
    ${userSelectNone};
    cursor: pointer;
  }
`
