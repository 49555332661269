import * as React from 'react'

import {
  Flex,
  IconNotification,
  WithBadge,
  WithPopover,
  WithTooltip,
} from '@procurify/ui'

import { InboxAPI } from '@webapp/api/InboxAPI'
import { InboxList } from '@webapp/components/InboxList'
import { useProcurifyIntl } from '@webapp/hooks'
import { type Inbox } from '@webapp/models'
import messages from '../messages'
import { AppHeaderButtonStyled } from '../styles/AppHeaderButtonStyled'

export interface IAppHeaderInboxProps {
  unreadCount?: number
  onFetch?: (res: any) => void
}

export const AppHeaderInbox = ({
  unreadCount,
  onFetch,
}: IAppHeaderInboxProps) => {
  const intl = useProcurifyIntl()
  const [notifications, setNotifications] = React.useState<Inbox[]>([])

  let fetchCancel

  const fetchInboxList = () => {
    const [request, cancel] = InboxAPI.getUnreadMessages()

    if (fetchCancel) {
      fetchCancel()
    }

    fetchCancel = cancel

    request
      .then((res) => {
        setNotifications(res.data)

        if (onFetch) {
          onFetch(res)
        }
      })
      .catch(() => undefined)
  }

  React.useEffect(() => {
    fetchInboxList()

    return () => {
      if (fetchCancel) fetchCancel()
    }
  }, [])

  const markAllAsRead = () => {
    InboxAPI.markAllAsRead()
    setNotifications([])
  }

  const onRead = (notification: Inbox) => {
    setNotifications(
      notifications.splice(notifications.indexOf(notification), 1)
    )

    InboxAPI.markAsRead(notification.id).then(() => {
      fetchInboxList()
    })
  }

  return (
    <WithPopover
      trigger='click'
      content={() => (
        <Flex.Row spacing={0}>
          <Flex.Item width={400}>
            <InboxList
              notifications={notifications}
              onMarkAllAsRead={markAllAsRead}
              onRead={onRead}
            />
          </Flex.Item>
        </Flex.Row>
      )}
    >
      <WithTooltip title={intl.formatMessage(messages.notifications)}>
        <AppHeaderButtonStyled
          aria-label={intl.formatMessage(messages.notifications)}
        >
          <WithBadge
            text={unreadCount}
            color='danger'
            top='-12px'
            right='-6px'
            show={!!unreadCount}
          >
            <IconNotification size={27} color='textPrimary' />
          </WithBadge>
        </AppHeaderButtonStyled>
      </WithTooltip>
    </WithPopover>
  )
}

AppHeaderInbox.displayName = 'AppHeaderInbox'
