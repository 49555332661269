import * as React from 'react'

import { DotLoader, Header, Space, Spacer } from '@procurify/ui'
import { useHistory } from 'react-router'

import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const REDIRECT_DELAY = 2000

export interface IRedirectViewProps {}

/**
 * This View simply redirect the user to the url defined
 * in the `?url=` param with the possibility of a custom message
 * with the `?msg=` param.
 *
 * Use the hook `const { redirect } = useDelayRedirect()`.
 */
export const RedirectView = (props: IRedirectViewProps) => {
  const intl = useProcurifyIntl()
  const history = useHistory()

  const urlParams = new URLSearchParams(history.location.search)

  const msg =
    urlParams.get('msg') ||
    intl.formatMessage(messages.defaultRedirectingMessage)
  const url = urlParams.get('url') || '/'

  let redirectTimer
  React.useEffect(() => {
    redirectTimer = setTimeout(() => history.replace(url), REDIRECT_DELAY)

    return () => {
      clearTimeout(redirectTimer)
    }
  }, [])

  return (
    <div>
      <Spacer space={3} />
      <Space direction='vertical' align='center' justify='center'>
        <DotLoader />

        <Header textAlign='center' as='h5'>
          {msg}
        </Header>
      </Space>
    </div>
  )
}

RedirectView.displayName = 'RedirectView'
