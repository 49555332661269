import { useMemo } from 'react'

import { type Crumb } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'
import {
  OrderApprovalPageStatuses,
  OrderDetailsPageReferrers,
  OrderStatusPageStatuses,
  PurchaseOrderListPageStatuses,
  ReceiveListPageStatuses,
} from '../../../pages/types'
import { useGetOrderPermissions } from '../models/useGetOrderPermissions'
import { useGetReferrer } from '../models/useGetReferrer'

export const useGetBreadcrumbsPresenter = ({
  orderId,
}: {
  orderId: string
}) => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  const { referrerSearchParam, referrerUrl } = useGetReferrer()
  const permissions = useGetOrderPermissions({ orderId })
  const canUserApproveOrder = permissions?.canApprove

  const supportedReferrers = useMemo(
    () => [
      {
        name: intl.formatMessage(messages.orderApprovalBreadcrumb),
        searchParamValue: OrderDetailsPageReferrers.ORDER_APPROVAL,
        url: url('OrderApprovalPage', {
          status: OrderApprovalPageStatuses.PENDING,
        }),
      },
      // NOTE: once Order Approvals is migrated to React, we can switch to the following rather than check the search param (FT-6884)
      // {
      //   name: intl.formatMessage(messages.orderApprovalBreadcrumb),
      //   tester: `^/orders/requests/(${Object.values(OrderApprovalPageStatuses).join('|')})(\\?.*)?$`,
      //   url: referrerUrl,
      // },
      {
        name: intl.formatMessage(messages.orderStatusBreadcrumb),
        tester: `^/orders/requests/(${Object.values(OrderStatusPageStatuses).join('|')})(\\?.*)?$`,
        url: referrerUrl,
      },
      {
        name: intl.formatMessage(messages.createPurchaseOrderBreadcrumb),
        tester: `^/procurement(\\?.*)?$`,
        url: referrerUrl,
      },
      {
        name: intl.formatMessage(messages.purchaseOrderListBreadcrumb),
        tester: `^/purchase-orders/(${Object.values(PurchaseOrderListPageStatuses).join('|')})(\\?.*)?$`,
        url: referrerUrl,
      },
      {
        name: intl.formatMessage(messages.purchaseOrderDetailsBreadcrumb),
        tester: `^/purchase-orders/([a-f0-9]{32})(\\?.*)?$`,
        url: referrerUrl,
      },
      {
        name: intl.formatMessage(messages.receiveListBreadcrumb),
        escapeCaseFormatting: true,
        tester: `^/receive/(${Object.values(ReceiveListPageStatuses).join('|')})(\\?.*)?$`,
        url: referrerUrl,
      },
      {
        name: intl.formatMessage(messages.receiveDetailsBreadcrumb),
        escapeCaseFormatting: true,
        tester: `^/receive/([a-f0-9]{32})(\\?.*)?$`,
        url: referrerUrl,
      },
    ],
    [intl, url, referrerUrl]
  )

  const referrerBreadcrumb: Crumb | null = useMemo(() => {
    let crumb = null

    // Check supported referrers for a match
    supportedReferrers.some((supportedReferrer) => {
      let isMatch = false

      if (supportedReferrer.searchParamValue) {
        isMatch = supportedReferrer.searchParamValue === referrerSearchParam
      } else if (referrerUrl !== undefined) {
        isMatch = new RegExp(supportedReferrer.tester).test(referrerUrl)
      }

      if (isMatch) {
        crumb = {
          name: supportedReferrer.name,
          link: supportedReferrer.url,
          ...('escapeCaseFormatting' in supportedReferrer
            ? { escapeCaseFormatting: supportedReferrer.escapeCaseFormatting }
            : {}),
        }
      }

      return isMatch
    })

    // If no match found, and we have enough info to determine a default value, set it
    if (crumb === null && canUserApproveOrder === true) {
      crumb = {
        name: intl.formatMessage(messages.orderApprovalBreadcrumb),
        link: url('OrderApprovalPage', {
          status: OrderApprovalPageStatuses.PENDING,
        }),
      }
    } else if (crumb === null && canUserApproveOrder === false) {
      crumb = {
        name: intl.formatMessage(messages.orderStatusBreadcrumb),
        link: url('OrderStatusPage', {
          status: OrderStatusPageStatuses.PENDING,
        }),
      }
    }

    return crumb
  }, [supportedReferrers, canUserApproveOrder, intl, url])

  const breadcrumbs = useMemo(() => {
    const list = [
      {
        name: intl.formatMessage(messages.orderDetailsBreadcrumb),
      },
    ]

    if (referrerBreadcrumb) {
      list.unshift(referrerBreadcrumb)
    }

    return list
  }, [referrerBreadcrumb])

  return breadcrumbs
}
