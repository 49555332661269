import * as React from 'react'

import { type TableProps } from '@material-ui/core/Table'

import { TableContainer } from './components/TableContainer'
import { TableStyled } from './styles/TableStyled'
import { TableBody } from './TableBody'
import { TableCell } from './TableCell'
import { TableHead } from './TableHead'
import { TableRow } from './TableRow'

/**
 * tableBodyCellPadding is a temporary prop used to modify the padding of the td elements rendered within a row within tbody
 * this will be removed when we create a standard variant with the Design team
 */
export interface ITableProps extends TableProps {
  zebraTableBodyAsRow?: boolean
  horizontalScroll?: boolean
  width?: React.CSSProperties['width']
  tableBodyCellPadding?: number
}

export const Table = ({
  children,
  zebraTableBodyAsRow = false,
  horizontalScroll,
  tableBodyCellPadding,
  ...otherProps
}: ITableProps) => {
  return (
    <TableContainer horizontalScroll={horizontalScroll}>
      <TableStyled
        zebraTableBodyAsRow={zebraTableBodyAsRow}
        tableBodyCellPadding={tableBodyCellPadding}
        {...otherProps}
      >
        {children}
      </TableStyled>
    </TableContainer>
  )
}

Table.displayName = 'Table'

Table.Head = TableHead
Table.Body = TableBody
Table.Row = TableRow
Table.Cell = TableCell
