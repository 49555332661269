import { defineMessages } from 'react-intl'

const scope = `procurify.components.WithUserTooltip`

export default defineMessages({
  approvalsDelegatedLabel: {
    id: `${scope}.approvalsDelegatedLabel`,
    defaultMessage: 'Approvals delegated',
  },
  delegatedTo: {
    id: `${scope}.delegatedTo`,
    defaultMessage: 'Delegated to <strong>{name}</strong>',
  },
  expectedToReturn: {
    id: `${scope}.expectedToReturn`,
    defaultMessage: 'Expected to return: {date}',
  },
})
