import * as React from 'react'

import styled from 'styled-components'

import { BaseAlert, type IBaseAlertProps } from './BaseAlert'
import { IconAttention } from '@ui/elements/Icon/components/IconAttention'
import { IconComponentStyled } from '@ui/elements/Icon/styles/IconComponentStyled'

export const WarningAlert = styled(
  (props: React.PropsWithChildren<IBaseAlertProps>) => (
    <BaseAlert icon={<IconAttention block size={20} />} {...props} />
  )
)`
  background-color: ${(props) => props.theme.colors.alert.warning.background};

  border-color: ${(props) => props.theme.colors.alert.warning.border};

  ${IconComponentStyled} {
    color: ${(props) => props.theme.colors.alert.warning.border};
  }
`

WarningAlert.displayName = 'WarningAlert'
