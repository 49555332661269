import styled from 'styled-components'

import { type ILabelProps } from '@ui/elements/Label'
import { userSelectNone } from '@ui/utils/userSelectNone'

export const LabelStyled = styled.div<ILabelProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(12)};
  font-weight: 600;
  line-height: 1;
  min-height: ${(props) => props.theme.typography.pxToRem(30)};

  position: relative;
  display: inline-flex;

  ${(props) => {
    if (!props.fluid) {
      return `max-width: ${props.theme.typography.pxToRem(180)};`
    }
  }}

  border-radius: ${(props) => props.theme.typography.pxToRem(4)};

  align-items: stretch;

  color: ${(props) => props.theme.colors.darkGreen};

  background: ${(props) => props.theme.colors.content.mainBackground};

  ${userSelectNone()};
`
