import { type PaginationConfigs } from './pagination.types'

export const CONFIGS_BY_ID: PaginationConfigs = {
  'expense-summary': {
    defaultPagination: {
      orderBy: '-id',
    },
    mode: 'url',
  },
}
