import { useLocation } from 'react-router'

import { OrderDetailsPageSearchParams } from '../../../pages/types'

const getPreviousPathFromState = (
  state: { from?: string } | null | undefined
) => {
  if (!state || typeof state !== 'object') {
    return undefined
  }

  return state?.from
}

export const useGetReferrer = () => {
  const { search, state } = useLocation()

  // NOTE: once Order Approvals is migrated to React, we can remove this and use the location state directly (FT-6884)
  const searchParams = new URLSearchParams(search)
  const referrerSearchParam = searchParams.get(
    OrderDetailsPageSearchParams.REFERRER
  )

  const referrerUrl = getPreviousPathFromState(state)

  return {
    referrerSearchParam,
    referrerUrl,
  }
}
