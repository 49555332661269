import { defineMessages } from 'react-intl'

const scope = `procurify.components.Page`

export default defineMessages({
  pageLoaderAriaLabel: {
    id: `${scope}.pageLoaderAriaLabel`,
    defaultMessage: 'Loading...',
  },
})
