import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

const IntegrationNetsuiteChunk = React.lazy(() =>
  import(
    /* webpackChunkName: 'IntegrationsNetsuiteView' */ '../containers/IntegrationNetsuiteView'
  ).then(({ IntegrationNetsuiteView }) => ({
    default: IntegrationNetsuiteView,
  }))
)

export class IntegrationNetsuitePage extends React.PureComponent<RouteComponentProps> {
  render() {
    return (
      <React.Suspense fallback={null}>
        <IntegrationNetsuiteChunk {...this.props} />
      </React.Suspense>
    )
  }
}
