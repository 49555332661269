import * as React from 'react'

import { type TableBodyProps } from '@material-ui/core/TableBody'

import { EmptyState } from './../../blocks/EmptyState'
import { TableBodyStyled } from './styles/TableBodyStyled'
import { TableRowStyled } from './styles/TableRowStyled'

export interface ITableBodyProps extends TableBodyProps {
  zebra?: boolean
  hoverable?: boolean
  zebraTableBody?: boolean
  zebraTableBodyAsRow?: boolean
  children?: JSX.Element[] | JSX.Element
}

export class TableBody extends React.Component<ITableBodyProps> {
  static defaultProps = {
    zebra: true,
    hoverable: true,
    zebraTableBody: false,
  }

  render() {
    const childrenWithInheritedProps = React.Children.map(
      this.props.children,
      (child: React.ReactElement<any>, index) => {
        if (!child) return null

        return React.cloneElement(child, {
          hoverable: this.props.hoverable,
          variant: 'body',
          index,
        })
      }
    )
    const { zebraTableBodyAsRow, ...otherProps } = this.props
    return (
      <TableBodyStyled {...otherProps}>
        {childrenWithInheritedProps ? (
          childrenWithInheritedProps
        ) : (
          <TableRowStyled hoverable={false}>
            <td colSpan={100}>
              <EmptyState />
            </td>
          </TableRowStyled>
        )}
      </TableBodyStyled>
    )
  }
}
