import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const ProcurementViewChunk = React.lazy(() =>
  import('../containers/ProcurementView').then(({ ProcurementView }) => ({
    default: ProcurementView,
  }))
)

export const ProcurementPage = (props: RouteComponentProps) => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.procurementPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.procurementPageTitle),
        },
      ]}
    >
      <ProcurementViewChunk {...props} />
    </Page>
  )
}
