import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const ProcurifyPaymentsViewChunk = React.lazy(() =>
  import('../containers/ProcurifyPaymentsView').then(
    ({ ProcurifyPaymentsView }) => ({
      default: ProcurifyPaymentsView,
    })
  )
)

export const ProcurifyPaymentsPage = () => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.procurifyPaymentsPageTitle)}
      breadcrumbs={[]}
      centered
    >
      <ProcurifyPaymentsViewChunk />
    </Page>
  )
}
