import * as React from 'react'

import { findDOMNode } from 'react-dom'

interface INodeResolverProps {
  innerRef: (
    instance: any
  ) => void | React.RefObject<HTMLElement | SVGElement | React.Component>
}

export default class NodeResolver extends React.Component<INodeResolverProps> {
  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    this.props.innerRef(findDOMNode(this))
  }
  componentWillUnmount() {
    this.props.innerRef(null)
  }
  render() {
    return this.props.children
  }
}

export class ScrollCapture extends React.PureComponent<any, any> {
  isBottom: boolean = false
  isTop: boolean = false
  scrollTarget: HTMLElement
  lastScrollTop: number = 0
  touchStart: number

  componentDidMount() {
    this.startListening(this.scrollTarget)
  }
  componentWillUnmount() {
    this.stopListening(this.scrollTarget)
  }
  startListening(el: HTMLElement) {
    // all the if statements are to appease Flow 😢
    if (typeof el.addEventListener === 'function') {
      el.addEventListener('scroll', this.onScroll, false)
      el.addEventListener('wheel', this.onWheel, false)
      el.addEventListener('touchstart', this.onTouchStart, false)
      el.addEventListener('touchmove', this.onTouchMove, false)
    }
  }
  stopListening(el: HTMLElement) {
    // all the if statements are to appease Flow 😢
    if (typeof el.removeEventListener === 'function') {
      el.removeEventListener('scroll', this.onScroll, false)
      el.removeEventListener('wheel', this.onWheel, false)
      el.removeEventListener('touchstart', this.onTouchStart, false)
      el.removeEventListener('touchmove', this.onTouchMove, false)
    }
  }

  handleEventDelta = (
    event: Event | React.UIEvent<HTMLElement>,
    delta: number
  ) => {
    const { onBottomArrive } = this.props

    const { scrollTop, scrollHeight, clientHeight } = this.scrollTarget
    const target = this.scrollTarget
    const isDeltaPositive = delta > 0
    const availableScroll = scrollHeight - clientHeight - scrollTop
    let shouldCancelScroll = false

    // reset bottom/top flags
    if (availableScroll > delta && this.isBottom) {
      this.isBottom = false
    }
    if (isDeltaPositive && this.isTop) {
      this.isTop = false
    }

    // bottom limit
    if (isDeltaPositive && delta > availableScroll) {
      if (onBottomArrive && !this.isBottom) {
        onBottomArrive(event)
      }
      target.scrollTop = scrollHeight
      shouldCancelScroll = true
      this.isBottom = true

      // top limit
    } else if (!isDeltaPositive && -delta > scrollTop) {
      target.scrollTop = 0
      shouldCancelScroll = true
      this.isTop = true
    }

    // cancel scroll
    if (shouldCancelScroll) {
      this.cancelScroll(event)
    }
  }

  onScroll = (event: React.UIEvent<HTMLElement> | Event) => {
    /**
     * An issue that was reported in the react-select repo fixed this but
     * it was never merged.
     *
     * https://github.com/JedWatson/react-select/issues/3232
     */
    const deltaY =
      (event as React.UIEvent<HTMLElement>).currentTarget.scrollTop -
      this.lastScrollTop
    this.lastScrollTop = (
      event as React.UIEvent<HTMLElement>
    ).currentTarget.scrollTop

    this.handleEventDelta(event, deltaY)
  }

  onWheel = (event: WheelEvent) => {
    this.handleEventDelta(event, event.deltaY)
  }
  onTouchStart = (event: TouchEvent) => {
    // set touch start so we can calculate touchmove delta
    this.touchStart = event.changedTouches[0].clientY
  }
  onTouchMove = (event: TouchEvent) => {
    const deltaY = this.touchStart - event.changedTouches[0].clientY
    this.handleEventDelta(event, deltaY)
  }

  getScrollTarget = (ref: HTMLElement) => {
    this.scrollTarget = ref
  }

  cancelScroll = (event: Event | React.UIEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  render() {
    return (
      <NodeResolver innerRef={this.getScrollTarget}>
        {this.props.children}
      </NodeResolver>
    )
  }
}
