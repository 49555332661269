import * as React from 'react'

import { Flex, Header } from '@procurify/ui'

import { type WatchlistUser } from '@webapp/models'
import { ApproverOverwatchUserTable } from './components/ApproverOverwatchUserTable'
import { type ApproverOverwatchUserTableData } from './components/ApproverOverwatchUserTable/ApproverOverwatchUserTable'
import { ApproverOverwatchUserWatchListTable } from './components/ApproverOverwatchUserWatchListTable'
import { ApproverOverwatchStyled } from './styles/ApproverOverwatchStyled'

export interface IApproverOverwatchProps {
  approvers?: ApproverOverwatchUserTableData[]
  selectedApprover?: WatchlistUser
  selectedApproverWatchlist?: WatchlistUser[]
  onApproverSearch?: (searchTerm: string) => void
  onApproverSelect?: (approver: WatchlistUser) => void
  onApproverUnsubscribe?: (approver: WatchlistUser) => void
  onOpenManageList?: () => void
  isApproverListFetching?: boolean
  isApproverWatchlistFetching?: boolean
  unsubscribingApproverID?: number
}

export const ApproverOverwatch: React.FC<IApproverOverwatchProps> = React.memo(
  ({
    approvers = [],
    selectedApprover,
    selectedApproverWatchlist = [],
    onApproverSearch = () => undefined,
    onApproverSelect = () => undefined,
    onApproverUnsubscribe = () => undefined,
    onOpenManageList = () => undefined,
    isApproverListFetching,
    isApproverWatchlistFetching,
    unsubscribingApproverID,
  }) => {
    return (
      <ApproverOverwatchStyled>
        <Flex.Row flexWrap='nowrap' height='100%'>
          <Flex.Item width={0.4} minWidth={400}>
            <ApproverOverwatchUserTable
              approvers={approvers}
              selectedApproverID={selectedApprover ? selectedApprover.id : null}
              onSearch={onApproverSearch}
              onSelect={onApproverSelect}
              isFetching={isApproverListFetching}
            />
          </Flex.Item>
          <Flex.Item width={0.6}>
            <ApproverOverwatchUserWatchListTable
              approver={selectedApprover}
              watchlist={selectedApproverWatchlist}
              onUnsubscribe={onApproverUnsubscribe}
              onOpenManageList={onOpenManageList}
              isFetching={isApproverWatchlistFetching}
              unsubscribingApproverID={unsubscribingApproverID}
            />
          </Flex.Item>
        </Flex.Row>
      </ApproverOverwatchStyled>
    )
  }
)

ApproverOverwatch.defaultProps = {
  approvers: [],
  selectedApproverWatchlist: [],
  onApproverSearch: () => undefined,
  onApproverSelect: () => undefined,
  onApproverUnsubscribe: () => undefined,
  onOpenManageList: () => undefined,
}
ApproverOverwatch.displayName = 'ApproverOverwatch'
