import * as React from 'react'

import { Helmet } from 'react-helmet'

import { useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

export const SPENDING_CARD_SETUP_PAGE = '/spending-card/setup'

const SpendingCardSetupChunk = React.lazy(() =>
  import('../containers/SpendingCardSetupView').then(
    ({ SpendingCardSetupView: SpendingCardSetup }) => ({
      default: SpendingCardSetup,
    })
  )
)

export const SpendingCardSetupPage = () => {
  const intl = useProcurifyIntl()

  return (
    <React.Suspense fallback={null}>
      <Helmet>
        <title>
          Procurify | {intl.formatMessage(messages.spendingCardSetupTitle)}
        </title>
      </Helmet>
      <SpendingCardSetupChunk />
    </React.Suspense>
  )
}
