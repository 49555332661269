import { type DepartmentApiModel } from '@webapp/core/data-access/api-models/DepartmentApiModel'
import { mapCurrency } from '@webapp/core/data-access/mappers/mapCurrency'
import { mapDepartment } from '@webapp/core/data-access/mappers/mapDepartment'
import { type AccountCodeTypeEnum } from '@webapp/platform/finance'
import { type PunchOutStatusEnum } from '../../entities/enums/PunchOutStatusEnum'
import { type OrderItemConstructorData } from '../../entities/OrderItem'
import { type OrderItemApiModel } from '../api-models/OrderItemApiModel'
import { type OrderItemCustomFieldValueApiModel } from '../api-models/OrderItemCustomFieldApiModel'

const mapAccount = (accountData: {
  account_code: {
    account_type: AccountCodeTypeEnum
    active: boolean
    code: string
    description: string
    id: number
    parent: Nullable<number>
  }
  active: boolean
  department: DepartmentApiModel
  id: number
}) => {
  return {
    accountCode: {
      id: accountData.account_code.id,
      active: accountData.account_code.active,
      code: accountData.account_code.code,
      description: accountData.account_code.description,
      type: accountData.account_code.account_type,
    },
    id: accountData.id,
    active: accountData.active,
    secondaryCategory: mapDepartment(accountData.department),
  }
}

const mapOrderItemCustomField = ({
  field,
  id,
  value,
}: OrderItemCustomFieldValueApiModel) => {
  return {
    field: {
      id: field.id,
      name: field.name,
      type: field.field_type,
      defaultValue: field.default_value,
      choices: field.field_choices,
      active: field.is_active,
      required: field.is_required,
    },
    id,
    value,
  }
}

const mapPunchOutSupplier = (punchoutSupplierData: {
  punchout_supplier_icon: Nullable<string>
  punchout_supplier_name: Nullable<string>
  punchout_vendor_reference: Nullable<number>
}) => {
  if (
    !punchoutSupplierData.punchout_supplier_icon ||
    !punchoutSupplierData.punchout_supplier_name ||
    punchoutSupplierData.punchout_vendor_reference === null
  )
    return null

  return {
    id: punchoutSupplierData.punchout_vendor_reference,
    icon: punchoutSupplierData.punchout_supplier_icon,
    name: punchoutSupplierData.punchout_supplier_name,
  }
}

const mapPunchOutConfirmationStatus = (punchoutConfirmationStatusData: {
  confirmation_status: Nullable<PunchOutStatusEnum>
  confirmation_status_description: Nullable<string>
}) => {
  if (
    punchoutConfirmationStatusData.confirmation_status === null ||
    punchoutConfirmationStatusData.confirmation_status_description === null
  )
    return null

  return {
    status: punchoutConfirmationStatusData.confirmation_status,
    description: punchoutConfirmationStatusData.confirmation_status_description,
  }
}

const mapPurchaseOrder = (purchaseOrderData: {
  purchase_order: Nullable<number>
  purchase_order_uuid: Nullable<string>
  po_note: string
}) => {
  if (
    purchaseOrderData.purchase_order === null ||
    purchaseOrderData.purchase_order_uuid === null
  )
    return null

  return {
    id: purchaseOrderData.purchase_order,
    uuid: purchaseOrderData.purchase_order_uuid,
    comment: purchaseOrderData.po_note,
  }
}

export const mapOrderItemApiModel = (
  orderItem: OrderItemApiModel
): OrderItemConstructorData => ({
  id: orderItem.id,
  name: orderItem.name,
  type: orderItem.type,
  status: orderItem.status,
  dateSubmitted: orderItem.submitted_date,
  dateRequired: orderItem.date_required,
  dateModified: orderItem.last_modified,
  account: mapAccount(orderItem.account),
  customFields: orderItem.custom_fields.map(mapOrderItemCustomField),
  sku: orderItem.sku,
  comment: orderItem.lineComment,
  currency: mapCurrency(orderItem.currency),
  price: orderItem.price,
  quantity: orderItem.quantity,
  unit: orderItem.unit,
  shippingAmount: orderItem.shipping_amount,
  taxAmount: orderItem.tax_amount,
  totalCost: orderItem.total_cost,
  vendorName: orderItem.vendor,
  punchoutSupplier: mapPunchOutSupplier(orderItem),
  punchoutConfirmationStatus: mapPunchOutConfirmationStatus(orderItem),
  order: {
    uuid: orderItem.order_uuid,
    id: orderItem.orderNum,
  },
  purchaseOrder: mapPurchaseOrder(orderItem),
})
