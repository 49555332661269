import {
  IconAccountsPayable,
  IconAnnouncement,
  IconApproved,
  IconCart,
  IconCreditCard,
  IconDashboard,
  IconFunds,
  IconInsights,
  IconReceive,
  IconReport,
  IconRequest,
  IconSettings,
} from '@procurify/ui'
import { type IntlShape } from 'react-intl'

import {
  SPENDING_CARD_MANAGEMENT_URL,
  SPENDING_CARD_RECONCILE_TRANSACTIONS_URL,
  SPENDING_CARD_TRANSACTIONS_URL,
} from '@webapp/app/routers/SpendingCardRouter/constants/urls'
import { type UrlBuilder } from '@webapp/hooks/useAppRoutes/types'
import { ExpenseStatusPageTabStatuses } from '@webapp/modules/expense'
import { ContractListPageStatuses } from '@webapp/pages/ContractListPage'
import { OrderStatusPageStatuses } from '@webapp/pages/OrderStatusPage'
import { PaymentApprovalListPageStatuses } from '@webapp/pages/PaymentApprovalListPage'
import { PaymentManagePayeesPageTabs } from '@webapp/pages/PaymentManagePayeesPage'
import { PaymentPayBillsPageStatuses } from '@webapp/pages/PaymentPayBillsPage'
import { ReceiveListPageTabStatuses } from '@webapp/pages/ReceiveListPage'
import { SpendingCardApprovalListPageTabs } from '@webapp/pages/SpendingCardApprovalListPage'
import { SpendingCardPhysicalCardTabs } from '@webapp/pages/SpendingCardPhysicalCardPage'
import { SpendingCardVirtualCardsPageTabs } from '@webapp/pages/SpendingCardVirtualCardsPage'
import { VendorApprovalListPageTabStatuses } from '@webapp/pages/VendorApprovalListPage'
import messages from './messages'
import { type INavigationItem, TagTypes } from './types'

/**
 * In an attempt to flatten the navigation bar,
 * keys are used as a representation of the headlink / subheader / sublink structure,
 * denoted and separated by "__" (double underscores)
 * An example key would look like one of the following:
 * "dashboard" - a navigation link without sublinks
 * "request__order" - a navigation link where the subheader is the same as the navigation link title ( Request -> Request -> Order ) and has sublinks
 * "ap__expense__unbilled_items" - a navigation link where the hasSubheader has a different title ( AP -> Expense Item -> Unbilled Items ) and has sublinks ( only used in AP )
 */
export const getBaseNavigation = (
  intl: IntlShape,
  url: UrlBuilder
): INavigationItem[] => {
  return [
    {
      title: intl.formatMessage(messages.billPaymentsLink),
      url: url('ProcurifyPaymentsPage'),
      id: 'whats_new__procurify_payments',
      tag: TagTypes.NEW,
      icon: IconAnnouncement,
      condition: ({ featureFlags, setupProgress }) => {
        return (
          featureFlags.bill_pay === true &&
          setupProgress.is_financial_account_setup === false
        )
      },
    },
    {
      title: intl.formatMessage(messages.dashboardLink),
      icon: IconDashboard,
      url: '/#/',
      legacy: true,
      id: 'dashboard',
      exactMatch: true,
    },
    {
      title: intl.formatMessage(messages.requestLink),
      icon: IconRequest,
      id: 'request',
      legacy: true,
      children: [
        {
          title: intl.formatMessage(messages.requestOrderLink),
          url: url('OrderStatusPage', {
            status: OrderStatusPageStatuses.PENDING,
          }),
          id: 'request__order',
          matchUrls: ['/orders/requests'],
        },
        {
          title: intl.formatMessage(messages.requestExpenseLink),
          url: url('ExpenseStatusPage', {
            status: ExpenseStatusPageTabStatuses.PENDING,
          }),
          id: 'request__expense',
          condition: ({ featureFlags }) => {
            return featureFlags.ARCH_150_MIGRATE_EXPENSE_STATUS
          },
        },
        {
          title: intl.formatMessage(messages.requestExpenseLink),
          url: '/#/expenses/requests',
          legacy: true,
          id: 'request__expense',
          condition: ({ featureFlags }) => {
            return !featureFlags.ARCH_150_MIGRATE_EXPENSE_STATUS
          },
        },
        {
          title: intl.formatMessage(messages.requestTravelLink),
          url: '/#/travels/requests',
          legacy: true,
          id: 'request__travel',
        },
        {
          title: intl.formatMessage(messages.requestPurchaseAgreementLink),
          url: '/#/agreements/requests',
          legacy: true,
          id: 'request__purchase_agreement',
          condition: ({ featureFlags }) => {
            return featureFlags.purchase_agreements === true
          },
          tag: TagTypes.BETA,
        },
      ],
    },
    {
      title: intl.formatMessage(messages.approvalLink),
      icon: IconApproved,
      id: 'approval',
      notificationKey: 'pending_approval_total',
      legacy: true,
      children: [
        {
          title: intl.formatMessage(messages.approvalOrderLink),
          url: '/#/orders/approvals',
          legacy: true,
          notificationKey: 'pending_approval_order',
          id: 'approval__order',
        },
        {
          title: intl.formatMessage(messages.approvalExpenseLink),
          url: '/#/expenses/approvals',
          legacy: true,
          notificationKey: 'pending_approval_expense',
          id: 'approval__expense',
        },
        {
          title: intl.formatMessage(messages.approvalTravelLink),
          url: '/#/travels/approvals',
          legacy: true,
          notificationKey: 'pending_approval_travel',
          id: 'approval__travel',
        },
        {
          title: intl.formatMessage(messages.approvalBillLink),
          url: '/#/bills/approvals',
          legacy: true,
          notificationKey: 'pending_approval_bill',
          id: 'approval__bill',
          condition: ({ featureFlags }) => {
            return featureFlags.disable_ap_navigation === false
          },
        },
        {
          title: intl.formatMessage(messages.approvalPaymentLink),
          url: '/#/ap/payments/approvals',
          legacy: true,
          notificationKey: 'pending_approval_payment',
          id: 'approval__payment',
          condition: ({ featureFlags }) => {
            return (
              !featureFlags.bill_pay &&
              !featureFlags.FT_4280_BILL_PAYMENT_CONSOLIDATION &&
              featureFlags.disable_ap_navigation === false
            )
          },
        },
        {
          title: intl.formatMessage(messages.approvalPaymentLink),
          url: url('PaymentApprovalListPage', {
            status: PaymentApprovalListPageStatuses.PENDING,
          }),
          matchUrls: ['/payments/approvals'],
          notificationKey: 'pending_approval_payment',
          id: 'approval__payment',
          condition: ({ featureFlags }) => {
            return (
              featureFlags.FT_4280_BILL_PAYMENT_CONSOLIDATION &&
              featureFlags.disable_ap_navigation === false
            )
          },
        },
        {
          title: intl.formatMessage(messages.approvalPurchaseAgreementLink),
          url: '/#/agreements/approvals',
          legacy: true,
          id: 'approval__purchase_agreement',
          condition: ({ featureFlags }) => {
            return featureFlags.purchase_agreements === true
          },
          tag: TagTypes.BETA,
        },
        {
          title: intl.formatMessage(messages.approvalSpendingCardLink),
          url: url('SpendingCardApprovalListPage', {
            tab: SpendingCardApprovalListPageTabs.PENDING,
          }),
          notificationKey: 'pending_approval_pay_requests',
          id: 'approval__pay_request',
          matchUrls: ['/spending-card/approvals/'],
          condition: ({ featureFlags }) => {
            return featureFlags.stripe_integration === true
          },
        },
        {
          title: intl.formatMessage(messages.approvalVendorLink),
          url: url('VendorApprovalListPage', {
            status: VendorApprovalListPageTabStatuses.PENDING,
          }),
          notificationKey: 'pending_approval_vendor_requests',
          id: 'approval__vendor_request',
          matchUrls: ['/vendor/approvals/'],
          condition: ({ featureFlags }) => {
            return (
              featureFlags.disable_vendor_creation === false &&
              featureFlags.disable_vendor_approvals_access === false &&
              featureFlags.enable_vendor_approvals === true
            )
          },
        },
      ],
    },
    {
      title: intl.formatMessage(messages.procureLink),
      icon: IconCart,
      id: 'procure',
      notificationKey: 'pending_procure',
      legacy: true,
      children: [
        {
          title: intl.formatMessage(messages.procureProcurementLink),
          url: '/procurement',
          notificationKey: 'pending_procure',
          id: 'procure__procurement',
          matchUrls: ['/#/purchase/order/create'],
        },
        {
          title: intl.formatMessage(messages.procurePurchaseOrdersLink),
          url: '/purchase-orders/open',
          id: 'procure__purchase_orders',
          matchUrls: ['/purchase-orders/', '/#/purchase/order/details'],
        },
        {
          title: intl.formatMessage(messages.procurePurchaseAgreementLink),
          url: '/#/purchase/agreement/status/open',
          legacy: true,
          id: 'procure__purchase_agreement',
          condition: ({ featureFlags }) => {
            return featureFlags.purchase_agreements === true
          },
          tag: TagTypes.BETA,
        },
        {
          title: intl.formatMessage(messages.procureVendorsLink),
          url: '/#/purchase/vendors',
          legacy: true,
          id: 'procure__vendors',
        },
        {
          title: intl.formatMessage(messages.procureContractsLink),
          url: url('ContractListPage', {
            status: ContractListPageStatuses.ACTIVE,
          }),
          id: 'procure__contracts',
          condition: ({ featureFlags }) =>
            !featureFlags.disable_contract_management,
          matchUrls: ['/contracts/'],
        },
        {
          title: intl.formatMessage(messages.procureProductCatalogLink),
          url: '/catalog',
          id: 'procure__product_catalog',
        },
      ],
    },
    {
      title: intl.formatMessage(messages.receiveLink),
      icon: IconReceive,
      id: 'receive',
      url: url('ReceiveListPage', { status: ReceiveListPageTabStatuses.OPEN }),
      notificationKey: 'pending_receive',
    },
    {
      title: intl.formatMessage(messages.spendingCardLink),
      icon: IconCreditCard,
      id: 'spending_card',
      children: [
        {
          title: intl.formatMessage(messages.spendingCardVirtualCardsLink),
          url: url('SpendingCardVirtualCardsPage', {
            tab: SpendingCardVirtualCardsPageTabs.ACTIVE,
          }),
          id: 'pay__virtual_cards',
          matchUrls: ['/spending-card/virtual-cards/'],
          condition: ({ setupProgress }) =>
            setupProgress.spending_card_bank_account_setup === true,
        },
        {
          title: intl.formatMessage(messages.spendingCardPhysicalCardLink),
          url: url('SpendingCardPhysicalCardPage', {
            tab: SpendingCardPhysicalCardTabs.OPEN,
          }),
          id: 'pay__my_card',
          matchUrls: ['/spending-card/physical-card/'],
          condition: ({ setupProgress }) =>
            setupProgress.spending_card_bank_account_setup === true,
        },
        {
          title: intl.formatMessage(messages.spendingCardTransactionsLink),
          url: SPENDING_CARD_TRANSACTIONS_URL,
          id: 'pay__my_transactions',
          condition: ({ setupProgress }) =>
            setupProgress.spending_card_bank_account_setup === true,
        },
        {
          title: intl.formatMessage(messages.payCardManagementLink),
          url: SPENDING_CARD_MANAGEMENT_URL,
          id: 'pay__card_management',
          notificationKey: 'pending_approval_cardrequest',
        },
        {
          title: intl.formatMessage(messages.payReconcileTransactionsLink),
          url: SPENDING_CARD_RECONCILE_TRANSACTIONS_URL,
          id: 'pay__reconcile_transactions',
          condition: ({ setupProgress }) =>
            setupProgress.spending_card_bank_account_setup === true,
        },
      ],
      condition: ({ featureFlags }) => {
        return featureFlags.stripe_integration === true
      },
    },
    {
      title: intl.formatMessage(messages.paymentLink),
      icon: IconFunds,
      legacy: true,
      id: 'payment',
      condition: ({ featureFlags }) => {
        return (
          featureFlags.legacy_payment === true &&
          featureFlags.disable_ap_navigation === false
        )
      },
      children: [
        {
          title: intl.formatMessage(messages.paymentPaymentRecordsLink),
          url: '/#/ap/payment-records/unpaid',
          legacy: true,
          id: 'payment__payment_records',
        },
        {
          title: intl.formatMessage(messages.paymentExpenseRecordsLink),
          url: '/#/ap/expense-records/unrecorded',
          legacy: true,
          id: 'payment__expense_records',
        },
        {
          title: intl.formatMessage(messages.paymentReceivingSummaryLink),
          url: '/#/ap/reports/receiving',
          legacy: true,
          id: 'payment__receiving_summary',
        },
      ],
    },
    {
      title: intl.formatMessage(messages.apLink),
      icon: IconAccountsPayable,
      id: 'ap',
      legacy: true,
      condition: ({ featureFlags }) => {
        return (
          featureFlags.legacy_payment === false &&
          featureFlags.disable_ap_navigation === false
        )
      },
      children: [
        {
          title: intl.formatMessage(messages.apBillsLink),
          legacy: true,
          id: 'bills',
          isSubheader: true,
          links: [
            'ap__bills',
            'ap__create_bills',
            'ap__manage_bills',
            'ap__purchase_order__unbilled_items',
            'ap__expense__unbilled_items',
          ],
        },
        {
          title: intl.formatMessage(messages.apCreateBillLink),
          url: url('BillCreatePage', {}),
          id: 'ap__bills',
          condition: ({ featureFlags }) => {
            return !featureFlags.CL_1058_AP_GRANULAR_PERMISSIONS
          },
        },
        {
          title: intl.formatMessage(messages.apCreateBillLink),
          url: url('BillCreatePage', {}),
          id: 'ap__create_bills',
          condition: ({ featureFlags }) => {
            return featureFlags.CL_1058_AP_GRANULAR_PERMISSIONS
          },
        },
        {
          title: intl.formatMessage(messages.apManageBillsLink),
          url: url('BillListPage', {
            status: 'draft',
          }),
          id: 'ap__bills',
          matchUrls: ['/bills/requests', '/payments/create'],
          condition: ({ featureFlags }) => {
            return !featureFlags.CL_1058_AP_GRANULAR_PERMISSIONS
          },
        },
        {
          title: intl.formatMessage(messages.apManageBillsLink),
          url: url('BillListPage', {
            status: 'draft',
          }),
          id: 'ap__manage_bills',
          matchUrls: ['/bills/requests', '/payments/create'],
          condition: ({ featureFlags }) => {
            return featureFlags.CL_1058_AP_GRANULAR_PERMISSIONS
          },
        },
        {
          title: intl.formatMessage(messages.apUnbilledOrderItemsLink),
          url: '/#/ap/unbilled/items',
          legacy: true,
          id: 'ap__purchase_order__unbilled_items',
          condition: ({ featureFlags }) => {
            return (
              featureFlags.disable_ap_navigation === false &&
              featureFlags.FT_4287_MIGRATE_UNBILLED_ITEMS_PAGE === false
            )
          },
        },
        {
          title: intl.formatMessage(messages.apUnbilledOrderItemsLink),
          url: url('UnbilledItemsPage', {
            status: 'active',
          }),
          id: 'ap__purchase_order__unbilled_items',
          condition: ({ featureFlags }) => {
            return (
              featureFlags.disable_ap_navigation === false &&
              featureFlags.FT_4287_MIGRATE_UNBILLED_ITEMS_PAGE === true
            )
          },
        },
        {
          title: intl.formatMessage(messages.apUnbilledExpenseItemsLink),
          url: '/#/ap/unbilled/expenses',
          legacy: true,
          id: 'ap__expense__unbilled_items',
          condition: ({ featureFlags }) => {
            return featureFlags.disable_ap_navigation === false
          },
        },
        {
          title: intl.formatMessage(messages.apCreditCardsLink),
          legacy: true,
          id: 'credit_cards',
          isSubheader: true,
          links: ['ap__credit_cards__reconciliation'],
        },
        {
          title: intl.formatMessage(messages.apReconciliationLink),
          url: '/#/ap/reconcile',
          legacy: true,
          id: 'ap__credit_cards__reconciliation',
          condition: ({ featureFlags }) => {
            return featureFlags.disable_ap_navigation === false
          },
        },
        {
          title: intl.formatMessage(messages.apPaymentLink),
          legacy: true,
          id: 'payment',
          isSubheader: true,
          links: [
            'ap__payment__payment_processing',
            'ap__payment__payee_management',
            'ap__payment__payment_log',
          ],
          condition: ({ featureFlags }) => {
            return !featureFlags.bill_pay
          },
        },
        {
          title: intl.formatMessage(messages.apPaymentTrackingLink),
          url: '/#/ap/payments/requests/',
          legacy: true,
          id: 'ap__payment__payment_processing',
          condition: ({ featureFlags }) => {
            return (
              !featureFlags.bill_pay &&
              !featureFlags.FT_4280_BILL_PAYMENT_CONSOLIDATION &&
              featureFlags.disable_ap_navigation === false
            )
          },
        },
        {
          title: intl.formatMessage(messages.apPayeeManagementLink),
          url: '/#/ap/payee-management',
          legacy: true,
          id: 'ap__payment__payee_management',
          condition: ({ featureFlags }) => {
            return (
              !featureFlags.bill_pay &&
              !featureFlags.FT_4280_BILL_PAYMENT_CONSOLIDATION &&
              featureFlags.disable_ap_navigation === false
            )
          },
        },
        {
          title: intl.formatMessage(messages.apPaymentHistoryLink),
          url: '/#/ap/payments/logs/',
          legacy: true,
          id: 'ap__payment__payment_log',
          condition: ({ featureFlags }) => {
            return (
              !featureFlags.bill_pay &&
              !featureFlags.FT_4280_BILL_PAYMENT_CONSOLIDATION &&
              featureFlags.disable_ap_navigation === false
            )
          },
        },
        {
          title: intl.formatMessage(messages.apPaymentsLink),
          id: 'payments',
          isSubheader: true,
          links: ['ap__payment__manage_payment', 'ap__payment__create_payment'],
          condition: ({ featureFlags }) => {
            return featureFlags.bill_pay
          },
        },
        {
          title: intl.formatMessage(messages.apPaymentManagementLink),
          url: '/payments/',
          id: 'ap__payment__manage_payment',
          condition: ({ featureFlags }) => {
            return (
              featureFlags.bill_pay &&
              !featureFlags.FT_4280_BILL_PAYMENT_CONSOLIDATION
            )
          },
        },
        {
          title: intl.formatMessage(messages.apPayBillsLink),
          url: url('PaymentPayBillsPage', {
            status: PaymentPayBillsPageStatuses.TO_BE_PAID,
          }),
          id: 'ap__payment__create_payment',
          matchUrls: [
            url('PaymentPreparePage', { uuid: '' }),
            url('PaymentPayBillsPage', {
              status: PaymentPayBillsPageStatuses.DRAFT,
            }),
          ],
          condition: ({ featureFlags }) => {
            return (
              featureFlags.FT_4280_BILL_PAYMENT_CONSOLIDATION &&
              featureFlags.disable_ap_navigation === false
            )
          },
        },
        {
          title: intl.formatMessage(messages.apPaymentManagementLink),
          url: url('PaymentListPage'),
          id: 'ap__payment__payment_processing',
          matchUrls: [url('PaymentDetailsPage', { uuid: '' })],
          exactMatch: true,
          condition: ({ featureFlags }) => {
            return (
              featureFlags.FT_4280_BILL_PAYMENT_CONSOLIDATION &&
              featureFlags.disable_ap_navigation === false
            )
          },
        },
        {
          title: intl.formatMessage(messages.apPayeeManagementLink),
          url: url('PaymentManagePayeesPage', {
            type: PaymentManagePayeesPageTabs.VENDORS,
            id: '',
            tab: '',
          }),
          id: 'ap__payment__payee_management',
          condition: ({ featureFlags }) => {
            return featureFlags.FT_4280_BILL_PAYMENT_CONSOLIDATION
          },
        },
        {
          title: intl.formatMessage(messages.apFinancialAccountLink),
          url: url('PaymentFinancialAccountPage'),
          id: 'ap__payment__financial_account',
          condition: ({ featureFlags }) => {
            return featureFlags.bill_pay
          },
        },
        {
          title: intl.formatMessage(messages.apSpendReportsLink),
          legacy: true,
          id: 'spend',
          isSubheader: true,
          links: [
            'ap__spend__accrual_report',
            'ap__spend__spend_tracker',
            'ap__spend__expense_records',
            'ap__spend__receiving_summary',
          ],
        },
        {
          title: intl.formatMessage(messages.apAccrualsLink),
          url: '/#/ap/reports/accrual',
          legacy: true,
          id: 'ap__spend__accrual_report',
          condition: ({ featureFlags }) => {
            return (
              featureFlags.bill_received_only ||
              featureFlags.disable_ap_navigation === false
            )
          },
        },
        {
          title: intl.formatMessage(messages.apOverallSpendLink),
          url: '/#/ap/reports/spend-tracker/',
          legacy: true,
          id: 'ap__spend__spend_tracker',
          condition: ({ featureFlags }) => {
            return featureFlags.disable_ap_navigation === false
          },
        },
        {
          title: intl.formatMessage(messages.apExpensesLink),
          url: '/#/ap/reports/expense-records',
          legacy: true,
          id: 'ap__spend__expense_records',
          condition: ({ featureFlags }) => {
            return featureFlags.disable_ap_navigation === false
          },
        },
        {
          title: intl.formatMessage(messages.apReceivingLink),
          url: '/#/ap/reports/receiving',
          legacy: true,
          id: 'ap__spend__receiving_summary',
          condition: ({ featureFlags }) => {
            return featureFlags.disable_ap_navigation === false
          },
        },
      ],
    },
    {
      title: intl.formatMessage(messages.reportsLink),
      icon: IconReport,
      id: 'report',
      legacy: true,
      children: [
        {
          title: intl.formatMessage(messages.reportsOrdersByVendorLink),
          url: '/#/reports/orders/vendor',
          legacy: true,
          id: 'report__orders_by_vendor',
        },
        {
          title: intl.formatMessage(messages.reportsOrdersByUserLink),
          url: '/#/reports/orders/user',
          legacy: true,
          id: 'report__orders_by_user',
        },
        {
          title: intl.formatMessage(
            messages.reportsOrdersBySecondaryOrgCategoryLink
          ),
          url: '/#/reports/orders/department',
          legacy: true,
          id: 'report__orders_by_department',
        },
        {
          title: intl.formatMessage(
            messages.reportsExpensesBySecondaryOrgCategoryLink
          ),
          url: '/#/reports/expenses/department',
          legacy: true,
          id: 'report__expenses_by_department',
        },
      ],
    },
    {
      title: intl.formatMessage(messages.dataAndInsightsLink),
      icon: IconInsights,
      id: 'data_and_insights',
      legacy: false,
      children: [
        {
          title: intl.formatMessage(messages.spendInsightsLink),
          url: url('SpendInsightsPage'),
          legacy: false,
          id: 'data_and_insights__spend_insights',
          condition: ({ featureFlags }) => {
            return featureFlags.FT_2274_ENABLE_SPEND_INSIGHTS_DASHBOARD === true
          },
        },
        {
          title: intl.formatMessage(messages.returnOnSpend),
          url: url('ReturnOnSpendPage'),
          legacy: false,
          id: 'data_and_insights__return_on_spend',
          condition: ({ featureFlags }) => {
            return (
              featureFlags.FT_2278_ENABLE_RETURN_ON_SPEND_CALCULATOR === true
            )
          },
        },
      ],
    },
    {
      title: intl.formatMessage(messages.settingsLink),
      icon: IconSettings,
      id: 'settings',
      url: '/settings',
    },
  ]
}
