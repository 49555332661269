import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { type PayRequest } from '@webapp/models/PayRequest'
import { SpendingCardVirtualCardsPageTabs } from '@webapp/pages/SpendingCardVirtualCardsPage'
import messages from './messages'

export interface SpendingCardVirtualCardRequestDetailsPageParams {
  uuid: PayRequest['uuid']
}

/**
 * TODO: to be replaced with new design - not priority for MVC FT_260_MULTIPLE_VIRTUAL_CARDS
 */
// const SpendingCardVirtualCardRequestDetailsPageViewChunk = React.lazy(() =>
//   import('../containers/SpendingCardVirtualCardRequestDetailsView').then(
//     ({ SpendingCardVirtualCardRequestDetailsView }) => ({
//       default: SpendingCardVirtualCardRequestDetailsView,
//     })
//   )
// )

const SpendingCardVirtualCardRequestDetailsPageViewChunk = React.lazy(() =>
  import('../containers/SpendingCardRequestDetailsView').then(
    ({ SpendingCardRequestDetailsView }) => ({
      default: SpendingCardRequestDetailsView,
    })
  )
)

export const SpendingCardVirtualCardRequestDetailsPage = (
  props: RouteComponentProps<SpendingCardVirtualCardRequestDetailsPageParams>
) => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(
        messages.spendingCardVirtualCardRequestDetailsPageTitle
      )}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.spendingCardVirtualCardsPageTitle),
          link: url('SpendingCardVirtualCardsPage', {
            tab: SpendingCardVirtualCardsPageTabs.PENDING,
          }),
        },
        {
          name: intl.formatMessage(
            messages.spendingCardVirtualCardRequestDetailsPageTitle
          ),
        },
      ]}
    >
      <SpendingCardVirtualCardRequestDetailsPageViewChunk {...props} />
    </Page>
  )
}
