import { type Enum } from '@webapp/utils'

export const OrderStatusPageStatuses = {
  ALL: 'all',
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
  PURCHASED: 'purchased',
  RECEIVED: 'received',
} as const
export type OrderStatusPageStatus = Enum<typeof OrderStatusPageStatuses>

export const OrderDetailsPageReferrers = {
  ORDER_STATUS: 'order_status',
  ORDER_APPROVAL: 'order_approval',
  PROCUREMENT: 'create_purchase_order',
  PURCHASE_ORDER_LIST: 'purchase_orders',
  PURCHASE_ORDER_DETAILS: 'purchase_order_details',
  RECEIVE_LIST: 'receive_purchase_order_list',
  RECEIVE_DETAILS: 'receive_purchase_order_details',
} as const
export type OrderDetailsPageReferrer = Enum<typeof OrderDetailsPageReferrers>
export const OrderDetailsPageSearchParams = {
  REFERRER: 'referrer',
}
export interface OrderDetailsPageParams {
  uuid: uuid
}

export const OrderApprovalPageStatuses = {
  ALL: 'all',
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
} as const
export type OrderApprovalPageStatus = Enum<typeof OrderApprovalPageStatuses>
export interface OrderApprovalPageParams {
  status: OrderApprovalPageStatus
}

export const PurchaseOrderListPageStatuses = {
  ALL: 'all',
  OPEN: 'open',
  PARTIAL: 'partial',
  CLOSED: 'closed',
  CANCELLED: 'cancelled',
  PAID: 'paid',
} as const
export type PurchaseOrderListPageStatus = Enum<
  typeof PurchaseOrderListPageStatuses
>

export const ReceiveListPageStatuses = {
  OPEN: 'open',
  PARTIAL: 'partial',
  CLOSED: 'closed',
  CANCELLED: 'cancelled',
  PAID: 'paid',
  ALL: 'all',
} as const
export type ReceiveListPageStatus = Enum<typeof ReceiveListPageStatuses>
