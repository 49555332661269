import styled from 'styled-components'

interface INavigationLinkNotificationIndicatorStyledProps {}

export const NavigationLinkNotificationIndicatorStyled = styled.div<INavigationLinkNotificationIndicatorStyledProps>`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  margin-left: ${(props) => props.theme.typography.pxToRem(8)};
  width: ${(props) => props.theme.typography.pxToRem(6)};
  height: ${(props) => props.theme.typography.pxToRem(6)};
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.darkGrey};

  @media (max-width: ${(props) => props.theme.breakpoints.values.xl + 'px'}) {
    display: none;
  }
`
