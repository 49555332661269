import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { type UnbilledItemStatus } from '@webapp/models/enums/UnbilledItemStatus'
import messages from './messages'

export interface UnbilledItemsPageParams {
  status: UnbilledItemStatus
}

const UnbilledItemsContainerChunk = React.lazy(() =>
  import('../containers/UnbilledItemsContainer').then(
    ({ UnbilledItemsContainer }) => ({
      default: UnbilledItemsContainer,
    })
  )
)

export const UnbilledItemsPage = (
  props: RouteComponentProps<UnbilledItemsPageParams>
) => {
  const intl = useProcurifyIntl()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.unbilledItemsPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.unbilledItemsPageTitle),
        },
      ]}
    >
      <UnbilledItemsContainerChunk status={props.match.params?.status} />
    </Page>
  )
}
