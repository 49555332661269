import { useQuery, type UseQueryOptions } from 'react-query'

import { Auth0AuthenticationAPI } from '@webapp/api/Auth0AuthenticationAPI'
import { type SsoConnection } from '@webapp/models'

const queryKey = '/authentication/connection/'

export const ssoConnectionQueryKey = queryKey

const queryFn = async () => {
  const {
    data: { data: ssoConnection },
  } = await Auth0AuthenticationAPI.getSsoConnection()

  return ssoConnection
}

export const useGetSsoConnection = (
  config?: Omit<UseQueryOptions<SsoConnection>, 'queryKey' | 'queryFn'>
) => {
  return useQuery([queryKey], queryFn, config)
}
