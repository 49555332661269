import { type CustomFieldType } from '@webapp/models/enums'
import { type ContentType, type CustomField } from '../../models'
import { ApiClient } from '../../services/api'

export const ENDPOINT = '/api/v3/custom-fields/'
export const CUSTOM_FIELD_URL = ENDPOINT

export interface AddCustomFieldSchema {
  content_type: ContentType['id']
  field_choices: string[]
  field_type: CustomFieldType
  is_required: boolean
  name: string
}

export interface EditCustomFieldSchema extends AddCustomFieldSchema {
  id: CustomField['id']
}

export const CustomFieldAPI = {
  getOrderCustomfields: (params = {}) => {
    return ApiClient.get<CustomField[]>(`${ENDPOINT}catalog-items/`, {
      params: {
        meta__is_active: 'True',
        ...params,
      },
    })
  },
  getOrderItemCustomfields: (params = {}) => {
    return ApiClient.get<CustomField[]>(`${ENDPOINT}order-items/`, {
      params: {
        meta__is_active: 'True',
        ...params,
      },
    })
  },
  getAPItemsCustomfields: (params = {}) => {
    return ApiClient.get<CustomField[]>(`${ENDPOINT}ap-items/`, {
      params: {
        ...params,
      },
    })
  },
  addCustomField: (payload: AddCustomFieldSchema) => {
    return ApiClient.post<AddCustomFieldSchema, CustomField>(
      `${ENDPOINT}`,
      payload
    )
  },
  editCustomField: (
    customFieldId: CustomField['id'],
    payload: EditCustomFieldSchema
  ) => {
    return ApiClient.put<EditCustomFieldSchema, CustomField>(
      `${ENDPOINT}${customFieldId}/`,
      payload
    )
  },
  disableCustomField: (customFieldId: CustomField['id']) => {
    return ApiClient.delete(`${ENDPOINT}${customFieldId}/`)
  },
  activateCustomField: (customFieldId: CustomField['id']) => {
    return ApiClient.put(`${ENDPOINT}${customFieldId}/activate/`)
  },
  getPoCustomfields: (params = {}) => {
    return ApiClient.get<CustomField[]>(`${ENDPOINT}purchase-orders/`, {
      params: {
        meta__is_active: 'True',
        ...params,
      },
    })
  },
}
