import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import messages from './messages'
import { ReceiveListPageTabStatuses } from './ReceiveListPage'

export interface ReceiveDetailsPageParams {
  uuid: uuid
}

const ReceiveDetailsViewChunk = React.lazy(() =>
  import('../containers/ReceiveDetailsView').then(({ ReceiveDetailsView }) => ({
    default: ReceiveDetailsView,
  }))
)

export const ReceiveDetailsPage = () => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.receiveDetailsPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.receiveListBreadcrumb),
          link: url('ReceiveListPage', {
            status: ReceiveListPageTabStatuses.OPEN,
          }),
          escapeCaseFormatting: true,
        },
        {
          name: intl.formatMessage(messages.receiveDetailsBreadcrumb),
          escapeCaseFormatting: true,
        },
      ]}
    >
      <ReceiveDetailsViewChunk />
    </Page>
  )
}
