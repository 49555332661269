import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import messages from './messages'

const ContractSettingsViewChunk = React.lazy(() =>
  import('../containers/ContractSettingsView').then(
    ({ ContractSettingsView }) => ({
      default: ContractSettingsView,
    })
  )
)

export const ContractSettingsPage = () => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(messages.contractSettingsPageTitle)}
      breadcrumbs={[
        {
          name: intl.formatMessage(messages.settingsPageTitle),
          link: url('SettingsPage'),
        },
        { name: intl.formatMessage(messages.contractSettingsPageTitle) },
      ]}
    >
      <ContractSettingsViewChunk />
    </Page>
  )
}
