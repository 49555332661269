import * as React from 'react'

import styled from 'styled-components'

import { type ITextButtonProps, TextButton } from '../TextButton'

export const InlineTextButtonStyled = styled(
  (props: React.PropsWithChildren<ITextButtonProps>) => (
    <TextButton {...props} />
  )
)`
  font-size: 1em;
  padding: 0;
`
