import * as React from 'react'

import { useSelector } from 'react-redux'
import { Redirect, Route, type RouteProps } from 'react-router'

import { type PermissionEnum } from '@webapp/core/entities/internal/enums/PermissionEnum'
import { type FeatureFlagKey } from '@webapp/core/services/FeatureFlag'
import {
  type FeatureSwitches,
  type NavigationPermissions,
  PermissionGroupNames,
} from '@webapp/models'
import {
  makeSelectFeatureFlags,
  makeSelectFeatureSwitches,
  makeSelectNavigationPermissions,
  makeSelectPermissions,
  makeSelectRole,
} from '@webapp/stores/global'

export interface IProtectedRouteProps extends RouteProps {
  navigationPermissions?: NavigationPermissions[]
  permissions?: PermissionEnum[]
  featureFlags?: FeatureFlagKey[]
  featureSwitches?: (keyof FeatureSwitches)[]
  isSuperuserRoleRequired?: boolean
}

export const ProtectedRoute = React.memo(
  ({
    navigationPermissions: requiredNavigationPermissions = [],
    permissions: requiredPermissions = [],
    featureFlags: requiredFeatureFlags = [],
    featureSwitches: requiredFeatureSwitches = [],
    component: Component,
    render,
    isSuperuserRoleRequired,
    ...otherProps
  }: IProtectedRouteProps) => {
    const userNavigationPermissions = useSelector(
      makeSelectNavigationPermissions()
    )
    const userPermissions =
      useSelector(makeSelectPermissions())?.map(
        (permission) => permission.codename
      ) || []
    const userRole = useSelector(makeSelectRole())
    const featureFlags = useSelector(makeSelectFeatureFlags())
    const featureSwitches = useSelector(makeSelectFeatureSwitches())

    const hasNavigationPermissions = requiredNavigationPermissions.every(
      (permission) => userNavigationPermissions.indexOf(permission) >= 0
    )

    const hasPermissions = requiredPermissions.every((permission) => {
      return userPermissions.includes(permission)
    })

    const hasFeatureFlags = requiredFeatureFlags.every((ff) => featureFlags[ff])
    const hasFeatureSwitches = requiredFeatureSwitches.every(
      (ff) => featureSwitches[ff]
    )

    const isSuperUser =
      userRole &&
      (userRole.name === PermissionGroupNames.SUPERUSER ||
        userRole.name === PermissionGroupNames.SITE_ADMINISTRATOR)

    const hasRoleAccess =
      !isSuperuserRoleRequired || (isSuperuserRoleRequired && isSuperUser)

    const isAllowed =
      hasNavigationPermissions &&
      hasPermissions &&
      hasFeatureFlags &&
      hasFeatureSwitches &&
      hasRoleAccess

    return (
      <Route
        {...otherProps}
        render={(props) => {
          if (isAllowed && Component) {
            return <Component {...props} />
          } else if (isAllowed && render) {
            return render(props)
          } else {
            return <Redirect to='/' />
          }
        }}
      />
    )
  }
)

ProtectedRoute.displayName = 'ProtectedRoute'
