import * as React from 'react'

import { CircleLoader } from '@procurify/ui'
import { useHistory, useLocation } from 'react-router'

import { type ParsedParams } from '@webapp/containers/SpendInsightsLinkOutView'
import { Page } from '@webapp/core/components/Page'
import { useAppRoutes, useProcurifyIntl } from '@webapp/hooks'
import { useParseLinkOutURLParams } from './hooks'
import messages from '../messages'

export interface SpendInsightsLinkOutPageParams {}

const SpendInsightsLinkOutChunk = React.lazy(() =>
  import('../../containers/SpendInsightsLinkOutView').then(
    ({ SpendInsightsLinkOutView }) => ({
      default: SpendInsightsLinkOutView,
    })
  )
)

export const SpendInsightsLinkOutPage = () => {
  const history = useHistory()
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()
  const queryParams = new URLSearchParams(useLocation().search)
  const visual = queryParams.get('visual')
  let parsedParams: ParsedParams
  const results = useParseLinkOutURLParams(queryParams, visual)

  if (results instanceof TypeError) {
    history.goBack()
  } else {
    parsedParams = results
  }

  if (parsedParams) {
    return (
      <Page
        pageTitle={intl.formatMessage(messages.spendInsightsPageTitle)}
        breadcrumbs={[
          {
            name: intl.formatMessage(messages.spendInsightsPageTitle),
            link: url('SpendInsightsPage'),
          },
          {
            name: intl.formatMessage(messages.spendInsightsLinkOutPageTitle, {
              graph: parsedParams?.name,
              date: parsedParams?.breadCrumb,
            }),
            escapeCaseFormatting: true,
          },
        ]}
      >
        <SpendInsightsLinkOutChunk parsedParams={parsedParams} />
      </Page>
    )
  } else {
    return (
      <Page
        pageTitle={intl.formatMessage(messages.spendInsightsPageTitle)}
        breadcrumbs={[
          {
            name: intl.formatMessage(messages.spendInsightsPageTitle),
            link: url('SpendInsightsPage'),
          },
        ]}
      >
        <CircleLoader />
      </Page>
    )
  }
}
