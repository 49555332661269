import * as React from 'react'

import Helmet from 'react-helmet'
import { type RouteComponentProps } from 'react-router'

export const SETTINGS_IMPORT_URL = '/settings/import'

const SettingsImportChunk = React.lazy(() =>
  import('../containers/SettingsImport').then(
    ({ ConnectedSettingsImport }) => ({
      default: ConnectedSettingsImport,
    })
  )
)

export class SettingsImportPage extends React.PureComponent<RouteComponentProps> {
  render() {
    return (
      <>
        <Helmet>
          <title>Procurify | Settings Import</title>
        </Helmet>
        <React.Suspense fallback={null}>
          <SettingsImportChunk {...this.props} />
        </React.Suspense>
      </>
    )
  }
}
