import { IconStyled } from '@procurify/ui'
import styled from 'styled-components'

export const BreadcrumbStyled = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: ${(props) => props.theme.typography.pxToRem(12)};
  ${IconStyled} {
    display: none;
  }
  .crumb + ${IconStyled} {
    margin-left: ${(props) => props.theme.typography.pxToRem(12)};
    display: initial;
    margin-right: ${(props) => props.theme.typography.pxToRem(12)};
  }
`
