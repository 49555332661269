import * as React from 'react'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import { SpendingCardPhysicalCardTabs } from '@webapp/pages/SpendingCardPhysicalCardPage'
import messages from './messages'

const SpendingCardCreateFundRequestViewChunk = React.lazy(() =>
  import('../containers/SpendingCardCreateFundRequestView').then(
    ({ SpendingCardCreateFundRequestView }) => ({
      default: SpendingCardCreateFundRequestView,
    })
  )
)

export const SpendingCardCreateFundRequestPage = () => {
  const intl = useProcurifyIntl()

  const { url } = useAppRoutes()

  return (
    <Page
      pageTitle={intl.formatMessage(
        messages.spendingCardCreateFundRequestPageTitle
      )}
      breadcrumbs={[
        {
          link: url('SpendingCardPhysicalCardPage', {
            tab: SpendingCardPhysicalCardTabs.OPEN,
          }),
          name: intl.formatMessage(messages.spendingCardPhysicalCardPageTitle),
        },
        {
          name: intl.formatMessage(
            messages.spendingCardCreateFundRequestPageTitle
          ),
        },
      ]}
    >
      <SpendingCardCreateFundRequestViewChunk />
    </Page>
  )
}
