import { useSubscribeBatchContractProgress } from './hooks/useSubscribeBatchContractProgress'
import { useSubscribeVendorPerformanceChannel } from './hooks/useSubscribeVendorPerformanceChannel'

export const SystemPushNotifications = () => {
  useSubscribeBatchContractProgress()
  useSubscribeVendorPerformanceChannel()

  // TODO: Refactor this component into a hook. This component is not rendering anything.
  return null
}

SystemPushNotifications.displayName = 'SystemPushNotifications'
