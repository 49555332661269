import * as Sentry from '@sentry/react'
import { DateTime } from 'luxon'

import { toast } from '@webapp/components/Notification'
import { type ParsedParams } from '@webapp/containers/SpendInsightsLinkOutView'
import {
  type SpendInsightPeriodDuration,
  SpendInsightPeriodDurations,
} from '@webapp/containers/SpendInsightsView/enums/SpendInsightPeriodDuration'
import { useProcurifyIntl, useSpendInsightsVisualNames } from '@webapp/hooks'
import {
  SpendInsightsAnnualVisuals,
  SpendInsightsLinkOutTables,
  SpendInsightsMonthlyVisuals,
  SpendInsightsQuarterlyVisuals,
} from '@webapp/models/enums'
import messages from '../messages'

const generateDateParams = (
  timeFrame: SpendInsightPeriodDuration,
  dateParams,
  parsedParams
) => {
  if (Object.keys(dateParams).length === 0) {
    parsedParams.breadCrumb = ``
    return parsedParams
  }

  const selectedDate = DateTime.fromObject(dateParams)
  parsedParams.selectedDate = selectedDate

  if (timeFrame === undefined) {
    timeFrame = SpendInsightPeriodDurations.YEAR
  }

  switch (timeFrame) {
    case SpendInsightPeriodDurations.MONTH:
      parsedParams.breadCrumb = ` (${selectedDate.toLocaleString({
        month: 'long',
        year: 'numeric',
      })})`
      parsedParams.startDate = selectedDate.startOf('month')
      parsedParams.endDate = selectedDate.endOf('month')
      return parsedParams

    case SpendInsightPeriodDurations.QUARTER:
      parsedParams.breadCrumb = ` (Q${selectedDate.quarter})`
      parsedParams.startDate = selectedDate.startOf('quarter')
      parsedParams.endDate = selectedDate.endOf('quarter')
      return parsedParams

    case SpendInsightPeriodDurations.YEAR:
      parsedParams.breadCrumb = ` (${selectedDate.year})`
      parsedParams.startDate = selectedDate.startOf('year')
      parsedParams.endDate = selectedDate.endOf('year')
      return parsedParams
  }
}

export const useParseLinkOutURLParams = (
  queryParams: URLSearchParams,
  visual: string
): ParsedParams | TypeError => {
  const intl = useProcurifyIntl()
  const spendInsightVisualNames = useSpendInsightsVisualNames()
  let parsedParams: any = {}
  parsedParams.filters = {}
  let timeFrame: SpendInsightPeriodDuration
  const dateParams: any = {}

  const parseLinkOutParam = (key: string, value: string) => {
    // gather selected dateTime params from TS
    if (key.includes('(Datetime)')) {
      if (key.includes('MONTH')) {
        dateParams.month = value
        timeFrame = SpendInsightPeriodDurations.MONTH
      } else if (key.includes('Quarter')) {
        dateParams.quarter = value
        timeFrame = SpendInsightPeriodDurations.QUARTER
      } else if (key.includes('Year')) {
        dateParams.year = DateTime.fromMillis(Number(value) * 1000, {
          zone: 'utc',
        }).year
      }
    } else if (key === 'visual') {
      parsedParams[key] = value
    } else if (key === 'Total Amount') {
      parsedParams.totalAmount = value
    } else {
      parsedParams.filters[key] = value
    }
  }

  const parseVisualsParams = () => {
    queryParams.forEach((value, key) => {
      parseLinkOutParam(key, value)
    })
    parsedParams = generateDateParams(timeFrame, dateParams, parsedParams)
  }

  switch (visual) {
    case SpendInsightsAnnualVisuals.APPROVED_RFOS:
      parsedParams.name = spendInsightVisualNames.APPROVED_RFOS
      parsedParams.linkOut = SpendInsightsLinkOutTables.RFO
      break

    case SpendInsightsAnnualVisuals.APPROVED_RFO_VALUES:
      parsedParams.name = spendInsightVisualNames.APPROVED_RFO_VALUES
      parsedParams.linkOut = SpendInsightsLinkOutTables.RFO
      break

    case SpendInsightsAnnualVisuals.CREATED_POS:
      parsedParams.name = spendInsightVisualNames.CREATED_POS
      parsedParams.linkOut = SpendInsightsLinkOutTables.PO
      break

    case SpendInsightsAnnualVisuals.PAYMENT_TERMS_BY_COMMITTED_SPEND:
      parsedParams.name =
        spendInsightVisualNames.PAYMENT_TERMS_BY_COMMITTED_SPEND
      parsedParams.linkOut = SpendInsightsLinkOutTables.SPEND
      break

    case SpendInsightsAnnualVisuals.REJECTED_RFOS:
      parsedParams.name = spendInsightVisualNames.REJECTED_RFOS
      parsedParams.linkOut = SpendInsightsLinkOutTables.RFO
      break

    case SpendInsightsAnnualVisuals.REJECTED_RFO_VALUES:
      parsedParams.name = spendInsightVisualNames.REJECTED_RFO_VALUES
      parsedParams.linkOut = SpendInsightsLinkOutTables.RFO
      break

    case SpendInsightsAnnualVisuals.SPEND_TREND:
    case SpendInsightsQuarterlyVisuals.SPEND_TREND:
    case SpendInsightsMonthlyVisuals.SPEND_TREND:
      parsedParams.name = spendInsightVisualNames.SPEND_TREND
      parsedParams.linkOut = SpendInsightsLinkOutTables.SPEND
      break

    case SpendInsightsAnnualVisuals.TOP_5_DEPARTMENTS:
      parsedParams.name = spendInsightVisualNames.TOP_5_DEPARTMENTS
      parsedParams.linkOut = SpendInsightsLinkOutTables.SPEND
      break

    case SpendInsightsAnnualVisuals.TOP_5_ITEMS:
      parsedParams.name = spendInsightVisualNames.TOP_5_ITEMS
      parsedParams.linkOut = SpendInsightsLinkOutTables.SPEND
      break

    case SpendInsightsAnnualVisuals.TOP_5_VENDORS:
      parsedParams.name = spendInsightVisualNames.TOP_5_VENDORS
      parsedParams.linkOut = SpendInsightsLinkOutTables.SPEND
      break

    default:
      toast.error({
        title: intl.formatMessage(messages.linkOutFilterErrorTitle),
        children: intl.formatMessage(messages.linkOutFilterErrorMessage),
      })

      Sentry.withScope((scope) => {
        scope.setLevel(Sentry.Severity.Warning)
        Sentry.captureException({
          message: `An invalid ThoughtSpot GUID was passed. This could be due to a dev not updating the enum or changing the TS GUID accidentally.`,
          originalException: `Invalid visual GUID: ${visual}`,
        })
      })
      return new TypeError(`Invalid visual GUID: ${visual}`)
  }
  parseVisualsParams()
  return parsedParams
}
