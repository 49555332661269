import * as React from 'react'

import { type RouteComponentProps } from 'react-router'

import { Page } from '@webapp/core/components/Page'
import { useProcurifyIntl } from '@webapp/hooks'
import { useAppRoutes } from '@webapp/hooks/useAppRoutes'
import messages from './messages'

export interface BillRequestDetailsPageParams {
  uuid: uuid
}

const BillRequestDetailsViewChunk = React.lazy(() =>
  import('../containers/BillRequestDetailsView').then(
    ({ BillRequestDetailsView }) => ({
      default: BillRequestDetailsView,
    })
  )
)

export const BillRequestDetailsPage = (
  props: RouteComponentProps<BillRequestDetailsPageParams>
) => {
  const intl = useProcurifyIntl()
  const { url } = useAppRoutes()

  const path = url('BillListPage', {
    status: 'draft',
  })

  return (
    <Page
      pageTitle={intl.formatMessage(messages.billDetailsPageTitle)}
      breadcrumbs={[
        {
          link: url('BillListPage', {
            status: 'draft',
          }),
          name: intl.formatMessage(messages.manageBillsPageTitle),
        },
        {
          name: intl.formatMessage(messages.billDetailsPageTitle),
        },
      ]}
    >
      <BillRequestDetailsViewChunk {...props} />
    </Page>
  )
}
