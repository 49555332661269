import styled, { keyframes } from 'styled-components'

const zoopIn = keyframes`
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`

const zoopOut = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
`
const trackProgress = keyframes`
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
`

interface NotificationWrapperStyledProps {
  offsetTop: React.CSSProperties['top']
}

export const NotificationWrapperStyled = styled.div<NotificationWrapperStyledProps>`
  box-sizing: border-box;
  max-height: 100%;
  overflow: hidden;
  padding: ${(props) => props.theme.space.small};
  pointer-events: auto;
  position: fixed;
  top: ${(props) => props.offsetTop};
  right: 0;
  z-index: ${(props) => props.theme.zIndex.notification};
  font-weight: initial;
  text-transform: initial;

  .Toastify__progress-bar {
    animation: ${trackProgress} linear 1;
  }
  .zoopIn {
    animation-name: ${zoopIn};
  }
  .zoopOut {
    animation-name: ${zoopOut};
  }

  .Toastify__toast-container > div {
    color: initial;
    line-height: initial;
    background: transparent;
    box-shadow: none;
    margin-bottom: ${(props) => props.theme.space.small};
    padding: ${(props) => props.theme.space.xsmall};
  }
`
NotificationWrapperStyled.displayName = 'NotificationWrapperStyled'
