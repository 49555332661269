import { defineMessages } from 'react-intl'

const scope = `procurify.order.features.getBreadcrumbsPresenter`

export default defineMessages({
  orderDetailsBreadcrumb: {
    id: `${scope}.orderDetailsBreadcrumb`,
    defaultMessage: 'Order details',
  },
  orderStatusBreadcrumb: {
    id: `${scope}.orderStatusBreadcrumb`,
    defaultMessage: 'Order status',
  },
  orderApprovalBreadcrumb: {
    id: `${scope}.orderApprovalBreadcrumb`,
    defaultMessage: 'Order approval',
  },
  createPurchaseOrderBreadcrumb: {
    id: `${scope}.createPurchaseOrderBreadcrumb`,
    defaultMessage: 'Create purchase order',
  },
  purchaseOrderListBreadcrumb: {
    id: `${scope}.purchaseOrderListBreadcrumb`,
    defaultMessage: 'Purchase orders',
  },
  purchaseOrderDetailsBreadcrumb: {
    id: `${scope}.purchaseOrderDetailsBreadcrumb`,
    defaultMessage: 'Purchase order details',
  },
  receiveListBreadcrumb: {
    id: `${scope}.receiveListBreadcrumb`,
    defaultMessage: 'PO receiving',
  },
  receiveDetailsBreadcrumb: {
    id: `${scope}.receiveDetailsBreadcrumb`,
    defaultMessage: 'PO receive details',
  },
})
