import * as React from 'react'

import { ClickAwayListener } from '@material-ui/core'
import { type DisplayProps, type SizingProps } from '@material-ui/system'

import {
  type IPopoverTooltipStyledProps,
  PopoverTooltipStyled,
} from './styles/MuiPopoverStyled'
import { PopoverTriggerStyled } from './styles/PopoverTriggerStyled'
import { type IUIProviderValue, UIContext } from '../../core/UIContext'

export interface WithPopoverChildrenProps {
  state: WithPopoverState
  reposition: () => void
  dismiss: () => void
  open: () => void
}

export interface IWithPopoverProps
  extends Omit<Partial<IPopoverTooltipStyledProps>, 'children' | 'content'> {
  children?: React.ReactNode
  content:
    | ((args: WithPopoverChildrenProps) => React.ReactElement<any>)
    | React.ReactElement<any>
  trigger?: 'hover' | 'click' | 'focus' | 'contextmenu'
  TooltipProps?: SizingProps
  TriggerProps?: DisplayProps
  setOpen?: (newOpen) => void
  isDisabled?: boolean
}

const initialState = {
  open: false,
}

type WithPopoverState = Readonly<typeof initialState>

export class WithPopover extends React.Component<
  IWithPopoverProps,
  WithPopoverState
> {
  static defaultProps = {
    trigger: 'hover',
    interactive: true,
  }
  static contextType = UIContext
  context: IUIProviderValue

  readonly state: WithPopoverState = {
    open: false,
  }

  togglePopover = () =>
    this.setState((state: WithPopoverState) => ({ open: !state.open }))
  showPopover = () => this.setState({ open: true })
  hidePopover = () => this.setState({ open: false })

  onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    this.togglePopover()
  }

  handleClickAway = (e) => {
    this.hidePopover()
  }

  onContextMenu = (e) => {
    e.preventDefault()
    this.showPopover()
  }

  reposition = () => {
    this.setState((state) => state)
  }

  render() {
    const {
      children,
      trigger,
      content,
      TooltipProps,
      TriggerProps,
      isDisabled,
      ...otherProps
    } = this.props

    const { container } = this.context

    const finalContent = typeof content !== 'function' ? () => content : content

    const renderedContent = (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <div>
          {finalContent({
            reposition: this.reposition,
            state: this.state,
            dismiss: this.hidePopover,
            open: this.showPopover,
          })}
        </div>
      </ClickAwayListener>
    )

    const child = React.Children.only(this.props.children)

    const newChildProps: any = {
      key: 'trigger',
    }

    if (trigger === 'click') {
      otherProps.disableHoverListener = true
      otherProps.disableFocusListener = true
      otherProps.open = this.state.open
      newChildProps.onClick = this.onClick
    } else if (trigger === 'focus') {
      otherProps.disableHoverListener = true
    } else if (trigger === 'contextmenu') {
      otherProps.disableHoverListener = true
      otherProps.disableFocusListener = true
      otherProps.open = this.state.open
      newChildProps.onContextMenu = this.onContextMenu
    }

    const newChild = React.cloneElement(
      child as React.ReactElement,
      newChildProps
    )
    return isDisabled ? (
      newChild
    ) : (
      <PopoverTooltipStyled
        title={renderedContent}
        {...TooltipProps}
        {...otherProps}
        PopperProps={{
          container,
        }}
      >
        <PopoverTrigger {...TriggerProps}>{newChild}</PopoverTrigger>
      </PopoverTooltipStyled>
    )
  }
}

export const PopoverTrigger = React.forwardRef<
  HTMLDivElement,
  Omit<React.HTMLProps<HTMLDivElement>, 'as' | 'ref'> & DisplayProps
>((props, ref) => {
  const {
    children,
    className,
    onBlur,
    onFocus,
    onMouseLeave,
    onMouseOver,
    onTouchEnd,
    onTouchStart,
    title,
    ...otherProps
  } = props
  return (
    <PopoverTriggerStyled
      className={className}
      onBlur={onBlur}
      onFocus={onFocus}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      title={title}
      ref={ref as React.RefObject<HTMLDivElement>}
      {...otherProps}
    >
      {children}
    </PopoverTriggerStyled>
  )
})
