import * as React from 'react'

import { FeedContentStyled } from './styles/FeedContentStyled'
import { FeedLabelStyled } from './styles/FeedLabelStyled'
import { FeedRowStyled } from './styles/FeedRowStyled'

export interface IFeedRowProps {
  bullet?: React.ReactNode
}

export class FeedRow extends React.PureComponent<IFeedRowProps> {
  static defaultProps = {}

  render() {
    const { children, bullet } = this.props
    return (
      <FeedRowStyled>
        <div>
          {bullet && <FeedLabelStyled>{bullet}</FeedLabelStyled>}
          <FeedContentStyled>{children}</FeedContentStyled>
        </div>
      </FeedRowStyled>
    )
  }
}
