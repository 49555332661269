import * as React from 'react'

import { useLocation } from 'react-router'

import { useLocationHelper } from '@webapp/hooks'
import { CONFIGS_BY_ID } from './pagination.config'
import {
  type PaginationStateById,
  type PaginationID,
  type PaginationParamsById,
  type PaginationState,
  type PaginationParams,
} from './pagination.types'

export interface PaginationContextValues {
  updatePage: (id: PaginationID, page: number) => void
  updatePageSize: (id: PaginationID, pageSize: number) => void
  updateNumPages: (id: PaginationID, numPages: number) => void
  updateSearch: (id: PaginationID, search: string) => void
  updateOrderBy: (id: PaginationID, orderBy: string) => void
  updateParams: (id: PaginationID, params: Partial<PaginationParams>) => void

  reset: (id: PaginationID) => void
  getPaginationParamsById: (id: PaginationID) => PaginationParams
  getPaginationStateById: (id: PaginationID) => PaginationState
}

const DEFAULT_PAGINATION_CONTEXT: PaginationContextValues = {
  updatePage: () => undefined,
  updatePageSize: () => undefined,
  updateNumPages: () => undefined,
  updateSearch: () => undefined,
  updateOrderBy: () => undefined,
  updateParams: () => undefined,

  reset: () => undefined,
  getPaginationParamsById: () => ({ page: 1, pageSize: 10 }),
  getPaginationStateById: () => ({ numPages: 1 }),
}

export const PaginationContext = React.createContext<PaginationContextValues>(
  DEFAULT_PAGINATION_CONTEXT
)

export const PaginationProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [paginationStateById, setPaginationStateByid] =
    React.useState<PaginationStateById>({})

  const [paginationParamsById, setPaginationParamsByid] =
    React.useState<PaginationParamsById>({})

  const location = useLocation()
  const { modifySearch, getPaginationParams } = useLocationHelper()

  const updatePaginationParams = (
    id: PaginationID,
    updateFn: (params: PaginationParams) => PaginationParams
  ) => {
    const { defaultPagination, mode = 'url' } =
      CONFIGS_BY_ID[id as PaginationID] || {}

    if (mode === 'url') {
      const { order_by, page, page_size, search } = getPaginationParams()

      const newParams = updateFn({
        page: page || defaultPagination?.page || 1,
        pageSize: page_size || defaultPagination?.pageSize || 10,
        orderBy: order_by || defaultPagination?.orderBy,
        search: search || defaultPagination?.search,
      })

      const newUrlParams = {
        page: newParams.page,
        page_size: newParams.pageSize,
        order_by: newParams.orderBy,
        search: newParams.search,
      }

      modifySearch(newUrlParams)

      setPaginationParamsByid((prev) => ({
        ...prev,
        [id]: newParams,
      }))
    } else {
      const newParams = updateFn(
        paginationParamsById[id] || { page: 1, pageSize: 10 }
      )

      setPaginationParamsByid((prev) => ({
        ...prev,
        [id]: newParams,
      }))
    }
  }

  const updatePaginationStateById = (
    id: PaginationID,
    updateFn: (state: PaginationState) => PaginationState
  ) => {
    const newState = updateFn(paginationStateById[id] || { numPages: 1 })

    setPaginationStateByid((prev) => ({
      ...prev,
      [id]: newState,
    }))
  }

  const updateNumPages = React.useCallback(
    (id: PaginationID, numPages: number) => {
      updatePaginationStateById(id, (prev) => ({ ...prev, numPages }))
    },
    [paginationStateById]
  )

  const updatePage = React.useCallback(
    (id: PaginationID, page: number) => {
      updatePaginationParams(id, (prev) => ({ ...prev, page }))
    },
    [paginationParamsById, location]
  )

  const updatePageSize = React.useCallback(
    (id: PaginationID, pageSize: number) => {
      updatePaginationParams(id, (prev) => ({ ...prev, pageSize }))
    },
    [paginationParamsById, location]
  )

  const updateSearch = React.useCallback(
    (id: PaginationID, search: string) => {
      updatePaginationParams(id, (prev) => ({ ...prev, search }))
    },
    [paginationParamsById, location]
  )

  const updateOrderBy = React.useCallback(
    (id: PaginationID, orderBy: string) => {
      updatePaginationParams(id, (prev) => ({ ...prev, orderBy }))
    },
    [paginationParamsById, location]
  )

  const updateParams = React.useCallback(
    (id: PaginationID, params: Partial<PaginationParams>) => {
      updatePaginationParams(id, (prev) => ({ ...prev, ...params }))
    },
    [paginationParamsById, location]
  )

  const reset = (id: PaginationID) => {
    const { defaultPagination } = CONFIGS_BY_ID[id as PaginationID] || {}

    updatePaginationParams(id, () => ({
      page: defaultPagination?.page || 1,
      pageSize: defaultPagination?.pageSize || 10,
      orderBy: defaultPagination?.orderBy,
      search: defaultPagination?.search,
    }))
  }

  const getPaginationParamsById = (id: PaginationID) => {
    const { mode, defaultPagination } = CONFIGS_BY_ID[id as PaginationID] || {}

    if (mode === 'url') {
      const { page, page_size, order_by, search } = getPaginationParams()

      return {
        page: page || defaultPagination?.page || 1,
        pageSize: page_size || defaultPagination?.pageSize || 10,
        orderBy: order_by || defaultPagination?.orderBy,
        search: search || defaultPagination?.search,
      }
    } else {
      return {
        page: paginationParamsById[id]?.page || defaultPagination?.page || 1,
        pageSize:
          paginationParamsById[id]?.pageSize ||
          defaultPagination?.pageSize ||
          10,
        orderBy:
          paginationParamsById[id]?.orderBy || defaultPagination?.orderBy,
        search: paginationParamsById[id]?.search || defaultPagination?.search,
      }
    }
  }

  const getPaginationStateById = (id: PaginationID) => {
    return {
      numPages: paginationStateById[id]?.numPages || 1,
    }
  }

  const paginationContext = React.useMemo<PaginationContextValues>(
    () =>
      ({
        updateNumPages,
        updatePage,
        updatePageSize,
        updateSearch,
        updateOrderBy,
        updateParams,
        reset,
        getPaginationParamsById,
        getPaginationStateById,
      }) satisfies PaginationContextValues,
    [paginationStateById, paginationParamsById, location]
  )

  return (
    <PaginationContext.Provider value={paginationContext}>
      {children}
    </PaginationContext.Provider>
  )
}
