import * as React from 'react'

import { Dialog } from '@material-ui/core'
import { type DialogProps } from '@material-ui/core/Dialog'
import styled, { css } from 'styled-components'

import { type IModalProps } from '../Modal'
import { CardStyled } from '@ui/elements/Card'

export interface IModalStyledProps extends DialogProps, IModalProps {}

export const ModalStyled = styled((props: IModalStyledProps) => {
  const { verticalAlign, ...otherProps } = props

  return (
    <Dialog
      {...otherProps}
      classes={{
        container: 'paper-container',
        paper: 'paper',
        paperWidthMd: 'paper-paperWidthMd',
        paperWidthSm: 'paper-paperWidthSm',
        paperWidthXl: 'paper-paperWidthXl',
      }}
    />
  )
})`
  font-size: ${(props) => props.theme.typography.pxToRem(16)};

  & .paper-container {
    padding: ${(props) =>
      props.fullScreen ? 0 : props.theme.typography.pxToRem(24)};
    overflow: auto;

    ${(props) => {
      if (props.verticalAlign === 'top') {
        return css`
          &:after {
            display: none;
          }
        `
      }
    }}
  }

  & .paper {
    margin: ${(props) => props.theme.typography.pxToRem(24)} auto;
    background-color: transparent;
    overflow: visible;

    ${(props) => {
      return css`
        vertical-align: ${props.verticalAlign};
      `
    }}
  }

  & .paper-paperWidthSm {
    min-width: ${(props) => props.theme.typography.pxToRem(640)};
  }

  & .paper-paperWidthMd {
    min-width: ${(props) => props.theme.typography.pxToRem(960)};
  }

  & .paper-paperWidthXl {
    min-width: ${(props) => props.theme.typography.pxToRem(1096)};
  }
`
ModalStyled.displayName = 'ModalStyled'
