export const CustomFieldErrorCodes = {
  FIELD_NAME_EXISTS: 'FIELD_NAME_EXISTS',
  TOO_MANY_FIELDS: 'TOO_MANY_FIELDS',
  UNSUPPORTED_FIELD_TYPE: 'UNSUPPORTED_FIELD_TYPE',
  CHANGE_CONTENT_TYPE: 'CHANGE_CONTENT_TYPE',
  INVALID_DROPDOWN_VALUE: 'INVALID_DROPDOWN_VALUE',
  INVALID_DATETIME_VALUE: 'INVALID_DATETIME_VALUE',
  INVALID_BOOLEAN_VALUE: 'INVALID_BOOLEAN_VALUE',
  SELECT_OPTIONS_REQUIRED: 'SELECT_OPTIONS_REQUIRED',
  DUPLICATE_FIELD_CHOICES: 'DUPLICATE_FIELD_CHOICES',
} as const
